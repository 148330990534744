import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Theme } from "../../config/aws-exports";
import { textAreaStyles } from "../../pages/ApplicationView/ApplicationView";

const Container = styled.div`
  p {
    font-size: 15px;
  }
  i {
    font-size: 14px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 2rem;
    font-weight: bold;
  }
`;
export default function ApplicationPopUp({ open, setOpen, state, name }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0 2.6rem",
          }}
        >
          <h3
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              color: Theme.color.black,
            }}
          >
            {name}
          </h3>
          <div>
            <IconButton onClick={handleClose}>
              <Close
                style={{
                  height: "30px",
                  width: "30px",
                }}
              />
            </IconButton>
          </div>
        </div>
        <DialogContent>
          <Container>
            <div
              style={{
                height: "80vh",
                width: "60vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h3>{state?.title}</h3>

                      <i>
                        {state?.emptype} &bull; {state?.loc}
                      </i>
                      <p style={{ display: "flex" }}>
                        {state?.no_opens} openings &bull;
                        {state?.app_count === 0 ? (
                          <>
                            <p style={{ color: "#0f80a4" }}>
                              {" "}
                              {state?.app_count} Application
                            </p>
                          </>
                        ) : (
                          <>
                            <p style={{ marginLeft: "0.8rem" }}>
                              {state?.app_count} Application
                            </p>
                          </>
                        )}
                      </p>
                      <p>Required experience: {state?.req_exp}</p>
                      <p>
                        CTC: {state?.ctc} {state?.ctype}
                      </p>
                      <p>
                        Status :{" "}
                        <span className={state?.status ? "open" : ""}>
                          {state?.status ? "open" : "close"}
                        </span>
                      </p>
                    </div>
                    <div style={{ alignSelf: "center" }}>
                      <p>
                        Expires on :{" "}
                        {moment(state?.op_end_date).format("DD MMM YYYY")}
                      </p>
                      <p>Posted by : {state?.postby}</p>
                    </div>
                  </div>
                  <div style={{ marginTop: "2rem" }}>
                    <h3>Job description &#58;</h3>
                    <textarea style={textAreaStyles} readOnly>
                      {state?.description}
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
