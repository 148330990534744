import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import React from "react";
import { Theme } from "../../config/aws-exports";

export default function ResumePopUp({ open, setOpen, src, name }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0 2.6rem",
          }}
        >
          <h3
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              color: Theme.color.black,
            }}
          >
            {name}
          </h3>
          <div>
            <IconButton onClick={handleClose}>
              <Close
                style={{
                  height: "30px",
                  width: "30px",
                }}
              />
            </IconButton>
          </div>
        </div>
        <DialogContent>
          <div
            style={{
              height: "80vh",
              width: "80vw",
              display: "grid",
              placeItems: "center",
            }}
          >
            <iframe src={src} frameborder="0" title="resume" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
