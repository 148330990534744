import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const ViewDoc = ({ handleAddClose, docFileName, docCurlFileName, type }) => {
  const classes = useStyles();
  const [src, setSrc] = useState();
  
  const ext = docFileName.split(".").pop(); // Get file extension
  const isPDF = ext === "pdf";
  
  useEffect(() => {
    
    if (type !== 'programs') {
      setSrc(`https://${Constants.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/dailyfile/${docFileName}`);
    } else {
      setSrc(`https://${Constants.DOMAIN
        }/${config?.aws_org_id?.toLowerCase()}-resources/curriculum/${docFileName}`);
    }
  }, []);

  return (
    <div style={{ width: "80%", background: "transparent", backgroundColor: "white", display: "grid" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "max-content" }}>
        <h3 style={{ padding: "10px" }}>{docFileName}</h3>

        <IconButton
          component="span"
          onClick={() => {
            handleAddClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px", height: "50rem", overflow: "scroll", overflowX: "hidden" }}>
        {docCurlFileName === undefined ? (
          <CardMedia
            style={{ height: "100%", width: "100%" }}
            className={classes.root}
            alt="ProfilePage"
            component="iframe"
            src={src}
          />
        ) : isPDF ? (
          <CardMedia
            style={{ height: "100%", width: "100%" }}
            className={classes.root}
            alt="ProfilePage"
            component="iframe"
            src={src}
          />
        ) : (
          <div>
            <img src={docCurlFileName} alt={docFileName} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewDoc;
