// Dependencies imports
import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import {  useDispatch,useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

// Local imports
import UserHeader from '../Header/UserHeader/UserHeader';
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { awsSignIn,authData } from "../../redux/auth/authSlice";
import { ReactComponent as Delete } from '../../assets/svg/delete_black_24dp.svg';
import AddAnnouncement from './AddAnnouncement';
import AddWebinar from './AddWebinar';
import DeleteAnnouncement from './DeleteAnnouncement';



//Styles imports
import Ann from './Announcement.module.scss';
import AnnouncementFile from './AnnouncementFile';

const header = {
    color: "white",
    fontFamily: "nunito",
    fontSize: "15px",
    fontWeight: "400px"
}

const programnames = {
    color: config.main_color_1,
    fontFamily: "nunito",
    fontSize: "18px",
    fontWeight: "500px"
}

const programtype = {
    color: "black",
    fontSize: "16px",
    fontWeight: "400px",
    fontFamily: "nunito",
    paddingBotton: "10px"
}

const batchnames = {
    color: Constants.app_dark_color,
    fontFamily: "nunito",
    fontSize: "12px",
    fontWeight: "500px",
}
const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: Constants.app_dark_color,
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
});

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

// Pagination function
function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

// Start of Announcement component
const Announcement = () => {
    const [tableData, setTableData] = useState([]);
    const [announcementData, setAnnouncementData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteEventData, setDeleteEventData] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openAnnouncment, setOpenAnnouncement] = useState(false);
    const [openWebinar, setOpenWebinar] = useState(false);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const[obj,setObj] = useState();
    const dispatch = useDispatch();


    let userDetails = useSelector(authData);
    const classes = useStyles();


    // Useeffect for api call
    useEffect(() => {
        getAnnouncement();
        breadcrumb();
        return () => { };
    }, []);

    const handleclickdelete = (item, index) => {
        // alert(JSON.stringify(item.announcid));
        setOpenDelete(true);
        setDeleteEventData(item.announcid);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleCloseAnnounce = () => {
        setOpenAnnouncement(false);
    };

    const handleCloseWebinar = () => {
        setOpenWebinar(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpenAnnouncement = () => {
        setOpenAnnouncement(true);
        handleClose();
    };

    const handleClickOpenWebinar = () => {
        setOpenWebinar(true);
        handleClose();
    };

    const handleFileOpen = () => {
        setOpen(true);
    };

    const handleFileClose = () => {
        setOpen(false);
    };

    // API call syntax to get batches
    async function getAnnouncement(programs) {
        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                rtype: "get"
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            console.log(bodyParam.body);
            const announcement = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.POST_ANNOUNCEMENT,
                bodyParam
            );
            console.log("announcement", JSON.stringify(announcement));
            setTableData(announcement.announcements);
            setAnnouncementData(announcement.announcements);
            setLoading(false);
        }
        catch (error) {
            console.error(error);
        }
    }

    function addLink(item) {
        setObj(item);
        handleFileOpen();
    };


    function tableview() {
        return (
            tableData.length >= 1 ?
                <TableBody>
                    {(rowsPerPage > 0
                        ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : tableData
                    ).map((item, index) => {
                        return (
                            <TableRow>
                                <TableCell style={programnames}>{item.title}</TableCell>
                                <TableCell style={programtype}>
                                    <div>
                                        {item.type !== 'web' ? <p>{item.description}</p> : null}
                                        {item.type !== 'web' ? <p  className={Ann.link} onClick={() => { addLink(item) }}>{item.filename}</p> : null}
                                        {item.type === 'web' ? <p>Webinar URL : <span  className={Ann.link}> <a  href={item.weburl} target="blank">{item.weburl} </a></span></p> : null}
                                        {item.type === 'web' ? <p>Webinar Details : {item.webtext}</p> : null}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => { handleclickdelete(item, index) }}>
                                        <Delete className={Ann.icon} />
                                    </div>

                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                :
                <TableBody>
                    <TableRow>
                        <TableCell style={programnames}></TableCell>
                        <TableCell style={programtype}><p style={{ paddingLeft: "25%" }}>No Data</p></TableCell>
                    </TableRow>
                </TableBody>
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[2] = {
            name:'Announcements',
            path:'/announcement'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    return (
        <div className={Ann.maincontainer}>
            <UserHeader Bindex={2}/>
            <div className={Ann.add}>
                <h3 className={Ann.header}>Announcements</h3>
                <div>
                    <button className={window.navigator.onLine === true ? Ann.btn_color : Ann.btn_colordis } disabled={!window.navigator.onLine} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        Add
                    </button> 
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        style={{marginTop: "40px"}}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClickOpenAnnouncement} style={{fontSize: "14px"}}>Announcement</MenuItem>
                        <MenuItem onClick={handleClickOpenWebinar} style={{fontSize: "14px"}}>Webinar</MenuItem>
                    </Menu>
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openDelete}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openDelete}>
                    <DeleteAnnouncement handleClose={handleCloseDelete} index={deleteEventData} getAnnouncement={getAnnouncement} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openWebinar}>
                    <AnnouncementFile handleFileClose={handleFileClose} obj = {obj} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openAnnouncment}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openAnnouncment}>
                    <AddAnnouncement handleClose={handleCloseAnnounce} announcementData={announcementData} getAnnouncement={getAnnouncement} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openWebinar}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openWebinar}>
                    <AddWebinar handleClose={handleCloseWebinar} announcementData={announcementData} getAnnouncement={getAnnouncement} />
                </Fade>
            </Modal>
            <div className={Ann.table}>
                {loading === false ? <TableContainer component={Paper}>
                    <Table>
                        <TableHead style={{ background: config.main_color_1 }}>
                            <TableRow>
                                <TableCell style={header}>
                                    Title
                                </TableCell>
                                <TableCell style={header}>Description</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        {loading ? '' : tableview()}
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    colSpan={3}
                                    count={tableData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Skeleton variant="rect" width={1500} height={500} /> </div>}
            </div>
        </div>
    );
};

export default Announcement;