// Dependencies imports
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// Style imports
import Lin from './LineChart.module.scss';
import { Constants } from '../../config/constants';
import config from "../../config/aws-exports";

// Component linechart
const LineChart = ({ data }) => {

    const [aclass, setAClass] = useState('week');
    const [labelDates, setLabelDates] = useState([]); // to passs in the days (week, month, year) for y-axis as labels to the graph
    const [gpData, setGpData] = useState([]); // to passs in the user count as data to the graph

    // To show the week data by default once the component mounts
    useEffect(() => {
        ConvertData(7);
        return () => { };
    }, []);


    function ConvertData(val) {

        let tarray = [];
        if (data.length > val) {
            tarray = data.slice(Math.max(data.length - val, 0));
        } else {
            tarray = data;
        }

        function Dates(stamp) {
            const d = new Date(stamp);
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            console.log(d.getDate() + " " + months[d.getMonth()])
            return (d.getDate() + " " + months[d.getMonth()]);
        }

        let tlabelDates = [];
        let tgpData = [];
        for (let i = 0; i < tarray.length; i++) {
            tlabelDates.push(Dates(tarray[i].TS));
            tgpData.push(tarray[i].UC);
        }

        console.log(tlabelDates);
        console.log(tgpData);
        setLabelDates(tlabelDates);
        setGpData(tgpData);
    }

    // data for graph
    const Gdata = {
        type: 'Line',
        animation: true,
        labels: labelDates,
        datasets: [
            {
                data: gpData,
                label: "No.of users",
                display: false,
                min: 0,
                max: 1,
                count: 1,
                fill: false,
                backgroundColor: '#fff',
                borderColor: config.main_color_1,
            },
        ],
    };

    const options = {
        legend: {
            display: true,
            position: "top",
            align: "end",
            maintainAspectRatio: false
        }
    };

    // Inline styles for the main div
    const styles = {
        display: "flex",
        alignContent: "flex-start"
    }

    const toggleClass = (val) => {
        setAClass(val);
    };

    return (
        <div>
            <div style={styles}>
                <div>
                    <button className={aclass === 'week' ? Lin.graphbuttonactive : Lin.graphbutton} onClick={() => { toggleClass('week'); ConvertData(7) }}>Week</button>
                    <button className={aclass === 'Month' ? Lin.graphbuttonactive : Lin.graphbutton} onClick={() => { toggleClass('Month'); ConvertData(30) }}>Month</button>
                    <button className={aclass === 'Year' ? Lin.graphbuttonactive : Lin.graphbutton} onClick={() => { toggleClass('Year'); ConvertData(365) }}>Year</button>
                </div>
            </div>
            <div style={{ width: "100%", height: "fit-content", padding: 50 }}>
                <Line data={Gdata} height={50} width={200} options={options} />
            </div>
        </div>
    );

};

export default LineChart;