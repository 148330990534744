import React, { useState } from "react";
import Content from "../components/Programs/Content";
import Aside from "../modules/SideNav/Aside";
import CareerDashboard from "../pages/CareerDashboard/CareerDashboard";

function CareerDashboardLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      /> */}
      <CareerDashboard handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default CareerDashboardLayout;
