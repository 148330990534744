
import React, { useState, useEffect, useContext  } from 'react';
//import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import Skeleton from "@material-ui/lab/Skeleton";
import swal from 'sweetalert';
// Local imports
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
//import { Constants } from "../../config/constants";
import Fac from '../Programs/Faculty.module.scss';

import QuestionsList from './ViewQuestions';
import feedbackContext from './CreateContext';

const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: config.main_color_1,
            fontSize: "16px",
            color: "#fff",
            fontFamily: "nunito"
        },
    },
    root1: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    }
});

// Start of Coordinator Grid Component
const AddCdirectorFeedBack = (props) => {
   
    //const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
  
    let userDetails = useSelector(authData);
    let { Cdfeedback,  floading, setFloading, setInFeedback, getCourseList } = props;
    // Useeffect for coordinator details api
    useEffect(() => {
        //feedback.sfd()
      
        return () => { };
    }, []);
  
    const handleClickOpenIn = () => {
        setOpen(true);
      }
   

    return (
        <div style={{ height: 500, width: '100%' }}>
      
            <div className={Fac.buttonholder}>
         {(userDetails.role == 'Admin' ) ? <button className={window.navigator.onLine === true ? Fac.btn_color : Fac.btn_colordis } 
         disabled={!window.navigator.onLine} onClick={handleClickOpenIn}>Add</button> : null } 
          </div>
            <div style={{ height: 450, width: '100%' }} className={classes.root}>
                {!floading ?
                    <>
                           <QuestionsList qlist={Cdfeedback} htype='3' qOpen={open} 
                           setQopen={setOpen} floading={floading} 
                           setFloading={setFloading}  getCourseList={getCourseList}/>
                    </> : 
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>
                        <Skeleton variant="rect" width="100%" height={350} />
                         </div>
                        }
            </div>
        </div >
    );
}

export default AddCdirectorFeedBack;