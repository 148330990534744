// Dependencies imports
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { Card, CardMedia, Typography, CardContent } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import Skeleton from "@material-ui/lab/Skeleton";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";

// Styles imports
import Cont from "./Content.module.scss";

// Start of Content Component
const Content = () => {
  const [progDetails, setProgDetails] = useState({});
  const [isLoading, setLoading] = useState(true);

  let userDetails = useSelector(authData);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getProgDetails();
    breadcrumb();
  }, []);

  async function getProgDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.eid = userDetails.eid;
    }
    try {
     
      const PDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSELIST,
        bodyParam
      );
    
      setProgDetails(PDetails);
      setLoading(false);
      console.log(PDetails);
    } catch (error) {
     
      console.error(error);
    }
  }

  const enableDisablePost = async (item, idx) => {
    
    setLoading(true);
    const bodyParam = {
      body: {
        tid: item.cid,
        oid: config.aws_org_id,
        btid: userDetails.curprgcou.bpid,
        bpid: userDetails.curprgcou.bpid,
        enable: !item.enable,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/enableCourse",
        bodyParam
      );
      progDetails.courses[idx].enable = !item.enable;
      setProgDetails(progDetails);
      setLoading(false);
      
    } catch (error) {
      console.log("enableNuggetserre", error);
    }
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Content",
      path: "/Content",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  function titleclick(data) {
    let sdata = { ...userDetails };
    
    sdata.seminfo = { tlabel: data.tlabel, semester: data.semester };
    let oldcpr = sdata.curprgcou;
    
    sdata.curprgcou = { ...data };
    sdata.curprgcou.bpid = oldcpr.bpid;
    sdata.curprgcou.bid = oldcpr.bpid;
    sdata.curprgcou.bname = oldcpr.bname;
    sdata.curprgcou.lname = oldcpr.lname;
    sdata.curprgcou.disname = oldcpr.disname;
    sdata.curprgcou.sname = oldcpr.sname;
    sdata.curprgcou.cname = oldcpr.cname;
    sdata.curprgcou.startdate = oldcpr.startdate;
    sdata.curprgcou.enddate = oldcpr.enddate;
    sdata.curprgcou.ctype = oldcpr.ctype;
    sdata.curprgcou.zone = oldcpr.zone;
    sdata.curprgcou.ptitle = oldcpr.ptitle;
    sdata.curprgcou.pid = oldcpr.pid;
    sdata.curprgcou.apr_status = oldcpr.apr_status;
    sdata.curprgcou.fdstatus = oldcpr.fdstatus;
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Course View",
      path: "/courseView",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));

    navigate("/courseView");
  }

  function onClickOfPage(name, path) {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: name,
      path: path,
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  return (
    <div className={Cont.maincontainer}>
      <UserHeader Bindex={2} />
      <div className={Cont.topbar}>
        <h3 className={Cont.header}>Content</h3>
        {userDetails.role === 'Admin' && (<button
          className={
            window.navigator.onLine === true
              ? Cont.btn_color
              : Cont.btn_colordis
          }
          disabled={!window.navigator.onLine}
          onClick={() => {
            navigate("/courseCreation");
            onClickOfPage("Course Creation", "/courseCreation");
          }}
        >
          Create course
        </button>)}
      </div>

      <div className={Cont.cardholder}>
        {isLoading && (
          <>
            {["h1", "h1", "h1"].map((item) => (
              <>
                <Card style={{ height: "300px" }}>
                  <CardMedia>
                    <div className={Cont.image}>
                      <Skeleton variant="rect" height={130} />
                    </div>
                  </CardMedia>
                  <CardContent style={{ padding: "10px" }}>
                    <h1 className={Cont.coursename}>
                      <Typography component="div" variant="h1">
                        <Skeleton />
                      </Typography>
                      <Typography component="div" variant="h3">
                        <Skeleton />
                      </Typography>
                    </h1>
                  </CardContent>
                </Card>
              </>
            ))}
          </>
        )}
        {progDetails.courses !== undefined &&
          progDetails.courses.length > 0 &&
          !isLoading ? (
          progDetails.courses.map((item, idx) => (
            <div className={Cont.cards}>
              <Card style={{ height: "300px" }}>
                <CardMedia>
                  <img
                    className={Cont.image}
                    src={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${userDetails.curprgcou.pid
                      }.png`}
                    alt="program"
                  />
                </CardMedia>
                <CardContent style={{ padding: "10px", textAlign: "center" }}>
                  <h2 className={Cont.coursename}>{item.tlabel}</h2>
                  <h3
                    className={Cont.coursecontent}
                    onClick={() => titleclick(item)}
                  >
                    {item.title.substring(0, 40)}
                    {item.title.length > 40 ? "..." : null}
                  </h3>

                  {userDetails.role === 'Admin' && (
                    <button
                      className={Cont[window.navigator.onLine ? 'disablebutton' : 'btn_colordisa']}
                      disabled={!window.navigator.onLine}
                      onClick={() => enableDisablePost(item, idx)}
                    >
                      Click to {item.enable ? 'Disable' : 'Enable'}
                    </button>
                  )}
                </CardContent>
              </Card>
            </div>
          ))
        ) : (
          <>{!isLoading && <p className={Cont.emptypage}>No content</p>}</>
        )}
      </div>
    </div>
  );
};

export default Content;
