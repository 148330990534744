import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import config from "../../config/aws-exports";
import { API, Auth } from "aws-amplify";
import { produce } from "immer";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { Theme } from "../../config/aws-exports";
import swal from "sweetalert";
import UserHeader from "../../components/Header/UserHeader/UserHeader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate, Outlet, useMatch } from "react-router-dom";
import Swal from "sweetalert2";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import state from "sweetalert/typings/modules/state";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const useStyles = makeStyles((theme) => ({
  root: {
    background: Theme.color.white,
    fontSize: "14px",
  },
  iconButton: {
    "&.MuiIconButton-root": {
      padding: 0,
      "&.MuiIconButton-label > .MuiSvgIcon-root": {
        fontSize: "2.5rem !important",
      },
    },
  },
  selected: {
    color: Theme.color.white,
    background: Theme.color.secondary,
  },
  textColorPrimary: {
    color: Theme.color.black,
    background: Theme.color.white,
  },
}));
export const ButtonPrimary = {
  color: Theme.color.white,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  background: Theme.color.primary,
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
const ButtonSecondary = {
  color: Theme.color.primary,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
const ButtonTernary = {
  color: Theme.color.secondary,
  borderColor: Theme.color.secondary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.secondary,
    borderColor: Theme.color.secondary,
    textTransform: "unset",
  },
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ProfileApprovals = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const matchApprovals = useMatch({
    path: "/profileapprovals",
    caseSensitive: true,
  });
  const [text, setText] = useState("");
  const [profileData, setProfileData] = useState();
  const [jobAppData, setJobAppData] = useState();
  const [loading, setLoading] = useState(true);
  // const [aploading, setApLoading] = useState(true);

  const [checked, setChecked] = useState(true);
  const classes = useStyles();
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, ind, type) => {
    // setChecked(event.target.checked);
    if (type === "pf") {
      setProfileData((currentFile) =>
        produce(currentFile, (draft) => {
          draft.profile[ind].checked =
            draft.profile[ind].checked === true ? false : true;
        })
      );
    } else {
      setJobAppData((currentFile) =>
        produce(currentFile, (draft) => {
          draft[ind].checked = draft[ind].checked === true ? false : true;
        })
      );
    }
  };
  useEffect(() => {
    getProfileData();
    // getJobApplicationData();
  }, []);
  async function getProfileData() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: 0,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("profilebody==", bodyParam.body);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getProfileApprovals",
        bodyParam
      );
      setProfileData(response.body);
      console.log("responsebody===", response.body);
      // if (
      //   res?.body?.stddtl?.profile_img !== undefined &&
      //   res?.body?.stddtl?.profile_img !== null
      // ) {
      //   var extype = res?.body?.stddtl?.profile_img.slice(
      //     (Math.max(0, res?.body?.stddtl?.profile_img.lastIndexOf(".")) ||
      //       Infinity) + 1
      //   );
      //   let primg1 = `https://${
      //     config.DOMAIN1
      //   }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
      //     res.body.stddtl.eid
      //   }/crprofile/profile.${extype}`;
      //   setPrimg(primg1);
      // }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  // async function getJobApplicationData() {
  //   const bodyParam = {
  //     body: {
  //       action: 0,
  //       ptype: "dash",
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };
  //   try {
  //     const response = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       "/getJobApplications",
  //       bodyParam
  //     );
  //     setJobAppData(response.body);

  //     setApLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const profileApprovalAPI = async (stdid, stdstatus, rejectText) => {
    const bodyParam = {
      body: {
        atype: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    bodyParam.body.stdid = stdid;
    bodyParam.body.stdstatus = stdstatus;
    if (stdstatus === 2) {
      bodyParam.body.rjstatus = rejectText;
    } else {
      bodyParam.body.rjstatus = null;
    }

    try {
      console.log("appbody==", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/approvalProfileOrApp",
        bodyParam
      );

      // setApLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text:
          "Profile " +
          (stdstatus === 1 ? "approval " : "rejected ") +
          "successfully",
        showConfirmButton: true,
      }).then((ok) => {
        if (ok.isConfirmed) {
          getProfileData();
        }
      });
      // getProfileData();
      // getJobApplicationData();
    } catch (error) {
      console.error(error);
    }
  };
  const appOrRejrHandle = async (type, stdstatus) => {
    let stdid = [];

    const checked = profileData?.profile?.filter((pro) => pro.checked === true);
    if (checked.length === 0) {
      swal({
        title: "oops.!",
        text: "Please select profile to reject or approve",
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    for (let i = 0; i < profileData?.profile?.length; i++) {
      if (profileData?.profile[i].checked) {
        stdid.push(profileData?.profile[i].std_id);
      }
    }
    // setApLoading(true);
    if (stdstatus === 2) {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Rejection Reason",
        inputPlaceholder: "Type in the reason for rejection",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        preConfirm: (value) => {
          if (!value) {
            Swal.showValidationMessage(" Your response is required");
          }
        },
      });
      if (text) {
        profileApprovalAPI(stdid, stdstatus, text);
      } else {
        return;
      }
    } else {
      profileApprovalAPI(stdid, stdstatus, null);
    }
    // console.log("approvalProfileOrApp===",stdid);

    // profileApprovalAPI(stdid, stdstatus, null);
  };
  return (
    <main>
      <UserHeader />
      {matchApprovals !== null && matchApprovals !== undefined && (
        <div style={{ width: "100%" }}>
          <div style={{ borderBottom: "1rem", borderColor: "black" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Waiting Approvals"
                {...a11yProps(0)}
                className={`${classes.selected} ${classes.root}`}
              />
              <Tab
                label="Approved"
                {...a11yProps(1)}
                className={`${classes.selected} ${classes.root}`}
              />
              <Tab
                label="Rejected"
                {...a11yProps(2)}
                className={`${classes.selected} ${classes.root}`}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            {profileData?.profile !== undefined && (
              <>
                {!profileData?.profile?.find(
                  (pro) => pro.profile_status === 1
                ) && (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      There is no profile data yet.
                    </h1>
                  </div>
                )}
                {profileData?.profile?.find(
                  (pro) => pro.profile_status === 1
                ) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "20px",
                    }}
                  >
                    <Button
                      style={ButtonSecondary}
                      variant="outlined"
                      size="small"
                      onClick={() => appOrRejrHandle("profile", 2)}
                    >
                      Reject
                    </Button>
                    <Button
                      style={ButtonPrimary}
                      variant="contained"
                      size="small"
                      onClick={() => appOrRejrHandle("profile", 1)}
                    >
                      Approve
                    </Button>
                  </div>
                )}
                {profileData?.profile?.map((pfdt, index) => {
                  let primg1;
                  if (
                    pfdt.profile_img !== undefined &&
                    pfdt.profile_img !== null
                  ) {
                    var extype = pfdt.profile_img.slice(
                      (Math.max(0, pfdt.profile_img.lastIndexOf(".")) ||
                        Infinity) + 1
                    );
                    primg1 = `https://${
                      config.DOMAIN1
                    }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
                      pfdt.eid
                    }/crprofile/profile.${extype}`;
                  }
                  return (
                    pfdt.profile_status === 1 && (
                      <div
                        style={{
                          boxShadow: Theme.boxShadow,
                          padding: "25px",
                          marginTop: "20px",
                          display: "flex",
                        }}
                      >
                        <Avatar
                          style={{ height: "90px", width: "90px" }}
                          src={primg1}
                          alt="name"
                        />
                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <h3 style={{ fontWeight: "bold" }}>
                              {pfdt.std_name}
                            </h3>
                            {pfdt.pname && (
                              <FiberManualRecordIcon
                                style={{ height: "10px", width: "10px" }}
                              />
                            )}
                            <p style={{ paddingLeft: "5px" }}>{pfdt.pname}</p>
                          </div>
                          {pfdt.grade && (
                            <div style={{ display: "flex", gap: "10px" }}>
                              <p>Grade :</p>
                              <h3 style={{ fontWeight: "bold" }}>
                                {pfdt.grade}/10
                              </h3>
                            </div>
                          )}
                          <p>Headline- {pfdt.head_line}</p>
                        </div>
                        {/* <div style={{ borderLeft: "2px solid #000" }} /> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "15px",
                            marginLeft: "14px",
                            gap: "10px",
                          }}
                        >
                          <div
                            className={classes.root}
                            style={{
                              background: pfdt.checked
                                ? `${Theme.color.primary}`
                                : "",
                              padding: "3px 16px 3px 9px",
                              borderRadius: "4px",
                              border: pfdt.checked
                                ? `2px solid ${Theme.color.primary}`
                                : `2px solid ${Theme.color.primary}`,
                              color: pfdt.checked
                                ? Theme.color.white
                                : Theme.color.primary,
                              fontWeight: "600",
                            }}
                          >
                            <Checkbox
                              {...label}
                              checked={pfdt.checked ? true : false}
                              onChange={(event) =>
                                handleChange(event, index, "pf")
                              }
                              className={classes.iconButton}
                              style={{
                                color: pfdt.checked
                                  ? Theme.color.white
                                  : Theme.color.primary,
                              }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {pfdt.checked ? "Selected" : "Select"}
                            </span>
                          </div>
                          <Button
                            style={ButtonTernary}
                            variant="outlined"
                            onClick={() => {
                              navigate("profile", { state: pfdt });
                            }}
                          >
                            View profile
                          </Button>
                        </div>
                      </div>
                    )
                  );
                })}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {profileData !== undefined && (
              <>
                {profileData?.profile?.find(
                  (pro) => pro.profile_status === 2
                ) ? (
                  ""
                ) : (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      There is no approved data yet.
                    </h1>
                  </div>
                )}
                {profileData?.profile?.map(
                  (pfdt, index) =>
                    pfdt.profile_status === 2 && (
                      <div
                        style={{
                          boxShadow: Theme.boxShadow,
                          padding: "25px",
                          marginTop: "20px",
                          display: "flex",
                        }}
                      >
                        <Avatar
                          style={{ height: "90px", width: "90px" }}
                          alt="name"
                        />
                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <h3 style={{ fontWeight: "bold" }}>
                              {pfdt.std_name}
                            </h3>
                            {pfdt.pname && (
                              <FiberManualRecordIcon
                                style={{ height: "10px", width: "10px" }}
                              />
                            )}
                            <p style={{ paddingLeft: "5px" }}>{pfdt.pname}</p>
                          </div>
                          {pfdt.grade && (
                            <div style={{ display: "flex", gap: "10px" }}>
                              <p>Grade :</p>
                              <h3 style={{ fontWeight: "bold" }}>
                                {pfdt.grade}/10
                              </h3>
                            </div>
                          )}
                          <p>Headline- {pfdt.head_line}</p>
                        </div>
                        {/* <div style={{ borderLeft: "2px solid #000" }} /> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "15px",
                            marginLeft: "14px",
                            gap: "10px",
                          }}
                        >
                          <Button
                            style={ButtonTernary}
                            variant="outlined"
                            onClick={() => {
                              navigate("profile", { state: pfdt });
                            }}
                          >
                            View profile
                          </Button>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {profileData !== undefined && (
              <>
                {profileData?.profile?.find(
                  (pro) => pro.profile_status === 3
                ) ? (
                  ""
                ) : (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      There is no rejected data yet.
                    </h1>
                  </div>
                )}
                {profileData?.profile?.map(
                  (pfdt, index) =>
                    pfdt.profile_status === 3 && (
                      <div
                        style={{
                          boxShadow: Theme.boxShadow,
                          padding: "25px",
                          marginTop: "20px",
                          display: "flex",
                        }}
                      >
                        <Avatar
                          style={{ height: "90px", width: "90px" }}
                          alt="name"
                        />
                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <h3 style={{ fontWeight: "bold" }}>
                              {pfdt.std_name}
                            </h3>
                            {pfdt.pname && (
                              <FiberManualRecordIcon
                                style={{ height: "10px", width: "10px" }}
                              />
                            )}
                            <p style={{ paddingLeft: "5px" }}>{pfdt.pname}</p>
                          </div>
                          {pfdt.grade && (
                            <div style={{ display: "flex", gap: "10px" }}>
                              <p>Grade :</p>
                              <h3 style={{ fontWeight: "bold" }}>
                                {pfdt.grade}/10
                              </h3>
                            </div>
                          )}
                          <p>Headline- {pfdt.head_line}</p>
                        </div>
                        {/* <div style={{ borderLeft: "2px solid #000" }} /> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "15px",
                            marginLeft: "14px",
                            gap: "10px",
                          }}
                        >
                          <Button
                            style={ButtonTernary}
                            variant="outlined"
                            onClick={() => {
                              navigate("profile", { state: pfdt });
                            }}
                          >
                            View profile
                          </Button>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </TabPanel>
          <Backdrop
            open={loading}
            style={{ zIndex: "1500", backgroundColor: "rgba(0, 0, 0, 0.75)" }}
          >
            <CircularProgress style={{ color: Theme.color.secondary }} />
          </Backdrop>
        </div>
      )}
      <Outlet />
    </main>
  );
};

export default ProfileApprovals;
