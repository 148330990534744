import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert";
import { produce } from "immer";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import GroupRadio from "./GroupRadio";
//import Cviewstyle from "./courseView.module.scss";
//import "./courseView.scss";
import { Tab } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function ViewSurvey({
  inopen,
  setInopen,
  setLoading,
  loading,
  surveyData, 
  setSurveData,
  uname,
}) {
  console.log("surveyData==",surveyData);
  //const [isdload, setIsdload] = useState(false);
  const classes = useStyles();
  function handleNuggetsClose() {
    setInopen(false);
    setLoading(false);
  }
  const panes = [ {
      menuItem: "Pre survey",
      render: () => (
        <Tab.Pane active={true}>
          {loading && <div style={{display:'flex',justifyContent:'center'}}>
          <CircularProgress style={{ color: config.main_color_1 }} />
          </div>}
          { !loading && 
           surveyData?.body?.map((item)=>(
            <>
            {item.qtype === 5 && item.json?.qitems?.map((jitem,index)=>(
              <>
               <div>
                  <p>{(index+1) + "."+jitem.istem}</p>
                  <GroupRadio  dval={jitem.res}/>
                </div>
              </>
            )
                 
            )}
            </>
           ))
        }  
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Post survey",
      render: () => (
        <Tab.Pane active={true}>
          { !loading && 
           surveyData?.body?.map((item)=>(
            <>
            {item.qtype === 6 && item.json?.qitems?.map((jitem,index)=>(
              <>
               <div>
                  <p>{(index+1) + "."+jitem.istem}</p>
                  <GroupRadio  dval={jitem.res}/>
                </div>
              </>
            )     
            )}
            </>
           ))
        }
        </Tab.Pane>
      ),
    },
  ];
  return (
    <Dialog
      open={inopen}
      maxWidth='sm'
      fullWidth="true"
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{uname} - Survey results</DialogTitle>
      <DialogContent>
        <div >
        <Tab panes={panes}  />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNuggetsClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
