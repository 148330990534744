// Dependencies imports
import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { useNavigate } from "react-router-dom";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import config from "../../config/aws-exports";
// Styles imports
import Prev from "./PreviousBatches.module.scss";
import { API } from "aws-amplify";
import swal from "sweetalert";

// Start of Previous Batches popup component
const PreviousBatches = (props) => {
  let userDetails = useSelector(authData);
  let { handleClose, batches } = props;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  console.log(userDetails);
  // Internal styles for table cells
  const header = {
    color: "white",
    fontFamily: "nunito",
    fontSize: "18px",
    fontWeight: "600px",
  };

  const batchnames = {
    color: config.main_color_1,
    fontFamily: "nunito",
    fontSize: "16px",
    fontWeight: "500px",
    paddingBotton: "10px",
  };

  function titleclick(data, ind) {

    let sdata = { ...userDetails };
    console.log(batches);
    sdata.curprgcou = { ...data };
    sdata.curprgcou.pid = batches.pid;
    sdata.curprgcou.bpid = data.bid;
    sdata.curprgcou.bname = data.bname;
    sdata.curprgcou.ptitle = batches.pname;
    sdata.curprgcou.lname = batches.batchs[ind].lname;
    sdata.curprgcou.sname = batches.batchs[ind].sname;
    sdata.curprgcou.disname = batches.batchs[ind].disname;
    sdata.curprgcou.colname = batches.batchs[ind].cname;
    sdata.curprgcou.ctype = batches.batchs[ind].ctype;
    sdata.curprgcou.zone = batches.batchs[ind].zone;
    sdata.curprgcou.apr_status = batches.batchs[ind].apr_status;

    if (sdata.breadcrumb == undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Manage Programs",
        path: "/Programs",
      };
      temp[1] = {
        name: `${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
        path: "/ManageBatch",
      };
      sdata.breadcrumb = temp;
    } else {
      sdata.breadcrumb = [];

      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: "Manage Programs",
        path: "/Programs",
      };
      temp[1] = {
        name: `${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
        path: "/ManageBatch",
      };
      sdata.breadcrumb = temp;
    }

    dispatch(awsSignIn(sdata));
    navigate("/ManageBatch");
  }
  async function approveBatch(item) {
    
    setLoading(true);
    var body1 = {
      oid: config.aws_org_id,
      bid: item.bid,
      bname: item.bname,
      pname: item.ptitle,
      aprstatus: true,
      atype: 2
    };
   
    const bodyParam = {
      body: body1,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(bodyParam.body);
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        `/updateBatchStatus`,
        bodyParam

      );
    
      swal({
        title: "Success!",
        text: "Batch Approved Successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(false);
          handleClose();
          window.location.reload();
        }
      });
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setLoading(false);
          swal({
            title: "Contact Developer!",
          });
        }
      });
    }
  }
  function tableview() {
    return (
      <TableBody>
        {batches?.batchs?.map((item, index) => {
          return (
            <TableRow>
              <TableCell
                style={batchnames}
                className={Prev.batch}
                onClick={() => {
                  titleclick(item, index);
                }}
              >
                {item.bname}
              </TableCell>
              <TableCell
                style={batchnames}
                className={Prev.batch}

              >
                {item.apr_status == false ? <button
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    minWidth: "150px",
                    width: 'auto',
                    borderRadius: "5px",
                    minHeight: "35px",
                    height: 'auto',
                    padding: '5px',
                    backgroundColor: '#6E2B69',
                    color: '#fff'
                  }}

                  onClick={() => approveBatch(item)}
                  disabled={!window.navigator.onLine}
                >
                  Approve
                </button> : null}
                
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <div className={Prev.popupcontainer}>
      <div className={Prev.iconholder}>
        <Close className={Prev.icon} onClick={handleClose} />
      </div>

      <div className={Prev.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: config.main_color_1 }}>
              <TableRow>
                <TableCell style={header}>

                  Previous batches
                </TableCell>
                <TableCell style={header}>

                  
                </TableCell>
              </TableRow>
            </TableHead>
            {tableview()}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default PreviousBatches;
