// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports
import { Constants } from "../../config/constants";
import { authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";

// Styles imports
import Add from './AddEvent.module.scss';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));

// Start of main delete user component
const DeleteUser = (props) => {
  let { handleCloseDelete, getFaculty, mailUserList, setSelectionModel } = props;
  let userDetails = useSelector(authData);
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  async function onDelete() {
    setSpin(true);
  
    const bodyParam = {
      body: {
        batchid: userDetails.curprgcou.bpid,
        emailid: mailUserList.map((item) => item.emailid),
        atype: 2,
        pid: userDetails.curprgcou.pid,
        role:'User'
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER_TO_BATCH,
        bodyParam
      );

      setSelectionModel([]);
      if (
        response == "Entered id is not a user" ||
        response == "User not found"
      ) {
        swal({
          title: "Error",
          text: response,
          icon: "error",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            handleCloseDelete();
          }
        });
      } else {
        swal({
          title: "Success",
          text: "User Successfully removed from the batch",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            handleCloseDelete();
          }
        });
      }
      handleCloseDelete();
      setSpin(false);
      getFaculty();
    } catch (error) {
      console.error(error);
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Contact Developer");
          setSpin(false);
        }
      });
    }
  }

  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>

          <div className={Add.form}>
            <form>
              <div className={Add.header}>Are you sure you want to Remove?</div>

              <div className={Add.buttonholder}>
                <button className={window.navigator.onLine === true ? Add.btn_color : Add.btn_colordis} disabled={!window.navigator.onLine} onClick={() => {
                  onDelete();
                }}>Yes</button>
                <button className={Add.cancel} onClick={handleCloseDelete}>NO</button>
              </div>
            </form>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>
  );
};

export default DeleteUser;