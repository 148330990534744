// Dependencies imports
import { Add } from "@material-ui/icons";
import React from "react";
import ReactPlayer from "react-player";
// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import Image from "../../assets/images/i3.png";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";
// Styles imports
import Ann from "./AnnouncementFile.module.scss";

const AnnouncementFile = (props) => {
  let { handleFileClose, obj } = props;
  console.log("data" + JSON.stringify(obj));
  return (
    <div className={Ann.mainpopup}>
      <div className={Ann.iconholder}>
        <Close onClick={handleFileClose} className={Ann.icon} />
      </div>
      <div
        className={Ann.mediaholder}
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          height: "100% !important",
          width: "100% !important",
          overflowY: "auto",
        }}
      >
        <div>
          {
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100% !important",
                  height: "500px",
                  overflowX: "hidden",
                  paddingBottom: "20px",
                }}
              >
                <iframe
                  src={`${obj.ourl}`}
                  width={600}
                  title="Iframe Example"
                ></iframe>
                {/* <Document
                      file={`https://${config.DOMAIN}/jssaher-resources/images/announcement-images/0047786524-3.pdf`}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document> */}
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default AnnouncementFile;
