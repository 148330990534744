// Dependencies imports
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Acc from "./AccordionPopup.module.scss";

const titlediv = {
  width: "30%",
  height: "30px",
};

const bardiv = {
  width: "40%",
  height: "20px",
};

const title = {
  fontSize: "14px",
  fontFamily: "nunito",
  fontWeight: 500,
  color: config.main_color_1,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    width: "100%",
    paddingTop: "20px",
  },
  root1: {
    width: "100%",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 400,
    color: config.main_color_1,
  },
  subheading: {
    color: "black",
    fontFamily: "nunito",
    fontSize: "14px",
    fontWeight: "600px",
  },
  "MuiAccordionDetails-root": {
    display: "inline-block",
    width: "100%",
    padding: "10px 20px",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          color="primary"
          style={{ backgroundColor: "whitesmoke" }}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

// Start of main accordion component
const AccordionPopup = (props) => {
  const [program, setProgram] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pid, setPid] = useState("");
  const [bid, setBid] = useState("");
  const [ptitle, setPtitle] = useState("");
  const [courseData, setCourseData] = useState(null);
  const classes = useStyles();
  let { handleClose } = props;

  let userDetails = useSelector(authData);

  useEffect(() => {
    getuserprogress();
    return () => {};
  }, []);

  async function getuserprogress() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let topic = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_TOTAL_PROGRESS,
        bodyParam
      );

      if (topic == "No Topics") {
        let obj = { myTopics: [] };
        //return obj;
        //convertData(obj);
      } else {
        // getProgramsDetails(topic);
        console.log(JSON.stringify(topic));
        let { bpdata } = topic;
        const list = bpdata.filter(
          (data) => data.bpid === userDetails.curprgcou.bpid
        );
        let courses = list[0].tcourses;
        setPid(list[0].pid);
        setBid(list[0].bpid);
        setPtitle(list[0].ptitle);
        setProgram(courses);

        //getCourse(topic);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getProgramsDetails(topic) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        bpid: userDetails.curprgcou.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.eid = userDetails.eid;
    }
    try {
      let program = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_Programs_Details,
        bodyParam
      );

      var topicsids = [];

      for (var k = 0; k < program.pcurriculum.length; k++) {
        program.pcurriculum[k].oc = true;

        topicsids.push(program.pcurriculum[k].tid);
      }

      getTopic(topic, program, topicsids);
    } catch (error) {
      console.error(error);
    }
  }

  async function getTopic(topic, program, topicsids) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        // eid: userDetails.selbatchuser.eid,
        topicids: topicsids,
        action: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const topics = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TOPIC,
        bodyParam
      );

      convertData(topic, program, topics.result);
    } catch (error) {
      console.error(error);
    }
  }

  function convertData(topic, program, topics) {
    let userdata = topic[userDetails.curprgcou.bpid].courses;

    for (var j = 0; j < program.pcurriculum.length; j++) {
      let btid = program.pcurriculum[j].btid;

      var cnt = 0;

      if (
        topics[program.pcurriculum[j].tid] != undefined &&
        topics[program.pcurriculum[j].tid].unit != undefined
      ) {
        for (
          var k = 0;
          k < topics[program.pcurriculum[j].tid].unit.length;
          k++
        ) {
          for (
            var m = 0;
            m < topics[program.pcurriculum[j].tid].unit[k].nuggets.length;
            m++
          ) {
            for (
              var n = 0;
              n <
              topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects
                .length;
              n++
            ) {
              let obid =
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[n]
                  .oid;

              if (
                userdata != undefined &&
                obid != undefined &&
                userdata[btid] != undefined &&
                userdata[btid].objects != undefined &&
                userdata[btid].objects[obid] != undefined &&
                userdata[btid].objects[obid].timespent != undefined
              ) {
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].obtime = userdata[btid].objects[obid].timespent;
              } else {
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].obtime = 0;
              }

              if (
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[n]
                  .obtime /
                  60 >
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[n]
                  .oduration
              ) {
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].obtime =
                  topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                    n
                  ].oduration * 60;
              }

              if (
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[n]
                  .obtime >= 60
              ) {
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].min = Math.floor(
                  topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                    n
                  ].obtime / 60
                );
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].sec =
                  topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                    n
                  ].obtime.toFixed() % 60;
              } else {
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].min = 0;
                topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].sec =
                  topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                    n
                  ].obtime.toFixed();
              }

              var per =
                (topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                  n
                ].obtime /
                  60 /
                  +topics[program.pcurriculum[j].tid].unit[k].nuggets[m]
                    .objects[n].oduration) *
                100;
              per = per !== undefined ? per.toFixed(0) : 0;
              topics[program.pcurriculum[j].tid].unit[k].nuggets[m].objects[
                n
              ].per = per;
              cnt++;
            }
          }
        }

        program.pcurriculum[j].topic = topics[program.pcurriculum[j].tid];
      }
    }

    setProgram(userdata);
  }
  async function getCourse(event) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
        pid: pid,
        bpid: bid,
        courseid: program[event.target.value].tid,
        bcourseid: program[event.target.value].bcid,
        ptitle: ptitle,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(JSON.stringify(bodyParam.body, null, 2));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE,
        bodyParam
      );
      console.log("response", response);
      if (
        response === undefined ||
        response === null ||
        response["errorType"] === "TypeError" ||
        Object.keys(response).length === 0
      ) {
        setCourseData(null);
      } else {
        setCourseData(response);
        console.log(JSON.stringify(response, null, 2));
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function listView() {
    return (
      <div>
        {courseData !== null &&
          courseData.unit.map((unit, idx) => (
            <Accordion key={"jaddf" + idx}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.subheading}>
                  {unit.unitname}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ width: "100%" }}>
                  <div className={classes.root}>
                    {unit.nuggets.map((nugget, idx) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.subheading}>
                            {nugget.ntitle}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          className={classes["MuiAccordionDetails-root"]}
                        >
                          {nugget.objects.map((object, idx) => {
                            let newvalue = parseInt(object.oduration);
                            let percent =
                              (Math.round(object.userOP.timespent) / newvalue) *
                              100;

                            return (
                              <div className={Acc.toggleholder}>
                                <div className={Acc.toggleinternal}>
                                  <div style={titlediv}>
                                    <Typography style={title}>
                                      {/* {object.userOP.timespent} */}
                                      {object.otitle}
                                    </Typography>
                                  </div>
                                  <div className={classes.root1}>
                                    {object.userOP.timespent === 0 ? (
                                      <LinearProgressWithLabel value={0} />
                                    ) : newvalue < object.userOP.timespent ? (
                                      <LinearProgressWithLabel value={100} />
                                    ) : (
                                      <LinearProgressWithLabel
                                        value={percent}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    );
  }

  return (
    <div className={Acc.mainpopup}>
      <div className={Acc.closeholder}>
        <Close onClick={handleClose} className={Acc.closebutton} />
      </div>
      <div className={classes.root}>
        <div className={Acc.select}>
          <label for="course-select" className={Acc.label}>
            Select a Course
          </label>
          <select
            id="course-select"
            className={Acc.courseselect}
            width={300}
            style={{ width: "300px" }}
            onChange={(event) => getCourse(event)}
          >
            <option
              selected="selected"
              width={300}
              style={{ width: "100px" }}
              disabled
              className={Acc.option}
            >
              Select Course
            </option>
            {program.map((value, index) => (
              <option
                value={index}
                key={"prog" + index}
                width={300}
                style={{ width: "300px" }}
                className={Acc.option}
              >
                {value.ttitle.substring(0, 50)}
                {value.ttitle.length > 50 ? "..." : null}
              </option>
            ))}
          </select>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width={1500} height={600} />{" "}
          </div>
        ) : (
          listView()
        )}
      </div>
    </div>
  );
};

export default AccordionPopup;
