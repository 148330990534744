// Dependencies imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { awsSignIn, authData } from "../../redux/auth/authSlice";

import config from "../../config/aws-exports";
//import { Constants } from "../../config/constants";

//import { generate } from "shortid";
import { DataGrid } from '@mui/x-data-grid';

import { makeStyles } from "@material-ui/core";
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';

// import { GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';

const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            background: config.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    ".MuiDataGrid-root": {
        fontSize: "14px !important",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer",
        width: "100%",
        overflow: 'auto'

    }
});

// Start of Reports component
const StudentView = (props) => {
    let { userData, selectionModel, setSelectionModel, columns } = props;
    const dispatch = useDispatch();
    const classes = useStyles();


    return (
        <div style={{ height: 400, width: '100%' }}>

            <div style={{ height: 400, width: '500' }} >
                <DataGrid
                    rows={userData}
                    columns={columns}
                    disableSelectionOnClick
                    onRowSelected={e => console.log(e)}
                    onSelectionModelChange={(newSelection) => {
                        console.log(newSelection);
                        setSelectionModel(newSelection);
                    }}
                    selectionModel={selectionModel}
                    
                    className={classes['.MuiDataGrid-root']}
                />
            </div>
        </div>
    );
};

export default StudentView;
