// Dependencies imports
import React, {useEffect} from 'react';
import { useDispatch,useSelector } from "react-redux";
import { awsSignIn,authData } from "../../redux/auth/authSlice";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import ApplicationGrid from './ApplicationGrid';
import { Link } from 'react-router-dom';

// Styles imports
import App from './Application.module.scss';

const Application = () => {
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);


    useEffect(() => {
        breadcrumb();
      }, []);

    

      function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[2] = {
            name:'Application',
            path:'/application'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }


    return (
        <div className={App.maincontainer}>
            <UserHeader Bindex={2}/>
            <div className={App.headerbutton}>
                <h3 className={App.header}>Applications</h3>
            </div>
            <div className={App.application}>
                <ApplicationGrid />
            </div>
        </div>

    );
};

export default Application;