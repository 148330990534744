// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const size = {
  phone: "37.5em",
  tab_port: "56.25em",
  tab_land: "75em",
  big_desktop: "112.5em",
};
export const Theme = {
  color: {
    primary: "#f18121",
    secondary: "#0f80a4",
    secondaryrgb: "15, 128, 164",
    grey_light_1: "#f7f7f7",
    grey_light_2: "#eee",

    grey_dark: "#777",
    grey_dark_2: "#999",
    grey_dark_3: "#333",

    white: "#fff",
    black: "#000",
  },
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  breakpoint: {
    phone: `only screen and (max-width: ${size.phone})`,
    tab_port: `only screen and (max-width: ${size.tab_port})`,
    tab_land: `only screen and (max-width: ${size.tab_land})`,
    big_desktop: `only screen and (min-width: ${size.big_desktop})`,
  },
};

// New instances are created from here
const jeevaprod = {

  main_color_1: "#6E2B69",
  main_color_2: "#6E2B69",
  DOMAIN: "d2wvz55zivlhjq.cloudfront.net",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "du6m8lkvjk",
      name: "JEEVA-AUTHORING",
      description: "",
      endpoint:
        "https://du6m8lkvjk.execute-api.ap-south-1.amazonaws.com/jeeva-prod",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "du6m8lkvjk",
      name: "JEEVA-AUTHORING",
      description: "",
      endpoint:
        "https://du6m8lkvjk.execute-api.ap-south-1.amazonaws.com/jeeva-prod",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "JEEVA-AUTHORING",
  aws_cloud_logic_custom_name_E: "JEEVA-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-dev-learned",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2wvz55zivlhjq.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "ap-south-1",
  aws_resource_name_prefix: "learned-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-south-1_JnMVpb38S",
  aws_user_pools_web_client_id: "asbnvj0q7kq7q1so1bkeujvqg",
  aws_cloud_logic_custom_endpoint:
    "https://ynv4bzc5g3.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "JEEVARAKSHA",
  aws_org_id_E: "JEEVARAKSHA",
  aws_user_pools_id_E: "ap-south-1_JnMVpb38S",
  aws_user_pools_web_client_id_E: "ka23jghkb687p73nesnepp691",
  aws_content_delivery_cloudfront_domain_E: "d2wvz55zivlhjq.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cloud_logic_custom_endpoint_E:
    "https://du6m8lkvjk.execute-api.ap-south-1.amazonaws.com/jeeva-prod",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "du6m8lkvjk",
      name: "JEEVA-AUTHORING",
      description: "",
      endpoint:
        "https://du6m8lkvjk.execute-api.ap-south-1.amazonaws.com/jeeva-prod",
      region: "us-east-1",
    },
  ],
  schema: "jeevarakshasm",
 
};


export default jeevaprod;
