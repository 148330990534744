// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import CircularProgress from '@material-ui/core/CircularProgress';

// Styles imports
import Add from './AddEvent.module.scss';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));

const DisbleUser = (props) => {
    let { handleClose ,editUserData,getUsersDetails} = props;
    const [spin , setSpin] = useState(false);
    const classes = useStyles();

    const onsubmit = () => {
        updateuser();    
    };

    async function updateuser(){
      setSpin(true);
   
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                eid:editUserData.col9,
                action: editUserData.col12 === false ? 1 : 0,
                urole: editUserData.col3
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
          };
          try {
            
            const response = await API.post(
              config.aws_cloud_logic_custom_name,
              Constants.GET_UPDATEUSERSATUS,
              bodyParam
             
            );

            if (response?.msg === "User Enabled") {
              swal({
                title: "Success",
                text: "User Enabled Successfully",
                icon: "success",
                dangerMode: false,
                closeOnClickOutside: false,
              }).then((willDelete) => {
                if (willDelete) {
                  window.location.reload();
                  setSpin(false);
                }
              });
              setSpin(false);
              handleClose();
              getUsersDetails();
            }  else {
              swal({
                title: "Sucess",
                text: "User Disabled Successfully!",
                icon: "success",
                dangerMode: false,
                closeOnClickOutside: false,
              }).then((willDelete) => {
                
                if (willDelete) {
                  window.location.reload();
                  setSpin(false);
                }
              });
              setSpin(false);
              handleClose();
              getUsersDetails();
            };
          } catch (error) {
            swal({
                title:  "Oooops!",
                text:  "Soomthing wrong!",
                icon: "warning",
                dangerMode: true,
                closeOnClickOutside: false,
              })
              .then(willDelete => {
                if (willDelete) {
                  window.location.reload();
                  setSpin(false);
                }
              });
              setSpin(false);
          }
    }
    return (
      <div>
      {spin === false ?
        <div className={Add.mainpopup}>
            <div className={Add.form}>
                <form>
                    {editUserData.col12 === true ? <div className={Add.header}>You want to enable the instructor ?</div> : <div className={Add.header}>You want to disable the instructor ?</div>}
                    <div className={Add.buttonholder}>
                        <button className={Add.submit} onClick={onsubmit} >Yes</button>
                        <button className={Add.cancel}  onClick={handleClose}>NO</button>
                    </div>
                </form>
            </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
        </div>
    );
};

export default DisbleUser;