import React, { useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Cviewstyle from "./courseCreation.module.scss";
import ObjectPopUp from "./AddObjects";
import UserHeader from "../../Header/UserHeader/UserHeader";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import {  useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import "./courseCreation.scss";
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Video from "../../../assets/svgjs/Video";
import Review from "../../../assets/svgjs/Review";
import Html from "../../../assets/svgjs/Html";
import QuizIc from "../../../assets/svgjs/Quiz";
import PdfIcon from "../../../assets/svgjs/Pdf";
import addimg from "../../../assets/images/addimg.png";
import Audio from "../../../assets/svg/Audio.svg";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import swal from "sweetalert";
import axios from "axios";

import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import eyeIcon from "../../../assets/images/eye.png";
import produce from "immer";

import EditNTitleAndEditUTitle from "../courseView/EditNTitleAndEditUTitle";
import YouTubeVideo from "../../../assets/svgjs/Youtube";
import Vimeo from "../../../assets/svgjs/Vimeo";
import CoursePlayer from "../courseView/CoursePlayer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import shortid from "shortid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",
    overflowY: "auto",
    background: "white",
    boxSShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    marginTop: "1rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "1200px",
    },
  },
  "& > *": {
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
let topicdata = {
  ttitle: "",
  tdescription: "",
  coursenum: "",
  semester: "",
  freenavigation: false,
  certification: false,
  tcertauth: "Enhanzed",
  tnoofcredits: 0,
  tduration: 0,
  version: 1,
  noofnuggets: 0,
  tobj: 0,
  tvid: 0,
  tdoc: 0,
  tfq: 0,
  tsq: 0,
  tasmt: 0,
  totalNuggets: 0,
};
export default function CourseCreation({ handleToggleSidebar }) {
  const classes = useStyles();
  let navigate = useNavigate();
  const theme = useTheme();
  const [courseDetails, setCourseDetails] = useState(topicdata);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = React.useState(false);
  const [dOpen, setDOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [istLoading, setIstLoading] = useState(false);
  const [curObject, setcurObject] = useState({});
  const [nuggets, setNuggets] = useState([]);
  const [objOpen, setObjOpen] = useState(false);
  const [editObj, setEditObj] = useState({
    editbtn: true,
    nTitleEdit: false,
    uTitleEdit: false,
    meditmode: false,
    oeditmode: false,
    nuggetchanges: false,
    topicimgchanges: false,
    overViewEdit: false,
    weekEdit: false,
    imgEdit: false,
    titleEdit: false,
    objectView: false,
  });
  const curObRef = useRef({});
  const [assignment, setAssignment] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(true);
  const [qisLoading, setQIsLoading] = useState(false);
  const [sMiniScore, setSMiniScore] = useState(false);
  const [objectData, setObjectData] = useState({});
  const refselectedCourseFile = useRef();
  const [expanded, setExpanded] = useState(false);
  const [oIndex, setOIndex] = useState(0);
  const [unitIndex, setUnitIndex] = useState(0);

  const redux = useRef({});
  const refUnitValue = useRef("");
  const refnuggetValue = useRef("");
  const [folder, setFolder] = useState(Math.round(new Date().getTime() / 1000));
  const [cNugget, setcNugget] = useState({});
  const [type, setType] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [nTitleIndexAndUTitleIndex, setNTitleIndexAndUTitleIndex] = useState({
    nIdx: null,
    uIdx: null,
  });
  const [selectedObj, setSelectedObj] = useState({});
  const [editType, setEditType] = useState("");
  const scrollRef = useRef();
  const accRef = useRef();
  const [miniScore, setMiniScore] = useState(0);
  const [summative, setSummative] = useState(false);
  const [sumScore, setSumScore] = useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();

  useEffect(() => {
    
    reduxRestore();
    setIsLoading(false);
    breadcrumb();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

    scrollRef.current.scrollTo(
      0,
      document.getElementById("scrol" + panel).offsetTop -
        scrollRef.current.scrollHeight +
        "px"
    );
  };

  const handleEditOpen = () => {
    setEditOpen(true);

    setEditObj({ ...editObj, nTitleEdit: true });
  };

  function renderObjectsext(objType) {
    switch (objType) {
      case "video":
        return "video/*";
      case "audio":
        return "audio/*";
      case "pdf":
        return ".pdf";
      case "html":
        return ".html";
      case "interactivity":
        return ".html, application/zip, application/x-zip-compressed, .zip";
      case "quiz":
        return ".json";
      default:
        return null;
    }
  }
  const renderSwitch = (param) => {
    if (param === "video") {
      return <Video className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "audio") {
      return <img src={Audio} className="video-size" alt="audio" />;
    } else if (param === "quiz" || param === "ptraining" || param === "pexam" || param === "ppt") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "Interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    } else if (param === "youtube") {
      return <YouTubeVideo  />;
    } else if (param === "vimeo") {
      return <Vimeo color="#f18121" />;
    }
  };
  function reduxRestore() {
    let sdata = { ...userDetails };
    redux.current = sdata;
  }

  const handleDClose = () => {
    setDOpen(false);
  };
  const handleClose = () => {
   
    setOpen(false);
    setEditObj({ ...editObj, oeditmode: false, objectView: false });
  };

  const changeHandler = (event) => {
    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];
     
      var body = {
        filetype: event.target.files[0].type,
        filename: "topic.png",
        folder: folder,
      };

      uploadfile(body);
    }
  };
  function checkData() {
  
    if (courseDetails.ttitle === "" || courseDetails.ttitle.length === 0) {
      swal("Oops!", "Course title cannot be empty", "error");
      return;
    }
    if (
      courseDetails.tdescription === "" ||
      courseDetails.tdescription.length === 0
    ) {
      swal(
        "Oops!",
        "Course description cannot be empty in Overview section",
        "error"
      );
      return;
    }
    if (refselectedCourseFile.current === undefined) {
      swal("Oops!", "Please select a Course image.", "error");
      return;
    }
    if (courseDetails.tduration === 0 || courseDetails.tduration === "") {
      swal("Oops!", "Please enter duration.", "error");
      return;
    }
    if (nuggets.length === 0) {
      swal("Oops!", "You need to add atleast 1 Module.", "error");
      return;
    }
    if (nuggets.length > 0) {
      for (let k = 0; k < nuggets.length; k++) {
        if (nuggets[k].nuggets.length > 0) {
          for (let j = 0; j < nuggets[k].nuggets.length; j++) {
            if (nuggets[k].nuggets[j].objects.length > 0) {
            } else {
              swal(
                "Oops!",
                "You need to add at least 1 Content in each Module",
                "error"
              );
              return;
            }
          }
        } else {
          swal(
            "Oops!",
            "You need to add at least 1 Module in each Unit",
            "error"
          );
          return;
        }
      }

      saveTopicGetID();
    }
  }
  async function uploadfile(body) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": refselectedCourseFile.current.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const viewAddEditCurrentObject = (object, idx, oidx, ids, nugget) => {
    console.log(JSON.stringify(object));
    let obj = {};

    obj.otherdata = {
      title: object.otitle,
      desc: object.odescription,
      dur: object.oduration,
      file: object.file,
      files: "",
    };
    if (object !== undefined) {
      if (object.semester) {
        obj.otherdata.semester = object.semester;
      }
    }
    
    if (object.oduration !== undefined && object.oduration != "") {
      obj.otherdata.min = Math.floor((object.oduration % 3600) / 60);
      obj.otherdata.sec = Math.floor((object.oduration % 3600) % 60);
    }
    obj.sobject = object.otype;
    obj.otype = object.otype;
    obj.oid = object.oid;
    obj.seltype = true;
    obj.error1 = false;
    obj.afile = renderObjectsext(object.otype);
    obj.uindex = idx;
    obj.oindex = oidx;
    obj.nindex = ids;
    obj.unit = nugget.unitname;
    obj.ourl = object.ourl;
    obj.curl = object.curl;
    obj.stype = object.otype;

    if (object.otype === "quiz") {
      obj.qtype = object.qtype;
      obj.cutoff = object.cutoff;
    }
    if (object.otype === "youtube" || object.otype === "vimeo") {
      obj.otherdata.embeddedcode = object.embeddedcode;
    }
    setSelectedObj(obj);
    setOIndex(oidx);
    setcurObject(object);
   
    setEditObj({ ...editObj, objectView: true });
    handleClickOpen("body");
    setOpen(true);
  };
  async function saveTopicGetID() {
    setIstLoading(true);
    let ndur = 0,
      tobj = 0,
      tvid = 0,
      tdoc = 0,
      tsq = 0,
      tfq = 0,
      tdur = 0;

    let nug = [...nuggets];
    nug = produce(nug, (v) => {
      for (let i = 0; i < v.length; i++) {
        ndur = 0;
        for (let k = 0; k < v[i].nuggets.length; k++) {
          for (let o = 0; o < v[i].nuggets[k].objects.length; o++) {
            tobj++;
            ndur = ndur + Number(v[i].nuggets[k].objects[o].oduration);
            tdur = tdur + Number(v[i].nuggets[k].objects[o].oduration);
            if (
              v[i].nuggets[k].objects[o].otype == "video" ||
              v[i].nuggets[k].objects[o].otype == "audio"
            ) {
              tvid++;
            } else if (v[i].nuggets[k].objects[o].otype == "quiz") {
              tsq++;
            } else {
              tdoc++;
            }
            if (o == v[i].nuggets[k].objects.length - 1) {
              v[i].nuggets[k].nduration = ndur;
            }
            console.log(tdur);
          }
        }
      }
    });
   
    setNuggets(nug);

    let courseD = { ...courseDetails };
    courseD = produce(courseD, (v) => {
      v.toduration = tdur;
      v.tobj = tobj;
      v.tvid = tvid;
      v.tdoc = tdoc;
      v.tsq = tsq;
    });

    setCourseDetails(courseD);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        NAME: userDetails.name,
        TOPICNAME: courseDetails.ttitle,
        eid: userDetails.email,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const getTopicId = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GETTOPICID,
        bodyParam
      );
     
      saveTopicDetails(getTopicId.tid, nug, courseD);
    } catch (error) {
      setIstLoading(false);
      console.error(error);
    }
  }

  async function saveTopicDetails(topicid, nugs, courseDetails) {
   
    try {
      const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          NAME: userDetails.name,
          email: userDetails.eid,
          role: userDetails.role,
          folder,
          tid: topicid,
          ttitle: courseDetails.ttitle,
          
          tlabel: `Semester-0,Course-0`,
          semester: 0,
          coursenum: 0,
          tdescription: courseDetails.tdescription,
          freenavigation: courseDetails.freenavigation,
          certification: courseDetails.certification,
          tcertauth: courseDetails.tcertauth,
          tnoofcredits: courseDetails.tnoofcredits,
          tduration: courseDetails.tduration,
          toduration: courseDetails.toduration,
          noofnuggets: nuggets.length,
          unit: nugs,
          totalNuggets: courseDetails.totalNuggets,
          version: 1,
          tobj: courseDetails.tobj,
          tvid: courseDetails.tvid,
          tdoc: courseDetails.tdoc,
          tfq: courseDetails.tfq,
          tsq: courseDetails.tsq,
          tasmt: courseDetails.tasmt,
          schema: config.schema,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: jwttoken,
        },
      };

      if (userDetails.curprgcou !== undefined && userDetails.curprgcou.pid) {
        bodyParam.body.pid = userDetails.curprgcou.pid;
        if (userDetails.curprgcou.bpid !== undefined) { 
          bodyParam.body.bpid = userDetails.curprgcou.bpid;
        } 
      }
     
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.CREATETOPIC,
        bodyParam
      );
      
      setIstLoading(false);
      swal({
        title: "Course Created Successfully",
        icon: "success",
        button: "Ok",
      }).then((willsave) => {
        if (willsave) {
          setNuggets([]);
          setCourseDetails(topicdata);
          refselectedCourseFile.current = undefined;
          navigate("/Content");
        }
      });
    } catch (error) {
      setIstLoading(false);
      navigate("/");
      console.error("createtopic" + error);
    }
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Course Creation",
      path: "/courseCreation",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  const enableDisablePost = async (status, nid, ids, idx) => {
    setIstLoading(true);
    const bodyParam = {
      body: {
        topicid: courseDetails.tid,
        oid: config.aws_org_id,
        btid: courseDetails.btid,
        nid,
        disable: !status,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (
      userDetails.curprg !== undefined &&
      userDetails.curprg.btid !== undefined
    ) {
      bodyParam.body.btid = userDetails.curprg.btid;
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/enableNuggets",
        bodyParam
      );
     
      nuggets[idx].nuggets[ids].nenabled = !status;
      setIstLoading(false);
     
    } catch (error) {
      console.log("enableNuggetserre", error);
    }
  };

  return (
    <div
      className={Cviewstyle.maincontainer}
      style={{ overflowX: "hidden", overflowY: "auto" }}
      ref={scrollRef}
    >
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div
        className="btn-toggle"
        onClick={() => handleToggleSidebar(true)}
      ></div>
      <UserHeader Bindex={3} />

      {editObj.objectView && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          onBackdropClick="false"
          className={classes.paper}
          fullScreen={fullScreen}
        >
          <DialogContent>
            <CoursePlayer
              handleClose={handleClose}
              courseDetails={courseDetails}
              setCourseDetails={setCourseDetails}
              objectData={objectData}
              setObjectData={setObjectData}
              oIndex={oIndex}
              setOIndex={setOIndex}
              curObject={curObject}
              setcurObject={setcurObject}
              curObRef={curObRef}
              assignment={assignment}
              setAssignment={setAssignment}
              summative={summative}
              setSummative={setSummative}
              sumScore={sumScore}
              setSumScore={setSumScore}
              miniScore={miniScore}
              setMiniScore={setMiniScore}
              sMiniScore={sMiniScore}
              setSMiniScore={setSMiniScore}
              qisLoading={qisLoading}
              setQIsLoading={setQIsLoading}
              editObj={editObj}
              setEditObj={setEditObj}
              assignmentLoad={assignmentLoad}
              setAssignmentLoad={setAssignmentLoad}
              renderSwitch={renderSwitch}
              setSelectedObj={setSelectedObj}
              selectedObj={selectedObj}
              setNuggets={setNuggets}
              nuggets={nuggets}
              folder={courseDetails.tid || folder}
              setOpen={setOpen}
              open={open}
            />
          </DialogContent>
        </Dialog>
      )}
      <ObjectPopUp
        objOpen={objOpen}
        setObjOpen={setObjOpen}
        renderSwitch={renderSwitch}
        folder={folder}
        cNugget={cNugget}
        setNuggets={setNuggets}
        nuggets={nuggets}
        setEditObj={setEditObj}
        editObj={editObj}
      />
      {editObj.nTitleEdit && (
        <EditNTitleAndEditUTitle
          open={editOpen}
          nuggets={nuggets}
          nTitleIndex={nTitleIndexAndUTitleIndex.nIdx}
          uTitleIndex={nTitleIndexAndUTitleIndex.uIdx}
          setNuggets={setNuggets}
          setEditObj={setEditObj}
          setEditOpen={setEditOpen}
          editObj={editObj}
          editType={editType}
        />
      )}

      <section className="section">
        <div className="section_inputs">
        
          <div className="section_inputs_items">
            <h3 clasName="section_inputs_items-titleSize">Course Title</h3>
            <input
              className="section_inputs_items-inputSize"
              value={courseDetails.ttitle}
              placeholder="Enter course title..."
              onChange={(e) => {
                let ttitle = e.target.value;
                setCourseDetails((prevtopicData) =>
                  produce(prevtopicData, (v) => {
                    v.ttitle = ttitle;
                  })
                );
              }}
            />
          </div>
          <div className="section_inputs_items">
            <h3 clasName="section_inputs_items-titleSize">Duration</h3>
            <input
              className="section_inputs_items-inputSize"
              autoComplete="false"
              pattern="\d*"
              placeholder="Enter course duration..."
              maxLength="4"
              value={
                courseDetails.tduration === 0 ? "" : courseDetails.tduration
              }
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  let tduration = e.target.value;
                  setCourseDetails((prevtopicData) =>
                    produce(prevtopicData, (v) => {
                      v.tduration = tduration;
                    })
                  );
                }
              }}
            />
          </div>
          <div className="section_inputs_items">
            <h3 clasName="section_inputs_items-titleSize">Overview</h3>
            <textarea
              className="section_inputs_items-inputSize"
              value={courseDetails.tdescription}
              placeholder="Enter course description..."
              onChange={(e) => {
                let tdisc = e.target.value;
                setCourseDetails((prevtopicData) =>
                  produce(prevtopicData, (v) => {
                    v.tdescription = tdisc;
                  })
                );
              }}
            ></textarea>
          </div>
        </div>
        <div className="section_image">
          <h3>Course Image</h3>
          <div className="section_image_fit" style={{ position: "relative" }}>
            {refselectedCourseFile.current === undefined && (
              <img
                className={Cviewstyle.imagebox}
                style={{ objectFit: "scale-down" }}
                src={addimg}
                alt="courseimage"
              />
            )}
            {refselectedCourseFile.current !== undefined && (
              <img
                className={Cviewstyle.imagebox}
                src={URL.createObjectURL(refselectedCourseFile.current)}
                alt="courseimage"
              />
            )}
            <input
              type="file"
              id="cfiles"
              style={{ visibility: "hidden", position: "absolute" }}
              onChange={changeHandler}
              accept="image/*"
            />
            <label
              for="cfiles"
              style={{ position: "absolute", right: "10px", bottom: "6px" }}
            >
              <AddAPhotoIcon style={{ color: "#0f80a4", cursor: "pointer",height:'2em',width:'2em' }} />
            </label>
          </div>
        </div>
      </section>
      <section style={{ marginTop: "2rem" }}>
        <h2>Course Content</h2>
        <div className={classes.root} style={{ padding: "1rem" }}>
          {nuggets.map((nugget, uidx) => (
            <Accordion
              key={uidx}
              expanded={expanded === `${uidx}`}
              onChange={handleChange(`${uidx}`)}
              id={"scrol" + uidx}
              ref={accRef}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    minHeight: "34px",
                  }}
                  className="unit-hover"
                >
                  <span>{nugget.unitname}</span>
                  <span className="unit-edit-icon">
                    <button
                      onClick={() => {
                        setEditType("Unit");

                        handleEditOpen();
                        setNTitleIndexAndUTitleIndex((currentTitle) =>
                          produce(currentTitle, (v) => {
                            v.uIdx = uidx;
                          })
                        );
                      }}
                      className="btn-reset btn-border"
                    >
                      <img src={editIcon} className="size" alt="edit-delete" />
                    </button>

                    <button
                      style={{
                        color: "white",
                        borderStyle: "groove ",
                        borderColor: config.main_color_2,
                        height: "30px ",
                        lineHeight: "0px ",
                        width: "150px",
                        backgroundColor: config.main_color_2,
                        fontSize: "15px ",
                        borderRadius: "3px ",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        let filteredUnit = nuggets.filter(
                          (nug) => nug.uid !== nugget.uid
                        );
                        setNuggets(filteredUnit);
                      }}
                    >
                      Remove unit
                    </button>
                  </span>
                </h2>
              </AccordionSummary>

              {nugget.nuggets.map(({ nenabled, ntitle, nid, objects }, ids) => (
                <React.Fragment key={ids + "sdfljkdj"}>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: ".8rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minHeight: "30px",
                      }}
                    >
                      <h3
                        className="edit-icons"
                        style={{
                          padding: ".6rem 0 .6rem 2.5rem",
                          fontWeight: "600",
                          fontFamily: "sans-serif",
                          fontSize: "1.5rem",
                          color: "rgba(0,0,0,.75)",
                          flexBasis: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {ntitle}
                        <div className="hide__edit-icon">
                          <span>
                            <button
                              onClick={() => {
                                setEditType("Module");
                                handleEditOpen();

                                setNTitleIndexAndUTitleIndex((currentTitle) =>
                                  produce(currentTitle, (v) => {
                                    v.nIdx = ids;
                                    v.uIdx = uidx;
                                  })
                                );

                                console.log(JSON.stringify(nuggets, null, 2));
                              }}
                              className="btn-reset btn-border"
                            >
                              <img
                                src={editIcon}
                                className="size"
                                alt="edit-delete"
                              />
                            </button>
                            <button
                              onClick={() => {
                                setNuggets((currentTopic) =>
                                  produce(currentTopic, (draft) => {
                                    draft[uidx].nuggets.splice(ids, 1);
                                  })
                                );
                              }}
                              className="btn-reset btn-border"
                            >
                              <img
                                src={deleteIcon}
                                className="size"
                                alt="edit-icon"
                              />
                            </button>
                          </span>
                        </div>
                      </h3>
                      {!editObj.meditmode && (
                        <button
                          style={styles.enableDisable}
                          onClick={(event) => {
                            enableDisablePost(nenabled, nid, ids, uidx);
                            event.stopPropagation();
                          }}
                        >
                          {nenabled ? "Disable" : "Enable"}
                        </button>
                      )}
                    </div>

                    <ul>
                      {objects.map((object, oidx) => (
                        <li
                          key={oidx + "asdfg"}
                          style={{
                            marginLeft: "10rem",
                            minHeight: "30px",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <h3
                            className="edit-icons"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: ".5rem",
                              fontSize: "1.5rem",
                              maxWidth: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span style={{ marginRight: "1rem" }}>
                                {renderSwitch(object.otype)}
                              </span>
                              <span>
                                {object.otitle.substring(0, 198) +
                                  `${object.otitle.length > 198 ? "..." : ""}`}
                              </span>
                            </div>

                            <div className="hide__edit-icon">
                              <span>
                                {object.otype !== "Interactivity" && (
                                  <button
                                    onClick={() =>
                                      viewAddEditCurrentObject(
                                        object,
                                        uidx,
                                        oidx,
                                        ids,
                                        nugget
                                      )
                                    }
                                    className="btn-reset btn-border"
                                  >
                                    <img
                                      src={eyeIcon}
                                      className="size"
                                      alt="edit-eye"
                                    />
                                  </button>
                                )}
                                {object.otype === "Interactivity" &&
                                  object.curl !== undefined && (
                                    <button
                                      onClick={() => {
                                        viewAddEditCurrentObject(
                                          object,
                                          uidx,
                                          oidx,
                                          ids,
                                          nugget
                                        );
                                      }}
                                      className="btn-reset btn-border"
                                    >
                                      <img
                                        src={eyeIcon}
                                        className="size"
                                        alt="edit-eye"
                                      />
                                    </button>
                                  )}
                                <button
                                  className="btn-reset btn-border"
                                  onClick={() => {
                                    if (object.otype == "quiz") {
                                      let crobj = { ...object };
                                      crobj.edit = true;
                                     
                                      viewAddEditCurrentObject(
                                        crobj,
                                        uidx,
                                        oidx,
                                        ids,
                                        nugget
                                      );
                                     
                                    } else {
                                      editObj.oeditmode = true;
                                      viewAddEditCurrentObject(
                                        object,
                                        uidx,
                                        oidx,
                                        ids,
                                        nugget
                                      );
                                    }
                                  }}
                                >
                                  <img
                                    src={editIcon}
                                    className="size"
                                    alt="edit-delete"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setNuggets((currentTopic) =>
                                      produce(currentTopic, (draft) => {
                                        draft[uidx].nuggets[ids].objects.splice(
                                          oidx,
                                          1
                                        );
                                      })
                                    );
                                  }}
                                  className="btn-reset btn-border"
                                >
                                  <img
                                    src={deleteIcon}
                                    className="size"
                                    alt="edit-icon"
                                  />
                                </button>
                              </span>
                            </div>
                          </h3>
                        </li>
                      ))}
                    </ul>

                    <div
                      style={{
                        margin: "2rem 0 0 10rem",
                        height: "30px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setcNugget({
                            nid,
                            ntitle,
                            objects,
                            ids,
                            uidx,
                            cedit: true,
                          });

                          setObjOpen(true);
                        }}
                        style={styles.editButton}
                      >
                        Add object
                      </button>
                    </div>
                  </AccordionDetails>
                </React.Fragment>
              ))}
              <div style={{ margin: "1rem 3rem" }}>
                <button
                  style={styles.editButton}
                  onClick={() => {
                    setDOpen(true);
                    setType("Module");
                    setUnitIndex(uidx);
                  }}
                >
                  Add Module
                </button>
              </div>
            </Accordion>
          ))}
          <button
            style={styles.editButton}
            onClick={() => {
              setType("Unit");
              setDOpen(true);
            }}
          >
            Add Unit
          </button>
        </div>
      </section>

      <section
        style={{
          marginTop: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "3rem",
        }}
      >
        <button
          variant="contained"
          color="primary"
          className={Cviewstyle.savebutton}
          onClick={() => checkData()}
        >
          Save
        </button>
        <button
          variant="contained"
          color="primary"
          className={Cviewstyle.cancel}
          style={{ borderColor: "#777777", color: "#777777" }}
          onClick={() => {
            swal({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willsave) => {
              if (willsave) {
                setCourseDetails(topicdata);
                setNuggets([]);
                refselectedCourseFile.current = undefined;
                navigate("/Content");
              }
            });
          }}
        >
          Discard
        </button>
      </section>
      <div>{/* <pre>{JSON.stringify(nuggets, null, 2)}</pre> */}</div>
      <Dialog
        open={dOpen}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleDClose}
        aria-labelledby="form-dialog-title"
        onBackdropClick="false"
      >
        <DialogTitle id="form-dialog-title">
          <h2 className={Cviewstyle.popadd}>Add {type}</h2>
        </DialogTitle>

        <DialogContent>
          <div className={Cviewstyle.inputholder}>
            {type == "Unit" && (
              <input
                id="standard-error-helper-text"
                placeholder="Unit Name"
                type="text"
                className={Cviewstyle.inputbox}
                onChange={(event) => {
                  const { value } = event.target;
                  refUnitValue.current = value;
                }}
              />
            )}

            {type == "Module" && (
              <input
                id="name"
                placeholder="Module Name"
                type="text"
                className={Cviewstyle.inputbox}
                onChange={(event) => {
                  const { value } = event.target;
                  refnuggetValue.current = value;
                  
                }}
              />
            )}
          </div>
        </DialogContent>
        <div className={Cviewstyle.buttonholder}>
          <DialogActions>
            <button onClick={handleDClose} className={Cviewstyle.cancel}>
              Cancel
            </button>
            <button
              onClick={() => {
                let tmpnt = [...nuggets];
                let cnid = 1;
                if (type == "Unit") {
                  if (
                    refUnitValue.current === undefined ||
                    refUnitValue.current.toString().replace(/\s/g, "")
                      .length === 0
                  ) {
                    swal("Oops!", "Please Enter Unit Name", "error");
                  } else {
                    
                    tmpnt = produce(tmpnt, (v) => {
                      v.push({
                        uid:
                          tmpnt.length === 0
                            ? 0
                            : tmpnt[tmpnt.length - 1].uid + 1,
                        unitname: refUnitValue.current,
                        nuggets: [],
                      });
                    });
                    setNuggets(tmpnt);
                    setEditObj({ ...editObj, meditmode: true });
                    handleDClose();
                  }
                  refUnitValue.current = "";
                  refnuggetValue.current = "";
                } else {
                  if (
                    refnuggetValue.current === undefined ||
                    refnuggetValue.current.toString().replace(/\s/g, "")
                      .length === 0
                  ) {
                    swal("Oops!", "Please Enter Module Name", "error");
                    return;
                  } else {
                    let objs = {};
                  
                    objs.nid = shortid.generate();
                    objs.enabled = false;
                    objs.ntitle = refnuggetValue.current;
                    objs.objects = [];
                    tmpnt = produce(tmpnt, (draft) => {
                      draft[unitIndex].nuggets.push(objs);
                    });
                    setNuggets(tmpnt);
                    setCourseDetails((curDetails) =>
                      produce(curDetails, (v) => {
                        v.totalNuggets = cnid;
                      })
                    );
                    refUnitValue.current = "";
                    refnuggetValue.current = "";

                    handleDClose();
                  }
                }
              }}
              className={Cviewstyle.save}
            >
              Save
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: config.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: config.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
  },
  enableDisable: {
    color: "black",
    borderStyle: "groove ",
    borderColor: "black",
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
  },
};
