import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert";
//import { produce } from "immer";
//import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import fdcss from "./Feedback.module.scss";
//import GroupRadio from "./GroupRadio";
//import Cviewstyle from "./courseView.module.scss";
//import "./courseView.scss";
import { API } from "aws-amplify";
import { Rating } from "semantic-ui-react";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  ratediv:{
    alignItems:'flex-start',
    flexDirection:'column',
    justifyContent:'center',
    gap:'20px',
    display: 'flex',
  },
  ratehead:{
   width:'100%'
  },
  ratestar:{
    width: '40%'
  },
  note:{
    paddingBottom: '10px',
  },
}));

export default function ViewComments({
    cmtOpen,
    setCmtOpen,
    cmtFeedback,
  setCmtFeedback,
  cid,
}) {
  
  const classes = useStyles();
  let userDetails = useSelector(authData);
  function handleNuggetsClose() {
    setCmtFeedback({'cmts':'','name':'','rate': 0,'headerName':''});
    setCmtOpen(false);
  }
 

  return (
    <Dialog
      open={cmtOpen}
      maxWidth='sm'
      fullWidth="true"
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title" >
      {/* <DialogTitle id="form-dialog-title"> Feedback</DialogTitle> */}
      <DialogContent>
        <div className={classes.note}>
        <h3>Feedback</h3>
           <h3>{cmtFeedback?.name}</h3>
        </div>
       
        {cmtFeedback && (
            <div className={classes.ratediv}>
            <p className={classes.ratehead}>{"1."+cmtFeedback.headerName}</p>
              <Rating maxRating={5} defaultRating={cmtFeedback.rate} 
              className={classes.ratestar} icon='star outline' size='massive'
              disabled/>
              <TextareaAutosize
                  className={fdcss.inputbox}
                aria-label="maximum height"
                minRows={2}
                disabled
                defaultValue={cmtFeedback.cmts}
                placeholder="Additional comments"
                style={{ resize: 'none' }}
                />
            </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNuggetsClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
