// import { Platform, Dimensions } from 'react-native';
import config from "./aws-exports";

// const { width, height } = Dimensions.get('window');

// const getHeight = () => {
//   if (height >= 812) {
//     return 75;
//   }
//   return 60;
// };

// const getPosition = () => {
//   if (height >= 812) {
//     return 40;
//   }
//   return 25;
// };

// eslint-disable-next-line no-undef

export const Constants = {
  app_images: "EDDEV",
  app_locale: "mddemo",
  app_border_color: "#f4f4f4",
  app_background_color: "#ffffff",
  // app_color: '#F0F0F0',
  app_dark_color: "#2665aa",
  app_light_color: "#2665aa",
  app_color: "#ffffff",
  app_button_color: "#e35f14",
  dashboard_linegraph_toggle_button_active_color: "e35f14",
  dashboard_linegraph_toggle_button_color: "#999999",
  app_toolbar_color: "#434343",
  app_button_text_color: "#fff",
  app_text_color: "#5C5C5C",
  app_button_text_size: 15,
  app_nav_title_color: "#000000",
  app_statusbar_color: "#434343",
  app_searchbar_background_color: "#5A5A5A",
  app_searchbar_placeholder: "#FFFFFF",
  app_searchbar_text: "#FFFFFF",
  app_searchbar_tintcolor: "#FFFFFF",
  app_font_family_regular: "NunitoSans-Regular",
  app_font_family_bold: "NunitoSans-Bold",
  not_seen_color: "#cccccc",
  not_seen_object_color: "#373737",
  app_grey_color: "#6a6a6a",
  // app_toolbar_height: getHeight(),
  // app_toolbar_position: getPosition(),
  // app_width: width,
  // app_height: height,
  SecretKey: "trfy56rsf6u5t6t6gjuyvy423eyd543165dwq4",
  GET_MY_PROGRAMS: "/getPrograms",
  // GET_PROGRAM_DETAILS: "/getProgramsDetails",
  GET_ANNOUNCEMENTS: "/PostAnnouncement",
  GET_FAQ: "/faq",
  GET_REMOVE_USER: "/deleteUser",

  // Block to be changed during the instance creation starts here
  main_color_1: "#0f80a4",
  main_color_2: "#f18121",
  DOMAIN: "www.jeevaraksha-admin.enhanzed.com",
  // Block to be changed during the instance creation ends here

  GET_USER_COUNT: "/getUserCount",
  GET_PROGRAMS: "/listPrograms",
  GET_BATCHES: "/getBatches",
  GET_USERS: "/getUsers",
  GET_COURSE_DETAILS: "/getTopicDetails",
  GET_Programs_Details: "/getProgramsDetails",
  GET_PROGRAM_BATCH_REPORT: "/getProgramBatchReport",
  GET_PROGRAM_BATCH_USERS: "/getProgramBatchUsers",
  GET_ADDUSER: "/addUsers",
  GET_COURSELIST: "/getCourseList",
  USER_BULKUPLOAD: "/addBulkUsers",
  GET_UPDATEUSER: "/updateUserDetails",
  POST_ANNOUNCEMENT: "/PostAnnouncement",
  GET_EVENTS: "/getevents",
  ADD_EVENTS: "/addEvent",
  GET_TOPIC: "/gettopic",
  UPDATE_USER_APPLICATION: "/updateUserApplication",
  GET_MINI_ASSIGNMENTS: "/getMiniAssignments",
  GET_ELGETASSESSMENTREPORT: "/EL-getAssessmentReport",
  GET_ASSESMENT: "/EL-getAssessment",
  GET_UPDATE_APPLICATION_STATUS: "/updateApplicationStatus",
  app_orange_theme: "#e35114",
  ADD_USER_TO_BATCH:"/addUserToBatch",

  // JSS
  ALL_PROGRAM: "/getAllProgramList",
  SYNC_PROGRAM: "/syncUserProgramProgress",
  GET_COURSE: "/getCourse",
  SYNC_USER_PROGRESS: "/syncUserProgress",
  GET_APPLICATION: "/getApplication",
  GCP_RESPONSE: "/getGoogleCloudResponse",
  GET_HTTP_RESPONSE: "/getHttpResponse",
  ANALYTICS_WEB_APP: "/analyticsWebApp",
  GET_QUIZ: "/getQuiz",
  ADD_ASSESSMENT: "/EL-addAssessment",
  GET_NOTIFICATIONS: "/getNotifications",
  GET_ASSESSMENT: "/getAssessment",
  UPDATE_ASSESSMENT: "/updateAssessment",
  UPDATE_MINI_ASSIGNMENT: "/uploadAssignment",
  GET_ASSESSMENT_QUIZ: "/getAssessmentQuiz",
  GET_ASSIGNMENT_DATA: "/getAssignmentData",
  GET_MAIL_TEMPLATE: "/getmailTemplates",
  UPDATE_EMAIL_TEMPLATES: "/updatemailTemplates",
  GET_UPDATEINSTRUCTORBATCH: "/updateInstructorBatch",
  GET_PRESIGNED_URL: "/getPreSignedURL",
  GET_USER_PROGRESS: "/getuserprogress",
  GET_USER_TOTAL_PROGRESS: "/getUserTotalProgress",
  GET_MY_TOPICS: "/getMyTopics",
  GET_ALL_QUIZ_SCORE: "/getAllQuizScores",
  GET_UPDATEUSERSATUS: "/updateUserStatus",
  GET_ELUPDATEINSTRUCTORBATCH: "/EL-updateInstructorBatch",
  GET_UPDATE_ASSESMENT_REPORT: "/EL-updateAssessmentReport",
  GET_ASSESSMENT_ANSWERS: "/EL-getAssessmentQuizReport",
  COURSE_VIDEO: "/courseVideo",
  REFERENCES: "/references",
  app_device_token: "",
  AWS_ORG_API_PATH: config.aws_cloud_logic_custom_endpoint_E,
  AWS_IMAGES_URL: "https://d1hfr1iyugj21x.cloudfront.net/",
  AWS_CLOUDFRONT_URL: "https://d1hfr1iyugj21x.cloudfront.net",
  COOKIE_URL: "https://d1hfr1iyugj21x.cloudfront.net",
  AWS_API_PATH: "JS-PLATFORM",
  GET_USER_TOPIC_PROGRESS: "/getUserDataMobile",
  SYNC_USER_TOPIC_PROGRESS: "/syncUserDataMobile",
  SYNC_STAR_RATE: "/syncUserStarRate",
  UPDATE_ANALYTICS: "/analyticsWebApp",
  GET_ORG_DETAILS: "/getOrgDetails",
  GET_USER_TOPICS: "/getMyTopics",
  // GET_PRESIGNED_URL: "/getPreSignedURL",
  GET_FEATURED_CONTENT: "/getFeaturedContent",
  GET_CATEGORIES: "/edGetCategories",
  GET_CATEGORY_DATA: "/edGetCategoryData",
  GET_SEARCH_TOPICS: "/searchTopics",
  GET_LIST_TOPICS: "/listTopics",
  GET_LIVE_SESSION: "/getLiveSessions",
  GET_OBJECT: "/getObjectMobile",
  GET_INSTRUCTOR_SESSION_COUNT: "/getInstructorSessionCount",
  GET_INSTRUCTOR_SESSION: "/getInstructorSessions",
  UPDATE_COURSE_ANALYTICS: "/updateCourseAnalytics",
  // GET_TOPIC: "/getTopic",
  GET_NUGGET: "/getNuggetMobile",
  GETTOPICID: "/getTopicID",
  CREATETOPIC: "/createTopic",
  GET_USER_CERTIFICATES: "/listUserCerts",
  TERMS_CONDITIONS: "https://www.enhanzed.com/t-c",
  HELP_SUPPORT: "https://www.enhanzed.com/help",
  FEEDBACK: "https://www.enhanzed.com/feedback",
  AUTH_COGNITO_EVENT: "AuthenticatedViaCognito",
  USER_AREASOFINTEREST: "Areas Of Interest",
  TOPIC_STARTED: "Topic Started",
  TOPIC_COMPLETED: "Topic Completed",
  UPDATE_USER_CERTIFICATES: "/generateUserCert",
  VIEW_USER_CERTIFICATE: "/getUserCert",
  CERT_GENERATED: "Certificate Generated",
  QUIZ_SCORE: "/getQuizScore",
  POST_QUIZ: "/postQuizScore",
  CLAIM_BADGE: "/claimBadge",
  GET_USER_OBJECT_LIST: "/getUsersObjectsData",
  GET_OBJECT_RATING: "/getObjectRating",
  ADD_BOOKMARK: "/addBookMark",
  DELETE_BOOKMARK: "/deleteBookmark",
  SYNC_OBJECT_RATING: "/syncObjectsData",
  LIST_BOOKMARKS: "/listBookmark",
  BOOKMARK_STATUS: "/getBookmarkStatus",
  UPDATE_OBJECT_ANALYTICS: "/ObjectsAnalytics",
  GET_RECENT_VIEWS: "/getRecenetView",
  UPDATE_VIEWS: "/updateRecentViewed",
  DELETE_TOKEN: "/deleteToken",
  DAILY_FILE_UPLOAD: "/addDailyDebrief",

  UPDATE_USER_REGISTRATION: "/addRegisterEvent",
  GET_REGISTERED_EVENTS: "/getRegisteredEvents",
  GET_DAILYGOAL: "/getDailyGoal",
  SET_DAILYGOAL: "/setDailyGoal",
  VIMEO_URL: "https://player.vimeo.com/video/",
  YOUTUBE_URL: "https://www.youtube.com/embed/",
  SHARE_URL: "https://www.learn.enhanzed.com/#/sharingobject?",
};
