// Dependencies imports
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Skeleton from "@material-ui/lab/Skeleton";
import { useNavigate } from 'react-router-dom';

// Local imports
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";



// Styles import
import Assesg from './AssesmentGrid.module.scss';


// Data for Faculty Grid
const columns = [
    {
        field: 'user',
        headerName: 'Username',
        width: 350,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'application',
        headerName: 'Application ID',
        width: 350,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'submitted',
        headerName: 'Submitted On',
        width: 250,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    },
    {
        field: 'status',
        headerName: 'Application Status',
        width: 250,
        flex: 1,
        headerClassName: 'super-app-theme--header',
    }
];



const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: config.main_color_1,
            fontSize: "16px",
            color: 'rgb(255,255,255)',
            fontFamily: "nunito"
        },
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        cursor: "pointer"
    }
});

// Start of Faculty Grid Component
const ApplicationGrid = () => {
    const [loading, setLoading] = useState(true);
    const [applicationData, setApplicationData] = useState([]);
    const classes = useStyles();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    let userDetails = useSelector(authData);

    // Useeffect for Application api
    useEffect(() => {
        getApplication();
        return () => { };
    }, []);

    function onClickOfPage(name,path){
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name: name,
            path: path
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    // function for toolbar options above datagrid
    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbar />
            </GridToolbarContainer>
        );
    }


    // Api call to get application details
    async function getApplication() {
        setLoading(true);
        const bodyParam = {
            body: {
                action: 1,
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
            bodyParam.body.eid = userDetails.eid;
        }
        try {
            console.log(bodyParam.body);
            const application = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_Programs_Details,
                bodyParam
            );
            console.log("applications", application);
            convertData(application.applications);

        }
        catch (error) {
            console.error(error);
        }
    }

    function convertData(data) {
        console.log(data);
        let temp = [];
        for (let i = 0; i < data.length; i++) {
            let obj = {};
            //          { id: 1, User: 'Naresh', application: 'JSSOEP12344', submitted: "11.7.2020", status: "under review" },
            //          appid: "0019862108-1591863084"
            // eid: "be8546de-8e01-42c3-a252-98187e29e3a3"
            // sdate: "11.6.2020"
            // status: 3
            // uname: "naresh"

            obj.id = i;
            obj.user = data[i].uname;
            obj.application = data[i].fappid;
            obj.submitted = data[i].sdate;
            obj.status = data[i].status == 2 ? 'Under Review' : data[i].status == 3 ? 'Approved' : data[i].status == 4 ? 'Rejected' : '';
            obj.eid = data[i].eid;
            obj.appid = data[i].appid;

            temp.push(obj);

        }
        setApplicationData(temp);
        setLoading(false);
    }

    const handleCellClick = (param, event) => {
        console.log(param.row);
        let sdata = { ...userDetails };
        sdata.curapplication = { ...param.row };
        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name: "Application Details",
            path: "/applicationdetails"
        };

        sdata.breadcrumb = temp;
        dispatch(awsSignIn(sdata));
        
        navigate("/applicationdetails");
    }

    return (
        <div className={Assesg.maindiv}>
            <div className={Assesg.topbar}>

            </div>
            {loading === false ?
                <Paper>
                    <div style={{ height: "600px", width: '100%', fontSize: "20px" }} className={classes.root}>
                        <DataGrid
                            rows={applicationData}
                            columns={columns}
                            components={{ Toolbar: CustomToolbar }}
                            className={classes['.MuiDataGrid-root']}
                            onCellClick={handleCellClick}
                            disableSelectionOnClick
                        />
                    </div>
                </Paper> : <div style={{ display: "flex", justifyContent: "flexStart", alignItems: "center", paddingTop: "40px" }}><Skeleton variant="rect" width="100%" height={600} /> </div>}
        </div>
    );
}

export default ApplicationGrid;