// Dependencies imports
import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import UsersIcon from '../../assets/svgjs/UsersIcon';
import FacultyIcon from "../../assets/svgjs/FacultyIcon";
import ContentIcon from "../../assets/svgjs/ContentIcon";
import ReportIcon from '../../assets/svgjs/ReportIcon';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { awsSignIn, authData } from "../../redux/auth/authSlice";

// Styles imports

import Manage from './ManageBatch.module.scss';

// Start of Manage batch component
const ManageBatch = () => {
    const dispatch = useDispatch();
    let userDetails = useSelector(authData);
    useEffect(() => {
        breadcrumb();
    }, []);

    function breadcrumb() {
        let sdata = { ...userDetails };
        if (sdata.breadcrumb == undefined) {
            sdata.breadcrumb = [];
            let temp = [...sdata.breadcrumb];
            temp[0] = {
                name: 'Manage Programs',
                path: '/Programs'
            };
            temp[1] = {
                name: `${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
                path: '/ManageBatch'
            };
            sdata.breadcrumb = temp;
        } else {
            let temp = [...sdata.breadcrumb];
            temp[0] = {
                name: 'Manage Programs',
                path: '/Programs'
            };
            temp[1] = {
                name: `${sdata.curprgcou.bname}-${sdata.curprgcou.ptitle}`,
                path: '/ManageBatch'
            };
            sdata.breadcrumb = temp;
        }
        dispatch(awsSignIn(sdata));
    }

    function onClickOfPage(name, path) {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];

        temp[2] = {
            name: name,
            path: path
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }


    return (
        <div className={Manage.containermain}>
            <UserHeader Bindex={1} />
            <div>
                <h3 className={Manage.heading}>{`
                ${userDetails.curprgcou.bname}-${userDetails.curprgcou.ptitle} - ${userDetails.curprgcou.ctype == 'pcourse' ? 'Provider Course' : 'Instructor Course'}`} </h3>
            </div>
            <Paper>
                <div className={Manage.topsection}>
                    <div className={Manage.leftsection}>
                        <div className={Manage.individual}>
                            <div className={Manage.leftside}>
                                <h4 className={Manage.typeleft}>State:</h4>
                            </div>
                            <div className={Manage.rightside} >
                                <h5 className={Manage.typeright}>

                                    {userDetails.curprgcou.sname}
                                </h5>
                            </div>
                        </div>
                        <div className={Manage.individual}>
                            <div className={Manage.leftside}>
                                <h4 className={Manage.typeleft}>District:</h4>
                            </div>
                            <div className={Manage.rightside}>
                                <h5 className={Manage.typeright}>

                                    {userDetails.curprgcou.disname}
                                </h5>
                            </div>
                        </div>
                        <div className={Manage.individual}>
                            <div className={Manage.leftside}>
                                <h4 className={Manage.typeleft}>Location:</h4>
                            </div>
                            <div className={Manage.rightside}>
                                <h5 className={Manage.typeright}>

                                    {userDetails.curprgcou.cname}
                                </h5>
                            </div>
                        </div>

                    </div>
                    {userDetails.curprgcou.etype !== 3 ? <div className={Manage.rightsection}>
                        <div className={Manage.individual}>
                            <div className={Manage.leftside}>
                                <h4 className={Manage.typeleft}>Program start date:</h4>
                            </div>
                            <div className={Manage.rightside}>
                                <h5 className={Manage.typeright}>{moment(userDetails.curprgcou.startdate).format("DD MMM YYYY")}</h5>
                            </div>
                        </div>
                        <div className={Manage.individual}>
                            <div className={Manage.leftside}>
                                <h4 className={Manage.typeleft}>Program end date:</h4>
                            </div>
                            <div className={Manage.rightside}>
                                <h5 className={Manage.typeright}>{moment(userDetails.curprgcou.enddate).format("DD MMM YYYY")}</h5>
                            </div>
                        </div>

                        <div className={Manage.individual}>
                            <div className={Manage.leftside}>
                                <h4 className={Manage.typeleft}>Enrollment Start date:</h4>
                            </div>
                            <div className={Manage.rightside}>
                                <h5 className={Manage.typeright}>{moment(userDetails.curprgcou.enrollenddate).format("DD MMM YYYY")}</h5>
                            </div>
                        </div>
                    </div> : <div className={Manage.rightsection}></div>}
                </div>
            </Paper>
            {/* <div className={Manage.bottomsection}> */}
            <div className={Manage.firstrow}>
                <Paper>
                    <div className={Manage.cardindividual}>
                        <div className={Manage.icontittle}>
                            <div className={Manage.iconholder}>
                                <UsersIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls2} />
                            </div>
                            <Link to={`/UserStats`} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Users', '/UserStats'); }}>Users</h2></Link>
                        </div>
                        <ul className={Manage.listblock}>
                            <li className={Manage.listitems}>User Analytics</li>
                            <li className={Manage.listitems}>List of users</li>
                        </ul>
                    </div>
                </Paper>
                <Paper>
                    <div className={Manage.cardindividual}>
                        <div className={Manage.icontittle}>
                            <div className={Manage.iconholder}>
                                <FacultyIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls2} />
                            </div>
                            <Link to={`/Faculty`} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Faculty', '/Faculty'); }}>Faculty</h2></Link>
                        </div>
                        <ul className={Manage.listblock}>
                            <li className={Manage.listitems}>Program coordinators</li>
                            <li className={Manage.listitems}>Instructors</li>
                        </ul>
                    </div>
                </Paper>
                {/* {userDetails.curprgcou.ptype == 1 */}
                {true ? <Paper>
                    <div className={Manage.cardindividual}>
                        <div className={Manage.icontittle}>
                            <div className={Manage.iconholder}>
                                <ContentIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls2} />
                            </div>
                            <Link to={`/Content`} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Content', '/Content'); }}>Content</h2></Link>
                        </div>
                        <ul className={Manage.listblock}>
                            <li className={Manage.listitems}>View all courses</li>
                            <li className={Manage.listitems}>Enable/Disable a course</li>
                        </ul>
                    </div>
                </Paper> : <Paper>
                    <div className={Manage.cardindividualdisabled}>
                        <div className={Manage.icontittle}>
                            <div className={Manage.iconholder}>
                                <ContentIcon className={Manage.manageiconsdisabled} cls1={Manage.cls1dis} cls2={Manage.cls2dis} />
                            </div>
                            <h2 className={Manage.cardheadingdis} >Content</h2>
                        </div>
                        <ul className={Manage.listblock}>
                            <li className={Manage.listitems}>View all courses</li>
                            <li className={Manage.listitems}>Enable/Disable a course</li>
                        </ul>
                    </div>
                </Paper>}
                <Paper>
                    <div className={Manage.cardindividual}>
                        <div className={Manage.icontittle}>
                            <div className={Manage.iconholder}>
                                <ReportIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls2} />
                            </div>
                            <Link to={`/Reports`} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Reports', '/Reports'); }}>Reports</h2></Link>
                        </div>
                        <ul className={Manage.listblock}>
                            <li className={Manage.listitems}>Attendance</li>
                            <li className={Manage.listitems}>User, Exam, Assignment and Content reports</li>
                        </ul>
                    </div>
                </Paper>
                {/* <Paper>
                        <div className={Manage.cardindividual}>
                            <div className={Manage.icontittle}>
                                <div className={Manage.iconholder}>
                                    <DiscussionIcon className={Manage.manageicons} cls1={Manage.cls1} />
                                </div>
                                <Link to={`/discussion`} style= { {pointerEvents: 'none'}} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Discussion', '/discussion'); }}>Discussions</h2></Link>
                            </div>
                            <ul className={Manage.listblock}>
                                <li className={Manage.listitems}>Post questions</li>
                                <li className={Manage.listitems}>View discussions</li>
                            </ul>
                        </div>
                    </Paper> */}
            </div>
            {/* <div className={Manage.firstrow}>
                    <Paper>
                        <div className={Manage.cardindividual}>
                            <div className={Manage.icontittle}>
                                <div className={Manage.iconholder}>
                                    <AnnouncementsIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls2} />
                                </div>
                                <Link to={`/announcement`} style= { {pointerEvents: 'none'}} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Announcements', '/announcement'); }}>Announcements</h2></Link>
                            </div>
                            <ul className={Manage.listblock}>
                                <li className={Manage.listitems}>Add announcement / webinar</li>
                                <li className={Manage.listitems}>View all announcements</li>
                            </ul>
                        </div>
                    </Paper>
                    <Paper>
                        <div className={Manage.cardindividual}>
                            <div className={Manage.icontittle}>
                                <div className={Manage.iconholder}>
                                    <EventIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls2} />
                                </div>
                                <Link to={`/events`} style= { {pointerEvents: 'none'}} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Events', '/events'); }}>Events</h2></Link>
                            </div>
                            <ul className={Manage.listblock}>
                                <li className={Manage.listitems}>Add / edit events</li>
                                <li className={Manage.listitems}>View all events</li>
                            </ul>
                        </div>
                    </Paper>
                    <Paper>
                        <div className={Manage.cardindividual}>
                            <div className={Manage.icontittle}>
                                <div className={Manage.iconholder}>
                                    <EmailIcon className={Manage.manageicons} cls1={Manage.cls1} />
                                </div>
                                <Link to={`/emailtemplate`} style= { {pointerEvents: 'none'}} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Email-Template', '/emailtemplate'); }}>E - mail template</h2></Link>
                            </div>
                            <ul className={Manage.listblock}>
                                <li className={Manage.listitems}>Edit email template</li>
                            </ul>
                        </div>
                    </Paper>
                    <Paper>
                        <div className={Manage.cardindividual}>
                            <div className={Manage.icontittle}>
                                <div className={Manage.iconholder}>
                                    <GradingIcon className={Manage.manageicons} cls1={Manage.cls1} cls2={Manage.cls4} cls3={Manage.cls3} />
                                </div>
                                <Link to={`/Grading`} style= { {pointerEvents: 'none'}} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Grading', '/Grading'); }}>Grading</h2></Link>
                            </div>
                            <ul className={Manage.listblock}>
                                <li className={Manage.listitems}>Miniassignments</li>
                                <li className={Manage.listitems}>Assignments</li>
                                <li className={Manage.listitems}>Assesments</li>
                            </ul>
                        </div>
                    </Paper>
                 
                </div> */}
            <div className={Manage.firstrow}>


                <Paper>

                    <div className={Manage.cardindividual}>
                        <div className={Manage.icontittle}>
                            <div className={Manage.iconholder}>
                                <FeedbackOutlinedIcon sx={{ color: '#6E2B69', width: "35px", height: '35px' }} />
                            </div>
                            <Link to={`/CreateFeedback`} className={Manage.links}><h2 className={Manage.cardheading} onClick={() => { onClickOfPage('Feedback', '/CreateFeedback'); }}>Feedback</h2></Link>
                        </div>
                        <ul className={Manage.listblock}>
                            <li className={Manage.listitems}>Add Feedback</li>
                        </ul>
                    </div>
                </Paper>

                <Paper style={{ visibility: 'hidden' }}>
                    <div className={Manage.cardindividual}>

                    </div>
                </Paper>

            </div>
            {/* </div> */}
        </div >
    );
};

export default ManageBatch;