// Dependencies imports
import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar } from '@material-ui/data-grid';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import Skeleton from "@material-ui/lab/Skeleton";
import Button from '@material-ui/core/Button';
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import swal from 'sweetalert';
import AddCoordinator from './AddCoordinator';
// Local imports
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import RemovePopup from './RemovePopup';
import Fac from './Faculty.module.scss'

const useStyles = makeStyles({
    root: {
        '& .super-app-theme--header': {
            backgroundColor: config.main_color_1,
            fontSize: "16px",
            color: "#fff",
            fontFamily: "nunito"
        },
    },
    root1: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
    ".MuiDataGrid-root": {
        fontSize: "14px",
        fontFamily: "nunito",
        fontWeight: 400,
        color: "black",
        '& .MuiDataGrid-sortIcon': {
            // Add your custom styles for the sort icon here
            color: 'white', // Example: Change the color to red
            fontSize: '20px', // Example: Change the font size
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },
});

// Start of Coordinator Grid Component
const CoordinatorGrid = (props) => {
    let { Instructors, getUsersDetails } = props;
    const [loading, setLoading] = useState(true);
    const [facultyData, setFacultyData] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRemoveCoordinator, setCurrentRemoveCoordinator] = useState("");
    const [roles, setRoles] = useState([]);
    const classes = useStyles();
    const [spin, setSpin] = useState(false);
    const [openCoordinator, setopenCoordinator] = useState(false);

    let userDetails = useSelector(authData);

    // Useeffect for coordinator details api
    useEffect(() => {
        getFaculty();
        return () => { };
    }, []);

    // function for toolbar options above datagrid
    function CustomToolbar() {
        return (
            <GridToolbarContainer style={{ display: "flex", justifyContent: "flex-end" }}>
                <GridToolbar />
            </GridToolbarContainer>
        );
    }

    // Api call to get coordinator details
    async function getFaculty() {
        setLoading(true);
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                schema: config.schema,
                role: 'CDirector'
            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            const faculty = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_PROGRAM_BATCH_USERS,
                bodyParam
            );
            convertData(faculty.body);
        }
        catch (error) {
            console.error(error);
        }
    }

    function convertData(data) {
        let temp = [];
        data.forEach((user, index) => {
            const { roles } = user;
            const role = roles?.find(role => role.batchid === userDetails?.curprgcou.bpid && role.role === 'CDirector');
            if (role) {
                const { first_name, last_name = '', emailid, ur, roles } = user;
                const obj = {
                    id: index,
                    name: `${first_name} ${last_name}`,
                    email: emailid,
                    userrole: ur,
                    roles: roles
                };
                temp.push(obj);
            }
        });
        setFacultyData(temp);
        setLoading(false);
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenCoordinator = () => {
        setopenCoordinator(true);
    }

    const handleClickCloseCoordinator = () => {
        setopenCoordinator(false);
    }

    async function removeCoordinator() {
        setSpin(true);
        let rolls = [...roles];
        if (roles?.length > 0) {
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].batchid == userDetails?.curprgcou?.bpid) {
                    rolls.splice(i, 1);
                }
            }
        }
        const bodyParam = {
            body: {

                oid: config.aws_org_id,
                cid: userDetails.curprgcou.bpid,
                emailid: [currentRemoveCoordinator],
                batch: userDetails.curprgcou.bname,
                batchid: userDetails.curprgcou.bpid,
                atype: 2,
                role: "CDirector",
                roles: rolls
            },

            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.ADD_USER_TO_BATCH,
                bodyParam
            );

            handleClose();

            if (response.statusCode === "200" || response.statusCode === 200) {
                swal({
                    title: "Success",
                    text: "Course Director successfully removed from the batch!",
                    icon: "success",
                    dangerMode: false,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            handleClose();
                            getFaculty();
                            setSpin(false);

                        }
                    });

            } else if (response.statusCode === "0" || response.statusCode === 0) {

                swal({
                    title: "Oooops!",
                    text: "Course Director already exist in the batch!",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);

                        }
                    });

            } else if (response.statusCode === "300" || response.statusCode === 300) {
                swal({
                    title: "Success",
                    text: "Course Director successfully removed from the batch!",
                    icon: "success",
                    dangerMode: false,
                })
                    .then(willDelete => {
                        if (willDelete) {
                            setSpin(false);
                            handleClose();
                            getFaculty();
                        }
                    });

            } else if (response.statusCode === "5" || response.statusCode === 5) {
                swal({
                    title: "Oooops!",
                    text: "This user is not an Course Director !",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {

                        }
                    });
            } else {
                swal({
                    title: "Oooops!",
                    text: "Course Director does not exist!",
                    icon: "warning",
                    dangerMode: true,
                })
                    .then(willDelete => {
                        if (willDelete) {


                            swal({
                                title: "Contact Developer",

                            })
                        }
                    });

            }
            setSpin(false);
            getFaculty();
            getUsersDetails();
        } catch (error) {
            setSpin(false);
            swal({
                title: "Oooops!",
                text: "Soomthing wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        swal("Oooops!", "Contact developer")
                    }
                });

        }
    }

    // Data for Coordinator Grid column
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 600,
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 600,
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: 'ins',
            headerName: 'Institution',
            width: 600,
            headerClassName: 'super-app-theme--header',
            flex: 1
        },
        {
            field: "",
            headerName: 'Remove Course Director',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            width: 400,
            renderCell: (params) => (
                <strong>

                    {(userDetails.role != "Admin") ? null : <Button
                        variant="contained"
                        size="small"
                        style={{ marginLeft: 16, background: config.main_color_2, color: "white", fontSize: "14px", fontFamily: "nunito" }}
                        disabled={!window.navigator.onLine}
                        onClick={() => { handleOpen(); setCurrentRemoveCoordinator(params.row.email); setRoles(params.row.roles) }}
                    >
                        Remove
                    </Button>}
                </strong>
            ),
        },
    ];

    return (
        <div style={{ height: 500, width: '100%' }}>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <RemovePopup handleClose={handleClose} onSubmit={removeCoordinator} spin={spin} setState={setSpin} getUsersDetails={getUsersDetails} />
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openCoordinator}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openCoordinator}>
                    <AddCoordinator handleClose={handleClickCloseCoordinator} getFaculty={getFaculty} facultyData={facultyData}
                        Instructors={Instructors} getUsersDetails={getUsersDetails} />
                </Fade>
            </Modal>
            <div className={Fac.buttonholder}>

                {(userDetails.role == 'Admin' && facultyData.length == 0) ? <button className={window.navigator.onLine === true ? Fac.btn_color : Fac.btn_colordis} disabled={!window.navigator.onLine} onClick={handleClickOpenCoordinator} >Add</button> : null}
            </div>
            <div style={{ height: 450, width: '100%' }} className={classes.root}>

                {loading === false ?
                    <DataGrid
                        rows={facultyData}
                        columns={columns}
                        components={{ Toolbar: CustomToolbar }}
                        disableSelectionOnClick
                        disableColumnMenu
                        className={classes['.MuiDataGrid-root']}
                    /> : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}><Skeleton variant="rect" width="100%" height={350} /> </div>}
            </div>
        </div >
    );
}

export default CoordinatorGrid;