// Dependencies imports
import React, { useState, useEffect } from "react";
import { Tab } from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { API, JS } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { ReactComponent as Video } from "../../assets/svg/content.svg";
import { ReactComponent as Doc } from "../../assets/svg/Syllabus.svg";
import { ReactComponent as Info } from "../../assets/svg/Program-info.svg";
import ProgressChart from "./ProgressChart";
import Image from "../../assets/images/i3.png";
import AccordionPopup from "./AccordionPopup";

// Styles imports
import User from "./UserProgress.module.scss";

// Internal Styles for the material UI components
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: config.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
};

// Materail Styles for Modal
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      background: config.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});

const UserProgress = () => {
  const [userData, setUserData] = useState({
    pid: "0",
    bpid: "0",
    pname: "",
    va: "0",
    dc: "0",
    kc: "0",
    ovp: "0",
  });
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  let userDetails = useSelector(authData);

  useEffect(() => {
    getuserprogress();
    breadcrumb();
    return () => {};
  }, []);

  async function getuserprogress() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        ur_id: userDetails.selbatchuser.ur_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      let topic = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USER_PROGRESS,
        bodyParam
      );
      console.log(JSON.stringify(topic))
      if (topic == "No Topics") {
        let obj = { myTopics: [] };
        //return obj;
        convertData(obj);
        setLoading(false);
      } else {
        var topicids = [];
        var tp = [];
        var otopics = [];

        if (topic[userDetails.curprgcou.bpid] != undefined) {
          topicids.push(userDetails.curprgcou.bpid);
        }

        if (topicids.length == 0) {
          let obj = { myTopics: [] };
          convertData(obj);
          setLoading(false);
        } else {
          // getMyTopics(topicids, topic);
          let data = topic[userDetails.curprgcou.bpid];
          topic.myTopics = [];
          let obj = {
            tid: data.pd.pid,
            bpid: data.pd.bpid,

            pname: data.pd.ptitle,
          };
          topic.myTopics.push(obj);

          topic.progress = data;

          convertData(topic);
          getAllQuizScores();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getMyTopics(topicids, topic) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
        topicids: topicids,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const topic2 = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_MY_TOPICS,
        bodyParam
      );

      let data = topic[userDetails.curprgcou.bpid];
      topic2.myTopics = [];
      let obj = {
        tid: data.pd.pid,
        bpid: data.pd.bpid,

        pname: data.pd.ptitle,
      };
      topic2.myTopics.push(obj);
      //topic2.otopics = otopics;
      topic2.progress = data;

      convertData(topic2);
      getAllQuizScores();
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllQuizScores() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.selbatchuser.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const users = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ALL_QUIZ_SCORE,
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function convertData(data) {
    let myapplication = data;
    let tobj = 0,
      tcobj = 0,
      tVideoObj = 0,
      tcVideoObj = 0,
      tAudioObj = 0,
      tcAudioObj = 0,
      tPdfObj = 0,
      tcPdfObj = 0,
      tHtmlObj = 0,
      tcHtmlObj = 0,
      tInteractivityObj = 0,
      tcInteractivityObj = 0,
      tYoutubeObj = 0,
      tcYoutubeObj = 0,
      tVimeoObj = 0,
      tcVimeoObj = 0,
      tQuizObj = 0,
      tcQuizObj = 0,
      tFFQuizObj = 0,
      tcFFQuizObj = 0,
      tSQuizObj = 0,
      tcSQuizObj = 0,
      tFQuizObj = 0,
      tcFQuizObj = 0;
    let tobjprogress, avprogress, dprogress, kprogress;

    if (myapplication.progress != undefined) {
      let object1 = myapplication.progress.courses;

      for (const [key, value] of Object.entries(object1)) {
        if (value.total != undefined) {
          tobj = tobj + value.total.tobj;
          tcobj = tcobj + value.total.tobj;
          tVideoObj = tVideoObj + value.total.tVideoObj;
          tcVideoObj = tcVideoObj + value.total.tcVideoObj;
          tAudioObj = tAudioObj + value.total.tAudioObj;
          tcAudioObj = tcAudioObj + value.total.tcAudioObj;
          tPdfObj = tPdfObj + value.total.tPdfObj;
          tcPdfObj = tcPdfObj + value.total.tcPdfObj;
          tHtmlObj = tHtmlObj + value.total.tHtmlObj;
          tcHtmlObj = tcHtmlObj + value.total.tcHtmlObj;
          tInteractivityObj = tInteractivityObj + value.total.tInteractivityObj;
          tcInteractivityObj =
            tcInteractivityObj + value.total.tcInteractivityObj;
          tYoutubeObj = tYoutubeObj + value.total.tYoutubeObj;
          tcYoutubeObj = tcYoutubeObj + value.total.tcYoutubeObj;
          tVimeoObj = tVimeoObj + value.total.tVimeoObj;
          tcVimeoObj = tcVimeoObj + value.total.tcVimeoObj;
          tQuizObj = tQuizObj + value.total.tQuizObj;
          tcQuizObj = tcQuizObj + value.total.tcQuizObj;
        }

        tobjprogress = tobj != 0 ? (tcobj * 100) / tobj : 0;
        let tav = tVideoObj + tAudioObj + tYoutubeObj + tVimeoObj;
        let tcav = tcVideoObj + tcAudioObj + tcYoutubeObj + tcVimeoObj;
        avprogress = tav != 0 ? (tcav * 100) / tav : 0;
        let td = tPdfObj + tHtmlObj + tInteractivityObj;
        let tcd = tcPdfObj + tcHtmlObj + tcInteractivityObj;
        dprogress = td != 0 ? (tcd * 100) / td : 0;
        kprogress = tQuizObj != 0 ? (tcQuizObj * 100) / tQuizObj : 0;
      }
    }

    if (myapplication.progress != undefined) {
      if (myapplication.myTopics[0] != undefined) {
        myapplication.myTopics[0].vprog = avprogress.toFixed(2);
        myapplication.myTopics[0].dprog = dprogress.toFixed(2);
        myapplication.myTopics[0].sqprog = kprogress.toFixed(2);
        myapplication.myTopics[0].progress = parseInt(tobjprogress.toFixed(2));

        let obj = {
          pid: myapplication.myTopics[0].tid,
          bpid: myapplication.myTopics[0].bpid,
          pname: myapplication.myTopics[0].pname,
          va: avprogress.toFixed(2),
          dc: dprogress.toFixed(2),
          kc: kprogress.toFixed(2),
          ovp: parseInt(tobjprogress.toFixed(2)),
        };
        setUserData(obj);
        setLoading(false);
      }
    }
  }

  // Data for tabs
  const panes = [
    {
      menuItem: "Overview",
      render: () => {
        return <Tab.Pane>{overview()}</Tab.Pane>;
      },
    },
  ];

  function overview() {
    return (
      <>
        <div className={User.topsection}>
          <Paper>
            <div className={User.container}>
              <div className={User.icon}>
                <Video />
              </div>
              <h2 className={User.title}>Videos/audios</h2>
              <h3 className={User.percent}>
                {loading === false ? (
                  userData.va + " %"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width={50} height={28} />{" "}
                  </div>
                )}{" "}
              </h3>
            </div>
          </Paper>
          <Paper>
            <div className={User.container}>
              <div className={User.icon}>
                <Doc />
              </div>
              <h2 className={User.title}>Documents</h2>
              <h3 className={User.percent}>
                {loading === false ? (
                  userData.dc + " %"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width={50} height={28} />{" "}
                  </div>
                )}{" "}
              </h3>
            </div>
          </Paper>
          <Paper>
            <div className={User.container}>
              <div className={User.icon}>
                <Info />
              </div>
              <h2 className={User.title}>Knowledge Checks</h2>
              <h3 className={User.percent}>
                {loading === false ? (
                  userData.kc + " %"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rect" width={50} height={28} />{" "}
                  </div>
                )}
              </h3>
            </div>
          </Paper>
          {/* <Paper>
                        <div className={User.container}>
                            <div className={User.icon}>
                                <Doc />
                            </div>
                            <h2 className={User.title}>Assesments</h2>
                            <h3 className={User.percent}>40.00%</h3>
                        </div>
                    </Paper>
                    <Paper>
                        <div className={User.container}>
                            <div className={User.icon}>
                                <Grading />
                            </div>
                            <h2 className={User.title}>Assignments</h2>
                            <h3 className={User.percent}>60.54%</h3>
                        </div>
                    </Paper> */}
        </div>
        <Paper>
          {userData !== undefined && (
            <div className={User.middlesection}>
              {loading === false ? (
                <ProgressChart progress={userData.ovp} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rect" width={150} height={150} />{" "}
                </div>
              )}
            </div>
          )}
        </Paper>

        {userData.pid != "0" && (
          <div className={User.table}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ background: config.main_color_1 }}>
                  <TableRow>
                    <TableCell style={header}>Program Image</TableCell>
                    <TableCell style={header}>Program Name</TableCell>
                    {/* <TableCell style={header}>Started on</TableCell>
                                    <TableCell style={header}>Last Access on</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ width: "30%" }}>
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton variant="rect" width={210} height={120} />{" "}
                        </div>
                      ) : (
                        <img
                          src={`https://${
                            config.DOMAIN
                          }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${
                            userData.pid
                          }.png`}
                          className={User.image}
                        />
                      )}
                    </TableCell>
                    <TableCell style={{ width: "30%" }}>
                      <h3
                        onClick={() => {
                          handleClickOpen();
                        }}
                        style={programnames}
                        className={User.programname}
                      >
                        {loading === false ? (
                          userData.pname
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Skeleton variant="text" width={300} height={20} />{" "}
                          </div>
                        )}
                      </h3>
                    </TableCell>
                    {/* <TableCell>
                                        <h4 style={programtype}>21/02/2021</h4>
                                    </TableCell>
                                    <TableCell>
                                        <h4 style={programtype}>21/02/2021</h4>
                                    </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </>
    );
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: sdata.selbatchuser.name,
      path: "/userprogress",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={User.maincontainer}>
      <UserHeader Bindex={3} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AccordionPopup handleClose={handleClose} />
        </Fade>
      </Modal>
      <h3 className={User.header}>User Progress</h3>
      <div className={User.tabholder}>
        <Tab panes={panes} />
      </div>
    </div>
  );
};

export default UserProgress;
