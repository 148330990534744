// Dependencies imports
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import swal from "sweetalert";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";

// mui

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import moment from "moment";
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { ReactComponent as Delete } from "../../assets/svg/delete_black_24dp.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit_black_24dp.svg";
import AddEvents from "./AddEvents";
import EditEvents from "./EditEvents";
import DeleteEvents from "./DeleteEvents";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";

//Styles imports
import Ann from "./Announcement.module.scss";
import LiveSession from "./LiveSession";

import { Close } from "@mui/icons-material";
import Tippy from "@tippyjs/react";
const ADD_TYPES = {
  EVENT: "EVENT",
  LIVE_SESSION: "LIVE_SESSION",
};
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: config.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
  width: "20%",
};

const programtype = {
  color: "#6a6a6a !important",
  fontSize: "14px",
  fontWeight: "400px",
  fontFamily: "nunito",
  paddingBotton: "10px",
  width: "60%",
};
const programetype = {
  color: "#6a6a6a !important",
  fontSize: "14px",
  fontWeight: "400px",
  fontFamily: "nunito",
  paddingBotton: "10px",
  width: "10%",
};
// Styles for Tables
const useStyles = makeStyles((theme) => ({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "860px",
      padding: "10px 30px",
    },
  },
}));
const defaultTippyProps = {
  // animation: "shift-away-subtle",
  arrow: true,
  interactive: true,
  interactiveBorder: 0,
  placement: "bottom-start",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,

  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
// Pagination function
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

// Start of Announcement component
const Events = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editEventData, setEditEventData] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteEventData, setDeleteEventData] = useState(false);
  const [page, setPage] = useState(0);
  const [addtype, setAddType] = useState("");
  const [etype, setEType] = useState("");
  const [editsessiondata, setEditSessionData] = useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch = useDispatch();

  const [eventType, setEventType] = useState(ADD_TYPES.EVENT);

  let userDetails = useSelector(authData);
  const classes = useStyles1();

  // Useeffect for api call
  useEffect(() => {
    getEvents();
    breadcrumb();
    return () => {};
  }, []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = (type) => {
    if (type === ADD_TYPES.LIVE_SESSION) {
      setEventType(ADD_TYPES.LIVE_SESSION);
    } else {
      setEventType(ADD_TYPES.EVENT);
    }
    setDialogOpen(true);
    setAddType("put");
    setEType("Add");
  };
  const handleEditOpen = (type) => {
    if (type.type === "livesession") {
      setEventType(ADD_TYPES.LIVE_SESSION);
    } else {
      setEventType(ADD_TYPES.EVENT);
    }
    setDialogOpen(true);
    setEditSessionData(type);
    setAddType("update");
    setEType("Edit");
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleclick = (item) => {
    console.log(item);
    setOpenEdit(true);
    setEditEventData(item);
    // console.log(event);
  };
  const handleclickdelete = (item, index) => {
    // alert(item.eid)
    swal({
      text: "Are you sure want to delete ?",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        addEvent(item);
      }
    });
    // setOpenDelete(true);
    // setDeleteEventData(item.eid);
    // setEditSessionData(item);
    // setAddType("delete");
  };
  async function addEvent(item) {
    const bodyParam = {
      body: {
        eid: item.eid,
        rtype: "delete",
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        action: `${item.type == "livesession" ? "livesessions" : "events"}`,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      // const users = response;
      swal({
        title: "Success",
        text: `${
          item.type == "livesession" ? "Live Session" : "Event "
        } Deleted successfully`,
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload();
          handleClose();
        }
      });
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          // setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // API call syntax to get batches
  async function getEvents() {
    setLoading(true);
    let batchjson = [];
    batchjson.push(userDetails.curprgcou.bpid);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        batchjson: batchjson,
        rtype: "get",
        admin: true,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const events = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_EVENTS,
        bodyParam
      );
      console.log("events", events);

      if (
        events !== undefined &&
        events.result !== undefined &&
        events.result.events
      ) {
        if (events.result[userDetails.curprgcou.bpid] !== undefined)
          setEventData(events.result[userDetails.curprgcou.bpid]);

        setTableData(events.result.events);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Maping rows and link to pagination
  function tableview() {
    return tableData.length >= 1 ? (
      <TableBody>
        {(rowsPerPage > 0
          ? tableData.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : tableData
        ).map((item, index) => {
          return (
            <TableRow>
              {item.type != undefined ? (
                <TableCell style={programetype}>
                  {item.type.toUpperCase()} <br />
                </TableCell>
              ) : null}
              <TableCell style={programnames}>
                {moment(item.start).format("DD-MM-YYYY hh:mm a")}
              </TableCell>

              <TableCell style={programtype}>
                {item.title} <br />
                <div>
                  {" "}
                  <a href={item.link} target="blank">
                    {item.link}{" "}
                  </a>
                </div>
              </TableCell>
              <TableCell style={{ width: "10%" }}>
                <div
                  onClick={() => {
                    handleEditOpen(item);
                  }}
                >
                  <Edit className={Ann.icon} />
                </div>
              </TableCell>
              {/* <TableCell style={{ width: "10%" }}>
                <div
                  onClick={() => {
                    handleclickdelete(item, index);
                  }}
                >
                  <Delete className={Ann.icon} />
                </div>
              </TableCell> */}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableBody>
        <TableRow>
          <TableCell style={programnames}></TableCell>
          <TableCell style={programtype}>
            <p style={{ paddingLeft: "25%" }}>No Data</p>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Events",
      path: "/events",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className={Ann.maincontainer}>
      <UserHeader Bindex={2} />

      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleClose}
        className={eventType === ADD_TYPES.EVENT ? "" : classes.paper}
        aria-labelledby="responsive-dialog-title"
        onBackdropClick="false"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>
            {eventType === ADD_TYPES.EVENT
              ? etype + " Event"
              : etype + " Live Session"}
          </h2>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {eventType === ADD_TYPES.EVENT ? (
            <AddEvents
              handleClose={handleClose}
              eventData={editsessiondata}
              addtype={addtype}
              seteditsession={editsessiondata}
            />
          ) : (
            <LiveSession
              liveSessionData={editsessiondata}
              seteditsession={editsessiondata}
              addtype={addtype}
              coursetype="batch"
              handleClose={handleClose}
            />
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <EditEvents
            handleClose={handleCloseEdit}
            editEventData={editEventData}
            getEvents={getEvents}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <DeleteEvents
            handleClose={handleCloseDelete}
            index={deleteEventData}
            addtype={addtype}
            seteditsession={editsessiondata}
          />
        </Fade>
      </Modal>
      <div className={Ann.add}>
        <h3 className={Ann.header}>Events</h3>
        <Tippy
          content={
            <>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 4, fontSize: "15px" }}
                  onClick={() => handleClickOpen(ADD_TYPES.EVENT)}
                >
                  <ListItemText primary="Events" />
                </ListItemButton>
                <ListItemButton
                  sx={{ pl: 4, fontSize: "15px" }}
                  onClick={() => handleClickOpen(ADD_TYPES.LIVE_SESSION)}
                >
                  <ListItemText primary="Live sessions" />
                </ListItemButton>
              </List>
            </>
          }
          {...defaultTippyProps}
        >
          <button
            className={
              window.navigator.onLine === true
                ? Ann.btn_color
                : Ann.btn_colordis
            }
            disabled={!window.navigator.onLine}
          >
            Add
          </button>
        </Tippy>
      </div>
      <div className={Ann.table}>
        {loading === false ? (
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead style={{ background: config.main_color_1 }}>
                <TableRow>
                  <TableCell style={header}>Event Type</TableCell>
                  <TableCell style={header}>Event Date & Time</TableCell>
                  <TableCell style={header}>Event Name</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {loading ? "" : tableview()}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width={1500} height={500} />{" "}
          </div>
        )}
      </div>
      {/* <AddEvents eventData = {eventData}  /> */}
    </div>
  );
};

export default Events;
