import React from "react";

export default function Vimeo({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4rem"
      height="4rem"
      viewBox="0 0 24 24"
      fill={color}
    >
      <path d="M0 0v24h24v-24h-24zm19.249 10.732c-1.628 3.478-5.558 8.213-8.042 8.213-2.448 0-2.802-5.221-4.139-8.696-.658-1.709-1.083-1.317-2.316-.454l-.752-.97c1.798-1.581 3.599-3.418 4.705-3.52 1.245-.12 2.012.731 2.299 2.554.379 2.396.908 6.114 1.832 6.114.72 0 2.494-2.95 2.585-4.004.162-1.544-1.135-1.591-2.261-1.109 1.781-5.836 9.194-4.761 6.089 1.872z" />
    </svg>
  );
}
