import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { awsSignOut, awsSignIn, authData } from "../../../redux/auth/authSlice";
import { SignOut } from "../../../redux/auth/loginSlice";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "./UserHeader.scss";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { Link, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const UserHeader = ({ Bindex }) => {
  const dispatch = useDispatch();
  let userDetails = useSelector(authData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const classes = useStyles();
  let navigate = useNavigate();

  useEffect(() => {
    breadcrumb();
  }, []);

  function breadcrumb() {
    let breadcrumb = [];
    if (userDetails.breadcrumb !== undefined) {
      breadcrumb = { ...userDetails.breadcrumb };
    }
    if (Bindex !== undefined) {
      let temp = [];
      for (let i = 0; i <= Bindex; i++) {
        if(breadcrumb[i] !== undefined){
          temp.push(breadcrumb[i]);
        } 
      }
      console.log("temp==",temp)
      if (temp !== undefined && temp.length > 0) {
        setBreadcrumbs(temp);
      }
      // alert(JSON.stringify(temp));
    }
    // alert(Bindex+JSON.stringify(breadcrumb))
  }

  function signingOut() {
    Auth.signOut();
    dispatch(awsSignOut());
    dispatch(SignOut());
    navigate("/");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="super-container">
      <div className={classes.root}>
        {breadcrumbs !== undefined && breadcrumbs.length > 0 ? (
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs.map((item) => {
              return (
                <Link className="bread" to={item?.path}>
                  {item?.name}
                </Link>
              );
            })}
            ;
          </Breadcrumbs>
        ) : null}
      </div>
      <div className="header">
        <div  style={{ fontSize: "15px", color: "#333333", marginRight: "-12px" }} >
          {userDetails.name}
        </div>
        <Avatar style={{ marginBottom: "-7px", cursor: "pointer" }}
          name="user"
          onClick={handleClick}
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          style={{ marginTop: "35px", marginLeft: "-15px" }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <Link to={`/faqs`} style={{ color: "black", textDecoration: "none" }}>
            <MenuItem
              style={{
                fontSize: "14px",
                color: "black",
                textDecoration: "none",
              }}
              onClick={() => {
                let faqfalse = { ...userDetails };
                faqfalse.sideactive = "/faqs";
                dispatch(awsSignIn(faqfalse));
              }}
            >
              FAQ
            </MenuItem>
          </Link> */}
          <MenuItem
            onClick={() => {
              signingOut();
            }}
            style={{ fontSize: "14px" }}
          >
            Signout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default UserHeader;
