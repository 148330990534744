import React, { useState } from "react";
import Faculty from "../components/Programs/Faculty";
import Aside from "../modules/SideNav/Aside";

function FacultyLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            /> */}
      <Faculty handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default FacultyLayout;
