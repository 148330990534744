// Dependencies imports
import React, { useEffect, useState } from 'react';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import swal from 'sweetalert';
import {
  makeStyles,
  CircularProgress,
  TextField,
  Box,
} from '@material-ui/core';
// Local imports
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";

// Styles imports
import Add from './AddAnnouncement.module.scss';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  program: {
    fontSize: "16px",
    color: "black",
    fontWeight: "500",
    paddingLeft: "5px",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const AddCoordinator = (props) => {
  let { handleClose, getFaculty, facultyData, Instructors,getUsersDetails } = props;
  const [personName, setPersonName] = useState([]);
  const [coordinatorEmailErr, setCoordinatorEmailErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);
  const [filtered, setFiltered] = useState([]);
  useEffect(() => {

    var arrray = [];
    if (facultyData != undefined && facultyData.length > 0) {
      for (let i = 0; i < facultyData.length; i++) {
        if (facultyData[i] !== null) {

          arrray.push(facultyData[i].email);
        }
      }
    }
    setPersonName(arrray);

    const filteredInstructors = Instructors?.filter(instructor => {
      return !(instructor?.col15?.some(role => role.batchid === userDetails?.curprgcou?.bpid));
    });

    setFiltered(filteredInstructors);
  }, []);
  const onsubmit = () => {
    let formIsValid = true;
    let neweid;
    setCoordinatorEmailErr("");
    if (personName.length === 0) {
      formIsValid = false;
      setCoordinatorEmailErr("*Please select course director from list")
      return;
    }

    if (formIsValid === true) {

      addCoordinator(neweid);
    }
  };

  async function addCoordinator() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: userDetails.curprgcou.bpid,
        emailid: personName,
        batch: userDetails.curprgcou.bname,
        batchid: userDetails.curprgcou.bpid,
        atype: 1,
        role: "CDirector"
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (
      userDetails.role == "Instructors" ||
      userDetails.role == "Coordinators"
    ) {
      bodyParam.body.role = "CDirector";
    }

    try {
      const response = await API.post(

        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER_TO_BATCH,
        bodyParam
      );

      handleClose();

      if (response.statusCode === "200" || response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Course Director added successfully to the batch",
          icon: "success",
          dangerMode: false,
        })
          .then(willDelete => {
            if (willDelete) {
              setSpin(false);
              handleClose();

            }
          });

      } else
        if (response.statusCode === "300" || response.statusCode === 300) {
          swal({
            title: "Success",
            text: "Course Director removed successfully to the batch",
            icon: "success",
            dangerMode: false,
          })
            .then(willDelete => {
              if (willDelete) {
                setSpin(false);
                handleClose();

              }
            });

        }
      setSpin(false);
      getUsersDetails();
      getFaculty();

    } catch (error) {

      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            swal({
              title: "Contact Developer",

            })
          }
        });

    }
    setSpin(false);
  }
  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add Course Director</h3>
          <div className={Add.multiselect}>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {coordinatorEmailErr}
            </div>

            <div style={{ width: "300px" }}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 300 }}
                options={filtered}
                autoHighlight
                getOptionLabel={(option) => `${option.col3} (${option.col1})`}
                InputLabelProps={{ style: { fontSize: 16 } }}
                renderOption={(props) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize: '15px' }} {...props}>
                    {`${props.col3} (${props.col1})`}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a Course Director"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                )}
                onChange={(_, value) => {
                  // Handle the selected value
                  setPersonName(value ? [value.col3] : []);
                }}
              />
            </div>
          </div>
          <h3 style={{ textAlign: "left", fontSize: "16px", marginTop: '15px', marginBottom: '10px' }}>
            Selected Course Director
          </h3>
          <div style={{ textAlign: "left", fontSize: "15px", marginLeft: "15px", height: "150px", overflow: 'auto' }}>
            <ol>
              {personName?.map((item) => (
                <li>{item}</li>
              ))}
            </ol>
          </div>
          <div className={Add.buttonholder}>
            <button className={Add.cancel} onClick={handleClose}>Cancel</button>
            <button className={Add.submit} onClick={onsubmit}>Add</button>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} /></div>}
    </div>
  );
};

export default AddCoordinator;