// Dependencies imports
import React, { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import AddInstructor from "./AddInstructor";
import EditInstructorCourse from "./EditInstructorCourse";
// Local imports
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import RemovePopup from "./RemovePopup";
import Fac from "./Faculty.module.scss";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
      fontSize: "16px",
      color: Constants.app_color,
      fontFamily: "nunito",
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    '& .MuiDataGrid-sortIcon': {
      // Add your custom styles for the sort icon here
      color: 'white', // Example: Change the color to red
      fontSize: '20px', // Example: Change the font size
    },
  },
  ".MuiButton-textPrimary": {
    color: config.main_color_1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
// Start of Instructor Grid Component
const InstructorGrid = (props) => {
  let { Instructors, getUsersDetails } = props;
  const [loading, setLoading] = useState(true);
  const [facultyData, setFacultyData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRemoveInstructor, setCurrentRemoveInstructor] = useState("");
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [openInstructor, setOpenInstructor] = useState(false);
  const [openCourse, setOpenCourse] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [roles, setRoles] = useState([]);
  let userDetails = useSelector(authData);

  // Useeffect for faculty details api
  useEffect(() => {
    getFaculty();
    return () => { };
  }, []);

  // function for toolbar options above datagrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }

  // Api call to get faculty details
  async function getFaculty() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        schema: config.schema,
        role: "Instructor",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const faculty = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAM_BATCH_USERS,
        bodyParam
      );

      convertData(faculty.body);
    } catch (error) {
      console.error(error);
    }
  }

  function convertData(data) {
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      let obj = {};
      obj.id = i;
      if (data[i].roles?.length > 0) {
        for (let k = 0; k < data[i].roles?.length; k++) {
          console.log(data[i].roles[k]);
          if (data[i].roles[k].batchid == userDetails?.curprgcou?.bpid && data[i].roles[k].role == 'Instructor') {

            var lastname = data[i].last_name == null ? '' : data[i].last_name;
            obj.name = data[i].first_name + " " + lastname;
            obj.email = data[i].emailid;
            obj.userrole = data[i].ur;
            obj.roles = data[i].roles;
            if (data[i].courseid !== undefined) {
              obj.course = data[i].courseid;
            }
            temp.push(obj);
          }
        }

      }
    }
    setFacultyData(temp);
    setLoading(false);
  }
  const handleClickOpenInstructor = () => {
    setOpenInstructor(true);
  };
  const handleClickCloseInstructor = () => {
    setOpenInstructor(false);
  };

  const handleClickCloseCourse = () => {
    setOpenCourse(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function removeInstructor() {
    setSpin(true);

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        cid: userDetails.curprgcou.bpid,
        emailid: [currentRemoveInstructor],
        batch: userDetails.curprgcou.bname,
        batchid: userDetails.curprgcou.bpid,
        action: "remove",
        atype: 2,

      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER_TO_BATCH,
        bodyParam
      );

      handleClose();
      if (response.statusCode === "200" || response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Instructor added Successfully to the Batch",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();

          }
        });
      } else if (response.statusCode === "0" || response.statusCode === 0) {
        swal({
          title: "Oooops!",
          text: "Instructor already exist in the batch!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else if (response.statusCode === "300" || response.statusCode === 300) {
        swal({
          title: "Success",
          text: "Instructor successfully removed from the batch!",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();

          }
        });
      } else if (response.statusCode === "5" || response.statusCode === 5) {
        swal({
          title: "Oooops!",
          text: "This user is not an Instructor !",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else {
        swal({
          title: "Oooops!",
          text: "Instructor does not exist!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      }
      getUsersDetails();
      getFaculty();
      setSpin(false);
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Oooops!", "Contact developer");
          setSpin(false);
        }
      });
    }
  }

  // Column Data for Instructor Grid
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 600,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 600,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: 'ins',
      headerName: 'Institution',
      width: 600,
      headerClassName: 'super-app-theme--header',
      flex: 1
    },
    {
      field: "Remove Instructor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>

          {userDetails.role == "Instructors" ? null : (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 16,
                background: config.main_color_2,
                color: "white",
                fontSize: "14px",
                fontFamily: "nunito",
              }}
              disabled={!window.navigator.onLine}
              onClick={() => {
                handleOpen();
                setCurrentRemoveInstructor(params.row.email);
                setRoles(params.row.roles);
              }}
            >
              Remove
            </Button>
          )}
        </strong>
      ),
    },

  ];
  const handleCellClick = (param) => {
    setEditData([param.row]);
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <RemovePopup
            handleClose={handleClose}
            onSubmit={removeInstructor}
            spin={spin}
            setState={setSpin}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInstructor}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInstructor}>
          <AddInstructor
            handleClose={handleClickCloseInstructor}
            getFaculty={getFaculty}
            facultyData={facultyData}
            Instructors={Instructors}
            getUsersDetails={getUsersDetails}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openCourse}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCourse}>
          <EditInstructorCourse
            handleClose={handleClickCloseCourse}
            getFaculty={getFaculty}
            facultyData={editData}
          />
        </Fade>
      </Modal>
      <div className={Fac.buttonholder}>
        {/* <a
          style={{ fontSize: "13px", cursor: "pointer", color: "blue", marginRight: "10px" }}
          onClick={downloadcsv}
        >
          Download Format
        </a>
        <button className={
          window.navigator.onLine === true
            ? Fac.btn_color
            : Fac.btn_colordis
        }
          style={{ marginRight: "10px" }}
          disabled={!window.navigator.onLine}
          onClick={() => {
            handleBulkOpen();
          }}
        >
          Bulk Upload
        </button> */}

        <button
          className={
            window.navigator.onLine === true
              ? Fac.btn_color
              : Fac.btn_colordis
          }
          disabled={!window.navigator.onLine}
          onClick={handleClickOpenInstructor}
        >
          Add
        </button>

      </div>

      <div style={{ height: 450, width: "100%" }} className={classes.root}>
        {loading === false ? (
          <DataGrid
            rows={facultyData}
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            disableSelectionOnClick
            className={classes[".MuiDataGrid-root"]}
            onCellClick={handleCellClick}
            disableColumnMenu
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <Skeleton variant="rect" width="100%" height={350} />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default InstructorGrid;
