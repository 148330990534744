import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert";
//import { produce } from "immer";
//import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import fdcss from "./Feedback.module.scss";
//import GroupRadio from "./GroupRadio";
//import Cviewstyle from "./courseView.module.scss";
//import "./courseView.scss";
import { API } from "aws-amplify";
import { Rating } from "semantic-ui-react";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  ratediv:{
    alignItems:'center',
    flexDirection:'column',
    justifyContent:'center',
    gap:'20px',
    display: 'flex',
  },
  ratehead:{
   width:'100%'
  },
  ratestar:{
    width: '50%'
  },
  note:{
    paddingBottom: '10px',
  },
}));

export default function ProvideFeeback({
  inopen,
  setInopen,
  setLoading,
  loading,
  Cdfeedback,
  cid,
  getOtherFeedBack,
}) {
    const refValue = useRef();
  const classes = useStyles();
  const [error, setError] = useState(false);
  let userDetails = useSelector(authData);
  function handleNuggetsClose() {
    setInopen(false);
    setLoading(false);
  }
  const handleSaveFb = async(param)=>{
    setError(false);
    for (let h = 0; h < Cdfeedback.length; h++) {
     if(Cdfeedback[h].res === undefined || Cdfeedback[h].res === ""){
       setError(true);
       return;
     }
    }
    setLoading(true);
    const bodyParam = {
      body: {
         cid ,
         ur_id : userDetails?.uData?.ur_id,
         fbresponse: Cdfeedback,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    
    try {
        console.log("bodyParam=",bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        // Constants.GET_COURSE_VIDEO,
        "/updateFeedbackOthers",
        bodyParam
      );
      swal("Thank you", "Your submission has been sent.", "success");
      setInopen(false);
      setLoading(false);
      getOtherFeedBack('Instructor');
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  
  }

  return (
    <Dialog
      open={inopen}
      maxWidth='sm'
      fullWidth="true"
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title"
     
    >
      <DialogTitle id="form-dialog-title"> Provide feedback</DialogTitle>
      <DialogContent>
        <div className={classes.note}>
           <p>Please rate the course for the questions below. For the questions that are not applicable to you, click on 'Not applicable' option</p>
           {error && <p style={{color:'red'}}> Please provide All feedback</p>}
        </div>
       
        {Cdfeedback.map((item,ind)=>(
            <div className={classes.ratediv}>
            <p className={classes.ratehead}>{(ind+1)+"."+item.istem}</p>
              <Rating maxRating={5} defaultRating={item.res} 
              className={classes.ratestar} icon='star outline' size='massive'
              onRate={(e, { rating, maxRating })=>item.res = rating} />
              <TextareaAutosize
                  className={fdcss.inputbox}
                aria-label="maximum height"
                minRows={2}
                defaultValue={item.cmt}
                placeholder="Additional comments"
                style={{ resize: 'none' }}
                onChange={(event) => {
                    const { value } = event.target;
                    refValue.current = value;
                    item.acmt = value;
                  }}
                />
            </div>
        ))}
      </DialogContent>
      <DialogActions>
      <Button onClick={handleSaveFb} color="primary">
          Save
        </Button>
        <Button onClick={handleNuggetsClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
