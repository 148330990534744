import React, { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import InstructorsCard from "../../modules/Cards/Instructors/InstructorsCard";
import { useSelector, useDispatch } from "react-redux";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";

import "../../modules/Tabs/Tabs.scss";
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import PlaceholderParagraph from "../../modules/Placeholder/PlaceholderParagraph";

import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import ClockOrange from "../../assets/svgjs/ClockOrange";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { FaBars } from "react-icons/fa";
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import progIbfo from "./ProgramInfo.module.scss";
import swal from "sweetalert";
import CB1 from "./CreateBatchOne.module.scss";
import ViewDoc from "./ViewDoc";
import { Constants } from "../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "& .MuiAccordionDetails-root": {
      display: "list-item",
      padding: "8px 16px 16px",
      listStyle: "none",
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

export default function ProgramsInfo({ handleToggleSidebar }) {
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [programsJSON, setProgramsJSON] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openBatch, setOpenBatch] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [selectedCurlFile, setSelectedCurlFile] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  let navigate = useNavigate();

  const [bdata, setBdata] = useState({
    bname: "",
    ctype: null,
    plid: "",
    pStart: null,
    eStart: null,
    pEnd: null,
    nodate: false,
    stid: 0,
    cntid: 0,
    disid: 0,
    clid: 0,
    zone: null
  });
  const [errmsg, setErrmsg] = useState("");
  const [ptype, setPtype] = useState(1);
  const [etype, setEtype] = useState(2);
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [enrollenddate, setEnrollenddate] = useState(new Date());
  const [disableDate, setDisableDate] = useState(false);
  const [spin, setSpin] = useState(false);
  const [others, setOthers] = useState(false);
  const [drpDpin, setDrpSpin] = useState(false);

  let userDetails = useSelector(authData);
  console.log('rrr',userDetails);
  const dispatch = useDispatch();
  const minDate = getTodayDate();

  useEffect(() => {
    breadcrumb();
    getProgramsDetailsPost();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    if (sdata.breadcrumb === undefined) {
      sdata.breadcrumb = [];
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: 'Manage Programs',
        path: '/Programs'
      };
      temp[1] = {
        name: `${sdata.curprgcou?.bname}-${sdata.curprgcou?.ptitle}`,
        path: '/ManageBatch'
      };
      sdata.breadcrumb = temp;
    } else {
      let temp = [...sdata.breadcrumb];
      temp[0] = {
        name: 'Manage Programs',
        path: '/Programs'
      };
      temp[1] = {
        name: `${sdata.curprgcou?.bname}-${sdata.curprgcou?.ptitle}`,
        path: '/ManageBatch'
      };
      sdata.breadcrumb = temp;
    }
    dispatch(awsSignIn(sdata));
  }

  async function getProgramsDetailsPost() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        bpid: userDetails.curprgcou?.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getProgramsDetails`,
        bodyParam
      );

      setProgramsJSON(response);
      setIsLoading(false);
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month/day is less than 10
    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    return `${year}-${month}-${day}`;
  }

  async function getLocationLoad(atype, id) {

    setDrpSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        ltype: atype,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (atype === 'state') {
      bodyParam.body.cid = id;
    } else if (atype === 'district') {
      bodyParam.body.sid = id;
    } else if (atype === 'location') {
      bodyParam.body.dis_id = id;
    } else if (atype === 'college') {
      bodyParam.body.loc_id = id;
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        '/getLocation',
        bodyParam
      );

      var programsJSN = { ...programsJSON };
      if (atype === 'state') {
        programsJSN.state = response.body;
        setBdata({ ...bdata, cntid: id, disid: 0, plid: "" });
        programsJSN.district = [];
        programsJSN.location = [];
        programsJSN.college = [];
        setOthers(false);
      } else if (atype === 'district') {
        programsJSN.district = response.body;
        setBdata({ ...bdata, stid: id, disid: 0, plid: "" });
        programsJSN.location = [];
        programsJSN.college = [];
        setOthers(false);
      } else if (atype === 'location') {
        programsJSN.location = response.body;
        setBdata({ ...bdata, disid: id, plid: "" });
      } else if (atype === 'college') {
        programsJSN.college = response.body;
        programsJSN.college.push({ "cid": "0", "cname": "others" });
        setBdata({ ...bdata, plid: id, clid: "" });
      }
      setProgramsJSON({ ...programsJSON, ...programsJSN })
      setDrpSpin(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleAddOpen = (val) => {

    let url = val?.cid + '.pdf';

    setSelectedFile(url);
    setSelectedCurlFile(url);
    setOpenAdd(true);
  };

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const panes = [
    {
      menuItem: "Overview",
      render: () => (
        <Tab.Pane className={progIbfo.overview}>
          {isLoading ? (
            <PlaceholderParagraph />
          ) : (
            <p
              className={progIbfo.p_text}
              dangerouslySetInnerHTML={{ __html: programsJSON.overview }}
            ></p>
          )}
          <div></div>
          <div>
            <h2 className={progIbfo.h2_margin}>Program features</h2>
            {isLoading ? (
              <div style={{ width: "100%", overflow: "hidden" }}>
                <PlaceholderParagraph />
              </div>
            ) : (
              <p
                className={progIbfo.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.features }}
              ></p>
            )}
          </div>
          <div>
            <h2 className={progIbfo.h2_margin}>Program outcomes</h2>
            {isLoading ? (
              <PlaceholderParagraph />
            ) : (
              <p
                className={progIbfo.p_text}
                dangerouslySetInnerHTML={{ __html: programsJSON.poutcomes }}
              ></p>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Instructors",
      render: () => (
        <Tab.Pane>
          <div>
            <div>
              {programsJSON.pinstructors === undefined ||
                programsJSON.pinstructors.length === 0 ? (
                <div
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  No Data
                </div>
              ) : (
                <div className="instructors">
                  <div className="instructors__h1">
                    <h2 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      Course Instructors
                    </h2>
                  </div>
                  <div>
                    <div
                      className="card-container"
                      style={{ float: "left", marginLeft: "50px" }}
                    >
                      {programsJSON.pinstructors.map(
                        ({ name, designation, org, pic }, idx) => (
                          <InstructorsCard
                            key={idx}
                            name={name}
                            designation={designation}
                            org={org}
                            pic={pic}
                            pid={programsJSON.pid}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              {programsJSON.ccoordinator === undefined ||
                programsJSON.ccoordinator.length === 0 ? null : (
                <div className="instructors">
                  <div className="instructors__h1">
                    <h2 style={{ marginLeft: "20px", marginTop: "20px" }}>
                      Course coordinators
                    </h2>
                  </div>
                  <div>
                    <div
                      className="card-container"
                      style={{ float: "left", marginLeft: "50px" }}
                    >
                      {programsJSON.ccoordinator.map(
                        ({ name, designation, org, pic }, idx) => (
                          <InstructorsCard
                            key={idx}
                            name={name}
                            designation={designation}
                            org={org}
                            pic={pic}
                            pid={programsJSON.pid}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {programsJSON.cmentor === undefined ||
              programsJSON.cmentor.length === 0 ? null : (
              <div className="instructors">
                <div className="instructors__h1">
                  <h2 style={{ marginLeft: "20px", marginTop: "20px" }}>
                    Course Mentor
                  </h2>
                </div>
                <div>
                  <div
                    className="card-container"
                    style={{ float: "left", marginLeft: "50px" }}
                  >
                    {programsJSON.cmentor.map(
                      ({ name, designation, org, pic }, idx) => (
                        <InstructorsCard
                          key={idx}
                          name={name}
                          designation={designation}
                          org={org}
                          pic={pic}
                          pid={programsJSON.pid}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Curriculum",
      render: () => (
        <Tab.Pane>
          <div>
            {programsJSON?.pcurriculum?.length === 0 ? (
              <h3 style={{ display: "flex", justifyContent: "center" }}>
                Select semester to view courses
              </h3>
            ) : (
              programsJSON?.pcurriculum.map((value) => {
                return (
                  <div className={progIbfo.pcurriculum}>
                    <div className={progIbfo.courses_container}>
                      <div className={progIbfo.ID_container}>
                        <div
                          className={progIbfo.image_container}
                          style={{
                            backgroundImage: `url('https://${config.DOMAIN
                              }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${value.cid
                              }.png')`,
                          }}
                        ></div>
                        <div className={progIbfo.details_container}>
                          <div>

                            <h2 style={{ fontSize: "15px", fontWeight: "bold", cursor: 'pointer' }} onClick={() => {
                              handleAddOpen(value);
                            }}>
                              {value.title}
                            </h2>
                          </div>
                          <div>
                            <p style={{ marginTop: "-12px" }}>
                              <span>
                                <ClockOrange
                                  className={progIbfo.clock_size__s}
                                  cls1={progIbfo.cls1_s}
                                  cls2={progIbfo.cls2_s}
                                />
                              </span>
                              <span style={{ fontSize: "small" }}>
                                {value.weeks === undefined || value.weeks === null ? 0 : value.weeks} Weeks
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "FAQs",
      render: () => (
        <Tab.Pane>
          {programsJSON
            ? programsJSON.faq.faq.map(({ name, ans }, idx) => (
              <div className={classes.root} key={idx}>
                <Accordion>
                  {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      <h2 style={{ fontSize: "16px", fontWeight: "bold" }}>
                        {name}
                      </h2>
                    </Typography>
                  </AccordionSummary> */}
                  <AccordionDetails>
                    {ans.map(({ name, ans }, id) => (
                      <div className={classes.root} key={id}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <h3 style={{ fontSize: "15px" }}>{name}</h3>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography style={{ fontSize: "14px" }}>
                              <p
                                className={progIbfo.p_text}
                                dangerouslySetInnerHTML={{ __html: ans }}
                              ></p>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
            : null}
        </Tab.Pane>
      ),
    },
  ];

  let handleTabChange = ({ activeIndex }) => setActiveIndex(activeIndex);
  const [ptButtonClass, setPtButtonClass] = useState("default"); //states for program type state changes

  // Program type toggle onclick function
  const pttoggleClass = (val, type) => {

    setPtype(type);
    setPtButtonClass(val);
    if (
      bdata.bname === undefined ||
      bdata.bname.replace(/\s/g, "").length === 0 ||
      bdata.bname.length < 3
    ) {
      if (bdata.bname === undefined) {
        setErrmsg("Please enter Batch name");
        return;
      } else {
        setErrmsg("Batch name should be atleast of 3 characters");
        return;
      }
    } else {
      setPtype(type);
      setErrmsg("");
    }
  };


  async function createbatchon(S, E, EnRD) {
    setSpin(true);
    var body1 = {
      oid: config.aws_org_id,
      ppid: userDetails.curprgcou.pid,
      useremail: userDetails.uData.emailid,
      username: userDetails.uData.first_name + " "+ userDetails.uData.last_name,
      amount: parseInt(bdata.amount),
      pformat: ptype,
      pbname: bdata.bname,
      ctype: bdata.ctype,
      plid: bdata.plid,
      clid: bdata.clid,
      pstatus: null,
      collegename: bdata.collegname,
      zone: bdata.zone,
      aprstatus: userDetails.role === 'Admin' ? true : false,
    };
    if (S !== undefined) {
      body1.pstart_date = S;
      body1.pend_date = E;
      body1.penroll_start_date = EnRD;
    }
    const bodyParam = {
      body: body1,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        `/createBatch`,
        bodyParam

      );
      closeModalBatch();
      var msg= 'Batch Created Successfully';
      if(userDetails?.role !== 'Admin'){
        msg =  'Created batch has submitted for approval';
      }
      swal({
        title: "Success!",
        text: msg,
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          closeModalBatch();
          setSpin(false);
        }
      });
      setSpin(false);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
          swal({
            title: "Contact Developer!",
          });
        }
      });
    }
  }
  function createbatch() {
    if (
      bdata.bname === undefined ||
      bdata.bname.replace(/\s/g, "").length === 0 ||
      bdata.bname.length < 3
    ) {
      if (bdata.bname === undefined) {
        setErrmsg("Please enter a Batch name");
        return;
      } else {
        setErrmsg("Batch name should be atleast of 3 characters");
        return;
      }
    } else {
      setErrmsg("");
    }

    if (bdata.ctype === undefined || bdata.ctype === null) {
      setErrmsg("Please select course type");
      return;
    } else {
      setErrmsg("");
    }
    if (
      bdata.amount === undefined ||
      bdata.amount.replace(/\s/g, "").length === 0
    ) {
      setErrmsg("Please enter course amount");
      return;
    }
    if (parseInt(bdata.amount) === 0) {
      setErrmsg("Course amount should be greater tha zero");
      return;
    }
    if (bdata.cntid === undefined || bdata.cntid === 0) {
      setErrmsg("Please select country");
      return;
    } else {
      setErrmsg("");
    }
    if (bdata.stid === undefined || bdata.stid === 0) {
      setErrmsg("Please select state");
      return;
    } else {
      setErrmsg("");
    }
    if (bdata.disid === undefined || bdata.disid === 0 || programsJSON.district === undefined || programsJSON.district.length === 0) {
      setErrmsg("Please select district");
      return;
    } else {
      setErrmsg("");
    }
    if (bdata.zone === undefined || bdata.disid === null) {
      setErrmsg("Please select zone");
      return;
    } else {
      setErrmsg("");
    }
    if (bdata.plid === undefined || bdata.plid === "" || bdata.plid.replace(/\s/g, "").length === 0
      || programsJSON.location === undefined || programsJSON.location.length === 0
    ) {
      setErrmsg("Please select location");
      return;
    } else {
      setErrmsg("");
    }
    if (bdata.clid === undefined || bdata.clid === "" || bdata.clid.replace(/\s/g, "").length === 0
      || programsJSON.college === undefined || programsJSON.college.length === 0
    ) {
      setErrmsg("Please select institution");
      return;
    } else {
      setErrmsg("");
    }
    if (ptype === 1 && etype === 1) {
      if (bdata.pStart === null || bdata.pStart === undefined) {
        setErrmsg("Please select a start date");
        return;
      } else if (bdata.pEnd === null || bdata.pEnd === undefined) {
        setErrmsg("Please select an end date");
        return;
      } else if (bdata.eStart === null || bdata.eStart === undefined) {
        setErrmsg("Please select an enrollment date");
        return;
      } else {
        let SD = new Date(bdata.pStart);
        let ED = new Date(bdata.pEnd);
        let EnD = new Date(bdata.eStart);

        if (SD > ED) {
          setErrmsg("Please select proper Start and End date");
          return;
        } else {
          setErrmsg("");
          let S = SD.getTime();
          setStartdate(S);
          let E = ED.getTime();
          setEnddate(E);
          let EnRD = EnD.getTime();
          setEnrollenddate(EnRD);
          createbatchon(S, E, EnRD);
        }
      }
    } else if ((ptype === 1 || ptype === 2) && (etype === 1 || etype === 2)) {
      if (bdata.pStart === null || bdata.pStart === undefined) {
        setErrmsg("Please select a start date");
        return;
      } else if (bdata.pEnd === null || bdata.pEnd === undefined) {
        setErrmsg("Please select a end date");
        return;
      } else if (bdata.eStart === null || bdata.eStart === undefined) {
        setErrmsg("Please select a enrollment date");
        return;
      } else if (bdata.pStart === undefined && bdata.nodate === false) {
        setErrmsg("Please select a start date");
        return;
      } else {
        setErrmsg("");
        if (bdata.pStart != undefined) {
          if (bdata.pEnd === undefined) {
            setErrmsg("Please select a end date");
            return;
          } else {
            setErrmsg("");
            let SD = new Date(bdata.pStart);
            let ED = new Date(bdata.pEnd);
            let EnD = new Date(bdata.eStart);
            if (SD > ED) {
              setErrmsg("Please select proper Start and End date");
              return;
            } else {
              setErrmsg("");
              let S = SD.getTime();
              setStartdate(S);
              let E = ED.getTime();
              setEnddate(E);
              let EnRD = EnD.getTime();
              setEnrollenddate(EnRD);
              createbatchon(S, E, EnRD);
            }
          }
        } else {
          if (bdata.nodate === false) {
            setErrmsg("Please select a end date");
            return;
          } else {
            setErrmsg("");
            createbatchon();
          }
        }
      }
    } else if ((ptype === 1 || ptype === 2) && etype === 3) {
      createbatchon();
    }
  }

  function closeModalBatch() {
    setOpenBatch(false);
    setBdata({
      bname: "",
      ctype: null,
      pStart: null,
      eStart: null,
      pEnd: null,
      nodate: false,
      zone: null
    });
    setErrmsg("");
    setPtype(1);
    setEtype(2);
  }

  return (
    <div className={progIbfo.containermain}>
      <UserHeader Bindex={0} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAdd}
        closeAfterTransition
      >
        <Fade in={openAdd}>
          <ViewDoc
            handleAddClose={handleAddClose}
            docFileName={selectedFile}
            docCurlFileName={selectedCurlFile}
            type={'programs'}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBatch}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBatch}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {spin === false ? (
              <div className={CB1.containermain}>
                <div className={CB1.iconholder} style={{ cursor: "pointer" }}>
                  <h3 className={CB1.heading}>Create batch</h3>
                  <Close
                    onClick={() => {
                      closeModalBatch();
                    }}
                  />
                </div>
                {drpDpin && <div className={classes.root1} style={{ position: "absolute", left: '50%', top: '50%' }}>
                  <CircularProgress style={{ color: config.main_color_1 }} />
                </div>}

                <div className={CB1.form}>
                  <p style={{
                    color: "red",
                    fontSize: "15px",
                    alignItems: "center",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                  >
                    {errmsg}
                  </p>
                  <div className={CB1.field}>
                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                    }}
                      for="batch-name"
                      required="required"
                    >
                      * Batch Name
                    </label>
                    <input
                      className={CB1.inputcontainer}
                      type="text"
                      id="batch-name"
                      disabled={drpDpin}
                      onChange={(e) =>
                        setBdata({ ...bdata, bname: e.target.value })
                      }
                      autoComplete="off"
                    ></input>
                  </div>
                  <div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * Course Type
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}
                      onChange={(e) =>
                        setBdata({ ...bdata, ctype: e.target.value })
                      }
                    >
                      <option value="0" selected="selected" disabled>
                        Select Course Type
                      </option>
                      <option value="pcourse">Provider Course</option>
                      <option value="icourse">Instructor Course</option>
                    </select>
                  </div>
                  <div className={CB1.field}>
                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                    }}
                      for="batch-amount"
                      required="required"
                    >
                      * Course Amount (INR)
                    </label>
                    <input
                      className={CB1.inputcontainer}
                      type="text" inputmode="numeric"
                      min="1"
                      id="batch-amount"
                      disabled={drpDpin}
                      onChange={(e) =>
                        setBdata({ ...bdata, amount: e.target.value })
                      }
                    ></input>
                  </div>
                  <div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * Country
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      minWidth: "200px",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}
                      onChange={(event) => getLocationLoad('state', event.target.value)}  >
                      <option value="0" selected="selected" disabled>
                        Select Country
                      </option>
                      {programsJSON?.country.map((value, index) => {
                        return (
                          <option value={value.cid} key={"curr" + index}>
                            {value.cname}
                          </option>
                        );
                      })}
                    </select>


                  </div>
                  {programsJSON?.state.length === 0 ? null : (<div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * State
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      minWidth: "200px",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}
                      onChange={(event) => getLocationLoad('district', event.target.value)} >
                      <option value="0" selected="selected" disabled>
                        Select State
                      </option>
                      {
                        programsJSON?.state.map((value, index) => {
                          return (
                            <option value={value.sid} key={"curr" + index}>
                              {value.sname}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>)}
                  {programsJSON?.district.length === 0 ? null : (<div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * District
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      minWidth: "200px",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}
                      onChange={(event) => getLocationLoad('location', event.target.value)} >
                      <option value="0" selected="selected" disabled>
                        Select District
                      </option>
                      {
                        programsJSON?.district.map((value, index) => {
                          return (
                            <option value={value.dis_id} key={"curr" + index}>
                              {value.dis_name}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>)}

                  {programsJSON?.location?.length === 0 ? null : (<div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * Zone
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      minWidth: "200px",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}

                      onChange={(e) =>
                        setBdata({ ...bdata, zone: e.target.value })
                      }>
                      <option value="0" selected="selected" disabled>
                        Select Zone
                      </option>
                      <option value="East">Bengaluru East</option>
                      <option value="West">Bengaluru West</option>
                      <option value="Mysuru">Mysuru</option>
                      <option value="Mangaluru">Mangaluru</option>
                      <option value="Shivamogga">Shivamogga</option>
                      <option value="Dharwad">Dharwad</option>
                      <option value="Kalburgi">Kalburgi</option>
                      <option value="NA">NA(not applicable)</option>
                    </select>
                  </div>)}
                  {programsJSON?.location?.length === 0 ? null : (<div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * Location
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      minWidth: "200px",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}

                      onChange={(event) => getLocationLoad('college', event.target.value)}>
                      <option value="0" selected="selected" disabled>
                        Select Location
                      </option>
                      {
                        programsJSON?.location.map((value, index) => {
                          return (
                            <option value={value.lid} key={"curr" + index}>
                              {value.lname}
                            </option>
                          );
                        })
                      }
                    </select>
                  </div>)}
                  {programsJSON?.college?.length === 0 ? null : (<div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * Institution
                    </label>

                    <select className="classInput_assign_assess" style={{
                      marginBottom: "0",
                      width: "67%",
                      minWidth: "200px",
                      borderRadius: "5px",
                      fontSize:"14px"
                    }}
                      onChange={(e) => {
                        setBdata({ ...bdata, clid: e.target.value });

                        if (e.target.value == "0") {
                          setOthers(true);
                        } else {
                          setOthers(false);
                        }
                      }
                      }>
                      <option value="0" selected="selected" disabled>
                        Select Institution
                      </option>
                      {
                        programsJSON?.college.map((value, index) => {
                          return (
                            <option value={value.cid} key={"curr" + index}>
                              {value.cname}
                            </option>
                          );
                        })
                      }
                    </select>

                  </div>)}
                  {others == false ? null : <div className={CB1.field + ' ' + CB1.divnmarginbotton}  >

                    <label className={CB1.labelwidth} style={{
                      fontSize: "16px",
                      fontWeight: "500px",
                      fontFamily: "nunito",
                      width: '16.7vw',
                    }}
                      required="required"
                    >
                      * Institution Name
                    </label>

                    <input
                      className={CB1.inputcontainer}
                      style={{ margin: 'unset', width: '67%' }}
                      type="text"
                      id="batch-college"
                      onChange={(e) =>
                        setBdata({ ...bdata, collegname: e.target.value })
                      }
                    ></input>

                  </div>}

                  <div className={CB1.field}>
                    <label className={CB1.labelwidth}
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                        width: "18.7vw",
                      }}

                      required="required"
                    >
                      * Program type
                    </label>
                    <div className={CB1.buttonholder}>
                      <button
                        className={
                          ptButtonClass === "default"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {

                          { !drpDpin && pttoggleClass("default", 1); }
                        }}
                      >
                        Instructor led
                      </button>
                      <button
                        className={
                          ptButtonClass === "selfpaced"
                            ? CB1.buttonactive
                            : CB1.buttonstable
                        }
                        onClick={() => {
                          { !drpDpin && pttoggleClass("selfpaced", 2); }
                        }}
                      >
                        Self paced
                      </button>
                    </div>
                  </div>

                  <div className={CB1.field}>
                    <label className={CB1.labelwidth}
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                        width: "18.7vw",
                      }}
                      for="program-start"
                      required="required"
                    >
                      * Program start date
                    </label>
                    <div className={CB1.dateholder}>

                      <input
                        min={minDate}
                        type="date"
                        id="program-start"
                        className={CB1.datecontainer}
                        onChange={(e) =>
                          setBdata({ ...bdata, pStart: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className={CB1.field}>
                    <label className={CB1.labelwidth}
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                        width: "18.7vw",
                      }}
                      for="program-end"
                      required="required"
                    >
                      * Program end date
                    </label>
                    <div className={CB1.dateholder}>
                      <input
                        
                        type="date"
                        id="program-end"
                        min={minDate}
                        className={CB1.datecontainer}
                        onChange={(e) =>
                          setBdata({ ...bdata, pEnd: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className={CB1.field}>
                    <label className={CB1.labelwidth}
                      style={{
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontFamily: "nunito",
                        width: "18.7vw",
                      }}
                      for="enrollment-start"
                      required="required"
                    >
                      * Enrollment start date
                    </label>
                    <div className={CB1.dateholder}>
                      <input
                        
                        type="date"
                        id="enrollment-start"
                        min={minDate}
                        className={CB1.datecontainer}
                        onChange={(e) =>
                          setBdata({ ...bdata, eStart: e.target.value })
                        }
                      ></input>
                    </div>
                  </div>
                  <div className={CB1.submitbuttonholder}>

                    <button style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      minWidth: "100px",
                      width: 'auto',
                      borderRadius: "5px",
                      height: "35px",
                    }}
                      onClick={() => createbatch()}
                      className={
                        window.navigator.onLine === true
                          ? CB1.btn_color
                          : CB1.btn_colordis
                      }
                      disabled={!window.navigator.onLine}
                    >
                      {userDetails.role === 'Admin' ? 'Create' : 'Submit for Approval'}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.root1}>
                <CircularProgress style={{ color: config.main_color_1 }} />
              </div>
            )}
          </div>
        </Fade>
      </Modal>
      <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div className={progIbfo.overview}>
        <div>
          <Typography component="div" key="h2" variant="h2">
            {isLoading ? (
              <Skeleton />
            ) : (
              <h3 className={progIbfo.header}>{programsJSON.pname}</h3>
            )}
          </Typography>
        </div>
        <div className={progIbfo.overview__card}>
          <div className={progIbfo.overview__cardContent}>
            <div className={progIbfo.content}>
              <div className={progIbfo.content__details}>
                <div className={progIbfo.align_self}>

                </div>

                <div className={progIbfo.align_self}>
                  {isLoading ? (
                    <Skeleton />
                  ) : userDetails.role == "Instructors" ||
                    userDetails.role == "Coordinators" ? null : (
                    <button
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        minWidth: "150px",
                        width: 'auto',
                        borderRadius: "5px",
                        minHeight: "35px",
                        height: 'auto',
                        padding: '5px'
                      }}
                      className={
                        window.navigator.onLine === true
                          ? progIbfo.btn_color
                          : progIbfo.btn_colordis
                      }
                      onClick={() => {
                        setOpenBatch(true);
                      }}
                      disabled={!window.navigator.onLine}
                    >
                      Create Batch
                    </button>
                  )}
                  {isLoading ? (
                    <Skeleton />
                  ) : userDetails.role == "Instructors" ||
                    userDetails.role == "CDirector" ? null : (
                    <button
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginLeft: '10px',
                        width: "150px",
                        borderRadius: "5px",
                        height: "35px",
                      }}
                      className={
                        window.navigator.onLine === true
                          ? progIbfo.btn_color
                          : progIbfo.btn_colordis
                      }
                      onClick={() => {
                        let sdata = { ...userDetails };
                        let temp = [...sdata.breadcrumb];
                        temp[1] = {
                          name: 'ProgramsInfo',
                          path: '/ProgramsInfo'
                        };
                        sdata.breadcrumb = temp;
                        dispatch(awsSignIn(sdata));
                        navigate("/Content");
                      }}
                      disabled={!window.navigator.onLine}
                    >
                      Add content
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Skeleton variant="rect" width="100%">
              <div style={{ paddingTop: "57%" }} />
            </Skeleton>
          ) : (
            <div className={progIbfo.overview__cardImage}>
              <img
                src={`https://${config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${programsJSON.pid
                  }.png`}
                alt=""
              />
            </div>
          )}
        </div>
        <div className={progIbfo.tabMargin}>
          {isLoading === false ? (
            <Tab
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={handleTabChange}
            />
          ) : (
            <Skeleton width="100%" height={500} />

          )}
        </div>
      </div>
    </div>
  );
}
