// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";

// Local imports
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { authData } from "../../redux/auth/authSlice";

// Styles imports
import Add from './AddAnnouncement.module.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
}));


const AddWebinar = (props) => {
    let { announcementData, handleClose, getAnnouncement } = props;
    const [webinarTitle, setWebinarTitle] = useState("");
    const [webinarUrl, setWebinarUrl] = useState("");
    const [webinarDescription, setWebinarDescription] = useState("");
    const [webinarId, setWebinarId] = useState("");
    const [webinarTitleErr, setWebinarTitleErr] = useState("");
    const [webinarUrlErr, setWebinarUrlErr] = useState("");
    const [webinarDescriptionErr, setWebinarDescriptionErr] = useState("");
    const [spin, setSpin] = useState(false);
    const classes = useStyles();

    let userDetails = useSelector(authData);


    const onChangeHandlerWebinarTitle = event => {
        setWebinarTitle(event.target.value);
    };
    const onChangeHandlerWebinarUrl = event => {
        setWebinarUrl(event.target.value);
    };
    const onChangeHandlerWebinarDescription = event => {
        setWebinarDescription(event.target.value);
    };

    const onsubmit = () => {
        // setname(name)
        let formIsValid = true;
        let neweid;
        setWebinarTitleErr("");
        setWebinarUrlErr("");
        setWebinarDescriptionErr("");

        if (webinarTitle.trim() === "") {
            formIsValid = false;
            setWebinarTitleErr("Please Enter Title");
            return;

        }
        if (webinarTitle.trim() != "" && webinarTitle.trim().length > 100) {
            setWebinarTitleErr("*Please Enter Title length less than 100 character");
            return;
        }
        if (webinarUrl.trim() === "") {
            formIsValid = false;
            setWebinarUrlErr("*Please Enter Event Url");
            return;

        }
        if (webinarDescription.trim() === "") {
            formIsValid = false;
            setWebinarDescriptionErr("*Please Enter Webinar description");
            return;

        }
        if (webinarDescription.trim() != "" && webinarDescription.trim().length > 160) {
            setWebinarDescriptionErr("*Please Enter Webinar description length less than 160 character");
            return;
        }
        if (formIsValid === true) {

            neweid = (announcementData == null || announcementData === undefined || announcementData.length === 0) ? '1' : +announcementData[announcementData.length - 1].announcid + 1

        }
        addannouncement(neweid);
    };


    async function addannouncement(id) {
        setSpin(true);
        const bodyParam = {
            body: {

                oid: config.aws_org_id,
                bpid: userDetails.curprgcou.bpid,
                annjson: {
                    title: webinarTitle,
                    type: "web",
                    weburl: webinarUrl,
                    webtext: webinarDescription,
                    announcid: id,
                    sdate: Math.floor(Date.now() / 1000)
                },

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };

        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.POST_ANNOUNCEMENT,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            // const users = response;
                handleClose();
            // const users = response;
            swal({
                title: "Success",
                text: "Webinar added successfully",
                icon: "success",
                dangerMode: false,
            })
                .then(willDelete => {
                    if (willDelete) {
                        handleClose();

                    }
                });
                setSpin(false);
                getAnnouncement();
        } catch (error) {
            swal({
                title: "Oooops!",
                text: "Soomthing wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {
                        setSpin(false);
                    }
                });
            console.log("getUserError", error);
        }
        console.log("Mount");
    }

    return (
        <div>
            {spin === false ?
                <div className={Add.mainpopup}>
                    <h3 className={Add.header}>Add Webinar</h3>
                    <div className={Add.caution}>{webinarTitleErr}</div>
                    <input type="text" placeholder="Title" className={Add.title} onChange={onChangeHandlerWebinarTitle} value={webinarTitle} />
                    <h4 className={Add.condition}>* Title should be less than 100 charecters</h4>
                    <div className={Add.caution}>{webinarUrlErr}</div>
                    <input type="text" placeholder="Url" className={Add.title} onChange={onChangeHandlerWebinarUrl} value={webinarUrl} />
                    <div className={Add.caution}>{webinarDescriptionErr}</div>
                    <textarea className={Add.textarea} rows={10} maxlength="160" placeholder="Description" onChange={onChangeHandlerWebinarDescription} value={webinarDescription} />
                    <h4 className={Add.condition}>* Description should be less than 160 characters</h4>
                    <div className={Add.buttonholder}>
                        <button className={Add.cancel} onClick={handleClose}>Cancel</button>
                        <button className={Add.submit} onClick={onsubmit} >Submit</button>
                    </div>
                </div> : <div className={classes.root}>
                    <CircularProgress style={{ color: config.main_color_1 }} /></div>}
        </div>
    );
};

export default AddWebinar;