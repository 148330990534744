// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import ClockGray from "../../../assets/svgjs/ClockGray";
import Cviewstyle from "./courseView.module.scss";
import DialogBox from "./Dialogbox";
import InstructorDialogBox from "./InstructorDialogBox";
import "./courseView.scss";
import UserHeader from "../../Header/UserHeader/UserHeader";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import CloseIcon from "@material-ui/icons/Close";
import { Tab } from "semantic-ui-react";

import { useSelector, useDispatch } from "react-redux";
import { setCookie } from "react-use-cookie";
import CoursePlayer from "./CoursePlayer";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";

// Local imports
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
// Style imports
import Caviewstyle from "../../../components/Programs/CourseCreation/courseCreation.module.scss";
import ObjectPopUp from "../../../components/Programs/CourseCreation/AddObjects";
import {
  Button,
  Backdrop,
  Accordion,
  AccordionSummary,
  makeStyles,
  TextareaAutosize,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import QuizIc from "../../../assets/svgjs/Quiz";
import PdfIcon from "../../../assets/svgjs/Pdf";

import Video from "../../../assets/svgjs/Video";
import Review from "../../../assets/svgjs/Review";
import Html from "../../../assets/svgjs/Html";
import Audio from "../../../assets/svg/Audio.svg";

import Skeleton from "@material-ui/lab/Skeleton";
import swal from "sweetalert";
import axios from "axios";
import RegexTextField from "../CourseCreation/RegexTextField";
import editIcon from "../../../assets/images/edit.png";
import produce from "immer";
import EditNTitleAndEditUTitle from "./EditNTitleAndEditUTitle";
import YouTubeVideo from "../../../assets/svgjs/YouTubeVideo";
import Vimeo from "../../../assets/svgjs/Vimeo";

import { ReactComponent as Edit } from "../../../assets/svg/edit_black_24dp.svg";
import DragAndDrop from "./DragAndDrop";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",
    overflowY: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    borderRadius: "10px",
  },
  paper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "1100px",
    },
  },
  livepaper: {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "860px",
      padding: "10px 30px",
    },
  },
  tooltipFull: {
    fontSize: 30,
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "#000",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  volumeButtonExit: {
    color: "lightgray",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#777",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CourseView({ handleToggleSidebar }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlyAlphanumericRegex = /[^0-9]/gi;
  const [courseDetails, setCourseDetails] = useState({
    tdescription: "Course Description",
  });

  const [objectData, setObjectData] = useState({});
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [assignment, setAssignment] = useState(false);
  const [assignmentLoad, setAssignmentLoad] = useState(true);
  const [cNugget, setcNugget] = useState({});
  const [errors, setErrors] = useState({ uval: false, nval: false });

  const [nuggets, setNuggets] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPage, setShowPage] = useState(true);
  const [summative, setSummative] = useState(false);
  const [sumScore, setSumScore] = useState(false);
  const [objOpen, setObjOpen] = useState(false);
  const [type, setType] = useState("");
  const [unitIndex, setUnitIndex] = useState(0);

  const [observerEmailErr, setObserverEmailErr] = useState("");

  const [qisLoading, setQIsLoading] = useState(false);
  const [sMiniScore, setSMiniScore] = useState(false);
  const [miniScore, setMiniScore] = useState(0);
  const [instructorData, setInstructorData] = useState([]);
  const [editObj, setEditObj] = useState({
    editbtn: true,
    meditmode: false,
    oeditmode: false,
    nuggetchanges: false,
    topicimgchanges: false,
    overViewEdit: false,
    weekEdit: false,
    nTitleEdit: false,
    uTitleEdit: false,
    imgEdit: false,
    titleEdit: false,
    objectView: false,
    editSemester: false,
  });
  const [nTitleIndexAndUTitleIndex, setNTitleIndexAndUTitleIndex] = useState({
    nIdx: 0,
    uIdx: 0,
  });
  const [editOpen, setEditOpen] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});
  const redux = useRef({});
  const [folder, setFolder] = useState();
  const refnuggetindex = useRef("");
  const [nopen, setNopen] = useState();
  const [inopen, setInopen] = useState(false);
  const [istLoading, setIstLoading] = useState(false);
  const [mainCourse, setMainCourse] = useState({});
  const [editType, setEditType] = useState("");
 
  let userDetails = useSelector(authData);
  const refselectedCourseFile = useRef();

  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);

  const [observerEmail, setObserverEmail] = useState("");
  const [observerOpen, setObserverOpen] = useState(false);
  const [observerLoading, setObserverLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [topicData, setTopicData] = useState({});
  const [removeData, setRemoveData] = useState({ objects: [], nuggets: [] });

  let lcourseDetails = userDetails;
  const scrollRef = useRef();
  const accRef = useRef();

  const handleChange = (panel) => (isExpanded) => {
    scrollRef.current.scrollTo(
      0,
      document.getElementById("acc" + panel).offsetTop -
      scrollRef.current.scrollHeight +
      1500
    );
    setExpanded(isExpanded ? panel : false);
  };
  const handleEditOpen = () => {
    setEditOpen(true);
    setEditObj({ ...editObj, nTitleEdit: true });
  };


  useEffect(() => {
    getCourseDetails();
    reduxRestore();
  }, []);

  async function getCourseDetails() {
    setIsLoading(true);
    const bodyParam = {
      body: {
        bpid: userDetails.curprgcou?.bpid,
        bname: userDetails.curprgcou?.bname,
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.pid,
        topicid: userDetails.curprgcou.cid,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const courseDetails = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE_DETAILS,
        bodyParam
      );

      courseDetails.topicimg = `https://${config.DOMAIN
        }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${courseDetails.cid
        }.png`;

      setTopicData(courseDetails);
      setNuggets(courseDetails.unit);
      setFolder(courseDetails.cid);

      setCourseDetails(courseDetails);
      setMainCourse(courseDetails);
      setShowPage(courseDetails.tenabled);

      saveLocale(courseDetails);
      
      const filteredInstructors = courseDetails?.observerList?.filter(instructor => {
        return !(instructor?.roles?.some(role => role.batchid === userDetails?.curprgcou?.bpid));
      });
      setFiltered(filteredInstructors);
      setIsLoading(false);

      let sdata = { ...redux.current };
     
      dispatch(awsSignIn(sdata));
    } catch (error) {
      console.error(error);
    }
  }
  function reduxRestore() {
    let sdata = { ...userDetails };
    console.log(sdata);
    redux.current = sdata;
  }

  async function validationcourse() {
    let tdur = 0,
      tobj = 0,
      tvid = 0,
      tdoc = 0,
      tsq = 0,
      tfq = 0,
      ndur = 0;
    let nug = [...nuggets];

    if (courseDetails.title == "" || courseDetails.title.length === 0) {
      swal("Oops!", "Course title cannot be empty", "error");
      return;
    }
    if (courseDetails.cr_desc == "" || courseDetails.cr_desc.length === 0) {
      swal(
        "Oops!",
        "Course description cannot be empty in Overview section",
        "error"
      );
      return;
    }
    if (
      courseDetails.weeks &&
      (courseDetails.weeks == "" || courseDetails.weeks.length === 0)
    ) {
      swal("Oops!", "Course duration cannot be empty", "error");
      return;
    }
    if (courseDetails.weeks < 1) {
      swal("Oops!", "Course duration must be greater than 0", "error");
      return;
    }
    if (nuggets.length === 0) {
      swal("Oops!", "You need to add atleast 1 Module.", "error");
      return;
    }
    if (nuggets.length > 0) {
      for (let k = 0; k < nuggets.length; k++) {
        if (nuggets[k].nuggets.length > 0) {
          for (let j = 0; j < nuggets[k].nuggets.length; j++) {
            if (nuggets[k].nuggets[j].objects.length > 0) {
            } else {
              swal(
                "Oops!",
                "You need to add at least 1 Content in each Module",
                "error"
              );
              return;
            }
          }
        } else {
          swal(
            "Oops!",
            "You need to add at least 1 Module in each Unit",
            "error"
          );
          return;
        }
      }
    }
    nug = produce(nug, (v) => {
      for (let i = 0; i < v.length; i++) {
        ndur = 0;
        for (let k = 0; k < v[i].nuggets.length; k++) {
          for (let o = 0; o < v[i].nuggets[k].objects.length; o++) {
            tobj++;
            ndur = ndur + Number(v[i].nuggets[k].objects[o].oduration);
            tdur = tdur + Number(v[i].nuggets[k].objects[o].oduration);
            if (
              v[i].nuggets[k].objects[o].otype == "video" ||
              v[i].nuggets[k].objects[o].otype == "audio"
            ) {
              tvid = tvid + 1;
            } else if (
              v[i].nuggets[k].objects[o].otype == "quiz" &&
              v[i].nuggets[k].objects[o].qtype == 1
            ) {
              tsq = tsq + 1;
            } else if (
              v[i].nuggets[k].objects[o].otype == "quiz" &&
              v[i].nuggets[k].objects[o].qtype != 1
            ) {
              tfq = tfq + 1;
            } else {
              tdoc = tdoc + 1;
            }
            if (
              v[i].nuggets[k].objects[o].otype == "interactivity" ||
              v[i].nuggets[k].objects[o].otype == "Interactivity"
            ) {
              v[i].nuggets[k].objects[o].ltype = "zp";
            }
            if (o == v[i].nuggets[k].objects.length - 1) {
              v[i].nuggets[k].nduration = ndur;
            }
          }
        }
      }
    });

    handleEditSave(tobj, tvid, tdoc, tsq, tfq, nug);
  }
  const handleEditSave = async (tobj, tvid, tdoc, tsq, tfq, nugs) => {
  
    setIstLoading(true);
    let topicjson = { ...courseDetails };
   
    topicjson.tlabel = `Semester-0,Course-0`;
    topicjson.coursenum = 0;
    topicjson.unit = nugs;
    topicjson.tobj = tobj;
    topicjson.tvid = tvid;
    topicjson.tdoc = tdoc;
    topicjson.tsq = tsq;
    topicjson.tfq = tfq;
    topicjson = produce(topicjson, (v) => {
      for (let q = 0; q < v.unit.length; q++) {
        for (let j = 0; j < v.unit[q].nuggets.length; j++) {
          for (var a = 0; a < v.unit[q].nuggets[j].objects.length; a++) {
            delete v.unit[q].nuggets[j].objects[a].curl;
            delete v.unit[q].nuggets[j].objects[a].file;
          }
        }
      }
      delete v.Policy;
      delete v.Signature;
      delete v.KeyPairId;
      delete v.topicimg;
    });

    const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    const bodyParam = {
      body: {
        topicjsonchanges: false,
        nuggetchanges: true,

        bpid: userDetails.curprgcou?.bpid,
        topicjson,
        topicimgchanges: editObj.topicimgchanges,
        oid: config.aws_org_id,
        eid: userDetails.eid,
        cid: topicjson.cid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: jwttoken,
      },
    };

    if (userDetails.curprgcou !== undefined) {
      if (userDetails.curprgcou?.btid !== undefined) {
        bodyParam.body.pid = userDetails.curprgcou.pid;
        bodyParam.body.btid = userDetails.curprgcou?.btid;
      } else {
        bodyParam.body.pid = userDetails.curprgcou.pid;
      }
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/edittopic",
        bodyParam
      );
      setIstLoading(false);
      window.location.reload();
    } catch (error) {
      console.log("getCategoryError", error);
    }
  };


  const enableDisablePost = async (status, nid, ids, idx) => {
    setIstLoading(true);
    const bodyParam = {
      body: {
        topicid: courseDetails.cid,
        oid: config.aws_org_id,
        btid: courseDetails.btid,
        nid,
        enabled: !status,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (
      userDetails.curprgcou !== undefined &&
      userDetails.curprgcou?.btid !== undefined
    ) {
      bodyParam.body.btid = userDetails.curprgcou?.btid;
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/enableNuggets",
        bodyParam
      );

      setNuggets((currentTitle) =>
        produce(currentTitle, (v) => {
          v[idx].nuggets[ids].enabled = !status;
        })
      );
      setRemoveData((currentRemove) =>
        produce(currentRemove, (draft) => {
          draft.nuggets.push(nid);
        })
      );
      setIstLoading(false);
    } catch (error) {
      console.log("enableNuggetserre", error);
    }
  };
 
  const renderSwitch = (param) => {
    if (param === "video") {
      return <Video className="video-size" cls1="cls-1--v" cls2="cls-2--v" />;
    } else if (param === "audio") {
      return <img src={Audio} className="video-size" alt="audio" />;
    } else if (param === "quiz" || param === "ptraining" || param === "pexam" || param === "ppt") {
      return <QuizIc className="quizAct" />;
    } else if (param === "html") {
      return (
        <Html
          className="video-size"
          cls1="cls-1--H"
          cls2="cls-2--H"
          cls3="cls-3--H"
        />
      );
    } else if (param === "Interactivity") {
      return <Review className="video-size" cls1="cls-1--R" cls2="cls-2--R" />;
    } else if (param === "pdf") {
      return <PdfIcon className="pdfAct" />;
    } else if (param === "youtube") {
      return <YouTubeVideo />;
    } else if (param === "vimeo") {
      return <Vimeo color="#f18121" />;
    }
  };

  const saveLocale = (data) => {
    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", data.Policy);
    setCookie("CloudFront-Signature", data.Signature);
    setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
  };

  function renderObjectsext(objType) {
    switch (objType) {
      case "video":
        return "video/*";
      case "audio":
        return "audio/*";
      case "pdf":
        return ".pdf";
      case "html":
        return ".html";
      case "Interactivity":
        return ".html, application/zip, application/x-zip-compressed, .zip";
      case "interactivity":
        return ".html, application/zip, application/x-zip-compressed, .zip";
      case "quiz":
        return ".json";
      default:
        return null;
    }
  }

  const viewAddEditCurrentObject = (object, idx, oidx, ids, nugget) => {
    let obj = {};
    obj.otherdata = {
      title: object.otitle,
      desc: object.odescription,
      dur: object.oduration,
      file: object.file,
      files: "",
    };

    if (object.oduration !== undefined && object.oduration != "") {
      obj.otherdata.min = Math.floor((object.oduration % 3600) / 60);
      obj.otherdata.sec = Math.floor((object.oduration % 3600) % 60);
    }
    obj.sobject = object.otype;
    obj.ptype = object.ptype;
    obj.otype = object.otype;
    obj.obj_id = object.obj_id;
    obj.seltype = true;
    obj.error1 = false;
    obj.afile = renderObjectsext(object.otype);
    obj.uindex = idx;
    obj.oindex = oidx;
    obj.nindex = ids;
    obj.unit = nugget.uname;
    obj.ourl = object.ourl;
    obj.curl = object.curl;
    if (object.stype == "chtml") {
      obj.stype = object.stype;
    } else {
      obj.stype = object.otype;
    }

    if (object.otype === "quiz") {
      obj.qtype = object.qtype;
      obj.cutoff = object.cutoff;
    }
    if (object.otype === "youtube" || object.otype === "vimeo") {
      obj.otherdata.embeddedcode = object.embeddedcode;
    }
    setSelectedObj(obj);
    setOIndex(oidx);
    setcurObject(object);
    
    handleClickOpen("body");
    setEditObj({ ...editObj, objectView: true });
    setOpen(true);
  };

  function CourseStructure() {
    return (
      <div>
        {istLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        

        {!isLoading && (
          <DialogBox
            type={type}
            nuggets={nuggets}
            setNuggets={setNuggets}
            nopen={nopen}
            setNopen={setNopen}
            setIsLoading={setIsLoading}
            courseDetails={courseDetails}
            setCourseDetails={setCourseDetails}
            errors={errors}
            setErrors={setErrors}
            unitIndex={unitIndex}
            setEditObj={setEditObj}
            editObj={editObj}
          />
        )}
        {!isLoading && userDetails.curprgcou?.bpid && (
          <InstructorDialogBox
            type={type}
            nuggets={nuggets}
            setNuggets={setNuggets}
            inopen={inopen}
            setInopen={setInopen}
            setIsLoading={setIsLoading}
            courseDetails={courseDetails}
            setCourseDetails={setCourseDetails}
            errors={errors}
            setData={setData}
            data={data}
            setErrors={setErrors}
            unitIndex={unitIndex}
            setEditObj={setEditObj}
            editObj={editObj}
            nuggetindex={refnuggetindex.current}
            setInstructorData={setInstructorData}
            instructorData={instructorData}
            getCourseDetails={getCourseDetails}
          />
        )}
        {editObj.nTitleEdit && (
          <EditNTitleAndEditUTitle
            open={editOpen}
            nuggets={nuggets}
            nTitleIndex={nTitleIndexAndUTitleIndex.nIdx}
            uTitleIndex={nTitleIndexAndUTitleIndex.uIdx}
            setNuggets={setNuggets}
            setEditObj={setEditObj}
            setEditOpen={setEditOpen}
            editObj={editObj}
            editType={editType}
          />
        )}
        {isLoading ? (
          <></>
        ) : (
          <div className={classes.root}>
            {nuggets?.map((nugget, idx) => (
              <Accordion
                key={idx}
                expanded={expanded === `${idx}`}
                onChange={handleChange(`${idx}`)}
                ref={accRef}
                id={"acc" + idx}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <h2
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      minHeight: "34px",
                    }}
                    className="unit-hover"
                  >
                    <span>{nugget.uname}</span>
                    {userDetails.role != 'CDirector' ?
                      <span className="unit-edit-icon">
                        <button
                          onClick={() => {
                            setEditType("Unit");
                            handleEditOpen();
                            setNTitleIndexAndUTitleIndex((currentTitle) =>
                              produce(currentTitle, (v) => {
                                v.nIdx = 0;
                                v.uIdx = idx;
                              })
                            );
                          }}
                          className="btn-reset btn-border"
                        >
                          <img
                            src={editIcon}
                            className="size"
                            alt="edit-delete"
                          />
                        </button>
                        <button
                          style={{
                            color: "white",
                            borderStyle: "groove",
                            borderColor: config.main_color_2,
                            height: "30px ",
                            lineHeight: "0px ",
                            width: "150px",
                            backgroundColor: config.main_color_2,
                            fontSize: "15px ",
                            borderRadius: "3px ",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            let filteredUnit = nuggets.filter(
                              (nug) => nug.unit_id !== nugget.unit_id
                            );
                            setNuggets(filteredUnit);
                            setEditObj({ ...editObj, meditmode: true });
                          }}
                        >
                          Remove unit
                        </button>
                      </span> : null}
                  </h2>
                </AccordionSummary>
                <DragAndDrop
                  objects={nugget?.nuggets}
                  uidx={idx}
                  renderSwitch={renderSwitch}
                  editObj={editObj}
                  setRemoveData={setRemoveData}
                  setNuggets={setNuggets}
                  nuggets={nuggets}
                  setcurObject={setcurObject}
                  topicData={topicData}
                  renderObjectsext={renderObjectsext}
                  setOIndex={setOIndex}
                  oIndex={oIndex}
                  setOpen={setOpen}
                  setObjOpen={setObjOpen}
                  isLoading={isLoading} setIsLoading={setIsLoading}
                  setInopen={setInopen}
                  setCourseDetails={setCourseDetails}
                  setData={setData}
                  setUnitIndex={setUnitIndex}
                  setSelectedObj={setSelectedObj}
                  refnuggetindex={refnuggetindex}
                  enableDisablePost={enableDisablePost}
                  handleEditOpen={handleEditOpen}
                  setEditType={setEditType}
                  setNTitleIndexAndUTitleIndex={setNTitleIndexAndUTitleIndex}
                  setcNugget={setcNugget}
                  viewAddEditCurrentObject={viewAddEditCurrentObject}
                  setEditObj={setEditObj}
                  userDetails={userDetails}
                  setInstructorData={setInstructorData}
                  instructorData={instructorData}
                />
                {userDetails.role != 'CDirector' ? <div style={{ margin: "1rem 3rem" }}>
                  <button
                    style={styles.enableDisable}
                    onClick={() => {
                      setNopen(true);
                      setType("Module");
                      setUnitIndex(idx);
                    }}
                  >
                    Add Module
                  </button>
                </div> : null}
              </Accordion>
            ))}
            {userDetails.role != 'CDirector' ? <div style={{ margin: "2rem 2rem" }}>
              <button
                style={styles.enableDisable}
                onClick={() => {
                  setNopen(true);
                  setType("Unit");
                }}
              >
                Add Unit
              </button>
            </div> : null}
          </div>
        )}
        <div>
          {editObj.meditmode && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "Save the changes",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willsave) => {
                    if (willsave) {
                     
                      validationcourse();
                    }
                  });
                }}
                style={{
                  backgroundColor: "rgb(227, 95, 20)",
                  color: "#fff",
                  fontSize:'12px',
                  height: "36px",
                  marginRight: "10px",
                  alignSelf: "center",
                }}
              >
                Save Course
              </Button>
              <Button
                variant="outlined"
                style={{ fontSize:'12px', height: "36px", alignSelf: "center" }}
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willsave) => {
                    if (willsave) {
                      setIsLoading(true);
                      setCourseDetails({ ...mainCourse });
                      editObj.meditmode = false;
                      editObj.editbtn = true;
                      setEditObj({ ...editObj });
                      setIsLoading(false);
                      window.location.reload();
                    }
                  });
                }}
              >
                Discard
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  const handleClose = () => {
    setOpen(false);
    editObj.oeditmode = false;
    editObj.objectView = false;
    setEditObj({ ...editObj });
  };

  const handleObserverClose = () => {
    setObserverOpen(false);
  };

  const panes = [
    {
      menuItem: "Course structure",
      render: () => (
        <Tab.Pane>
          <CourseStructure />
        </Tab.Pane>
      ),
    },

  ];
  const handleTabChange = (aIndex) => {
    if (editObj.meditmode && aIndex != 0) {
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willsave) => {
        if (willsave) {
          setIsLoading(true);
          editObj.meditmode = false;
          editObj.editbtn = false;
          editObj.overViewEdit = false;
          setCourseDetails({ ...mainCourse });
          setEditObj({ ...editObj });
          setActiveIndex(aIndex);
          setIsLoading(false);
          window.location.reload();
        } else {
          setActiveIndex(0);
          setCourseDetails({ ...courseDetails });
        }
      });
    } else if (!editObj.meditmode) {
      if (aIndex == 0) {
        setEditObj({ ...editObj, editbtn: true });
        setNuggets(mainCourse.unit);
        setCourseDetails({ ...mainCourse });
      } else {
        setOpen(false);
        editObj.oeditmode = false;
        editObj.editbtn = false;
        setEditObj({ ...editObj });
      }

      setActiveIndex(aIndex);
    } else {
      setActiveIndex(0);
      setCourseDetails({ ...courseDetails });
    }
  };
  const changeHandler = (event) => {
    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];

      var body = {
        filetype: event.target.files[0].type,
        filename: "topic.png",
        folder: courseDetails.cid,
      };

      uploadfile(body);
    }
  };
  async function uploadfile(body) {
    setIstLoading(true);

    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": refselectedCourseFile.current.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then(() => {
          setEditObj({ ...editObj, topicimgchanges: true, meditmode: true });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleAddObserverToCourse = async () => {
    setObserverEmailErr("");
    if (observerEmail === '') {
      setObserverEmailErr("*Please select observer from list")
      return;
    }
    setObserverLoading(true);
    const bodyParams = {
      body: {
        cid: courseDetails.cid,
        bpid: userDetails.curprgcou?.bpid,
        bname: userDetails.curprgcou?.bname,
        schema: config.schema,
        oldemailid: courseDetails?.observerMail,
        emailid: observerEmail,
        role: 'Observer'
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/addobservertocourse",
        bodyParams
      );
      setObserverLoading(false);
      setObserverOpen(false);
      getCourseDetails();
    } catch (err) {
      console.error(err);
      setObserverLoading(false);
      setObserverOpen(false);
    }
  };
  return (
    <main ref={scrollRef}>
      <div
        className="btn-toggle"
        onClick={() => handleToggleSidebar(true)}
      ></div>
      <UserHeader Bindex={3} />
      <ObjectPopUp
        objOpen={objOpen}
        setObjOpen={setObjOpen}
        renderSwitch={renderSwitch}
        folder={folder}
        cNugget={cNugget}
        setNuggets={setNuggets}
        nuggets={nuggets}
        setEditObj={setEditObj}
        editObj={editObj}
      />
      {editObj.objectView && (
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          onBackdropClick="false"
          className={classes.paper}
          fullScreen={fullScreen}
        >
          <DialogContent>
            <CoursePlayer
              handleClose={handleClose}
              courseDetails={courseDetails}
              setCourseDetails={setCourseDetails}
              objectData={objectData}
              setObjectData={setObjectData}
              oIndex={oIndex}
              setOIndex={setOIndex}
              curObject={curObject}
              setcurObject={setcurObject}
              curObRef={curObRef}
              assignment={assignment}
              setAssignment={setAssignment}
              summative={summative}
              setSummative={setSummative}
              sumScore={sumScore}
              setSumScore={setSumScore}
              miniScore={miniScore}
              setMiniScore={setMiniScore}
              sMiniScore={sMiniScore}
              setSMiniScore={setSMiniScore}
              qisLoading={qisLoading}
              setQIsLoading={setQIsLoading}
              editObj={editObj}
              setEditObj={setEditObj}
              assignmentLoad={assignmentLoad}
              setAssignmentLoad={setAssignmentLoad}
              renderSwitch={renderSwitch}
              setSelectedObj={setSelectedObj}
              selectedObj={selectedObj}
              setNuggets={setNuggets}
              nuggets={nuggets}
              folder={courseDetails.cid}
              setOpen={setOpen}
              open={open}
            />
          </DialogContent>
        </Dialog>
      )}

      <div className={Cviewstyle.management}></div>
      <div className={Cviewstyle.management_info}>
        <div className={Cviewstyle.h1} style={{ display: "flex" }}>
          <h1
            style={{ color: "black", fontSize: "20px", maxWidth: "66%" }}
            className="title-hover"
          >
            <>
              {!editObj.titleEdit && (
                <strong
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {courseDetails.title}{" "}
                  {userDetails.role != 'CDirector' ? <span
                    className="hide_edit-title"
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "-60px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setEditObj({ ...editObj, titleEdit: true });
                      }}
                      className="btn-reset btn-border"
                    >
                      <img src={editIcon} className="size" alt="edit-delete" />
                    </button>
                  </span> : null}
                </strong>
              )}
              {editObj.titleEdit && (
                <strong style={{ display: "flex", alignItems: "center" }}>
                  <TextareaAutosize
                    maxRows={3}
                    cols={200}
                    aria-label="maximum height"
                    placeholder=""
                    defaultValue={courseDetails.title}
                    style={{
                      width: "100%",
                      fontSize: "18px",
                      padding: "1rem",
                      resize: "none",
                    }}
                    onChange={(e) => {

                      setCourseDetails((prevcourseDetails) =>
                        produce(prevcourseDetails, (v) => {
                          v.title = e.target.value;
                        })
                      );
                      setEditObj({ ...editObj, meditmode: true });
                    }}
                  />
                  <span>
                    <button
                      onClick={() => {
                        setEditObj({ ...editObj, titleEdit: false });
                      }}
                      className="btn-reset btn-border"
                    >
                      <CloseIcon
                        className="size"
                        style={{
                          fontSize: "2rem !important",
                          color: "#19478a",
                          fill: "#19478a",
                        }}
                      />
                    </button>
                  </span>
                </strong>
              )}
            </>
          </h1>
         
        </div>
        <div
          style={{ marginBottom: "2rem", fontWeight: "bold" }}
          className="semester-hover"
        >
          {editObj.editSemester ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <select
                onChange={(e) => {
                  setCourseDetails((prevcourseDetails) =>
                    produce(prevcourseDetails, (v) => {
                      v.semester = e.target.value;
                    })
                  );
                  setEditObj({ ...editObj, meditmode: true });
                }}
                style={{ width: "250px", padding: ".5rem", fontSize: "1.4rem" }}
                defaultValue={courseDetails.semester}
              >
                {[
                  {
                    sem: "----Select Semester----",
                    value: 0,
                    disabled: true,
                  },
                  {
                    sem: "Semester 1",
                    value: 1,
                  },
                  {
                    sem: "Semester 2",
                    value: 2,
                  },
                  {
                    sem: "Semester 3",
                    value: 3,
                  },
                  {
                    sem: "Semester 4",
                    value: 4,
                  },
                  {
                    sem: "Semester 5",
                    value: 5,
                  },
                  {
                    sem: "Semester 6",
                    value: 6,
                  },
                  {
                    sem: "Semester 7",
                    value: 7,
                  },
                  {
                    sem: "Semester 8",
                    value: 8,
                  },
                ].map((semester) => (
                  <option
                    style={{ fontSize: "1.4rem" }}
                    key={semester.value}
                    value={semester.value}
                    disabled={semester.disabled}
                  >
                    {semester.sem}
                  </option>
                ))}
              </select>
              <input
                defaultValue={courseDetails.coursenum}
                value={courseDetails.coursenum}
                placeholder="Enter course label..."
                pattern="\d*"
                maxLength="4"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    let coursenum = e.target.value;
                    setCourseDetails((prevtopicData) =>
                      produce(prevtopicData, (v) => {
                        v.coursenum = coursenum;
                      })
                    );
                    setEditObj({ ...editObj, meditmode: true });
                  }
                }}
                style={{
                  width: "250px",
                  padding: ".5rem",
                  fontSize: "1.4rem",
                  marginLeft: "1rem",
                }}
              />
              <button
                onClick={() => {
                  setEditObj({ ...editObj, editSemester: false });
                }}
                className="btn-reset btn-border"
              >
                <CloseIcon
                  className="size"
                  style={{
                    fontSize: "2rem !important",
                    color: "#19478a",
                    fill: "#19478a",
                  }}
                />
              </button>
            </div>
          ) : (
            <div
              className="semester-hover"
              style={{ display: "flex", alignItems: "center" }}
            >
             

              <span className="semester-edit">
                <button
                  onClick={() => {
                    setEditObj({ ...editObj, editSemester: true });
                  }}
                  className="btn-reset btn-border"
                >
                  <img src={editIcon} className="size" alt="semester-edit" />
                </button>
              </span>
            </div>
          )}
        </div>
        <div className={Cviewstyle.flex_box}>
          <div className={Cviewstyle.flex_box__container}>
            <div className={Cviewstyle.time_line}>
              <div className="week-hover" style={{ display: "flex" }}>
                <>
                  {!editObj.weekEdit && (
                    <div
                      className="week-border"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>
                        <ClockGray
                          className="clock-size"
                          cls1="cls1"
                          cls2="cls2"
                        />
                      </span>
                      <span
                        style={{
                          fontSize: "15px",
                          marginBottom: ".5rem",
                          marginRight: ".5rem",
                        }}
                      >
                        {courseDetails.weeks} days
                      </span>
                    </div>
                  )}
                </>
                {userDetails.role != 'CDirector' ? <span className="hide__edit-week">
                  <button
                    onClick={() => {
                      setEditObj({ ...editObj, weekEdit: true });
                    }}
                    className="btn-reset btn-border"
                  >
                    <img src={editIcon} className="size" alt="edit-edit" />
                  </button>
                </span> : null}
              </div>
            </div>

            {editObj.weekEdit && (
              <div
                style={{
                  width: "170px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RegexTextField
                  regex={onlyAlphanumericRegex}
                  label="Weeks"
                  defaultValue={courseDetails.weeks}
                  value={courseDetails.weeks || ""}
                  onChange={(e) => {
                    setCourseDetails({
                      ...courseDetails,
                      weeks: e.target.value,
                    });
                    setEditObj({ ...editObj, meditmode: true });
                  }}
                />
                <span
                
                >
                  <button
                    onClick={() => {
                      setEditObj({ ...editObj, weekEdit: false });
                    }}
                    className="btn-reset btn-border"
                  >
                    <CloseIcon
                      className="size"
                      style={{
                        fontSize: "2rem !important",
                        color: "#19478a",
                        fill: "#19478a",
                      }}
                    />
                  </button>
                </span>
              </div>
            )}

            <div>
              {editObj.overViewEdit && (
                <div className="discc-border" style={{ position: "relative" }}>
                  <TextareaAutosize
                    maxRows={10}
                    aria-label="maximum height"
                    placeholder=""
                    defaultValue={courseDetails.cr_desc}
                    value={courseDetails.cr_desc || ""}
                    style={{
                      width: "100%",
                      fontSize: "18px",
                      padding: "1rem",
                      resize: "none",
                    }}
                    onChange={(e) => {
                      setCourseDetails((prevcourseDetails) =>
                        produce(prevcourseDetails, (v) => {
                          v.cr_desc = e.target.value;
                        })
                      );
                      setEditObj({ ...editObj, meditmode: true });
                    }}
                  />
                  <span
                    className="hide__edit-wise"
                    style={{
                      position: "absolute",
                      top: "-3.3rem",
                      right: "-13px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setEditObj({ ...editObj, overViewEdit: false });
                      }}
                      className="btn-reset btn-border"
                    >
                      <CloseIcon
                        className="size"
                        style={{
                          fontSize: "2rem !important",
                          color: "#19478a",
                          fill: "#19478a",
                        }}
                      />
                    </button>
                  </span>
                </div>
              )}
              {!editObj.overViewEdit && (
                <div className="disc-border" style={{ position: "relative" }}>
                  <p
                    style={{ fontSize: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html: courseDetails.cr_desc,
                    }}
                  ></p>
                  {userDetails.role != 'CDirector' ? <span
                    className="hide__edit-disc"
                    style={{
                      position: "absolute",
                      top: "-2.8rem",
                      right: "-13px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setEditObj({ ...editObj, overViewEdit: true });
                      }}
                      className="btn-reset btn-border"
                    >
                      <img src={editIcon} className="size" alt="edit-edit" />
                    </button>
                  </span> : null}
                </div>
              )}
            </div>
          </div>
          <div
            className={Cviewstyle.management__image}
            style={{
              position: "relative",
              height: "290px",
              borderRadius: "10px",
            }}
          >
            {(!editObj.imgEdit ||
              refselectedCourseFile.current === undefined) &&
              !isLoading && (
                <span
                  className="image-hover"
                  style={{ display: "flex", position: "relative" }}
                >
                  <img
                    src={courseDetails.topicimg}
                    className={Caviewstyle.eimagebox}
                    alt="Courseimage"
                  />
                  {editObj.imgEdit ? (
                    <span
                      className="hide_image-icon"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "-10px",
                      }}
                    >
                      <button
                        onClick={() => {
                          setEditObj({ ...editObj, imgEdit: false });
                        }}
                        className="btn-reset btn-border"
                      >
                        <CloseIcon
                          className="size"
                          style={{
                            fontSize: "1.8rem !important",
                            color: "#19478a",
                            fill: "#19478a",
                          }}
                        />
                      </button>
                    </span>
                  ) : (
                    <span
                      className="hide_image-icon"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "-10px",
                      }}
                    >
                      {userDetails.role != 'CDirector' ? <button
                        onClick={() => {
                          setEditObj({ ...editObj, imgEdit: true });
                        }}
                        className="btn-reset btn-border"
                      >
                        <img className="size" src={editIcon} alt="editImage" />
                      </button> : null}
                    </span>
                  )}
                </span>
              )}
            {isLoading ? <Skeleton variant="rect" height={210} /> : null}
            {editObj.imgEdit && (
              <>
                {refselectedCourseFile.current !== undefined && (
                  <img
                    className={Caviewstyle.eimagebox}
                    src={URL.createObjectURL(refselectedCourseFile.current)}
                    alt="topicimage"
                  />
                )}

                <input
                  type="file"
                  id="cfiles"
                  style={{ visibility: "hidden", position: "absolute" }}
                  onChange={changeHandler}
                  accept="Image/*"
                />
                <label
                  for="cfiles"
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "6px",
                  }}
                >
                  <AddAPhotoIcon
                    style={{ color: "#19478a", cursor: "pointer" }}
                  />
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={Cviewstyle.tabContainer}>
        {!courseDetails?.observerMail && userDetails.role != 'CDirector' && (
          <button
            className={`${Cviewstyle.Add_observer} ${Cviewstyle.position}`}
            onClick={() => {
              setObserverOpen(true);
            }}
          >
            Add observer
          </button>
        )}
        {courseDetails?.observerMail && (
          <h2
            className={`${Cviewstyle.position} ${Cviewstyle.observerEmailContainer}`}
          >
            {courseDetails?.observerMail}
            {userDetails.role != 'CDirector' ? <span className={Cviewstyle.editContainer}>
              <Edit
                onClick={() => {
                  setObserverOpen(true);
                  setObserverEmail(courseDetails?.observerMail);
                  setCourseDetails((prev) => {
                    return {
                      ...prev,
                      observerList: prev.observerList?.map((item) => {
                        if (item.emailid === prev.observerMail) {
                          item.select = true;
                        }
                        return item;
                      }),
                    };
                  });
                }}
              />
            </span> : null}
          </h2>
        )}
        <Tab
          panes={panes}
          onTabChange={(e, { activeIndex }) => handleTabChange(activeIndex)}
          activeIndex={activeIndex}
        />
      </div>
      {observerOpen && (
        <Dialog open={observerOpen} onClose={handleObserverClose}>
          <DialogTitle ><p style={{fontSize:'25px'}}>Add Observer</p></DialogTitle>
          <DialogContent>
            <div style={{ width: "300px" }}>
              <Autocomplete
                id="country-select-demo"
                sx={{ width: 300 }}
                options={filtered}
                autoHighlight
                getOptionLabel={(option) => option.emailid + " (" + option.first_name + ")"}
                InputLabelProps={{ style: { fontSize: 14 } }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, fontSize:'15px' }} {...props}>

                    {option.emailid} ({option.first_name})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a Observer"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}

                    InputLabelProps={{ style: { fontSize: 15 } }}
                  />
                )}
                onChange={(event, value) => {
                  setObserverEmail(value.emailid);
                }}
              />
            </div>
            
          </DialogContent>
          <DialogActions>
            <Button style={{fontSize:'12px'}} onClick={handleObserverClose}>Cancel</Button>

            <button
              className={`${Cviewstyle.Add_observer}`}
              onClick={handleAddObserverToCourse}
              disabled={observerLoading ? true : false}
            >
              {observerLoading ? "Loading..." : "Add observer"}
            </button>
          </DialogActions>
        </Dialog>
      )}
    </main>
  );
}
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: config.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: config.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
    fontWeight: "bold",
  },
  enableDisable: {
    color: "#f18121",
    border: "1px solid #f18121",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    padding: ".3rem 2rem",
    alignSelf: "center",
    fontWeight: "bold",
  },
  addinstructor: {
    color: "#f18121",
    border: "1px solid #f18121",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    padding: ".3rem 2rem",
    alignSelf: "center",
    fontWeight: "bold",
    marginRight: "4px",
  },
};
