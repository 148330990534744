// Dependencies imports
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";

import { useNavigate } from "react-router-dom";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

// Local imports
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import UserHeader from "../Header/UserHeader/UserHeader";
import PreviousBatches from "./PreviousBatches";

// styles imports
import Prog from "./Programs.module.scss";

// Internal Styles for the material UI components
const header = {
  color: "white",
  fontFamily: "nunito",
  fontSize: "15px",
  fontWeight: "400px",
};

const programnames = {
  color: config.main_color_1,
  fontFamily: "nunito",
  fontSize: "16px",
  fontWeight: "500px",
};

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});

// Styles for Pagination
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

// Pagination function
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

// Start of Main Program components
const Programs = () => {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [batches, setBatches] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();
  let userDetails = useSelector(authData);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  // Useeffect for api call
  useEffect(() => {
    getPrograms().then(values => {

      var val = values.programs;
      var tval = [];
      if (userDetails?.role !== "Admin") {
        val = values?.programs.filter(prg => {
          for (let index = 0; index < prg.length; index++) {

            prg[index]?.batchs.filter(batch => {
              if (userDetails.uData?.batchid?.includes(batch.bid)) {
                tval.push(prg[index])
              }
            });
          }
        })
        val = tval;
      }

      const uniqueAuthors = val.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.pid === current.pid)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      console.log(uniqueAuthors);
      setTableData(uniqueAuthors);
      setLoading(false);
    });
    return () => { };
  }, []);


  // API call syntax to get programs
  async function getPrograms() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        schema: config.schema
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const programs = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PROGRAMS,
        bodyParam
      );

      if (userDetails.role !== "Admin") {
        var pgm = { "programs": [] };
        if (userDetails.uData?.batchid !== null) {
          for (let i = 0; i < userDetails.uData?.batchid.length; i++) {
            var mydata = programs.programs.filter(
              x => x.batchs.some(y => y.bid == userDetails.uData.batchid[i]));

            pgm.programs.push(mydata)
          }
        }
        return pgm;
      }

      else {

        return programs;
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function titleclick(data) {

    let sdata = { ...userDetails };

    sdata.curprgcou = { ...data };
    sdata.curprgcou.pid = data.pid;
    delete sdata.curprgcou.bpid;

    dispatch(awsSignIn(sdata));

    navigate("/ProgramsInfo");
  }

  function tableview() {

    return (
      <TableBody>
        {tableData.length == 0 ? (
          <TableRow style={{ height: "50vh" }}>
            <TableCell style={{ width: "25%" }}></TableCell>
            <TableCell style={{ width: "50%" }}>
              <h5 style={{ textAlign: "center" }}>No Data</h5>
            </TableCell>
            <TableCell style={{ width: "25%" }}></TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {(rowsPerPage > 0
          ? tableData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
          : tableData
        ).map((item) => {
          return (
            <TableRow>
              <TableCell style={{ width: "20%" }}>
                <img
                  className={Prog.image}
                  src={`https://${config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/program-images/${item.pid
                    }.png`}
                  alt=""
                ></img>
              </TableCell>
              <TableCell style={{ width: "40%" }}>
                <h3
                  onClick={() => titleclick(item)}
                  style={programnames}
                  className={Prog.titleclick}
                >
                  {item.pname}
                </h3>
              </TableCell>

              <TableCell style={{ width: "20%" }}>

                <button
                  className={Prog.batchbutton}
                  onClick={() => {
                    let tbtitem = item
                    if (userDetails?.role !== "Admin") {
                      tbtitem.batchs = item?.batchs?.filter(batch => userDetails.uData?.batchid?.includes(batch.bid));
                    }
                    setBatches(tbtitem);
                    handleClickOpen();
                  }}
                >
                  <h3 className={Prog.batchtitle}>View batches  </h3>

                </button>
              </TableCell>
              {userDetails?.role == 'Admin' ? <TableCell style={{ width: "20%", textAlign: 'center' }}>
                <h3
                  style={programnames}

                >
                  {item.count === undefined || item.count === 0 ? '' : `${item.count}`}
                </h3>
              </TableCell> : null}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  function loader() {
    let a = [1, 2, 3, 4];
    return (
      <TableBody>
        {a.map(() => {
          return (
            <TableRow>
              <TableCell style={{ width: "25%" }}>
                <Skeleton variant="rect" width={210} height={118} />
              </TableCell>
              <TableCell style={{ width: "50%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
              <TableCell style={{ width: "25%" }}>
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Main Component Return
  return (
    <div className={Prog.maincontainer}>
      <UserHeader />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PreviousBatches handleClose={handleClose} batches={batches} />
        </Fade>
      </Modal>
      <div className={Prog.manage}>
        <h3 className={Prog.heading}>Manage programs</h3>

      </div>
      <div className={Prog.table}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ background: config.main_color_1 }}>
              <TableRow>
                <TableCell style={header}>Program Image</TableCell>
                <TableCell style={header}>
                  Program Name
                </TableCell>

                <TableCell style={header}>Batches</TableCell>
                {userDetails?.role == 'Admin' ? <TableCell style={header}>Batches for approval</TableCell> : null}
              </TableRow>
            </TableHead>

            {loading ? loader() : tableview()}
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Programs;
