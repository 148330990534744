import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  OutlinedInput,
} from "@material-ui/core";
//import { useDispatch, useSelector } from "react-redux";
//import { awsSignIn, authData } from "../../redux/auth/authSlice";
import vregi from './AddEvent.module.scss';
import { Close } from '@material-ui/icons';
//const backIcon = require('../Assets/imgs/back.png');
//const requiredIcon = require('../Assets/imgs/required.png');

export default function ViewRegistration(props) {

  //let userDetails = useSelector(authData);
  //const dispatch = useDispatch();

  const { regiDtls, handleNuggetsClose } = props;


  //  useEffect(() => {

  // console.log("viewregiDtls",regiDtls[0])
  //  }, []);

  return (

    <div style={{ margin: 20 }}>
      <div cl0assName={vregi.iconcontainer} style={{ display: "flex", alignItems: "center", placeContent: "space-between" }}>
        <p style={{ fontSize: 16, fontWeight: '700' }}>General Info</p>
        <Close className={vregi.closebutton} onClick={handleNuggetsClose} />
      </div>

      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >First Name (As per records):</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["First Name"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Second Name (As per records):</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Last Name"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Email:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Email"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Mobile Number:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Contact"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Year Of Birth:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Year of Birth"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Gender:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Gender"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Education:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Education"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Year:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Year"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Amount:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Amount"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont}>Blood Group:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Blood Group"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Name of University/Medical Council - state/country:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["University Name"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >University ID/Medical License Number/Med/Dental/Nursing/Pharmacy/Ayush council etc:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["University Id"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >KMC no or RGUHS student No./Aadhar/PAN/Voter id:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Dococument Id"]} />
      </div>
      <div>
        <p style={{ fontSize: 16, fontWeight: '700', marginTop: 20 }}>Permanent Address</p>
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Address Line 1:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Permanent Address 1"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>
          <p className={vregi.pfont}>Address Line 2:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Permanent Address 2"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>
          <p  className={vregi.pfont} >Address Line 3:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Permanent Address 3"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >State:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Permanent State"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >City:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Permanent City"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >PIN:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Permanent Pin"]} />
      </div>
      <div>
        <p style={{ fontSize: 16, fontWeight: '700', marginTop: 20 }}>Present Address</p>
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >Address Line 1:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled
          className={vregi.fullwidth} defaultValue={regiDtls[0]["Present Address 1"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>
          {/*  */}
          <p className={vregi.pfont} >Address Line 2:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Present Address 2"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>
          {/*  */}
          <p className={vregi.pfont}>Address Line 3:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Present Address 3"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >State:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Present State"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >City:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Present City"]} />
      </div>
      <div style={{ marginTop: 10, marginHorizontal: 10 }}>
        <div style={{ flexDirection: 'row' }}>

          <p className={vregi.pfont} >PIN:</p>
        </div>
        <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={regiDtls[0]["Present Pin"]} />
      </div>

      <p style={{ color: 'white', fontSize: 18, fontWeight: '700', textAlign: 'center' }}>Save</p>

    </div>
  )


}
