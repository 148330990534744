import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";

import Add from "./DailyFileUpload.module.scss";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API, JS } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const DailyFileUpload = (props) => {
  const [spin, setSpin] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [wrongFile, setWrongFile] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const [title, setTitle] = useState("");
  const [err, setErr] = useState("");
  const [date, setDate] = useState("");
  const [link, setLink] = useState("");
  const [filename, setFileName] = useState("");
  const classes = useStyles();
  let { handleClose, getDailyDebrief } = props;
  let userDetails = useSelector(authData);
  let lcourseDetails = userDetails.curprg;
  
  
  const onsubmit = (event) => {

    setErr("");

    if (title == undefined || title.trim() === "") {

      setErr("Please Enter Title");
      return;
    }
    // if (title != undefined && title.trim() != "" && title.trim().length > 100) {
    //   setErr("*Please Enter Event Title length less than 100 character");
    //   return;
    // }

    if (!isFilePicked) {
      setErr("Please select file to upload");
      return;
    }
    if (wrongFile === true) {
      setErr("Only pdf file format is allowed to upload");
      return;
    }

    if (isFilePicked) {
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length) || selectedFile.name;
      getPreSignedUrl(extend)
    }
    else {
      add();
    }
  };
  async function add(fname) {
    setSpin(true);
    let timestamp = new Date().getTime();
    console.log(filename);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        action: "put",
        title,
        id: timestamp,
        cdate: timestamp,
        filename: fname
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.DAILY_FILE_UPLOAD,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      handleClose();
      swal({
        title: "Success",
        text: "File added successfully",
        icon: "success",
        dangerMode: false,
      })
        .then(willDelete => {
          if (willDelete) {
            handleClose();
            getDailyDebrief();
          }
        });
      setSpin(false);

    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {

          }
        });
      console.log("getUserError", error);
      setSpin(false);
    }
    console.log("Mount");
  }

  async function getPreSignedUrl(extend) {
    setSpin(true);
    let fname = timeStamp.toString() + "." + extend;

    const bodyParam = {
      body: {

        oid: config.aws_org_id,
        type: 'dailyfile',
        filetype: selectedFile.type,
        filename: fname,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      setFileName(fname);
      fileUpload(selectedFile, response, fname);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  async function fileUpload(file, url, fname) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {
        add(fname);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  const onChangeHandlerTitle = event => {
    setTitle(event.target.value);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log("Selectedfile", event.target.files[0]["name"]);

    let extend = event.target.files[0]["name"].substring(event.target.files[0]["name"].lastIndexOf('.') + 1, event.target.files[0]["name"].length) || event.target.files[0]["name"];
    if (extend == "pdf") {
      setWrongFile(false);
    }
    else {
      setWrongFile(true);
    };
    // console.log("extend", extend);
    let tstamp = new Date().getTime();
    setTimeStamp(tstamp);
    setIsFilePicked(true);
  };

  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add Daily De-brief</h3>
          <div className={Add.form}>
            <div className={Add.caution}>{err}</div>
            <form onSubmit={(event) => {
              event.preventDefault();
            }}>

              <div className={Add.fieldholder}>
                <div className={Add.labelholder}>
                  <h3 className={Add.label} style={{ textAlign: "left" }}>Title</h3>
                </div>
                <div className={Add.inputholder}>
                  <div className={Add.caution}></div>
                  <input type="text" placeholder="Type your title here..." maxlength="160" rows={5} className={Add.area} onChange={onChangeHandlerTitle} value={title}  ></input>
                </div>
              </div>

              <div className={Add.fieldholder}>
                <div clasName={Add.labelholder}>
                  <h3 className={Add.label}>Select File</h3>
                </div>
                <div className={Add.inputholder}>
                  {/* <input type="text" placeholder={"Add your link"} className={Add.date}></input> */}
                  <input type="file" id="file" name="file not selected" accept=".pdf" className={Add.browse} onChange={changeHandler} />
                </div>
              </div>
              <div className={Add.buttonholder}>
                <button className={Add.cancel} onClick={handleClose}>Cancel</button>
                <button className={Add.submit} onClick={onsubmit} >Submit</button>
              </div>
            </form>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} /></div>}
    </div>

  );
};

export default DailyFileUpload;
