// Dependencies imports
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Tab } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useDispatch } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";


// Local imports
import UserHeader from '../Header/UserHeader/UserHeader';
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";


//Styles imports
import Grad from './Grading.module.scss';


// Start of Grading component
const Grading = () => {

    // Data for tabs
    const [programDetail, setProgramDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    let userDetails = useSelector(authData);
    let navigate = useNavigate();
    const dispatch = useDispatch();



    useEffect(() => {
        getProgramsDetails();
        breadcrumb();
    }, []);

    function onClickOfPage(name,path){
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name: name,
            path: path
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }

    
    function titleclick(data, PValue) {
        console.log("data", data)
        let sdata = { ...userDetails };

        sdata.grade = { ...data };

        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name: "Grading Details",
            path: "/gradingdetails"
        };

        sdata.breadcrumb = temp;

        console.log("dhfdf", (sdata.curprgcou));

        dispatch(awsSignIn(sdata));

        navigate("/gradingdetails");
    }

    function titleclickasses(data, PValue) {
        console.log("data", data)
        let sdata = { ...userDetails };

        sdata.asses = { ...data };

        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name: "Assesment Details",
            path: "/assesmentdetails"
        };

        sdata.breadcrumb = temp;
        console.log("dhfdf", (sdata.curprgcou));

        dispatch(awsSignIn(sdata));

        navigate("/assesmentdetails");
    }

    function titleclickassign(data, PValue) {
        console.log("data", data)
        let sdata = { ...userDetails };

        sdata.assign = { ...data };

        let temp = [...sdata.breadcrumb];
        
        temp[3] = {
            name: "Assignment Details",
            path: "/assignmentdetails"
        };

        sdata.breadcrumb = temp;
        console.log("dhfdf", (sdata.curprgcou));

        dispatch(awsSignIn(sdata));

        navigate("/assignmentdetails");
    }
    const panes = [
        {
            menuItem: 'Miniassignments',
            render: () => (
                <Tab.Pane >
                    {loading === false ? <div className={Grad.cardholder}>
                        {programDetail.map((p, index) => (
                            // <Tab icon={p.icon} label={p.title} />

                            <div className={Grad.cards}>
                                <Card style={{ height: "300px" }}>
                                    <CardMedia>
                                        <img className={Grad.image} src={`https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${p.tid}.png`} alt="business"></img>
                                    </CardMedia>
                                    <CardContent style={{ padding: "10px" }}>
                                        <h2 className={Grad.coursename}>{p.tlabel}</h2>
                                        <h3 className={Grad.coursecontent} onClick={() => { titleclick(p)}}>{p.ttitle} </h3>
                                    </CardContent>
                                </Card>
                            </div>
                        ))};
                    </div> : <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "20px", gap: "30px" }}><Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /> <Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /><Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /></div>}

                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Assignments',
            render: () => (
                <Tab.Pane active={true}>
                    {loading === false ? <div className={Grad.cardholder}>
                        {programDetail.map((p, index) => (
                            <div className={Grad.cards}>
                                <Card style={{ height: "300px" }}>
                                    <CardMedia>
                                        <img className={Grad.image} src={`https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${p.tid}.png`} alt="business"></img>
                                    </CardMedia>
                                    <CardContent style={{ padding: "10px" }}>
                                        <h2 className={Grad.coursename}>{p.tlabel}</h2>
                                        <h3 className={Grad.coursecontent} onClick={() => { titleclickassign(p) }}>{p.ttitle} </h3>
                                    </CardContent>
                                </Card>
                            </div>
                        ))}
                    </div> : <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "20px", gap: "30px" }}><Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /> <Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /><Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /></div>}

                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Assesments',
            render: () => (
                <Tab.Pane active={true}>
                    {loading === false ? <div className={Grad.cardholder}>
                        {programDetail.map((p, index) => (
                            <div className={Grad.cards}>
                                <Card style={{ height: "300px" }}>
                                    <CardMedia>
                                        <img className={Grad.image} src={`https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${p.tid}.png`} alt="business"></img>
                                    </CardMedia>
                                    <CardContent style={{ padding: "10px" }}>
                                        <h2 className={Grad.coursename}>{p.tlabel}</h2>
                                        <h3 className={Grad.coursecontent} onClick={() => { titleclickasses(p) }}>{p.ttitle} </h3>
                                    </CardContent>
                                </Card>
                            </div>
                        ))};
                    </div> : <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", padding: "20px", gap: "30px" }}><Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /> <Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /><Skeleton variant="rect" width="300px" height={300} style={{ borderRadius: "5px" }} /></div>}

                </Tab.Pane>
            ),
        }
    ];

    async function getProgramsDetails() {
        const bodyParam = {
            body: {
                oid: config.aws_org_id,
                pid: userDetails.curprgcou.opid,
                bpid: userDetails.curprgcou.bpid,// these thre are same

            },
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        };
        if (userDetails.role == "Instructors" || userDetails.role == "Coordinators") {
            bodyParam.body.eid = userDetails.eid;
        }
        try {
            const response = await API.post(
                config.aws_cloud_logic_custom_name,
                Constants.GET_Programs_Details,
                bodyParam
                //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
            );
            const users = response;
            console.log("2" + JSON.stringify(users))


            if (response.pcurriculum !== undefined) {
                // for (var i = 0; i < response.pcurriculum.length; i++)
                // {
                //     let obj = {};
                //     obj.tid =  response.pcurriculum[i].tid;
                //     obj.ttitle = response.pcurriculum[i].ttitle;
                //     programdetails.push(obj);
                //     programname.push(obj.ttitle)
                // }
                response.pcurriculum = response.pcurriculum.reduce((acc, current) => {
                    const x = acc.find(item => item.tid === current.tid);
                    if (!x) {
                      return acc.concat([current]);
                    } else {
                      return acc;
                    }
                  }, []);
                setProgramDetail(response.pcurriculum);
                
            }





            //   setRows(trows);
            //   setColumns(tcolumns);
            //   setLoading(false);
            // setIsLoading(false);

        } catch (error) {
            swal({
                title: "Oooops!",
                text: "Soomthing wrong!",
                icon: "warning",
                dangerMode: true,
            })
                .then(willDelete => {
                    if (willDelete) {

                    }
                });
            console.log("getUserError", error);
        }
        console.log("Mount");
        setLoading(false);
    }

    function breadcrumb() {
        let sdata = { ...userDetails };
        let temp = [...sdata.breadcrumb];
        
        temp[2] = {
            name:'Grading',
            path:'/Grading'
        };

        sdata.breadcrumb = temp;

        dispatch(awsSignIn(sdata));
    }
    return (
        <div className={Grad.containermain}>
            <UserHeader Bindex={2}/>
            <h3 className={Grad.heading}>Grading</h3>
            <Tab panes={panes} />
        </div>
    );
};

export default Grading;