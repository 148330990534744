import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import produce from "immer";

export default function EditNTitleAndEditUTitle({
  open,
  nuggets,
  setNuggets,
  nTitleIndex,
  uTitleIndex,
  editObj,
  setEditObj,
  editType,
  setEditOpen,
  courseDetails,
  setCourseDetails,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [unit, setUnit] = React.useState("");
  const [unitChange, setUnitChange] = React.useState(true);
  console.log(nuggets);
  console.log({ nTitleIndex, uTitleIndex });
  const unitChangedTitle = () => {
    setUnitChange(false);
  };

  React.useEffect(() => {
    if (nuggets !== undefined && nuggets !== null && nuggets?.length !== 0) {
      if (editType == "Unit") {
        setUnit(nuggets[uTitleIndex].uname);
      } else {
        setUnit(nuggets[uTitleIndex].nuggets[nTitleIndex].ntitle);
      }
    }
  }, [open]);

  const handleClose = () => {
    setEditOpen(false);
    setUnit("");
    setEditObj({ ...editObj, nTitleEdit: false });
    setUnitChange(true);
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        onBackdropClick="false"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title"><p style={{fontSize:'20px'}}>Edit {editType} </p></DialogTitle>
        <DialogContent>
          {editType === "Unit" ? (
            <textarea
              cols="50"
              rows="5"
              style={{
                resize: "none",
                paddingTop: ".5rem",
                paddingLeft: ".5rem",
                fontSize:'12px',
              }}
              value={unit}
              onChange={(e) => {
                setUnit(e.target.value);
                unitChangedTitle();
                setUnitChange(false);
              }}
            ></textarea>
          ) : (
            <textarea
              cols="50"
              rows="5"
              style={{
                resize: "none",
                paddingTop: ".5rem",
                paddingLeft: ".5rem",
                fontSize:'12px',
              }}
              value={unit}
              onChange={(e) => {
                setUnit(e.target.value);

                unitChangedTitle();
              }}
            ></textarea>
          )}
          {(unit === "" || unit?.length === 0) && (
            <div style={{ color: "red" }}>
              Please enter {editType === "Unit" ? "unit" : "module"} name.
              <span style={{ color: "red" }}>*</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (unit !== "" || unit.length !== 0) {
                let nugget = [...nuggets];
                console.log("UNIT", nugget);
                nugget = produce(nugget, (v) => {
                  if (editType == "Unit") {
                    v[uTitleIndex].uname = unit;
                  } else {
                    v[uTitleIndex].nuggets[nTitleIndex].ntitle = unit;
                  }
                });
                setNuggets(nugget);
                editObj.nTitleEdit = false;
                editObj.meditmode = true;
                setEditObj({ ...editObj });
                handleClose();
              }
            }}
            color="primary"
            autoFocus
            disabled={unitChange}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
