import React, { useState } from "react";
import Application from "../components/Programs/Application";

import Aside from "../modules/SideNav/Aside";

function ApplicationLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      /> */}
      <Application handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default ApplicationLayout;
