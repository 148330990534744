// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports
import { Constants } from "../../config/constants";
import { authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";


// Styles imports
import Add from '../Programs/AddEvent.module.scss';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));

const DeletePlatUser = (props) => {
  let {  handleDeleteClose, getUsersDetails, editUserData} = props;
  const [ur_id, setUr_Id] = useState(editUserData.col8);
  const [eid, setEid] = useState(editUserData.col9);
  const [urole, setUrole] = useState(editUserData.col4);
  let userDetails = useSelector(authData);
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  async function DeleteUser() {
    console.log("user details" + JSON.stringify(userDetails));
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        // eid: editUserData.col9,
        urole: urole,
        atype: 2,
        schema: "jeevarakshasm",
        ur_id:ur_id,
        eid:eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("bd" + JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ADDUSER,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      console.log("Myresponse"+JSON.stringify(response));
        swal({
          title: "Success",
          text: "User deleted successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
        handleDeleteClose();
        getUsersDetails();
      
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    handleDeleteClose();
    getUsersDetails();
    console.log("Mount");
  };

  return(
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          {/* <h3 className={Add.header}>Delete Event</h3> */}
          <div className={Add.form}>
            <form>
              <div className={Add.header}>Are you sure you want to delete ?</div>
              <div className={Add.buttonholder}>
                <button className={window.navigator.onLine === true ? Add.btn_color : Add.btn_colordis } disabled={!window.navigator.onLine} onClick={() => {
                DeleteUser();
              }}>Yes</button>
                <button className={Add.cancel} onClick={handleDeleteClose}>NO</button>
              </div>
            </form>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: Constants.main_color_1 }} /></div>}
    </div>
  );
};

export default DeletePlatUser;