import {
  Avatar,
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import NavigateNext from "@material-ui/icons/NavigateNext";
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useMatch } from "react-router-dom";
import styled from "styled-components";
import { Theme } from "../../config/aws-exports";
import config from "../../config/aws-exports";
import { useState } from "react";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../CareerDashboard/CareerDashboard";

import { ButtonTernary } from "../Approvals/JobApplicationApprovals";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import ResumePopUp from "../../components/ResumePopUp/ResumePopUp";
import ApplicationPopUp from "../../components/ApplicationPopUp/ApplicationPopUp";
// import { Navigate } from "react-big-calendar";

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  p {
    min-width: 60px;
    text-align: right;
    margin-right: 20px;
  }
`;
const textAreaStyles = {
  marginTop: "1rem",
  width: "100%",
  minHeight: "45vh",
  resize: "none",
  border: "none",
  outline: 0,
  background: "transparent",
  fontSize: "15px",
  "&:focus": {
    border: 0,
    outline: 0,
  },
};
const tableItem = {
  fontSize: "12px",
  textAlign: "left",
  color: Theme.color.grey_dark,
};
const tableHead = {
  fontSize: "12px",
  textAlign: "left",
  fontWeight: "bold",
};
const itemH2 = {
  fontWeight: "bold",
  fontSize: "17.5px",
  margin: "0",
  color: Theme.color.black,
};
export const TextField = styled.input`
  height: 34px;
  width: 98%;
  border-radius: 5px;
  font-size: 14px;
  outline: 0;
  border: 0;
  padding-left: 1rem;
  box-shadow: ${Theme.boxShadow};
`;
const Container = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 2rem;
  p {
    font-size: 15px;
  }
  i {
    font-size: 14px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 2rem;
    font-weight: bold;
  }
`;
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const breadcrumbs = [];
const AllStudentsView = () => {
  const location = useLocation();
  const userDetails = useSelector(authData);
  const navigate = useNavigate();
  const match = useMatch("/career/application");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [appList, setAppList] = useState([]);
  const [academic, setAcademic] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [primag, setPrimg] = useState("");
  const [loc, setLoc] = useState("");
  const [Resumefl, setResumefl] = useState("");
  const { state } = location;
  const [rejectButtonClick, setRejectButtonClick] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [appOpen, setAppOpen] = React.useState(false);
  const [stateData, setStateData] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickAppOpen = (data) => {
    setAppOpen(true);
    setStateData(data);
  };

  useEffect(() => {
    API.post(config.aws_cloud_logic_custom_name, "/getWorkAndAcademi", {
      body: {
        stdid: state?.std_id || userDetails.state.std_id,
        action: "PO",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    }).then((res) => {
      console.log("idss.", state?.jid, state?.std_id);
      console.log("res profile==", res);
      setData(res.body);
      setAcademic(res.body.academi);
      setAppList(res.body.applist);
      setStudentDetails(res.body.stddtl);
      if (
        res?.body?.stddtl?.profile_img !== undefined &&
        res?.body?.stddtl?.profile_img !== null
      ) {
        var extype = res?.body?.stddtl?.profile_img.slice(
          (Math.max(0, res?.body?.stddtl?.profile_img.lastIndexOf(".")) ||
            Infinity) + 1
        );
        let primg1 = `https://${
          config.DOMAIN1
        }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
          res.body.stddtl.eid
        }/crprofile/profile.${extype}`;
        setPrimg(primg1);
      }
      if (
        res?.body?.stddtl?.resume_img !== undefined &&
        res?.body?.stddtl?.resume_img !== null
      ) {
        let primg2 = `https://${
          config.DOMAIN1
        }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
          res.body.stddtl.eid
        }/crresume/resumefile.pdf`;
        setResumefl(primg2);
      }
      const locs = res.body.stddtl.cloc.split(",");
      setLoc(locs[0]);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Container>
        <div style={{ maxWidth: "50%" }}>
          <h3>{studentDetails?.std_name}</h3>
          <div>
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Avatar
                style={{
                  height: 150,
                  width: 150,
                }}
                src={primag}
                alt="image"
              />
              <h3
                style={{
                  maxWidth: "400px",
                  marginBottom: "2rem",
                  textAlign: "center",
                }}
              >
                {studentDetails?.head_line}
              </h3>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
            >
              <InputContainer>
                <p>Email</p>
                <TextField
                  size="small"
                  fullWidth
                  value={studentDetails?.emailid || ""}
                />
              </InputContainer>
              <InputContainer>
                <p>Phone</p>
                <TextField
                  size="small"
                  fullWidth
                  value={studentDetails?.ph_no || ""}
                />
              </InputContainer>
              <InputContainer>
                <p>Location</p>

                <TextField size="small" fullWidth value={loc || ""} />
              </InputContainer>
              <InputContainer>
                <p>Collage</p>

                <TextField
                  size="small"
                  fullWidth
                  value={
                    studentDetails?.cname + ", " + studentDetails?.cloc || ""
                  }
                />
              </InputContainer>
              <InputContainer>
                <p>Resume</p>
                <Button
                  style={ButtonTernary}
                  variant="outlined"
                  onClick={handleClickOpen}
                >
                  View / Download
                </Button>
              </InputContainer>
            </div>
          </div>

          <h3 style={{ marginTop: "35px" }}>About</h3>
          <div
            style={{
              margin: "1rem 0",
              color: Theme.color.grey_dark,
              fontSize: "15px",
            }}
          >
            {state?.about_me}
          </div>
          <Divider />
          <h3 style={{ marginTop: "35px" }}>Skills</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              gap: "2rem",
              color: Theme.color.grey_dark,
              margin: "2.5rem 0",
              flexWrap: "wrap",
            }}
          >
            {state?.skills.map((skills, key) => {
              let skill = {
                1: "Communication",
                2: "Leadership",
                3: "Problem Solving",
                4: "Critical Thinking",
                5: "Creativity",
                6: "Teamwork",
              };
              return (
                <Button
                  key={key}
                  size="small"
                  variant="outlined"
                  color="inherit"
                  style={{ fontSize: "12px" }}
                >
                  {skills === "1" && skill[skills]}
                  {skills === "2" && skill[skills]}
                  {skills === "3" && skill[skills]}
                  {skills === "4" && skill[skills]}
                  {skills === "5" && skill[skills]}
                  {skills === "6" && skill[skills]}
                </Button>
              );
            })}
          </div>
          <Divider />
          <h3 style={{ marginTop: "35px" }}>Work experience</h3>

          {data?.workexp?.map((wkexp, key) => {
            const object = { ...wkexp };
            let yd = object.yandm.split(",");
            let tdformat = `${yd[0].replace("(", "")} ${yd[1]} ${
              yd[2]
            } ${yd[3].replace(")", "")}`;
            return (
              <div style={{ margin: "2.5rem 0" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1.5rem",
                  }}
                >
                  <h3 style={itemH2}>Job title</h3>
                  <FiberManualRecordIcon
                    style={{ height: "10px", width: "10px" }}
                  />
                  <p>{wkexp.cname}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <h3 style={itemH2}>{tdformat}</h3>

                  <p>
                    ({wkexp.start_date} - {wkexp.end_date})
                  </p>
                </div>
                <p style={{ marginTop: "1rem" }}>{wkexp.description}</p>
              </div>
            );
          })}
          <Divider />
          <h3 style={{ marginTop: "35px" }}>Academics</h3>
          <div direction="row" style={{ color: Theme.color.grey_dark, my: 3 }}>
            <TableContainer
              component={Paper}
              elevation={0}
              style={{
                color: Theme.color.grey_dark,
                border: `1px solid ${Theme.color.grey_dark}`,
              }}
            >
              <Table
                style={{
                  minWidth: 650,
                  color: "inherit",
                }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead style={{ color: Theme.color.grey_dark }}>
                  <TableRow>
                    <TableCell>
                      <p style={tableHead}>Programs</p>
                    </TableCell>
                    <TableCell align="right">
                      <p style={tableHead}>Courses</p>
                    </TableCell>
                    <TableCell align="right">
                      <p style={tableHead}>Grades</p>
                    </TableCell>
                    <TableCell align="right">
                      <p style={tableHead}>Status</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {academic.map((row) => {
                    const status = { status: "" };
                    if (row.grade) {
                      if (parseFloat(row.grade) < 4.0) {
                        status.status = "Fail";
                      } else {
                        status.status = "Pass";
                      }
                    } else {
                      status.status = "In progress";
                    }
                    return (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell scope="row">
                          <p style={tableItem}>{row.pname}</p>
                        </TableCell>
                        <TableCell align="right">
                          {row.cname.map((course) => (
                            <p style={tableItem}>{course}</p>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          <p style={tableItem}>{row.grade}</p>
                        </TableCell>
                        <TableCell align="right">
                          <p style={tableItem}>{status.status}</p>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div style={{ borderLeft: "2px solid #777" }} />
        <div
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            {appList?.map((jApDt, index) => (
              <div
                style={{
                  boxShadow: Theme.boxShadow,
                  padding: "25px",
                  marginTop: "20px",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    fontSize: "15px",
                  }}
                >
                  <h3 style={itemH2}>{jApDt.title}</h3>
                  <h3 style={itemH2}>{jApDt.cname}</h3>
                  <p>
                    <i>{jApDt.emptype}</i> <i>{jApDt.loca}</i>{" "}
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p>Required work experience: </p>
                    <h3 style={itemH2}>{jApDt.req_exp} yrs</h3>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "15px",
                    marginLeft: "14px",
                    gap: "15px",
                  }}
                >
                  <Link to="#" onClick={() => handleClickAppOpen(jApDt)}>
                    View Application
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          justifyContent: "center",
          marginTop: "3rem",
        }}
      >
        <Button
          style={ButtonPrimary}
          variant="outlined"
          size="small"
          onClick={() => {
            navigate("/allstudents");
          }}
        >
          Back
        </Button>
      </div>
      {appOpen && (
        <ApplicationPopUp
          open={appOpen}
          setOpen={setAppOpen}
          state={stateData}
          name={studentDetails?.std_name}
        />
      )}
      {open && (
        <ResumePopUp
          open={open}
          setOpen={setOpen}
          src={Resumefl}
          name={studentDetails?.std_name}
        />
      )}
      <Backdrop
        open={loading}
        style={{ zIndex: "1500", backgroundColor: "rgba(0, 0, 0, 0.75)" }}
      >
        <CircularProgress style={{ color: Theme.color.secondary }} />
      </Backdrop>
    </>
  );
};

export default AllStudentsView;
