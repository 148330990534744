import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
//import FormLabel from '@mui/material/FormLabel';

import { makeStyles } from "@material-ui/core/styles";

export default function GroupRadio(props) {
    const useStyles = makeStyles((theme) => ({
        divdis: {
          pointerEvents: "none",
        },
      }));
      const classes = useStyles();
  return (
    <FormControl>
     
      <RadioGroup
        row
        aria-labelledby="demo-form-control-label-placement"
        name="position"
        defaultValue={props.dval}
      >
        <FormControlLabel
          value="0"
          className={classes.divdis}
          control={<Radio />}
          label="Very Poor"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="1"
          className={classes.divdis}
          control={<Radio />}
          label="Poor"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="2"
          className={classes.divdis}
          control={<Radio />}
          label="Avg"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="3"
          className={classes.divdis}
          control={<Radio />}
          label="Good"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="4"
          className={classes.divdis}
          control={<Radio />}
          label="Highly skilled"
          labelPlacement="bottom"
        />
       
      </RadioGroup>
    </FormControl>
  );
}
