import { Box } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import config from "../../config/aws-exports";
import { API, Auth } from "aws-amplify";
import { produce } from "immer";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { Theme } from "../../config/aws-exports";
import swal from "sweetalert";
import UserHeader from "../../components/Header/UserHeader/UserHeader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate, Outlet, useMatch } from "react-router-dom";
// import state from "sweetalert/typings/modules/state";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const useStyles = makeStyles((theme) => ({
  root: {
    background: Theme.color.white,
    fontSize: "14px",
  },
  iconButton: {
    "&.MuiIconButton-root": {
      padding: 0,
      "&.MuiIconButton-label > .MuiSvgIcon-root": {
        fontSize: "2.5rem !important",
      },
    },
  },
  selected: {
    color: Theme.color.white,
    background: Theme.color.secondary,
  },
  textColorPrimary: {
    color: Theme.color.black,
    background: Theme.color.white,
  },
}));
export const ButtonPrimary = {
  color: Theme.color.white,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  background: Theme.color.primary,
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
const ButtonSecondary = {
  color: Theme.color.primary,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
const ButtonTernary = {
  color: Theme.color.secondary,
  borderColor: Theme.color.secondary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.secondary,
    borderColor: Theme.color.secondary,
    textTransform: "unset",
  },
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Approvals = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const matchApprovals = useMatch({
    path: "/approvals",
    caseSensitive: true,
  });
  const [text, setText] = useState("");
  const [profileData, setProfileData] = useState();
  const [jobAppData, setJobAppData] = useState();
  const [loading, setLoading] = useState(true);
  const [aploading, setApLoading] = useState(true);

  const [checked, setChecked] = useState(true);
  const classes = useStyles();
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, ind, type) => {
    // setChecked(event.target.checked);
    if (type === "pf") {
      setProfileData((currentFile) =>
        produce(currentFile, (draft) => {
          draft.profile[ind].checked =
            draft.profile[ind].checked === true ? false : true;
        })
      );
    } else {
      setJobAppData((currentFile) =>
        produce(currentFile, (draft) => {
          draft[ind].checked = draft[ind].checked === true ? false : true;
        })
      );
    }
  };
  useEffect(() => {
    getProfileData();
    getJobApplicationData();
  }, []);
  async function getProfileData() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: 0,
        ptype: "dash",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("profilebody==", bodyParam.body);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getProfileApprovals",
        bodyParam
      );
      setProfileData(response.body);
      console.log("responsebody===", response.body);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function getJobApplicationData() {
    const bodyParam = {
      body: {
        action: 0,
        ptype: "dash",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getJobApplications",
        bodyParam
      );
      setJobAppData(response.body);

      setApLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const appOrRejrHandle = async (type, stdstatus) => {
    let stdid = [],
      appids = [];
    if (type === "profile") {
      const checked = profileData?.profile?.filter(
        (pro) => pro.checked === true
      );
      if (checked.length === 0) {
        swal({
          title: "oops.!",
          text: "Please select profile to reject or approve",
          icon: "error",
          dangerMode: true,
        });
        return;
      }
      for (let i = 0; i < profileData?.profile?.length; i++) {
        if (profileData?.profile[i].checked) {
          stdid.push(profileData?.profile[i].std_id);
        }
      }
    } else {
      const checked2 = jobAppData.filter((job) => job.checked === true);
      if (checked2.length === 0) {
        swal({
          title: "oops.!",
          text: "Please select application to reject or approve",
          icon: "error",
          dangerMode: true,
        });
        return;
      }
      for (let i = 0; i < jobAppData?.length; i++) {
        if (jobAppData[i].checked) {
          appids.push(jobAppData[i].app_id);
        }
      }
    }

    // console.log("approvalProfileOrApp===",stdid);
    setApLoading(true);
    const bodyParam = {
      body: {
        atype: type === "profile" ? 1 : 2,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (type === "profile") {
      bodyParam.body.stdid = stdid;
      bodyParam.body.stdstatus = stdstatus;
    } else {
      bodyParam.body.appids = appids;
      bodyParam.body.astatus = stdstatus;
    }
    try {
      console.log("appbody==", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/approvalProfileOrApp",
        bodyParam
      );

      setApLoading(false);
      swal({
        title: "Success!",
        text:
          (type === "profile" ? "Profile " : "application ") +
          (stdstatus === 1 ? "approval " : "rejected ") +
          "successfully",
        icon: "success",
        dangerMode: false,
      });
      getProfileData();
      getJobApplicationData();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <main>
      <UserHeader />
      {matchApprovals !== null && matchApprovals !== undefined && (
        <div style={{ width: "100%" }}>
          <div style={{ borderBottom: "1rem", borderColor: "black" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Profile approvals"
                {...a11yProps(0)}
                className={`${classes.selected} ${classes.root}`}
              />
              <Tab
                label="Job application approvals"
                {...a11yProps(1)}
                className={`${classes.selected} ${classes.root}`}
              />
            </Tabs>
          </div>

          <TabPanel value={value} index={0}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <Button
                style={ButtonSecondary}
                variant="outlined"
                size="small"
                onClick={() => appOrRejrHandle("profile", 2)}
              >
                Reject
              </Button>
              <Button
                style={ButtonPrimary}
                variant="contained"
                size="small"
                onClick={() => appOrRejrHandle("profile", 1)}
              >
                Approve
              </Button>
            </div>
            {profileData?.profile?.map(
              (pfdt, index) =>
                pfdt.profile_status === 0 && (
                  <div
                    style={{
                      boxShadow: Theme.boxShadow,
                      padding: "25px",
                      marginTop: "20px",
                      display: "flex",
                    }}
                  >
                    <Avatar
                      style={{ height: "90px", width: "90px" }}
                      alt="name"
                    />
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        fontSize: "15px",
                        marginLeft: "14px",
                      }}
                    >
                      <div style={{ display: "flex", gap: 1 }}>
                        <h3>{pfdt.std_name}</h3>
                        <p style={{ paddingLeft: "5px" }}>{pfdt.pname}</p>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <p>Grade :</p>
                        <h3>{pfdt.grade}/10</h3>
                      </div>
                      <p>Headline- {pfdt.head_line}</p>
                    </div>
                    {/* <div style={{ borderLeft: "2px solid #000" }} /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: "15px",
                        marginLeft: "14px",
                        gap: "10px",
                      }}
                    >
                      <div
                        className={classes.root}
                        style={{
                          background: pfdt.checked
                            ? `${Theme.color.primary}`
                            : "",
                          padding: "3px 16px 3px 9px",
                          borderRadius: "4px",
                          border: pfdt.checked
                            ? `2px solid ${Theme.color.primary}`
                            : `2px solid ${Theme.color.primary}`,
                          color: pfdt.checked
                            ? Theme.color.white
                            : Theme.color.primary,
                          fontWeight: "600",
                        }}
                      >
                        <Checkbox
                          {...label}
                          checked={pfdt.checked}
                          onChange={(event) => handleChange(event, index, "pf")}
                          className={classes.iconButton}
                          style={{
                            color: pfdt.checked
                              ? Theme.color.white
                              : Theme.color.primary,
                          }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {pfdt.checked ? "Selected" : "Select"}
                        </span>
                      </div>
                      <Button
                        style={ButtonTernary}
                        variant="outlined"
                        onClick={() => {
                          navigate("profile", { state: pfdt });
                        }}
                      >
                        View profile
                      </Button>
                    </div>
                  </div>
                )
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <Button
                style={ButtonSecondary}
                variant="outlined"
                size="small"
                onClick={() => appOrRejrHandle("approve", 2)}
              >
                Reject
              </Button>
              <Button
                style={ButtonPrimary}
                variant="contained"
                size="small"
                onClick={() => appOrRejrHandle("approve", 1)}
              >
                Approve
              </Button>
            </div>
            {jobAppData?.map((jApDt, index) => (
              <div
                style={{
                  boxShadow: Theme.boxShadow,
                  padding: "25px",
                  marginTop: "20px",
                  display: "flex",
                  position: "relative",
                }}
              >
                <Avatar style={{ height: "90px", width: "90px" }} alt="name" />

                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    fontSize: "15px",
                    marginLeft: "14px",
                    width: "34%",
                  }}
                >
                  <div style={{ display: "flex", gap: 1 }}>
                    <h3>{jApDt.std_name}</h3>
                    <p>{jApDt.pname}</p>
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p>Grade :</p>
                    <h3>{jApDt.grade}/10</h3>
                  </div>
                  <p>Headline- {jApDt.head_line}</p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p>Work experience: </p>
                    <h3>1yr 6m</h3>
                  </div>
                </div>
                <div style={{ borderLeft: "2px solid #000" }} />
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    fontSize: "15px",
                    marginLeft: "14px",
                  }}
                >
                  <h3>{jApDt.title}</h3>
                  <h3>{jApDt.cname}</h3>
                  <p>
                    <i>{jApDt.etype}</i> <i>{jApDt.lname}</i>{" "}
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p>Required work experience: </p>
                    <h3>{jApDt.reqexp} yrs</h3>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontSize: "15px",
                    marginLeft: "14px",
                    gap: "15px",
                  }}
                >
                  <Button
                    style={ButtonTernary}
                    variant="outlined"
                    onClick={() => {
                      navigate("application", { state: jApDt });
                    }}
                  >
                    View Application
                  </Button>
                  <div
                    className={classes.root}
                    style={{
                      background: jApDt.checked ? `${Theme.color.primary}` : "",
                      padding: "3px 16px 3px 9px",
                      borderRadius: "4px",
                      border: checked
                        ? `2px solid ${Theme.color.primary}`
                        : `2px solid ${Theme.color.primary}`,
                      color: jApDt.checked
                        ? Theme.color.white
                        : Theme.color.primary,
                      fontWeight: "600",
                    }}
                  >
                    <Checkbox
                      {...label}
                      checked={jApDt.checked}
                      onChange={(event) => handleChange(event, index, "ap")}
                      className={classes.iconButton}
                      style={{
                        color: jApDt.checked
                          ? Theme.color.white
                          : Theme.color.primary,
                      }}
                    />
                    <span style={{ marginLeft: "8px" }}>
                      {jApDt.checked ? "Selected" : "Select"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>
        </div>
      )}
      <Outlet />
    </main>
  );
};

export default Approvals;
