import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Theme } from "../../config/aws-exports";
import { authData, awsSignIn } from "../../redux/auth/authSlice";

import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import "./Allstudents.scss";
// import { DataGrid } from "@material-ui/data-grid";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@material-ui/icons/ArrowDropUpRounded";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import styled from "styled-components";
import { Link, useLocation, Outlet, useMatch } from "react-router-dom";
// import state from "sweetalert/typings/modules/state";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ButtonPrimary } from "../CareerDashboard/CareerDashboard";
import UserHeader from "../../components/Header/UserHeader/UserHeader";
const BlueRadio = withStyles({
  root: {
    color: Theme.color.secondary,
    "&$checked": {
      color: Theme.color.secondary,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const defaultTippyProps = {
  animation: "shift-away-subtle",
  arrow: false,
  interactive: true,
  interactiveBorder: 0,
  placement: "bottom-end",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 300,
  theme: "light",
  distance: 24,
  trigger: "click",
};
export const Wrapper = styled.div`
  width: 100%;
  .cus-select {
    min-width: 200px;
    padding: 6px 6px 6px 13px;
    border: 1px solid ${Theme.color.grey_dark};
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${Theme.color.grey_dark};
    font-size: 15px;
    border-radius: 5px;
  }
  p {
    font-size: 15px;
  }
  .tippy-container {
    width: 260px;
    max-height: max-content;
    padding: 1rem;
  }
  .sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mb-2 {
    margin-bottom: 2rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .mb-3 {
    margin-bottom: 3rem;
  }

  a {
    color: ${Theme.color.secondary};
  }
`;

const AllStudents = () => {
  const location = useLocation();
  const match = useMatch("/allstudents");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const userDetails = useSelector(authData);
  const [values, setValues] = useState({});
  const [arrowDown, setArrowDown] = useState(true);
  const [arrowDown2, setArrowDown2] = useState(true);
  const [grade, setGrade] = useState("");
  const [status, setStatus] = useState("");
  const [program, setProgram] = useState("");
  const [fData, setFilterData] = useState([]);
  const [programData, setProgramData] = useState([]);

  useEffect(() => {
    API.post(config.aws_cloud_logic_custom_name, "/getAllStudent", {
      body: {
        oid: "LEARNED",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // Authorization: jwttoken,
      },
    })
      .then((res) => {
        setIsLoading(false);
        setData(res.body.students);
        setFilterData(res.body.students);
        const filteredPrograms = [];
        res.body.students.forEach((std) => {
          if (std.pname) filteredPrograms.push(std.pname);
        });
        setProgramData(filteredPrograms);
      })
      .catch((err) => {
        console.log(
          JSON.stringify({
            eid: userDetails.eid,
            stdmst: true,
          })
        );
        alert(err.message);
      });
  }, []);
  // const handleChange = (event, label) => {
  //   setSearch((pre) => ({ ...pre, [label]: event.target.value }));
  // };

  function dynamicSort(property, order) {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }
    return function (a, b) {
      // a should come before b in the sorted order
      if (a[property] < b[property]) {
        return -1 * sort_order;
        // a should come after b in the sorted order
      } else if (a[property] > b[property]) {
        return 1 * sort_order;
        // a and b are the same
      } else {
        return 0 * sort_order;
      }
    };
  }

  const sortBy = (type, value) => {
    let filteredData = [...data];
    const sortData = filteredData.sort(dynamicSort(type, value));
    setFilterData(sortData);
  };

  const handleChange = (event) => {
    setValues((prev) => ({ [event.target.name]: event.target.value }));
    sortBy(event.target.name, event.target.value);
  };

  const filterBy = (status, grade, program) => {
    let filteredData = [...data];
    filteredData = filteredData.filter((job) => {
      let result = false;
      let s = false;
      let g = false;
      let p = false;
      if (status !== "") {
        if (status === 0) {
          if (job.hirecount === 0) s = true;
        } else if (status > 0) {
          if (job.hirecount > 0) s = true;
        }
      }

      if (grade !== "") {
        if (grade === 10.0) {
          if (job.grade <= 10.0 && job.grade >= 9.0) g = true;
        } else if (grade === 9.0) {
          if (job.grade <= 9.0 && job.grade >= 8.0) g = true;
        } else if (grade === 8.0) {
          if (job.grade <= 8.0 && job.grade >= 7.0) g = true;
        } else if (grade === 7.0) {
          if (job.grade <= 7.0 && job.grade >= 5.0) g = true;
        } else if (grade === 5.0) {
          if (job.grade <= 5.0 && job.grade >= 0) g = true;
        }
      }
      if (program !== "") {
        if (job.pname === program) p = true;
      }

      // status and grade and program
      if (status !== "" && grade !== "" && program !== "") {
        if (s && g && p) result = true;
      }
      // status and grade
      if (status !== "" && grade !== "" && program === "") {
        if (s && g) result = true;
      }
      // status and program
      if (status !== "" && grade === "" && program !== "") {
        if (s && p) result = true;
      }
      // grade and program
      if (status === "" && grade !== "" && program !== "") {
        if (g && p) result = true;
      }

      //only status
      if (status !== "" && grade === "" && program === "") {
        if (s) result = true;
      }
      // only grade
      if (status === "" && grade !== "" && program === "") {
        if (g) result = true;
      }
      // only program
      if (status === "" && grade === "" && program !== "") {
        if (p) result = true;
      }
      if (status === "" && grade === "" && program === "") result = true;

      return result;
    });

    setFilterData(filteredData);
  };
  const handleFilterStatus = (type, value) => {
    console.log({ value });
    console.log({ status, grade });
    filterBy(value, grade, program);
  };
  const handleFilterGrade = (type, value) => {
    console.log({ value });
    console.log({ status, grade });
    filterBy(status, value, program);
  };
  const handleFilterProgram = (type, value) => {
    console.log({ value });
    console.log({ status, grade });
    filterBy(status, grade, value);
  };
  return (
    <main>
      <UserHeader />
      {match && (
        <Wrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3>All students</h3>
            <div style={{ display: "flex", alignItems: "center", gap: "3rem" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ minWidth: "60px" }}>Sort by :</p>
                <Tippy
                  content={
                    <div className="tippy-container">
                      <div className="mb-1">
                        <p>Grades</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="sortByGrade"
                            name="grade"
                            value={values.grade || ""}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="desc"
                              control={<BlueRadio />}
                              label="High to Low"
                            />
                            <FormControlLabel
                              value="asc"
                              control={<BlueRadio />}
                              label="Low to High"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <Divider className="mb-3" />
                      <div>
                        <p>No. of applications</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="sortByNoOfApplications"
                            name="appcount"
                            value={values.appcount || ""}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="desc"
                              control={<BlueRadio />}
                              label="High to Low"
                            />
                            <FormControlLabel
                              value="asc"
                              control={<BlueRadio />}
                              label="Low to High"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  }
                  {...defaultTippyProps}
                >
                  <div
                    className="cus-select"
                    onClick={() => setArrowDown(!arrowDown)}
                  >
                    <span>Select</span>
                    <div>
                      {arrowDown ? (
                        <ArrowDropDownRoundedIcon />
                      ) : (
                        <ArrowDropUpRoundedIcon />
                      )}
                    </div>
                  </div>
                </Tippy>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ minWidth: "70px" }}>Filter by :</p>
                <Tippy
                  content={
                    <div className="tippy-container">
                      <div className="mb-1">
                        <p>Status</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="filterByStatus"
                            name="status"
                            value={status}
                          >
                            <FormControlLabel
                              value={1}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterStatus("status", 1);
                                    setStatus(1);
                                  }}
                                />
                              }
                              label="With job offers"
                            />
                            <FormControlLabel
                              value={0}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterStatus("status", 0);
                                    setStatus(0);
                                  }}
                                />
                              }
                              label="Without job offers"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <Divider className="mb-3" />
                      <div>
                        <p>Grades</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="filterByGrades"
                            name="grade"
                            value={grade}
                          >
                            <FormControlLabel
                              value={10.0}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterGrade("grade", 10.0);
                                    setGrade(10.0);
                                  }}
                                />
                              }
                              label="9.0 - 10.0"
                            />
                            <FormControlLabel
                              value={9.0}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterGrade("grade", 9.0);
                                    setGrade(9.0);
                                  }}
                                />
                              }
                              label="8.0 - 9.0"
                            />
                            <FormControlLabel
                              value={8.0}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterGrade("grade", 8.0);
                                    setGrade(8.0);
                                  }}
                                />
                              }
                              label="7.0 - 8.0"
                            />
                            <FormControlLabel
                              value={7.0}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterGrade("grade", 7.0);
                                    setGrade(7.0);
                                  }}
                                />
                              }
                              label="5.0 - 7.0"
                            />
                            <FormControlLabel
                              value={5.0}
                              control={
                                <BlueRadio
                                  onClick={() => {
                                    handleFilterGrade("grade", 5.0);
                                    setGrade(5.0);
                                  }}
                                />
                              }
                              label="< 5.0"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Divider className="mb-3" />
                        <p>Programs</p>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="filterByProgram"
                            name="program"
                            value={program}
                          >
                            {programData &&
                              programData.map((pro) => (
                                <FormControlLabel
                                  value={pro}
                                  control={
                                    <BlueRadio
                                      onClick={() => {
                                        handleFilterProgram("programs", pro);
                                        setProgram(pro);
                                      }}
                                    />
                                  }
                                  label={pro}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={ButtonPrimary}
                          variant="contained"
                          size="small"
                          onClick={() => {
                            filterBy("", "", "");
                            setStatus("");
                            setProgram("");
                            setGrade("");
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  }
                  {...defaultTippyProps}
                >
                  <div
                    className="cus-select"
                    onClick={() => setArrowDown2(!arrowDown2)}
                  >
                    <span>Select</span>
                    <div>
                      {arrowDown2 ? (
                        <ArrowDropDownRoundedIcon />
                      ) : (
                        <ArrowDropUpRoundedIcon />
                      )}
                    </div>
                  </div>
                </Tippy>
              </div>
            </div>
          </div>
          {fData?.map((pfdt, id) => (
            <div
              style={{
                boxShadow: Theme.boxShadow,
                padding: "25px",
                marginTop: "20px",
                display: "flex",
              }}
              key={id + "sdfsd"}
            >
              <Avatar style={{ height: "90px", width: "90px" }} alt="name" />
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  fontSize: "15px",
                  marginLeft: "14px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <h3 style={{ fontWeight: "bold" }}>{pfdt.std_name}</h3>
                  {pfdt.pname && (
                    <FiberManualRecordIcon
                      style={{ height: "10px", width: "10px" }}
                    />
                  )}
                  <p style={{ paddingLeft: "5px" }}>{pfdt.pname}</p>
                </div>
                {pfdt.grade && (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p>Grade :</p>
                    <h3 style={{ fontWeight: "bold" }}>{pfdt.grade}/10</h3>
                  </div>
                )}
                <p>Headline- {pfdt.head_line}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "15px",
                  marginLeft: "14px",
                  gap: "10px",
                }}
              >
                <h3 style={{ fontWeight: "bold" }}>
                  Offers : {pfdt.hirecount}
                </h3>
                <p>No. of applications: {pfdt.appcount}</p>
                <Link
                  to="view"
                  state={pfdt}
                  onClick={() => {
                    let sData = { ...userDetails };
                    sData.state = pfdt;
                    dispatch(awsSignIn(sData));
                  }}
                >
                  View
                </Link>
              </div>
            </div>
          ))}
          <Backdrop
            open={isLoading}
            style={{ zIndex: "1500", backgroundColor: "rgba(0, 0, 0, 0.75)" }}
          >
            <CircularProgress style={{ color: Theme.color.secondary }} />
          </Backdrop>
          {/* <pre>{JSON.stringify(fData, null, 2)}</pre> */}
        </Wrapper>
      )}
      <Outlet />
    </main>
  );
};

export default AllStudents;
