// Dependencies import
import React, { useState } from 'react';

// Local imports
import Send from './SendNotification.module.scss';
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import swal from 'sweetalert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";


const SendNotification = (props) => {
    let { handleEmailClose, mailUserList, toTenants, setSelectionModel } = props;
    const [action, setAction] = useState("");
    const [subject, setSubject] = useState("");
    const [bodyMessage, setBodyMessage] = useState("");
    const [url, setUrl] = useState("");
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [link, setLink] = useState(false);
    const [spin, setSpin] = useState(false);
    let userDetails = useSelector(authData);


    async function sendMessage() {

        let regex =
            "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
        let result = new RegExp(regex, "i");
        if (action === "" || action === undefined || action === null) {
            setError1(true);

        }
        else if (subject === "" || subject.replace(/\s/g, "").length === 0) {
            setError2(true);

        }
        else if (bodyMessage === "" || subject.replace(/\s/g, "").length === 0) {
            setError3(true);
        }
        else if (link === true && (url === "" || !(result.test(url)))) {


            setError4(true);

        }
        else {
            setSpin(true);
            var body1 = {
                action: action,
                nttitle: subject,
                ntmsg: bodyMessage,
                tenantName: config.aws_org_id,
                oid: config.aws_org_id,
                batchid: userDetails?.curprgcou?.bpid,
                atype: 0,
                ntid: 0
            }
            if (link == true) {
                
                body1.pr_link = url;
            }
            if (toTenants === true) {
                let newListUPD = [];
                let newListIds = []
                if (mailUserList !== undefined) {
                    for (var i = 0; i < mailUserList.length; i++) {

                        if (mailUserList[i].ur_id !== undefined) {
                            newListIds.push(mailUserList[i].ur_id);
                        }
                        if (mailUserList[i].emailid !== undefined) {

                            newListUPD.push(mailUserList[i].emailid);
                        }


                    }

                }
                body1.email = newListUPD;
                body1.ur_id = newListIds;
            } 
            const bodyParam = {
                body: body1
                ,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };
            try {
                console.log(bodyParam.body);
                const users = await API.post(
                    config.aws_cloud_logic_custom_name,
                    `/groupNotification`,
                    bodyParam
                );
                console.log("users", users);
                setSelectionModel([]);
                swal("Notification/Mail sent successfully!")
                handleEmailClose();

                setSpin(false);

            }
            catch (error) {
                console.error(error);
                swal({ type: "error", text: "Notification not sent to users. Please Contact Production Support Team." })
            }
        }
    }

    function handleChange(e) {

        setAction(e.target.value)
    }

    function clicking(e) {

        if (e.target.value == "true") {
            setLink(!link)
        }

    }

    return (
        <div style={{ width: "100%", hight: "100%" }}>
            {spin === false ?
                <div className={Send.popupcontainer}>
                    <h3 className={Send.header}>Send Notification</h3>
                    <lable className={Send.caution}>{error1 == true ? "Please select one" : ""}</lable>
                    <div className={Send.radioholder}>
                        <div className={Send.inholder}>
                            <input type="radio" value="MAIL" name="type-select" className={Send.input} onChange={(e) => { handleChange(e) }}></input>
                            <span for="mail" className={Send.radiolable} >Mail</span>
                        </div>
                        {/* <div className={Send.inholder}>
                            <input type="radio" value="NOTIFICATION" name="type-select" className={Send.input} onChange={(e) => { handleChange(e) }}></input>
                            <span className={Send.radiolable} for="notification" >Notification</span>
                        </div>
                        <div className={Send.inholder}>
                            <input type="radio" value="BOTH" name="type-select" className={Send.input} onChange={(e) => { handleChange(e) }}></input>
                            <span className={Send.radiolable} for="both"  >Both</span>
                        </div> */}
                    </div>
                    <div className={Send.inputholder}>
                        <lable className={Send.caution}>{error2 == true ? "Please enter title" : ""}</lable>
                        <h4 className={Send.inputlable}>Title/Subject</h4>
                        <input type="text" className={Send.inputbox} value={subject} onChange={(e) => { setSubject(e.target.value) }}></input>
                    </div>
                    <div className={Send.inputholder}>
                        <lable className={Send.caution}>{error3 == true ? "Please enter text" : ""}</lable>
                        <h4 className={Send.inputlable}>Description/Body</h4>
                        <input type="text" className={Send.inputbox} value={bodyMessage} onChange={(e) => { setBodyMessage(e.target.value) }}></input>
                    </div>
                    <input type="checkbox" value="true" onClick={(e) => clicking(e)} /><span className={Send.radiolable}>Include link</span>
                    {link ? (
                        <div className={Send.inputholder}>
                            <lable className={Send.caution}>{error4 == true ? "Please include valid url" : ""}</lable>
                            <h4 className={Send.inputlable}>Url</h4>
                            <input type="text" id="linkbox" className={Send.inputbox} value={url} onChange={(e) => { setUrl(e.target.value) }}></input>
                        </div>)
                        : null}
                    <div className={Send.buttonholder}>
                        <button className={Send.cancel} onClick={handleEmailClose}>Cancel</button>
                        <button className={window.navigator.onLine === true ? Send.btn_color : Send.btn_colordis} disabled={!window.navigator.onLine} onClick={() => sendMessage()}>Submit</button>
                    </div>


                </div> : <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: config.main_color_1 }} /></div>}
        </div>
    );
};

export default SendNotification;