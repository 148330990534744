import React, { useState } from "react";

import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";

import User from "./CreateUser.module.scss";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const CreateUser = (props) => {
  let { handleClose, getUsersDetails, rows, type } = props;
  const [userNameErr, setUserNameErr] = useState("");
  const [rollNumberErr, setRollNumberErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [userFirstName, setuserFirstName] = useState("");
  const [userRollNumber, setUserRollNumber] = useState("");
  const [userLastName, setuserLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pno, setPno] = useState("");
  const [gender, setGender] = useState(0);
  const [address, setAddress] = useState("");
  const [ctype, setCtype] = useState("BCLS");
  const [edspin, setEdSpin] = useState(false);

  const onChangeHandlerUserFirstName = (event) => {
    setuserFirstName(event.target.value);
  };
  const onChangeHandleruserRollNumber = (event) => {
    setUserRollNumber(event.target.value);
  };

  const onChangeHandlerUserLastName = (event) => {
    setuserLastName(event.target.value);
  };
  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerPno = (event) => {
    setPno(event.target.value);
  };
  const onChangeHandlerGender = (event) => {
    setGender(event.target.value);
  };
  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };
  const onChangeHandlerUrole = (event) => {
    setCtype(event.target.value);
  };

  const onsubmit = () => {

    let formIsValid = true;
    setUserNameErr("");
    setRollNumberErr("");
    setEmailErr("");
    setPnoErr("");
    setGenderErr("");
    if ((userRollNumber === "" ||
      userRollNumber === null ||
      (userRollNumber != undefined && userRollNumber.trim() === ""))
    ) {
      formIsValid = false;
      setRollNumberErr("Please enter roll number");
      return;
    }
    if (
      userFirstName === "" ||
      userFirstName === null ||
      (userFirstName != undefined && userFirstName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter user name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }
    if (
      pno !== null &&
      pno !== undefined &&
      pno.trim() !== "" &&
      pno.length < 10
    ) {
      formIsValid = false;
      setPnoErr(
        "Please enter a valid phone number (should be atleast 10 digits)"
      );
      return;
    }
    if (gender === "" || gender === null) {
      formIsValid = false;
      setGenderErr("Please select gender");
      return;
    }
    if (userFirstName !== "") {
      if (!userFirstName.match(/^[a-zA-Z0-9 ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }
    if (rows != undefined && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        if (email.toLowerCase() == rows[i].col2) {
          setEmailErr("User Already Exists as " + rows[i].col3);
          return;
        }
      }
    }

    if (formIsValid == true) {
      Checkeuser();
    }
    async function Checkeuser() {
      setEdSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          rno: userRollNumber,
          emailid: email.toLowerCase(),
          urole: type == 'user' ? "User" : "Instructor",
          ctype: ctype,
          cno: pno,
          schema: "jeevarakshasm",
          atype: 0,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          '/checkUserExistsOrNot',
          bodyParam

        );

        if (response.rno > 0) {
          swal({
            title: "Oooops!",
            text: response.msg,
            icon: "warning",
            dangerMode: true,
          }).then(() => {

          });
          setSpin(false);
          setEdSpin(false);
        } else if (response.emailid >= 1) {
          swal({
            title: "Oooops!",
            text: response.msg,
            icon: "warning",
            dangerMode: true,
          }).then(() => {

          });
          setSpin(false);
          setEdSpin(false);
        } else {
          Updateuser();
        }
      } catch (error) {
        setSpin(false);
        swal({
          title: "Oooops!",
          text: "Something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      }
    }
    async function Updateuser() {
      setSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          first_name: userFirstName,
          rno: userRollNumber,
          last_name: userLastName,
          emailid: email.toLowerCase(),
          urole: type == 'user' ? "User" : "Instructor",
          gen: gender,
          address: address,
          cno: pno,
          ctype: ctype,
          dev: 2,
          atype: 0,
          schema: "jeevarakshasm",
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_ADDUSER,
          bodyParam

        );

        if (response.statusCode === 200 || response.statusCode === "200") {
          swal({
            title: "Success",
            text: "User added successfully",
            icon: "success",
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
          handleClose();
          getUsersDetails();
        } else if (response.Code === 0 || response.Code === "0") {
          swal({
            title: "Oooops!",
            text: "User already exists!",
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
        } else {
          swal({
            title: "Oooops!",
            text: "User not added due to some reason!",
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              setSpin(false);
            }
          });
          handleClose();
          getUsersDetails();
        }
      } catch (error) {
        setSpin(false);
        swal({
          title: "Oooops!",
          text: "Something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      }

    }
  };

  return (
    <div className={User.maindiv}>
      {spin === false ? (
        <div className={User.maincontainer}>
          <div cl0assName={User.iconcontainer} style={{ display: "flex", alignItems: "center", placeContent: "space-between" }}>
            <h3 className={User.create}>Create {type == 'user' ? "User" : "Instructor"}</h3>
            <Close className={User.closebutton} onClick={handleClose} />
          </div>
          {edspin && <div className={classes.root} style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress style={{ color: config.main_color_1 }} />
          </div>}
          <div className={User.form}>
            <div className={User.field}>
              <label style={{
                fontSize: "16px",
                fontWeight: "500px",
                fontFamily: "nunito",
              }}
                for="rollno"
                required
              >
                * Roll Number
              </label>
              <input
                className={User.inputcontainer}
                type="text"
                id="rollno"
                onChange={onChangeHandleruserRollNumber}
                value={userRollNumber}
                required
              />
            </div> 
            <div style={{
              color: "#cc0000",
              marginBottom: "12px",
              marginTop: "-10px",
              textAlign: "start",
              marginLeft: "20%",
            }}
            >
              {rollNumberErr}
            </div> 
            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="name"
                required
              >
                * First Name
              </label>
              <input
                className={User.inputcontainer}
                type="text"
                id="firstname"
                onChange={onChangeHandlerUserFirstName}
                value={userFirstName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {userNameErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="name"
                required
              >
                * Last Name
              </label>
              <input
                className={User.inputcontainer}
                type="text"
                id="lastname"
                onChange={onChangeHandlerUserLastName}
                value={userLastName}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {userNameErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="email"
                required
              >
                * Email
              </label>
              <input
                className={User.inputcontainer}
                type="email"
                id="email"
                onChange={onChangeHandlerEmail}
                value={email}
                required
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {emailErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="contact"
              >
                Ph. No
              </label>
              <input
                className={User.inputcontainer}
                type="number"
                id="contact"
                onChange={onChangeHandlerPno}
                value={pno}
              />
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {pnoErr}
            </div>

            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="gender"
              >
                Gender
              </label>
              <select
                className={User.inputcontainer}
                name="gender"
                id="gender"
                onChange={onChangeHandlerGender}
                value={gender}
              >
                <option value="">Select</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Trans Gender</option>
                <option value="4">Others</option>
              </select>
            </div>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {genderErr}
            </div>

            <div className={User.field} style={{ justifyContent: "unset" }}>
              <label
                style={{
                  fontSize: "15px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="address"
              >
                Personal Address
              </label>
              <textarea
                className={User.textareacontainer}
                id="address"
                rows="4"
                onChange={onChangeHandlerAddress}
                value={address}
              />
            </div>
            <div className={User.field}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500px",
                  fontFamily: "nunito",
                }}
                for="role"
              >
                Course
              </label>
              <select
                className={User.inputcontainer}
                name="ctype"
                id="ctype"
                required
                onChange={onChangeHandlerUrole}
                value={ctype}
              >

                <option value="BCLS">BCLS</option>
                <option value="ECLS">ECLS</option>
                <option value="ENLS">ENLS</option>
              </select>

            </div>
            <br></br>
            <br></br>
            <input
              type="submit"
              value="Save"
              onClick={onsubmit}
              className={
                window.navigator.onLine === true
                  ? User.btn_color
                  : User.btn_colordis
              }
              disabled={!window.navigator.onLine}
            ></input>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default CreateUser;
