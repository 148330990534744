import {
  Avatar,
  Backdrop,
  Button,
  Checkbox,
  Divider,
  makeStyles,
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  Outlet,
  useMatch,
  useLocation,
} from "react-router-dom";
import config from "../../config/aws-exports";
import { API, Auth } from "aws-amplify";
import UserHeader from "../../components/Header/UserHeader/UserHeader";
import { Theme } from "../../config/aws-exports";
import { produce } from "immer";
import "./CareerDashboard.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { CircularProgress } from "@mui/material";
// import Swal from "sweetalert2/dist/sweetalert2.js";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const useStyles = makeStyles((theme) => ({
  root: {},
  iconButton: {
    "&.MuiIconButton-root": {
      padding: 0,
      "&.MuiIconButton-label > .MuiSvgIcon-root": {
        fontSize: "2.5rem !important",
      },
    },
  },
}));
const TotalBox = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: 150,
  width: 250,
  background: Theme.color.grey_light_2,
};
const TotalContainer = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "20px",
  marginTop: "30px",
};
const TotalHead = { textAlign: "center", fontWeight: "bold" };
const TotalPara = { marginTop: 4, fontSize: "15px", textAlign: "center" };
export const ButtonSecondary = {
  color: Theme.color.primary,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
export const ButtonPrimary = {
  color: Theme.color.white,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  background: Theme.color.primary,
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
const ButtonTernary = {
  color: Theme.color.secondary,
  borderColor: Theme.color.secondary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.secondary,
    borderColor: Theme.color.secondary,
    textTransform: "unset",
  },
};
const ProfileApprovalHead = {
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  marginTop: "30px",
};
export default function CareerDashboard() {
  const [text, setText] = useState("");
  const location = useLocation();
  const [profileData, setProfileData] = useState();
  const [jobAppData, setJobAppData] = useState();
  const [loading, setLoading] = useState(true);
  const [aploading, setApLoading] = useState(true);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  // const [checked2, setChecked2] = useState(false);
  const [rejectionText, setRejectionText] = useState("");
  const classes = useStyles();
  const match = useMatch("/dashboard");

  const handleChange = (event, ind, type) => {
    if (type === "pf") {
      setProfileData((currentFile) =>
        produce(currentFile, (draft) => {
          draft.profile[ind].checked =
            draft.profile[ind].checked === true ? false : true;
        })
      );
    } else {
      setJobAppData((currentFile) =>
        produce(currentFile, (draft) => {
          draft[ind].checked = draft[ind].checked === true ? false : true;
        })
      );
    }
  };

  useEffect(() => {
    getProfileData();
    getJobApplicationData();
  }, [location.pathname]);

  async function getProfileData() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: 0,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("profilebody==", bodyParam.body);
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getProfileApprovals",
        bodyParam
      );
      setProfileData(response.body);
      console.log("responsebody===", response.body);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  async function getJobApplicationData() {
    const bodyParam = {
      body: {
        action: 0,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getJobApplications",
        bodyParam
      );
      setJobAppData(response.body);

      setApLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const appOrRejrHandle = async (type, stdstatus) => {
    let stdid = [],
      appids = [];
    if (type === "profile") {
      const checked = profileData?.profile?.filter(
        (pro) => pro.checked === true
      );
      if (checked.length === 0) {
        swal({
          title: "oops.!",
          text: "Please select profile to reject or approve",
          icon: "error",
          dangerMode: true,
        });
        return;
      }
      for (let i = 0; i < profileData?.profile?.length; i++) {
        if (profileData?.profile[i].checked) {
          stdid.push(profileData?.profile[i].std_id);
        }
      }
      setApLoading(true);
      if (stdstatus === 3) {
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Rejection Reason",
          inputPlaceholder: "Type in the reason for rejection",
          inputAttributes: {
            "aria-label": "Type your message here",
          },
          showCancelButton: true,
          preConfirm: (value) => {
            if (!value) {
              Swal.showValidationMessage(" Your response is required");
            }
          },
        });
        if (text) {
          handleApproveAndRejectAPI(type, stdstatus, stdid, appids, text);
        } else {
          return;
        }
      } else {
        handleApproveAndRejectAPI(type, stdstatus, stdid, appids, null);
      }
    } else {
      const checked2 = jobAppData.filter((job) => job.checked === true);
      if (checked2.length === 0) {
        swal({
          title: "oops.!",
          text: "Please select application to reject or approve",
          icon: "error",
          dangerMode: true,
        });
        return;
      }
      for (let i = 0; i < jobAppData?.length; i++) {
        if (jobAppData[i].checked) {
          appids.push(jobAppData[i].app_id);
        }
      }
      if (stdstatus === 2) {
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Rejection Reason",
          inputPlaceholder: "Type in the reason for rejection",
          inputAttributes: {
            "aria-label": "Type your message here",
          },
          showCancelButton: true,
          preConfirm: (value) => {
            if (!value) {
              Swal.showValidationMessage(" Your response is required");
            }
          },
        });
        if (text) {
          handleApproveAndRejectAPI(type, stdstatus, stdid, appids, text);
        } else {
          return;
        }
      } else {
        handleApproveAndRejectAPI(type, stdstatus, stdid, appids, null);
      }
    }

    // console.log("approvalProfileOrApp===",stdid);
  };

  const handleApproveAndRejectAPI = async (
    type,
    stdstatus,
    stdid,
    appids,
    rejectText
  ) => {
    const bodyParam = {
      body: {
        atype: type === "profile" ? 1 : 2,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (type === "profile") {
      bodyParam.body.stdid = stdid;
      bodyParam.body.stdstatus = stdstatus;
      if (stdstatus === 2) {
        bodyParam.body.rjstatus = rejectText;
      } else {
        bodyParam.body.rjstatus = null;
      }
    } else {
      bodyParam.body.appids = appids;
      bodyParam.body.astatus = stdstatus;
      if (stdstatus === 2) {
        bodyParam.body.rjstatus = rejectText;
      } else {
        bodyParam.body.rjstatus = null;
      }
    }

    try {
      console.log("appbody==", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/approvalProfileOrApp",
        bodyParam
      );

      setApLoading(false);
      swal({
        title: "Success!",
        text:
          (type === "profile" ? "Profile " : "Application ") +
          (stdstatus === 1 ? "approval " : "rejected ") +
          "successfully",
        icon: "success",
        dangerMode: false,
      }).then((ok) => {
        if (ok) {
          getProfileData();
          getJobApplicationData();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main>
      <UserHeader />
      {match && (
        <>
          <div style={TotalContainer}>
            <div style={TotalBox}>
              <div>
                <h1 style={TotalHead}>
                  {profileData === undefined ||
                  profileData.stdcount === undefined
                    ? 0
                    : profileData.stdcount}
                </h1>
                <p style={TotalPara}>Students</p>
              </div>
            </div>
            <div style={TotalBox}>
              <div>
                <h1 style={TotalHead}>
                  {profileData === undefined ||
                  profileData.jobcount === undefined
                    ? 0
                    : profileData.jobcount}
                </h1>
                <p style={TotalPara}>Jobs open</p>
              </div>
            </div>
            <div style={TotalBox}>
              <div>
                <h1 style={TotalHead}>
                  {profileData === undefined ||
                  profileData.appjobcount === undefined
                    ? 0
                    : profileData.appjobcount}
                </h1>
                <p style={TotalPara}>Students applied for jobs</p>
              </div>
            </div>
            <div style={TotalBox}>
              <div>
                <h1 style={TotalHead}>
                  {profileData === undefined ||
                  profileData.stdshort === undefined
                    ? 0
                    : profileData.stdshort}
                </h1>
                <p style={TotalPara}>Students shortlisted</p>
              </div>
            </div>
          </div>

          <div style={ProfileApprovalHead}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h3 style={{ fontWeight: "bold", fontSize: "22px" }}>
                Profile approvals
              </h3>
            </div>
            {!loading && profileData?.profile.length > 0 && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Button
                  style={ButtonSecondary}
                  variant="outlined"
                  size="small"
                  onClick={() => appOrRejrHandle("profile", 3)}
                >
                  Reject
                </Button>
                <Button
                  style={ButtonPrimary}
                  variant="contained"
                  size="small"
                  onClick={() => appOrRejrHandle("profile", 2)}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>

          {!loading &&
            !profileData?.profile.find(
              (pstatus) => pstatus.profile_status === 1
            ) && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h1
                  style={{
                    fontSize: "bold",
                    fontWeight: "bold",
                    margin: "100px 0",
                  }}
                >
                  No. Profile data yet.!
                </h1>
              </div>
            )}

          {!loading &&
            profileData?.profile?.map((pfdt, index) => {
              let primg1;
              if (pfdt.profile_img !== undefined && pfdt.profile_img !== null) {
                var extype = pfdt.profile_img.slice(
                  (Math.max(0, pfdt.profile_img.lastIndexOf(".")) || Infinity) +
                    1
                );
                primg1 = `https://${
                  config.DOMAIN1
                }/${config.aws_org_id.toLowerCase()}-resources/images/users/${
                  pfdt.eid
                }/crprofile/profile.${extype}`;
              }
              return (
                pfdt.profile_status === 1 && (
                  <div
                    style={{
                      boxShadow: Theme.boxShadow,
                      padding: "25px",
                      marginTop: "20px",
                      display: "flex",
                    }}
                  >
                    <Avatar
                      style={{ height: "90px", width: "90px" }}
                      src={primg1}
                      alt="name"
                    />
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        fontSize: "15px",
                        marginLeft: "14px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <h3 style={{ fontWeight: "bold" }}>{pfdt.std_name}</h3>
                        {pfdt.pname && (
                          <FiberManualRecordIcon
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}
                        <p style={{ paddingLeft: "5px" }}>{pfdt.pname}</p>
                      </div>
                      {pfdt.grade && (
                        <div style={{ display: "flex", gap: "10px" }}>
                          <p>Grade :</p>
                          <h3 style={{ fontWeight: "bold" }}>
                            {pfdt.grade}/10
                          </h3>
                        </div>
                      )}
                      <p>Headline- {pfdt.head_line}</p>
                    </div>
                    {/* <div style={{ borderLeft: "2px solid #000" }} /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontSize: "15px",
                        marginLeft: "14px",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          background: pfdt.checked
                            ? `${Theme.color.primary}`
                            : "",
                          padding: "3px 16px 3px 9px",
                          borderRadius: "4px",
                          border: pfdt.checked
                            ? `2px solid ${Theme.color.primary}`
                            : `2px solid ${Theme.color.primary}`,
                          color: pfdt.checked
                            ? Theme.color.white
                            : Theme.color.primary,
                          fontWeight: "600",
                        }}
                      >
                        <Checkbox
                          {...label}
                          checked={pfdt.checked ? true : false}
                          onChange={(event) => handleChange(event, index, "pf")}
                          className={classes.iconButton}
                          style={{
                            color: pfdt.checked
                              ? Theme.color.white
                              : Theme.color.primary,
                          }}
                        />
                        <span style={{ marginLeft: "8px" }}>
                          {pfdt.checked ? "Selected" : "Select"}
                        </span>
                      </div>
                      <Button
                        style={ButtonTernary}
                        variant="outlined"
                        onClick={() => {
                          navigate("profile", { state: pfdt });
                        }}
                      >
                        View profile
                      </Button>
                    </div>
                  </div>
                )
              );
            })}

          <div style={ProfileApprovalHead}>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h1 style={{ fontSize: "22px", fontWeight: "bold" }}>
                Job application approvals
              </h1>
            </div>
            {!aploading && jobAppData?.length > 0 && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <Button
                  style={ButtonSecondary}
                  variant="outlined"
                  size="small"
                  onClick={() => appOrRejrHandle("approve", 2)}
                >
                  Reject
                </Button>
                <Button
                  style={ButtonPrimary}
                  variant="contained"
                  size="small"
                  onClick={() => appOrRejrHandle("approve", 1)}
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
          {!aploading && jobAppData?.find((p) => p.ap_status !== 0) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "bold",
                  fontWeight: "bold",
                  margin: "100px 0",
                }}
              >
                No. Job application data yet.!
              </h1>
            </div>
          )}
          {!aploading &&
            jobAppData?.map(
              (jApDt, index) =>
                jApDt.ap_status === 0 && (
                  <>
                    <div
                      style={{
                        boxShadow: Theme.boxShadow,
                        padding: "25px",
                        marginTop: "20px",
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <Avatar
                        style={{ height: "90px", width: "90px" }}
                        alt="name"
                      />

                      <div
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          fontSize: "15px",
                          marginLeft: "14px",
                          width: "34%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                          }}
                        >
                          <h3 style={{ fontWeight: "bold" }}>
                            {jApDt.std_name}
                          </h3>
                          {jApDt.pname && (
                            <FiberManualRecordIcon
                              style={{ height: "10px", width: "10px" }}
                            />
                          )}
                          <p>{jApDt.pname}</p>
                        </div>
                        {jApDt.grade && (
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Grade :</p>
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.grade}/10
                            </h3>
                          </div>
                        )}
                        <p>Headline- {jApDt.head_line}</p>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <p>Work experience: </p>
                          <h3 style={{ fontWeight: "bold" }}>1yr 6m</h3>
                        </div>
                      </div>
                      <div style={{ borderLeft: "2px solid #000" }} />
                      <div
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          fontSize: "15px",
                          marginLeft: "14px",
                        }}
                      >
                        <h3 style={{ fontWeight: "bold" }}>{jApDt.title}</h3>
                        <h3 style={{ fontWeight: "bold" }}>{jApDt.cname}</h3>
                        <p>
                          <i>{jApDt.etype}</i> <i>{jApDt.lname}</i>{" "}
                        </p>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <p>Required work experience: </p>
                          <h3 style={{ fontWeight: "bold" }}>
                            {jApDt.reqexp} yrs
                          </h3>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          fontSize: "15px",
                          marginLeft: "14px",
                          gap: "15px",
                        }}
                      >
                        <Button
                          style={ButtonTernary}
                          variant="outlined"
                          onClick={() => {
                            navigate("application", { state: jApDt });
                          }}
                        >
                          View Application
                        </Button>
                        <div
                          className={classes.root}
                          style={{
                            background: jApDt.checked
                              ? `${Theme.color.primary}`
                              : "",
                            padding: "3px 16px 3px 9px",
                            borderRadius: "4px",
                            border: jApDt.checked
                              ? `2px solid ${Theme.color.primary}`
                              : `2px solid ${Theme.color.primary}`,
                            color: jApDt.checked
                              ? Theme.color.white
                              : Theme.color.primary,
                            fontWeight: "600",
                          }}
                        >
                          <Checkbox
                            {...label}
                            checked={jApDt.checked ? true : false}
                            onChange={(event) =>
                              handleChange(event, index, "ap")
                            }
                            className={classes.iconButton}
                            style={{
                              color: jApDt.checked
                                ? Theme.color.white
                                : Theme.color.primary,
                            }}
                          />
                          <span style={{ marginLeft: "8px" }}>
                            {jApDt.checked ? "Selected" : "Select"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )
            )}
          <Backdrop
            open={loading || aploading}
            style={{ zIndex: "1500", backgroundColor: "rgba(0, 0, 0, 0.75)" }}
          >
            <CircularProgress style={{ color: Theme.color.secondary }} />
          </Backdrop>
        </>
      )}

      <Outlet />
    </main>
  );
}
