import React, { useState } from "react";

import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";

import User from "./CreateUser.module.scss";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { authData } from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const CreateUser = (props) => {
  let { handleClose, type, rows, getFaculty } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [csvFile, setCsvFile] = useState("");
  const [csvArray, setCsvArray] = useState([]);
  const [fileErr, setFileErr] = useState();
  const [userData, setUserData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  let userDetails = useSelector(authData);

  const onsubmit = () => {

    setUserData([]);
    setErrorData([]);
    setFileErr("");
    var temp = [];
    var temp2 = [];
    if (type === "batch") {
      let size = parseInt(rows.length + csvArray.length);
      if (size > 40) {
        setFileErr("Slot size is 40, please add only 40 students");
        return;
      }
    }
    if (csvFile === undefined || csvFile === "") {
      setFileErr("Please select csv file to upload");
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (csvArray.length > 0) {
      for (var i = 0; i < csvArray.length; i++) {
        if (type == 'instructor') {
          csvArray[i].role = 'Instructor';
        } else {
          csvArray[i].role = 'User';
        }

        if (type != "batch") {
          if (
            csvArray[i].FirstName != undefined &&
            csvArray[i].FirstName != "" &&
            csvArray[i].LastName != undefined &&
            csvArray[i].LastName != "" &&
            csvArray[i].Email != undefined &&
            csvArray[i].Email != "" &&
            pattern.test(csvArray[i].Email) &&
            csvArray[i].gender != undefined &&
            csvArray[i].gender != "" &&
            csvArray[i].course != undefined &&
            csvArray[i].course != "" &&
            csvArray[i].Rollno != undefined &&
            csvArray[i].Rollno != ""
          ) {
            temp.push(csvArray[i]);
          } else {
            temp2.push(csvArray[i]);
          }
        } else {
          if (
            csvArray[i].Name != undefined &&
            csvArray[i].Name != "" &&
            csvArray[i].Email != undefined &&
            csvArray[i].Email != "" &&
            pattern.test(csvArray[i].Email)
          ) {
            temp.push(csvArray[i]);
          } else {
            temp2.push(csvArray[i]);
          }
        }
      }
    }
    setErrorData(temp2);
    if (csvArray.length == 0) {
      setFileErr("File has no records");
      return;
    }
    if (temp2.length == 0) {
      if (type != "batch") {
        Updateuser(temp);
      } else {
        Updatebatchuser(temp);
      }
    }
  };
  async function Updateuser(afile) {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        jsonfile: afile,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.USER_BULKUPLOAD,
        bodyParam
      );

      if (response != null && response.jsarr.length > 0) {
        setSpin(false);
        setErrorData(response.jsarr);
      } else {
        swal({
          title: "Success",
          text: "Users added successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            window.location.reload("./users");
          }
        });
        handleClose();
      }
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "Something went wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    }
  }
  async function Updatebatchuser(afile) {

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        jsonfile: afile,
        batchid: userDetails.curprgcou.bid,
        pid: userDetails.curprgcou.pid,
        role: 'User',
        atype: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/addBulkUserToBatch`,
        bodyParam
      );

      if (response != null && response.code == 0) {
        setSpin(false);
        setUserData(response.jsarr);
      } else if (response != null && response.code == 1) {
        setSpin(false);
        setErrorData(response.jsarr);
      } else {
        swal({
          title: "Success",
          text: "Users added successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            getFaculty();
          }
        });
        handleClose();
      }
    } catch (error) {
      console.error(error);
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Contact Developer");
          setSpin(false);
        }
      });
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "csv":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!isHTML(e.target.files[0].name)) {
      swal("Oops!", "Select correct file format..!", "error");
      return;
    }

    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const xd = e.target.result;

      var array = CSVToArray(xd);

      var objArray = [];
      for (var i = 1; i < array.length; i++) {
        objArray[i - 1] = {};
        for (var k = 0; k < array[0].length && k < array[i].length; k++) {
          var key = array[0][k];
          if (array[i][k] != undefined || array[i][k] != "") {
            objArray[i - 1][key] =
              key == "course" || key == "Email"
                ? array[i][k].toLowerCase()
                : array[i][k];
          }
        }
      }
      var filteredarray;

      if (type != "batch") {

        filteredarray = objArray.filter(
          (key) =>
            key.FirstName != undefined &&
            key.LastName != undefined &&
            key.Email != undefined &&
            key.Contactno != undefined &&
            key.gender != undefined &&
            key.address != undefined &&
            key.course != undefined &&
            key.FirstName != " " &&
            key.LastName != " " &&
            key.Email != " " &&
            key.Contactno != " " &&
            key.gender != " " &&
            key.address != " " &&
            key.course != " "
        );
      } else {

        filteredarray = objArray.filter(
          (key) =>
            key.Name != undefined &&
            key.Email != undefined &&
            key.Name != " " &&
            key.Email != " "
        );
      }

      setCsvArray(filteredarray);
    };
    reader.readAsText(file);
  };

  const CSVToArray = function (strData, strDelimiter) {
    strDelimiter = strDelimiter || ",";

    var objPattern = new RegExp(
      "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
      "gi"
    );

    var arrData = [[]];

    var arrMatches = null;

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1];

      if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
        arrData.push([]);
      }

      if (arrMatches[2]) {
        var strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3].length == 0 ? "" : arrMatches[3];
      }

      arrData[arrData.length - 1].push(strMatchedValue);
    }

    return arrData;
  };
  async function downloadcsv() {
    const response = await fetch(
      `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/formats/userlist.csv`
    );
    const data = await response.text();
    const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
    const url = window.URL.createObjectURL(blob);
    let dwldLink = document.createElement("a");
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", "userlist.csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  return (
    <div className={User.maindiv}>
      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
            <h3 className={User.create}>Bulk Upload</h3>
            <div>
            <a style={{ fontSize: "14px", cursor: "pointer", color: "blue",marginRight:'10px' }}
              onClick={downloadcsv} >
              Download Format
            </a>
            <Close className={User.closebutton} onClick={handleClose} />
            </div>
          </div>
          <div className={User.form}>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              <p>{fileErr}</p>
            </div>
            <div
              className={User.field}
              style={{
                border: "1px solid gray",
                padding: "50px",
                display: "block",
              }}
            >
              <input
                type="file"
                id="files"
                accept=".csv"
                onChange={(e) => handleFileUpload(e)}
              />

            </div>

            <br></br>

            {userData.length > 0 ? (
              <>
                {type != "batch" ? (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    These users are already exist in portal. Please remove from
                    file and upload again.
                  </p>
                ) : (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    These users are already exist in batch. Please remove from
                    file and upload again.
                  </p>
                )}
                <div
                  style={{
                    height: "200px",
                    overflow: "scroll",
                    marginTop: "10px",
                  }}
                >
                  {type != "batch" ? (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Contanct No</th>
                        <th>Gender</th>
                        <th>Course</th>
                      </thead>
                      <tbody>
                        {userData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.first_name}</td>
                            <td>{item.emailid}</td>
                            <td>{item.address}</td>
                            <td>{item.cno}</td>
                            <td>{item.gen}</td>
                            <td>{item.ctype}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Name</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {userData.map((item, i) => (
                          <tr key={i}>
                            <td>
                              {item.first_name}
                            </td>
                            <td>
                              {item.emailid == undefined
                                ? item.Email
                                : item.emailid}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ) : null}
            {errorData.length > 0 ? (
              <>
                <p style={{ fontSize: "14px", textAlign: "left" }}>
                  Below are the invalid entries please remove from file and
                  upload again.
                </p>

                <div
                  style={{
                    height: "200px",
                    overflow: "scroll",
                    marginTop: "10px",
                  }}
                >
                  {type != "batch" ? (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Gender</th>
                        <th>Address</th>
                        <th>Course</th>
                        <th>Error Message</th>
                      </thead>
                      <tbody>
                        {errorData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.FirstName}</td>
                            <td>{item.LastName}</td>
                            <td>{item.Email}</td>
                            <td>{item.Contactno}</td>
                            <td>{item.gender}</td>
                            <td>{item.address}</td>
                            <td>{item.course}</td>
                            <td>{item.errmsg}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Name</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {errorData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Name}</td>
                            <td>{item.Email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ) : null}
            <br></br>
            <input
              type="submit"
              value="Save"
              onClick={onsubmit}
              className={
                window.navigator.onLine === true
                  ? User.btn_color
                  : User.btn_colordis
              }
              disabled={!window.navigator.onLine}
            ></input>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default CreateUser;
