import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Dropdown, ModalActions } from "semantic-ui-react";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPlayer from "react-player";
import { awsSignIn, authData, awsSignOut } from "../../../redux/auth/authSlice";
import UserHeader from "../../Header/UserHeader/UserHeader";
import useWindowDimensions from "../../../modules/Window/Window";
import ProgramCard from "../../../modules/ProgramCard/ProgramCard";
// import "./MyPrograms.scss";
import { FaBars } from "react-icons/fa";
import { Constants } from "../../../config/constants";
import config from "../../../config/aws-exports";
import { API, button, Auth } from "aws-amplify";
import "./CoursePlayer.scss";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Fullscreen from "@material-ui/icons/Fullscreen";
import Cviewstyle from "../../../components/Programs/CourseCreation/courseCreation.module.scss";
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import {
  Fade,
  InputAdornment,
  Backdrop,
  Modal,
  CircularProgress,
  makeStyles,
  TextField,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
  CardMedia,
} from "@material-ui/core";
import Done from "../../../assets/svgjs/Done";
import screenfull from "screenfull";

import { Formik, useField, useFormik, Form, Field } from "formik";
import CustomImageInput from "../../../modules/CustomImageInput/CustomImageInput";
import * as Yup from "yup";
import { event, valHooks } from "jquery";

import axios from "axios";
import Reject from "../../../assets/svgjs/Reject";
import EditorJs from "react-editor-js";
import eEditorJS from "@editorjs/editorjs";
import { EDITOR_JS_TOOLS } from "../../../components/Programs/CourseCreation/EditorConstants";
import RegexTextField from "../../../components/Programs/CourseCreation/RegexTextField";
import edjsHTML from "editorjs-html";
import JSZip from "jszip";
import CreateQuiz from "../../../components/CreateQuiz/CreateQuiz";
import produce from "immer";
import swal from "sweetalert";

const CoursePlayer = (props) => {
  let {
    handleClose,
    courseDetails,
    setCourseDetails,
    objectData,
    setObjectData,
    oIndex,
    setOIndex,
    curObject,
    setcurObject,
    curObRef,
    assignment,
    setAssignment,
    summative,
    sumScore,
    setSumScore,
    miniScore,
    setMiniScore,
    sMiniScore,
    setSMiniScore,
    qisLoading,
    setQIsLoading,
    getQuizScore,
    getAssignmentDataPost,
    assignmentLoad,
    setAssignmentLoad,
    editObj,
    setEditObj,
    renderSwitch,
    selectedObj,
    setSelectedObj,
    setNuggets,
    nuggets,
    folder,
    setOpen,
    open,
  } = props;

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [qtype, setQtype] = useState("");
  const [answerReveal, setAnswerReveal] = useState(false);
  const [popUp, setPopup] = useState(false);
  const [option, setOption] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const formData = useRef([]);
  const full = useRef(null);
  const [settingnull, setSettingnull] = useState(0);

  const { height, width } = useWindowDimensions();
  const [fullscreen, setFullScreen] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [formativeAns, setFormativeAns] = useState([]);
  const [isSubmittingg, setSubmittingg] = useState(false);
  const instanceRef = useRef(null);
  const [typesButtons, setTypeButtons] = useState(false);

  const [istLoading, setIstLoading] = useState(false);
  const iniotypes = [
    { type: "Narrative", id: "2", img: "html" },
    { type: "PDF", id: "3", img: "pdf" },
    { type: "Quiz", id: "4", img: "quiz" },
    { type: "Interactivity", id: "5", img: "Interactivity" },
    { type: "Video", id: "6", img: "video" },
    { type: "Audio", id: "7", img: "audio" },
  ];
  const [otypes, setOtypes] = useState(iniotypes);
  const [selectedFile, setSelectedFile] = useState();
  // const courseDetails = useRef({});
  // const objectData = useRef({});

  const [loaded, setLoaded] = useState(false);
  const [markObjectAsComplete, setMarkObjectAsCompleted] = useState(false);

  let h = true;
  // const [courseDetails, setCourseDetails] = useState({});
  // const [objectData, setObjectData] = useState({});
  // const [oIndex, setOIndex] = useState(0);
  // const [curObject, setcurObject] = useState({});

  const [editQuiz, setEditQuiz] = useState(false);
  const startTime = useRef(0);

  let userDetails = useSelector(authData);
  let objData = userDetails.curprgcouobj;
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        color: "black !important",
      },
      "& .MuiFormLabel-root": {
        color: "black !important",
      },
    },
    textarea11: {
      "& .MuiInputBase-input": {
        color: " #black !important",
        fontSize: "12.5px !important",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,

      height: "200px",
      width: "500px",
      borderRadius: "4px",
      border: "none",
      padding: theme.spacing(2, 4, 3),
    },
    modalConfirm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "98%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    //console.log(nuggets[selectedObj.unit][selectedObj.nindex].objects[selectedObj.oindex])
    startTime.current = Math.round(new Date().getTime());
    setLoaded(true);
    if (curObject.edit && !curObject.filechanges) {
      getQuiz(curObject);
    } else if (curObject.edit && curObject.file) {
      setQtype(curObject.file.qtype);

      setQuizQuestion(curObject.file.qitems);

      selectedObj.quizQuestion = curObject.file.qitems;
      selectedObj.qtitle = curObject.otitle;
      setSelectedObj(selectedObj);
      editObj.oeditmode = true;
      setEditObj({ ...editObj });

      setBtnClick(true);
      setQIsLoading(false);
      setAssignmentLoad(false);
      setEditQuiz(true);
    }
    // courseDetails.current = objData.course;
    // objectData.current = objData.objects;

    // setCourseDetails(objData.course);
    // setObjectData(objData.objects);

    //getCourse();
    //setOIndex(userDetails.oindex);
  }, []);

  async function getCourse() {
    let lcourseDetails = userDetails.curprgcou;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_COURSE,
        bodyParam
      );

      const topicsJSON = response.nuggets;
      // function groupByKey(array, key) {
      //   return array.reduce((hash, obj) => {
      //     if (obj[key] === undefined) return hash;
      //     return Object.assign(hash, {
      //       [obj[key]]: (hash[obj[key]] || []).concat(obj),
      //     });
      //   }, {});
      // }
      // var result = groupByKey(topicsJSON, "unit");
      // let temp = [];
      // for (let i = 0; i < topicsJSON.length; i++) {
      //   for (let j = 0; j < topicsJSON[i].objects.length; j++) {
      //     temp.push(topicsJSON[i].objects[j]);
      //   }
      // }

      setCourseDetails(response);
      // setObjectData(temp);
      //setcurObject(temp[userDetails.oindex]);
      // curObRef.current = temp[userDetails.oindex];
      setLoaded(true);
    } catch (error) {
      console.error(error);
    }
  }
  async function getQuiz(obj) {
    setEditQuiz(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.obj_id,
        oid: config.aws_org_id,
        cid: courseDetails.cid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getquiz`,
        bodyParam
      );

      if (response !== undefined || response !== null) {
        const { qitems, qtype } = response;
        console.log("qitems", qitems);
        setQtype(qtype);

        setQuizQuestion(qitems);
        if (obj.edit === true) {
          selectedObj.quizQuestion = qitems;
          selectedObj.qtitle = curObject.otitle;
          setSelectedObj(selectedObj);
          editObj.oeditmode = true;
          setEditObj({ ...editObj });
        }

        setBtnClick(true);
        setQIsLoading(false);
        setAssignmentLoad(false);
      } else {
        setQuizQuestion([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function syncUserProgress(userProgressData) {
    let lcourseDetails = userDetails.curprgcou;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
        courseProgress: userProgressData,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SYNC_USER_PROGRESS,
        bodyParam
      );
    } catch (error) {
      console.error(error);
    }
  }

  function setMarkComplete() {
    setTimeout(() => {
      if (
        curObRef.current.userOP.op == 0 &&
        (courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false)
      ) {
        setMarkObjectAsCompleted(false);
      } else if (curObRef.current.userOP.op != 2) {
        let check = checkQuiz(curObRef.current);

        if (check) {
          if (markObjectAsComplete == false) {
            setMarkObjectAsCompleted(true);
          }
        }
      }
      // if (curObRef.current.userOP.op != 2) {
      //   setMarkObjectAsCompleted(true);
      // }
    }, 5000);
  }

  function checkQuiz(obj) {
    if (obj.otype === "quiz") {
    }
    return true;
  }

  function renderObjects() {
    let objType = curObject.otype;

    // let tobjectData = [...curObject];
    let toIndex = oIndex;

    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "Interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "quiz":
        return quizView();
      case "vimeo":
        return VimeoView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }

  function prev() {
    setMarkObjectAsCompleted(false);
    setBtnClick(false);
    setTryAgain(true);
    setFormativeAns([]);

    //var newObject = JSON.parse(JSON.stringify(oldObject));
    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;

    startTime.current = Math.round(new Date().getTime());

    let l = tobjectData.length - 1;
    if (toIndex > 0) {
      toIndex = toIndex - 1;
      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);
      curObRef.current = tobjectData[toIndex];
      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].obj_id);
        getQuizScore(tobjectData[toIndex]);
      }
    }

    saveredux(toIndex);
  }

  function next() {
    setBtnClick(false);
    setMarkObjectAsCompleted(false);

    setTryAgain(true);
    setFormativeAns([]);

    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;

    startTime.current = Math.round(new Date().getTime());

    let l = tobjectData.length - 1;
    if (toIndex < l) {
      toIndex = toIndex + 1;
      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);
      curObRef.current = tobjectData[toIndex];
      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].obj_id);
        getQuizScore(tobjectData[toIndex]);
      }
    }

    saveredux(toIndex);
  }

  function saveredux(toIndex) {
    let sdata = { ...userDetails };
    sdata.oindex = toIndex;
    dispatch(awsSignIn(sdata));
    //navigate("/coursePlayer");
  }

  function LockView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1>Please Mark Complete previously viewed object.</h1>
        </div>
      </div>
    );
  }
  function unitLockedView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1>This Module is disabled from Admin</h1>
        </div>
      </div>
    );
  }

  function VideoView() {
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={
            curObject.filechanges
              ? URL.createObjectURL(curObject.file)
              : curObject.ourl
          }
          controls={true}
          width="1100px"
          height="660px"
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "1050px",
            height: "600px",
            overflow: "hidden auto",
          }}
        >
          <ReactPlayer
            url={
              curObject.filechanges
                ? URL.createObjectURL(curObject.file)
                : curObject.ourl
            }
            controls={true}
          />
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "1000px",
            height: "660px",
          }}
        >
          <ReactPlayer
            url={
              curObject.otype !== "vimeo"
                ? `https://youtu.be/${curObject.ourl}`
                : `https://player.vimeo.com/video/${curObject.ourl}?title=0&loop=0`
            }
            controls={true}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "1000px",
            height: "500px",
          }}
        >
          <iframe
            src={`https://www.youtube.com/embed/${curObject.ourl}?rel=0&showinfo=0`}
            controls={true}
            height="660px"
            width="100%"
            frameBorder="0"
            title="youtubeOnly"
          />
        </div>
      </div>
    );
  }

  function PdfView() {
    console.log(curObject);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          height: height - 300,
          overflowX: "hidden",
        }}
      >
        <iframe
          src={
            curObject.ourl === undefined
              ? URL.createObjectURL(curObject.file)
              : curObject.ourl
          }
          height="200"
          width="100%"
          title="pdfonly"
        />
      </div>
    );
  }

  function WebView() {
    return (
      <div
        style={{
          display: "flex",
          // width: width - 500,
          height: height - 300,
          //height: "100%",
          justifyContent: "center",
        }}
      >
        <iframe
          src={curObject.ourl}
          width={"100%"}
          //height={"100%"}
          height={height - 100}
          title="WebView"
        />
      </div>
    );
  }

  function toggleFullScreen() {
    screenfull.toggle(full.current);
  }

  function isVideo(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
      default:
        return false;
    }
  }
  function isInteractivity(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "zip":
      case "html":
        return true;
      default:
        return false;
    }
  }
  function isAudio(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "mp3":
      case "wav":
      case "msv":
      case "m4p":
        return true;
      default:
        return false;
    }
  }
  function isPDF(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "html":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  const handleChangeptype = (event) => {
    setOption(event.target.value);
  }
  const ochangeHandler = (event) => {
    // setSelectedFile(new File([""], "filename"));

    if (event.target.files[0] !== undefined) {
      if (
        !isVideo(event.target.files[0].name) &&
        selectedObj.afile === "video/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isInteractivity(event.target.files[0].name) &&
        selectedObj.afile ===
        ".html, application/zip, application/x-zip-compressed, .zip"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }

      if (!isPDF(event.target.files[0].name) && selectedObj.afile === ".pdf") {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isHTML(event.target.files[0].name) &&
        selectedObj.afile === ".html"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      if (
        !isAudio(event.target.files[0].name) &&
        selectedObj.afile === "audio/*"
      ) {
        swal("Oops!", "Select correct file format..!", "error");
        return;
      }
      setSelectedFile(event.target.files[0]);
      let seletob = { ...selectedObj };
      seletob = produce(seletob, (v) => {
        v.otherdata.files = event.target.files[0].name;
      });

      setSelectedObj(seletob);
    }
  };
  function onFullScreen() {
    setFullScreen(!fullscreen);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (quizQuestion == undefined) {
    handleClose();
    return;
  }

  let percent = (score / quizQuestion.length) * 100;

  // responseSave(formData);
  function handleAnswerOption(ques) {
    return ques;
  }

  const getAssignmentDataPost1 = async (quizid) => {
    setQIsLoading(true);
    setSMiniScore(false);

    setMiniScore(0);
    const bodyParam = {
      body: {
        quizid: quizid,
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSIGNMENT_DATA,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      if (response.errorMessage !== undefined) {
        setAssignment(true);
      } else {
        if (response.score !== undefined) {
          setSMiniScore(true);

          setMiniScore(response.score);
        }
        setAssignment(false);
      }
      setQIsLoading(false);
      return response;
    } catch (error) { }
  };

  async function saveResponse(data, obj1) {
    const obj = {};
    obj.response = data;

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        quizdata: obj,
        eid: userDetails.eid,
        objid: obj1.obj_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_MINI_ASSIGNMENT,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
    } catch (error) { }
  }

  async function postQuizScorePost(score) {
    let objsr = {};
    let lcourseDetails = userDetails.curprgcou;

    const bodyParam = {
      body: {
        eid: userDetails.eid,
        scorejson: objsr,
        oid: config.aws_org_id,
        rtype: "put",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (lcourseDetails.bpid !== undefined) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let date1 = date.getDate();
      let sdate = date1 + "." + month + "." + year;
      objsr.bpid = lcourseDetails.bpid;
      objsr.name = curObject.otitle;
      objsr.cdate = sdate;
      objsr.score = score;
      objsr[curObject.obj_id] = score;
      bodyParam.body.scorejson = objsr;
    }

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_QUIZ,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      getQuizScore(curObject);
    } catch (error) { }
  }

  const handleNextQuestionClick = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
      console.log(formData.current);
    }
  };

  const handlePrevQuestion = () => {
    //saveResponse(formData.current, curObject);

    const prevQuestion = currentQuestion - 1;
    /*  if (prevQuestion > 0) { */
    setCurrentQuestion(prevQuestion);
    /*   } */
  };

  const handleAnswerOptionClick = (ansOpts, idx) => {
    if (ansOpts.correct == "true" || ansOpts.correct === true) {
      setScore(score + 1);
    }
    let answer = [...formativeAns];
    answer.push(idx);
    setFormativeAns(answer);
    handleNextQuestionClick();
  };
  let qobj = curObject.obj_id.split("-");

  let imgDomain = `https://${config.DOMAIN
    }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${courseDetails.cid
    }/`;
  let imgTemp = `https://${config.DOMAIN}/${folder}/`;

  const FILE_SIZE = 16000 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
  ];
  const validationSchema = Yup.object().shape({
    text: Yup.string().required(
      <div style={{ fontSize: "12px" }}>"A text is required"</div>
    ),
  });

  function validateEmail(value) {
    let error;
    if (value) {
      if (
        value.type === "image/png" ||
        value.type === "image/jpg" ||
        value.type === "application/pdf" ||
        value.type === "image/jpeg"
      ) {
        error = "";
      } else {
        error = `*File not supported, please match requested type.`;
      }
      return error;
    }
  }

  function validateUsername(value) {
    let error;

    if (value === "" || value.replace(/\s/g, "").length === 0) {
      error = "*A text is required";
    } else {
      error = "";
    }
    return error;
  }

  const getPreSignedUrl = async (value, obj) => {
    setSubmittingg(true);

    // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    let fileName = new Date().getTime();

    let quest = handleAnswerOption(quizQuestion[currentQuestion]);
    quest.response = value.response;
    quest.filename = fileName + value.file.name;
    formData.current.push(quest);
    if (value.file) {
      const bodyParam = {
        body: {
          type: "minassign",
          filename: fileName + value.file.name,
          filetype: value.file.type,
          obj_id: config.aws_org_id,
          eid: userDetails.eid,
          quizid: obj.obj_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: jwttoken,
        },
      };

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_PRESIGNED_URL,
          bodyParam
        );

        handleNextQuestionClick();
        setSubmittingg(false);

        /* if (currentQuestion === quizQuestion.length) {
          setOpen(true);
        } */

        fileUpload(value.file, response);
      } catch (error) { }
    } else {
      handleNextQuestionClick();
      setSubmittingg(false);
    }
  };

  async function fileUpload(file, url) {
    await axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
          "Cache-Control": "no-cache no-store must-revalidate",
        },
      })
      .then((res) => { })
      .catch((err) => {
        console.error(err);
      });
  }

  const TextInputComponent = ({ field, ...props }) => {
    const { errorMessage, touched } = props;
    const { name, value, onChange, onBlur } = field;
    return (
      <div className={classes.root}>
        <TextField
          fullWidth
          className={classes.textarea11}
          placeholder="Type your response here..."
          multiline
          rows={4}
          name={name}
          variant="outlined"
          color="primary"
          error={touched && errorMessage ? true : false}
          label="Insert some text"
          helperText={touched && errorMessage ? errorMessage : undefined}
          onChange={onChange}
          inputProps={{ style: { fontSize: '14px' } }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
        />
      </div>
    );
  };

  function answerPrev() {
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    }
  }

  function quizView() {
    return (
      <div className="body">
        {btnClick ? (
          <div className="app1">
            {showScore ? (
              <div className="score-section">
                {qtype !== 3 ? (
                  <div>
                    You scored {score} out of {quizQuestion.length}
                    {/* ;
                    {(score / quizQuestion.length) * 100} */}
                    {qtype == 2 &&
                      ((score / quizQuestion.length) * 100 >= 75 || tryAgain) ? (
                      <div>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            setScore(0);
                            setTryAgain(false);
                            setOpen(false);
                          }}
                        >
                          Exit
                        </button>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            getQuiz(curObject);
                            setCurrentQuestion(0);
                            setAnswerReveal(true);
                            setShowScore(false);
                            //setQIsLoading(true);
                          }}
                        >
                          {qisLoading ? (
                            <CircularProgress color="default" size={30} />
                          ) : (
                            "Answers"
                          )}
                        </button>
                      </div>
                    ) : qtype == 3 &&
                      ((score / quizQuestion.length) * 100 >= 75 ||
                        tryAgain) ? (
                      // <div style={{ fontSize: "16px" }}>
                      //   {" "}
                      //   You should score more than {curObject.cutoff}% to
                      //   complete this Quiz.
                      // </div>
                      <div>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            setScore(0);
                            setTryAgain(false);
                            setOpen(false);
                          }}
                        >
                          Exit
                        </button>
                        <button
                          className="btnQuizLD"
                          style={{
                            width: "200px",
                            border: "3px solid white",
                            fontSize: "15px",
                          }}
                          onClick={() => {
                            getQuiz(curObject);
                            setCurrentQuestion(0);
                            setAnswerReveal(true);
                            setShowScore(false);
                            //setQIsLoading(true);
                          }}
                        >
                          {qisLoading ? (
                            <CircularProgress color="default" size={30} />
                          ) : (
                            "Answers"
                          )}
                        </button>
                      </div>
                    ) : (
                      <div>Submitted</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <h3>Goto Home Page</h3>
                    <div>
                      <button
                        className="buttonQuizCt"
                        onClick={() => {
                          setScore(0);
                          setCurrentQuestion(0);
                          getQuiz(curObject);
                        }}
                      >
                        Exit
                      </button>
                    </div>
                  </div>
                )}
                {qtype !== 1 &&
                  qtype !== 3 &&
                  (score / quizQuestion.length) * 100 < 75 &&
                  tryAgain === false ? (
                  <div>
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        if (!curObject.file) {
                          setBtnClick(false);
                          getQuiz(curObject);
                          setScore(0);
                          setShowScore(false);
                          setCurrentQuestion(0);
                          setTryAgain(true);
                          setFormativeAns([]);
                        } else {
                          setBtnClick(false);
                          setQuizQuestion(curObject.file.qitems);
                          setScore(0);
                          setShowScore(false);
                          setCurrentQuestion(0);
                          // setTryAgain(true);
                          setFormativeAns([]);
                        }
                      }}
                    >
                      Try Again
                    </button>
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        setBtnClick(false);
                        setOpen(false);
                        setScore(0);
                        setCurrentQuestion(0);
                        setTryAgain(false);
                        setFormativeAns([]);
                      }}
                    >
                      Exit
                    </button>
                  </div>
                ) : qtype == 4 ? (
                  <div>
                    {" "}
                    <button
                      className="buttonQuizCt"
                      onClick={() => {
                        setBtnClick(false);
                        handleClose();
                        setScore(0);
                        setCurrentQuestion(0);
                      }}
                    >
                      Exit
                    </button>
                  </div>
                ) : null}
              </div>
            ) : answerReveal ? (
              <div>
                <div className="scrollQuiz">
                  <div className="question-section">
                    <div className="question-count">
                      <span>Question {currentQuestion + 1}</span>/
                      {quizQuestion.length}
                    </div>
                    <div
                      className="question-text"
                      dangerouslySetInnerHTML={{
                        __html: quizQuestion[currentQuestion].istem,
                      }}
                    >
                      {/* {quizQuestion[currentQuestion].istem} */}
                    </div>

                    {quizQuestion[currentQuestion].imageurl ? (
                      <div className="img_container">
                        <img
                          src={`${curObject.filechanges ? imgTemp : imgDomain}${quizQuestion[currentQuestion].imageurl
                            }`}
                          alt="item"
                        />
                      </div>
                    ) : null}
                  </div>
                  {qtype !== 3 ? (
                    <div className="answer-section">
                      {quizQuestion[currentQuestion].iopts.map(
                        (answerOption, idx) => (
                          <div style={{ display: "flex" }}>
                            {" "}
                            {formativeAns[currentQuestion] !== idx ? (
                              <button className={`buttonQuizAR`}>
                                {answerOption.content}
                              </button>
                            ) : (
                              <button
                                className={`buttonQuizAR`}
                                style={{
                                  backgroundColor: "lightgray",
                                  border: "2px solid #234668",
                                }}
                              >
                                {answerOption.content}
                              </button>
                            )}
                            {answerOption.correct == true ? (
                              <Done
                                className="right_size"
                                cls1="cls1_D"
                                cls2="cls2_D"
                              />
                            ) : (
                              <Reject
                                className="Wrong_size"
                                cls1="cls1_D"
                                cls2="cls2_D"
                              />
                            )}
                            {formativeAns[currentQuestion] !== idx ? null : (
                              <p
                                style={{
                                  fontSize: "14px",
                                  paddingLeft: "8px",
                                  paddingTop: "8px",
                                }}
                              >
                                Your Response
                              </p>
                            )}
                          </div>
                        )
                      )}
                      <br />
                    </div>
                  ) : null}
                  {currentQuestion > 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-size"
                      onClick={() => answerPrev()}
                      style={{ fontSize: "12px" }}
                    >
                      Prev
                    </Button>
                  ) : null}
                  {currentQuestion + 1 === quizQuestion.length ? (
                    <div></div>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="btn-siz"
                      onClick={() => answerNext()}
                      style={{ float: "right", fontSize: "12px" }}
                    >
                      Next
                    </Button>
                  )}

                  <br />
                  <br />
                  <br />

                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    className="btn-sizeClose"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid black",
                      width: "100px",
                      marginLeft: "45%",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      //handleClose();
                      setCurrentQuestion(0);
                      setShowScore(true);
                      setAnswerReveal(false);
                      if (curObject.file) {
                        setQuizQuestion(curObject.file.qitems);
                        setQIsLoading(false);
                      }
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <div className="scrollQuiz">
                <div className="question-section">
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/
                    {quizQuestion.length}
                  </div>
                  <div
                    className="question-text"
                    dangerouslySetInnerHTML={{
                      __html: quizQuestion[currentQuestion].istem,
                    }}
                  ></div>

                  {qtype == 4 || qtype == 5 || qtype == 6 ? (
                    <div>
                      {currentQuestion > 0 ? (
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          className="btn-size"
                          onClick={() => answerPrev()}
                          style={{ fontSize: "12px" }}
                        >
                          Prev
                        </Button>
                      ) : null}
                      {currentQuestion + 1 === quizQuestion.length ? (
                        <div></div>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          fullWidth
                          className="btn-siz"
                          onClick={() => answerNext()}
                          style={{ float: "right", fontSize: "12px" }}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  ) : null}
                  {quizQuestion[currentQuestion].imageurl ? (
                    <div className="img_container">
                      <img
                        src={`${curObject.filechanges ? imgTemp : imgDomain}${quizQuestion[currentQuestion].imageurl
                          }`}
                        alt="item"
                      />
                    </div>
                  ) : null}
                </div>
                {qtype != 3 ? (
                  <div className="answer-section">
                    {quizQuestion[currentQuestion].iopts.map(
                      (answerOption, idx) => (
                        <button
                          key={"a" + idx}
                          // className="buttonQuiz"
                          className={`buttonQuiz`}
                          data-id={`${answerOption.correct === "true"
                            ? answerOption.correct
                            : null
                            }`}
                          onClick={() => {
                            handleAnswerOptionClick(answerOption, idx);
                            console.log("ans", answerOption, idx);
                          }}
                        >
                          {answerOption.content}
                        </button>
                      )
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="answer-section">
                      {quizQuestion[currentQuestion].iopts.map(
                        (answerOption, idx) => (
                          <button
                            key={"a" + idx}
                            // className="buttonQuiz"
                            className={`buttonQuiz`}
                            data-id={`${answerOption.correct === "true"
                              ? answerOption.correct
                              : null
                              }`}
                            onClick={() => {
                              handleAnswerOptionClick(answerOption, idx);
                              console.log("ans", answerOption, idx);
                            }}
                          >
                            {answerOption.content}
                          </button>
                        )
                      )}
                    </div>
                    {/* <TextareaAutosize
                      maxRows={6}
                      style={{ height: "200px", width: "100%" }}
                      aria-label="maximum height"
                      placeholder="Maximum 6 rows"
                    /> */}
                    {/* {currentQuestion > 0 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-size"
                        onClick={() => answerPrev()}
                        style={{ fontSize: "12px" }}
                      >
                        Prev
                      </Button>
                    ) : null}
                    {currentQuestion + 1 === quizQuestion.length ? (
                      <div></div>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        className="btn-siz"
                        onClick={() => answerNext()}
                        style={{ float: "right", fontSize: "12px" }}
                      >
                        Next
                      </Button>
                    )} */}

                    <br />
                    <br />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {curObject.qtype == 3 ? (
              <div>
                {navigator.onLine ? (
                  <button
                    className="btnQuizLD"
                    onClick={() => {
                      console.log(JSON.stringify(curObject, null, 2));

                      if (!curObject.file) {
                        getQuiz(curObject);
                        setShowScore(false);
                      } else {
                        setQuizQuestion(curObject.file.qitems);
                        setShowScore(false);
                        setBtnClick(true);
                        setQtype(curObject.file.qtype);
                        setQIsLoading(false);
                        setAssignmentLoad(false);
                      }

                      setQIsLoading(true);
                      setAssignmentLoad(true);
                    }}
                  >
                    Start Quiz
                  </button>
                ) : (
                  <p>you are offline</p>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {summative ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#000",
                        textAlign: "center",
                        margin: "2.5rem 0",
                        boxShadow: "0 0 0 5px #e35f14",
                        borderRadius: "100%",
                        height: "120px",
                        width: "120px",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {sumScore} %
                      </span>
                    </h1>
                  </div>
                ) : (
                  <>
                    <br />
                    {/* {JSON.stringify(curObject)} */}
                    {curObject.qtype == 2 ? (
                      <div style={{ padding: "0 40px", textAlign: "justify" }}>
                        <h3 style={{ marginLeft: "-14px" }}>Instructions:</h3>
                        <div style={{ fontSize: "14px" }}>
                          <ol>
                            <li>
                              {" "}
                              Unit formative quiz consists of Multiple Choice
                              Questions.
                            </li>
                            <li> Click on "START QUIZ" button.</li>
                            <li>
                              {" "}
                              On starting the quiz, One question appears at a
                              time.
                            </li>
                            <li>
                              {" "}
                              Once you select an answer, you will get the next
                              question.
                            </li>
                            <li>
                              On answering all the questions, you should get a
                              score of {curObject.cutoff}% or more to view the
                              right answers and then click on "MARK COMPLETE"
                              button.
                            </li>
                            <li>
                              Once you click on "MARK COMPLETE", the system
                              would allow you to move to the next Unit.
                            </li>
                            <li>
                              If you get {`<`} {curObject.cutoff}% score, you
                              must click on "TRY AGAIN" button to retake the
                              quiz and improve your score.
                            </li>
                          </ol>
                        </div>
                        <br />
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <button
                      className="btnQuizLD"
                      onClick={() => {
                        if (!curObject.file) {
                          getQuiz(curObject);
                          setShowScore(false);
                          setQIsLoading(true);
                        } else {
                          setQuizQuestion(curObject.file.qitems);
                          setShowScore(false);
                          setBtnClick(true);
                          setQtype(curObject.file.qtype);
                          setQIsLoading(false);
                          setAssignmentLoad(false);
                        }
                      }}
                    >
                      {qisLoading ? (
                        <CircularProgress color="default" size={30} />
                      ) : (
                        "Start Quiz"
                      )}
                    </button>{" "}
                    <br />
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  const finalSave = async () => {
    let obj = { ...selectedObj };
    console.log(JSON.stringify(obj, null, 2));
    obj = produce(obj, (v) => {
      v.otherdata.dur =
        Number(selectedObj.otherdata.min) * 60 +
        Number(selectedObj.otherdata.sec);
    });

    let file;
    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    let filename = "";

    let object = {
      otitle: selectedObj.otherdata.title,
      odescription: selectedObj.otherdata.desc,
      otype: selectedObj.otype,
      ptype: selectedObj.ptype,
      oduration: obj.otherdata.dur,
      ourl: selectedObj.ourl,
      obj_id: selectedObj.obj_id,
      curl: obj.curl,
    };

    var body = {
      folder: folder,
    };
    if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
      body.filetype = "text/html";
      body.filename =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
      object.newfile =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".html";
      object.filechanges = true;
      object.stype = "chtml";
      const savedData = await instanceRef.current.save();

      const edjsParser = edjsHTML();
      // blocks
      file = edjsParser.parse(savedData);
      file = JSON.parse(JSON.stringify(file));

      let html = ` <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta></head><body>`;
      for (let i = 0; i < file.length; i++) {
        html = html + file[i];
      }
      html = html + "</body></html>";
      file = html;
    } else if (selectedObj.otype == "quiz") {
      body.filename =
        Math.floor(Math.random() * (999 - 100 + 1) + 100) + ".json";
      object.newfile = body.filename;
      object.filechanges = true;
      //object.oduration = selectedObj.quizdata.qitems.length * 60;
      file = selectedObj.quizdata;
      object.file = selectedObj.quizdata;
      object.cutoff = selectedObj.quizdata?.cutoff;
      object.qtype = selectedObj.otherdata.quesType;
      if (selectedObj.otherdata.quesType == '3') {
        console.log("file.attempt", file.attempt);
        // delete file.attempt["1"].catList;
        // delete file.attempt["2"].catList;
        // delete file.attempt["3"].catList;
        object.cutoff = 100;
        object.oduration = file.attempt["1"].qitems.length * 60;
      } else {
        object.oduration = file.qitems.length * 60;
      }
      body.filetype = "application/json";
    } else if (selectedFile !== undefined) {
      file = selectedFile;
      body.filename = id + selectedFile.name;
      body.filetype = selectedFile.type;
      object.newfile = id + selectedFile.name;
      object.filechanges = true;
      object.file = selectedFile;
    } else if (selectedObj.otherdata.file && selectedFile == undefined) {
      object.file = selectedObj.otherdata.file;
      object.curl = URL.createObjectURL(selectedObj.otherdata.file);
    } else if (selectedObj.otype == "youtube" || selectedObj.otype == "vimeo") {
      object.embeddedcode = selectedObj.otherdata.embeddedcode;
    } else if (selectedObj.otype == "Interactivity") {
      object.ltype = "zp";
    }

    //var typejson = {"type" :"uploadfile","file":selectedFile,"body":body };
    //setisBookmarkedLoaded(true);
    if (object.filechanges === true) {
      uploadfile(body, file, object);
    } else {
      let nug = [...nuggets];
      nug = produce(nug, (v) => {
        v[selectedObj.uindex].nuggets[selectedObj.nindex].nchanges = true;
        v[selectedObj.uindex].nuggets[selectedObj.nindex].objects[
          selectedObj.oindex
        ] = object;
      });

      setNuggets(nug);
      setOpen(false);

      setTypeButtons(true);

      editObj.oeditmode = false;
      editObj.meditmode = true;

      setEditObj({ ...editObj });
    }
  };
  const checkData = async () => {
    let selectData = { ...selectedObj };
    selectData = produce(selectData, (v) => {
      v.otherdata.dsval = false;
      v.otherdata.tval = false;
      v.otherdata.mval = false;
      v.otherdata.sval = false;
      v.otherdata.eval = false;
    });
    setSelectedObj(selectData);

    if (
      selectData.otherdata.title === undefined ||
      selectData.otherdata.title == "" ||
      selectData.otherdata.title.replace(/\s/g, "").length === 0
    ) {
      selectData = produce(selectData, (v) => {
        v.otherdata.tval = true;
      });
      setSelectedObj(selectData);
      return;
    }
    if (selectData.otype == "youtube" || selectData.otype == "vimeo") {
      if (
        selectData.otherdata.embeddedcode === undefined ||
        selectData.otherdata.embeddedcode == "" ||
        selectData.otherdata.embeddedcode.replace(/\s/g, "").length === 0
      ) {
        selectData = produce(selectData, (v) => {
          v.otherdata.eval = true;
        });
        setSelectedObj(selectData);
        return;
      }
    }

    if (
      selectData.otherdata.desc === undefined ||
      selectData.otherdata.desc == "" ||
      selectData.otherdata.desc.replace(/\s/g, "").length === 0
    ) {
      selectData = produce(selectData, (v) => {
        v.otherdata.dsval = true;
      });
      setSelectedObj(selectData);
      return;
    }

    if (
      selectData.otherdata.min === undefined ||
      selectData.otherdata.min == "" ||
      selectData.otherdata.min.toString().replace(/\s/g, "").length === 0
    ) {
      if (selectData.otherdata.min.toString() !== "0") {
        selectData = produce(selectData, (v) => {
          v.otherdata.mval = true;
        });
        setSelectedObj(selectData);
        return;
      }
    }

    if (
      selectData.otherdata.sec === undefined ||
      selectData.otherdata.sec == "" ||
      selectData.otherdata.sec <= 0 ||
      selectData.otherdata.sec.toString().replace(/\s/g, "").length === 0
    ) {
      selectData = produce(selectData, (v) => {
        v.otherdata.sval = true;
      });
      setSelectedObj(selectData);
      return;
    }
    console.log(selectData);
    setSelectedObj(selectData);

    finalSave();
  };
  async function uploadfile(body, file, object) {
    setIstLoading(true);
    console.log(object);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(JSON.stringify(bodyParam.body, null, 2));
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        //"/getpreSignedURL",
        bodyParam
      );
      let ftype = "";
      if (selectedObj.stype !== undefined && selectedObj.stype == "chtml") {
        ftype = "text/html";
      } else if (selectedObj.otype == "quiz") {
        ftype = "application/json";
      } else {
        ftype = file.type;
      }
      console.log(preSignedData);
      console.log(file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": ftype,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          let nug = [...nuggets];
          nug = produce(nug, (v) => {
            v[selectedObj.uindex].nuggets[selectedObj.nindex].nchanges = true;
            v[selectedObj.uindex].nuggets[selectedObj.nindex].objects[
              selectedObj.oindex
            ] = object;
          });

          setNuggets(nug);

          setOpen(false);
          editObj.oeditmode = false;
          editObj.meditmode = true;
          setEditObj({ ...editObj });
          setIstLoading(false);
          setTypeButtons(true);
          //setisBookmarkedLoaded(false);
        })
        .catch((error) => {
          //setisBookmarkedLoaded(false);
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {istLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div style={{ width: "100%", minHeight: "26px" }}>
        {/* {modallans()} */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "18px",
              paddingLeft: "2%",
              paddingTop: "1%",
              fontWeight: "500",
              maxWidth: "1000px",
            }}
          >
            {curObject.otitle}
            {/* {curObject.otitle.substring(0, 150) +
              `${curObject.otitle.length > 150 ? "..." : ""}`} */}
          </div>
          <span
            style={{
              float: "right",
              cursor: "pointer",
              color: "black",
              fontSize: "26px",
              paddingRight: "20px",
            }}
            onClick={() => {
              handleClose();
              setTryAgain(false);
              setFormativeAns([]);
              editObj.oeditmode = false;
              setEditObj({ ...editObj });
            }}
          >
            x
          </span>
        </div>
      </div>
      {/* <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <UserHeader /> */}

      {loaded == true ? (
        <div style={{ height: "100%", width: "100%" }}>
          <div
            ref={full}
            style={{
              width: "100%",
              // padding: "2%",
              position: "relative",
            }}
          >
            {editObj.oeditmode && (
              <>
                <div
                  style={{ margin: "0px 50px 0px 50px", textAlign: "center" }}
                >
                  {selectedObj.seltype && selectedObj.sobject != "quiz" && (
                    <div>
                      <div style={{ margin: "20px 15px", overflow: "hidden" }}>
                        {selectedObj.error1 && (
                          <p className={Cviewstyle.verror1}>
                            {selectedObj.sobject}
                            title cannot be empty
                          </p>
                        )}

                        <form autoComplete="off">
                          <TextField
                            label="Title"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            defaultValue={selectedObj.otherdata.title}
                            error={selectedObj.otherdata.tval}
                            helperText={
                              selectedObj.otherdata.tval
                                ? "Please Enter Title"
                                : ""
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              setSelectedObj((curr) =>
                                produce(curr, (v) => {
                                  v.otherdata.title = e.target.value;
                                })
                              );
                            }}
                            inputProps={{ style: { fontSize: '14px' } }}
                            InputLabelProps={{ style: { fontSize: '14px' } }}
                          />
                          <TextField
                            label="Description"
                            id="outlined-margin-normal"
                            className={classes.textField}
                            defaultValue={selectedObj.otherdata.desc}
                            error={selectedObj.otherdata.dsval}
                            helperText={
                              selectedObj.otherdata.dsval
                                ? "Please Enter Description"
                                : ""
                            }
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                              setSelectedObj((curr) =>
                                produce(curr, (v) => {
                                  v.otherdata.desc = e.target.value;
                                })
                              );
                            }}
                            inputProps={{ style: { fontSize: '14px' } }}
                            InputLabelProps={{ style: { fontSize: '14px' } }}
                          />
                         
                          {selectedObj.stype == 'ptraining' ? (<div style={{ textAlign: 'left', fontSize: '14px', marginLeft: '10px' }}>
                            <InputLabel id="select-label">Option</InputLabel>
                            <Select
                              labelId="select-label"
                              value={selectedObj.ptype}
                              onChange={(e) => {
                                setSelectedObj((curr) =>
                                  produce(curr, (v) => {
                                    v.ptype = e.target.value == 'none' ? null : e.target.value;
                                  })
                                );
                              }}
                              label="Option"
                              className={classes.textField}
                              style={{ fontSize: '14px' }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="Haemorrhage">Haemorrhage Control</MenuItem>
                              <MenuItem value="Choking">Choking</MenuItem>
                              <MenuItem value="none">Others</MenuItem>
                            </Select>
                          </div>): null}
                          <div style={{ display: "flex" }}>
                            <RegexTextField
                              label="Duration"
                              error={selectedObj.otherdata.mval}
                              defaultValue={selectedObj.otherdata.min}
                              helperText={
                                selectedObj.otherdata.mval
                                  ? "Please Enter Min"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Min
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                // selectedObj.otherdata.min = e.target.value;
                                setSelectedObj((curr) =>
                                  produce(curr, (v) => {
                                    v.otherdata.min = e.target.value;
                                  })
                                );
                              }}
                              inputProps={{ style: { fontSize: '14px' } }}
                              InputLabelProps={{ style: { fontSize: '14px' } }}
                            />
                            <RegexTextField
                              label=""
                              error={selectedObj.otherdata.sval}
                              defaultValue={selectedObj.otherdata.sec}
                              helperText={
                                selectedObj.otherdata.sval
                                  ? "Please Enter Sec"
                                  : ""
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Sec
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                // selectedObj.otherdata.sec = e.target.value;
                                setSelectedObj((curr) =>
                                  produce(curr, (v) => {
                                    v.otherdata.sec = e.target.value;
                                  })
                                );
                              }}
                              inputProps={{ style: { fontSize: '14px' } }}
                              InputLabelProps={{ style: { fontSize: '14px' } }}
                            />
                          </div>
                          {(selectedObj.sobject === "youtube" ||
                            selectedObj.sobject === "vimeo") && (
                              <TextField
                                label="Embed ID"
                                id="outlined-margin-normal"
                                className={classes.textField}
                                error={selectedObj.otherdata.eval}
                                defaultValue={selectedObj.otherdata.embeddedcode}
                                helperText={
                                  selectedObj.otherdata.eval
                                    ? "Please Enter Embed ID"
                                    : ""
                                }
                                margin="normal"
                                variant="outlined"
                                onChange={(e) => {
                                  setSelectedObj((curr) =>
                                    produce(curr, (v) => {
                                      v.otherdata.embeddedcode = e.target.value;
                                    })
                                  );
                                }}
                                inputProps={{ style: { fontSize: '14px' } }}
                                InputLabelProps={{ style: { fontSize: '14px' } }}
                              />
                            )}
                        </form>
                      </div>
                      {selectedObj.stype == "chtml" && (
                        <>
                          {selectedObj.ferror && (
                            <p
                              style={{
                                color: "red",
                                margin: "0 60px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              Please enter some data.{" "}
                            </p>
                          )}
                          <div
                            style={{
                              height: "300px",
                              border: "1px solid #ddd",
                              margin: "0px 60px",
                              padding: "6px 28px",
                              overflow: "hidden auto",
                            }}
                          >
                            <EditorJs
                              tools={EDITOR_JS_TOOLS}
                              instanceRef={(instance) =>
                                (instanceRef.current = instance)
                              }
                            />
                          </div>
                        </>
                      )}
                      {(selectedObj.stype === undefined ||
                        (selectedObj.stype != "chtml" &&
                          selectedObj.stype != "youtube" &&
                          selectedObj.stype != "vimeo" && selectedObj.stype != "pexam" && selectedObj.stype != "ptraining")) && (
                          <>
                            <p className={Cviewstyle.pvalid}>
                              Please select a {selectedObj.sobject} to upload
                            </p>
                            <div
                              className={Cviewstyle.insidediv}
                              style={{
                                margin: "20px 59px 19px 24px",
                                width: "95%",
                              }}
                            >
                              <div className={Cviewstyle.centeritem}>
                                <input
                                  type="file"
                                  id="ofiles"
                                  style={{
                                    visibility: "hidden",
                                    position: "absolute",
                                  }}
                                  onChange={ochangeHandler}
                                  accept={selectedObj.afile}
                                />
                                <label for="ofiles">
                                  <p
                                    variant="contained"
                                    style={{
                                      backgroundColor: "rgb(227, 95, 20)",
                                      color: "#fff",
                                      width: "100px",
                                      height: "36px",
                                      marginRight: "10px",
                                      alignSelf: "center",
                                      padding: "10px",
                                      cursor: "pointer",
                                      borderRadius: "2px",
                                      fontSize: '14px'
                                    }}
                                  >
                                    Browse
                                  </p>
                                </label>
                              </div>

                              {selectedObj.otherdata.files != "" && (
                                <p className={Cviewstyle.pfile}>
                                  {selectedObj.otherdata.files}
                                </p>
                              )}
                              {selectedObj.otherdata.files == "" && (
                                <p className={Cviewstyle.pfile}>
                                  {selectedObj.sobject}{" "}
                                  file not selected{" "}
                                </p>
                              )}
                              {selectedObj.ferror && (
                                <p
                                  className={Cviewstyle.pfile}
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  Please select file{" "}
                                </p>
                              )}
                            </div>
                          </>
                        )}
                    </div>
                  )}
                  {selectedObj.sobject != "quiz" && (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "rgb(227, 95, 20)",
                          color: "#fff",
                          width: "100px",
                          height: "36px",
                          marginRight: "10px",
                          alignSelf: "center",
                          fontSize: '14px'
                        }}
                        onClick={() => checkData()}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        style={{
                          width: "100px",
                          height: "36px",
                          alignSelf: "center",
                          fontSize: '14px'
                        }}
                        onClick={() => {
                          editObj.oeditmode = false;
                          setEditObj({ ...editObj });
                          handleClose();
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                  {selectedObj.seltype && selectedObj.sobject == "quiz" && (
                    <>
                      <CreateQuiz
                        selectedObj={selectedObj}
                        setSelectedObj={setSelectedObj}
                        finalSave={finalSave}
                        folder={folder}
                        typesButtons={typesButtons}
                        setTypeButtons={setTypeButtons}
                        quizQuestion={quizQuestion}
                        setQuizQuestion={setQuizQuestion}
                        editQuiz={editQuiz}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          {!editObj.oeditmode && renderObjects()}
        </div>
      ) : null}
    </div>
  );
};

export default CoursePlayer;
let styles = {
  editButton: {
    color: "white",
    borderStyle: "groove ",
    borderColor: config.main_color_2,
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "5px",
    backgroundColor: config.main_color_2,
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
    fontWeight: "bold",
  },
  enableDisable: {
    color: "black",
    borderStyle: "groove ",
    borderColor: "black",
    height: "30px ",
    lineHeight: "0px ",
    width: "150px",
    marginTop: "15px",
    marginRight: "50px",
    backgroundColor: "white",
    fontSize: "15px ",
    borderRadius: "3px ",
    float: "right",
  },
};
