// Dependencies imports
import React, { useState } from 'react';
import swal from 'sweetalert';
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { authData } from "../../redux/auth/authSlice";

// Styles imports
import Add from './AddAnnouncement.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent"
  },
}));

const AddAnnouncement = (props) => {
  let { announcementData, handleClose, getAnnouncement } = props;

  const [announcmentTitle, setAnnouncmentTitle] = useState("");
  const [announcmentFile, setAnnouncmentFile] = useState("");
  const [announcmentDescription, setAnnouncmentDescription] = useState("");
  const [announcmentId, setAnnouncmentId] = useState("");
  const [announcmentTitleErr, setAnnouncmentTitleErr] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();



  // const [webinarUrlErr, setAnnouncmentErr] = useState("");
  const [announcmentDescriptionErr, setAnnouncmentDescriptionErr] = useState("");

  let userDetails = useSelector(authData);

  const onChangeHandlerAnnouncmentTitle = event => {
    setAnnouncmentTitle(event.target.value);
  };
  const onChangeHandlerAnnouncmentFile = event => {
    setAnnouncmentFile(event.target.value);
  };
  const onChangeHandlerAnnouncmentDescription = event => {
    setAnnouncmentDescription(event.target.value);
  };
  const changeHandler = (event) => {
    console.log("1", event.target.files)
    console.log("2", event.target.files[0].name)
    setSelectedFile(event.target.files[0]);
    let tstamp = new Date().getTime();
    setTimeStamp(tstamp);
    // setAnnouncmentFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmission = () => {
  };

  const onsubmit = (event) => {
    // setname(name)
    // alert(event.target.files[0]);
    let formIsValid = true;
    let neweid;
    setAnnouncmentTitleErr("");
    setAnnouncmentDescriptionErr("");
    if (announcmentTitle.trim() === "") {
      formIsValid = false;
      setAnnouncmentTitleErr("Please Enter Title");
      return;
    }
    if (announcmentTitle.trim() != "" && announcmentTitle.trim().length > 100) {
      setAnnouncmentTitleErr("*Please Enter Event Title length less than 100 character");
      return;
  }
    if (announcmentDescription.trim() === "") {
      formIsValid = false;
      setAnnouncmentDescriptionErr("*Please Enter Announcement description");
      return;
    }
    if (announcmentDescription.trim() != "" && announcmentDescription.trim().length > 160) {
      setAnnouncmentDescriptionErr("*Please Enter Announcement description length less than 160 character");
      return;
  }
   
    if (formIsValid === true) {
      neweid = (announcementData == null || announcementData === undefined || announcementData.length === 0) ? '1' : +announcementData[announcementData.length - 1].announcid + 1

    }
    if (isFilePicked === true) {
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')+1, selectedFile.name.length) || selectedFile.name;
      console.log("hiiiiiiii"+extend)
      
      getPreSignedUrl(extend)
    }
    else {
      addannouncement(neweid);
    }
  };


  async function addannouncement(id) {
    setSpin(true);
    let timestamp = new Date().getTime();
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        annjson: {
          title: announcmentTitle,
          type: "text",
          description: announcmentDescription,
          announcid: timestamp,
          sdate: Math.floor(Date.now() / 1000)
        },
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (isFilePicked === true) {
      let type = "";
      let extend = selectedFile.name.substring(selectedFile.name.lastIndexOf('.')+1, selectedFile.name.length) || selectedFile.name;
      if(extend == "JPG" || extend == "PNG" || extend == "JPEG" || extend == "GIF" || extend == "jpg" || extend == "png" || extend == "jpeg" || extend == "gif"){
        type = "image";
      }else if( extend == "mp4"){
        type = "video";
      }else if(extend == "mp3" ){
        type = "audio";
      }else{
        type = "pdf";
      }
      bodyParam.body.annjson.type = type;
      bodyParam.body.annjson.filename = selectedFile.name;
      bodyParam.body.annjson.mfname = timeStamp+"."+extend;
    }
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_ANNOUNCEMENT,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      handleClose();
      swal({
        title: "Success",
        text: "Announcement added successfully",
        icon: "success",
        dangerMode: false,
      })
        .then(willDelete => {
          if (willDelete) {
            handleClose();
          }
        });
      setSpin(false);
      getAnnouncement();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {

          }
        });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  async function getPreSignedUrl(extend) {
    setSpin(true);
    const bodyParam = {
      body: {

        oid: config.aws_org_id,
        type: 'announcement',
        filetype: selectedFile.type,
        filename: timeStamp.toString() + "." + extend


      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("presignedbody" + JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );


      fileUpload(selectedFile, response);
      // setSpin(false);

    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      })
        .then(willDelete => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  async function fileUpload(file, url) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => {

        console.log(res);
        addannouncement();

      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div>
      {spin === false ?
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add Announcement</h3>
          <div className={Add.caution}>{announcmentTitleErr}</div>
          <input type="text" placeholder="Title" className={Add.title} onChange={onChangeHandlerAnnouncmentTitle} value={announcmentTitle} />
          <h4 className={Add.condition}>* Title should be less than 100 characters</h4>
          <div className={Add.caution}>{announcmentDescriptionErr}</div>
          <textarea className={Add.textarea} rows={10} placeholder="Description" maxlength="160" onChange={onChangeHandlerAnnouncmentDescription} value={announcmentDescription} />
          <h4 className={Add.condition}>* Description should be less than 160 charecters</h4>
          <div className={Add.fileinput}>
            <input type="file" id="file" name="file not selected" accept=".jpg,.png,.jpeg,.gif,.pdf,.mp4,.mp3" className={Add.browse} style={{  border: "1px solid #6a6a6a", borderRadius: "5px", height: "fit-content", width: "fit-content", padding: "0px 10px" }} onChange={changeHandler} />
            {/* <label for="file" className={Add.browse} style={{ border: "1px solid #6a6a6a", borderRadius: "5px", height: "fit-content", width: "fit-content", padding: "0px 10px" }}> Browse</label> */}

          </div>
          <div className={Add.buttonholder}>
            <button className={Add.cancel} onClick={handleClose}>Cancel</button>
            <button className={Add.submit} onClick={onsubmit} >Submit</button>
          </div>
        </div> : <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} /></div>}
    </div>
  );
};

export default AddAnnouncement;