// Dependencies imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { awsSignIn, authData } from "../../redux/auth/authSlice";

import config from "../../config/aws-exports";
//import { Constants } from "../../config/constants";

//import { generate } from "shortid";
import { DataGrid } from '@mui/x-data-grid';

import { makeStyles, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      background: config.main_color_1,
      fontSize: "16px",
      color: 'rgb(255,255,255)',
      fontFamily: "nunito"
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },

  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    width: "100%",
    overflow: 'auto'

  }
});
function getInstructorName(ins) {
  
  if (typeof ins[0] === 'string') {
    const parts = ins[0].split(',');
    if (parts.length >= 2) {
      return (
        <h2 style={{ fontSize: "16px" }}>  - {parts[1]}</h2>
      );
    }
  }

  return null; // Return null or a default value if 'ins' is not a valid string.
}
// Start of Reports component
const ModuleWiseFeedBack = (props) => {
  let { moduleData, selectionModel, setSelectionModel, mcolumns } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  console.log("moduleData", moduleData);
  return (
    <div style={{ height: 400, width: '100%' }}>
      {moduleData !== null && moduleData?.length > 0
        ? moduleData.map(({ ntitle, nid, columns, students, instructor }, idx) => (
          <div className={classes.root} key={idx}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <h2 style={{ fontSize: "16px" }}>{ntitle}</h2>
                </Typography>
                {instructor && (
                  <Typography className={classes.heading}>
                    {getInstructorName(instructor)}
                  </Typography>
                )}
              </AccordionSummary>
              <AccordionDetails>

                <div className={classes.root} key={idx}>
                  <div style={{ height: 400 }} >
                    {/* {console.log(students,"columns999",columns)} */}
                    {students != null && students?.length > 0 && <DataGrid
                      rows={students}
                      columns={columns}
                      disableSelectionOnClick
                      onRowSelected={e => console.log(e)}
                      // onSelectionModelChange={(newSelection) => {
                      //     console.log(newSelection);
                      //     setSelectionModel(newSelection);
                      // }}
                      // selectionModel={selectionModel}
                      // onCellClick={handleCellClick}
                      className={classes['.MuiDataGrid-root']}
                    />}
                  </div>
                </div>

              </AccordionDetails>
            </Accordion>
          </div>
        ))
        : null}

    </div>
  );
};

export default ModuleWiseFeedBack;
