import React, { useState } from "react";
import UserStats from "../components/Programs/UserStats";
import Aside from "../modules/SideNav/Aside";

function UserStatsLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      /> */}
      <UserStats handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default UserStatsLayout;
