import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Typography, Accordion, AccordionSummary, AccordionDetails
} from "@material-ui/core";
import swal from "sweetalert";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { awsSignIn, authData, awsSignOut } from "../../redux/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import Done from "../../../src/assets/svgjs/Done";
import Reject from "../../../src/assets/svgjs/Reject";
import Rep from "./Reports.module.scss";
//import Cviewstyle from "./courseView.module.scss";
//import "./courseView.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  table: {
    minWidth: 500,
  },
  tcell: {
    color: '#fff',
    fontSize: '14px',
  },
  container: {
    maxHeight: 229,
  },

}));

export default function ViewScores({
  snopen,
  setSnopen,
  loading,
  scoreData,
  setScoreData,
  uname,
}) {
  console.log("surveyData==", scoreData);
  //const [isdload, setIsdload] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);
  const [reslut, setResult] = useState("");
  console.log(userDetails);
  function handleNuggetsClose() {
    setSnopen(false);
    setScoreData([]);
  }
  function AcccessibleTable(props) {
    const classes = useStyles();
    const data = props?.rows != null ? props?.rows : [];
    const groupedData = data.reduce((acc, item) => {
      const { title } = item;
      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title].push(item);
      return acc;
    }, {});

    return (
      <div>
        {Object.entries(groupedData).map(([key, value]) => (
          <div key={key}>
            {/* <h3

              style={{
                padding: "10px",
                fontWeight: "600",
                fontFamily: "sans-serif",
                fontSize: "1.5rem",
                color: "rgba(0,0,0,.75)",
                flexBasis: "90%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                minHeight: "30px",
              }}
            >{key}
            </h3> */}
            <TableContainer component={Paper} className={classes.container}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <caption></caption>
                <TableHead >
                  <TableRow>
                    <TableCell className={classes.tcell}>Attempt</TableCell>
                    <TableCell className={classes.tcell}>Score</TableCell>
                    {/* {userDetails?.curprgcou?.pid !== 1 ? <TableCell className={classes.tcell}>Object</TableCell> : null}
                    <TableCell className={classes.tcell}>Instructor</TableCell> */}
                    <TableCell className={classes.tcell}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value.map((row, ind) => (
                    <TableRow key={ind}>
                      <TableCell component="th" scope="row">
                        {ind + 1}
                      </TableCell>
                      <TableCell >{row}</TableCell>
                      {/* {userDetails?.curprgcou?.pid !== 1 ? <TableCell >{row.otitle}</TableCell> : null} */}
                      {/* <TableCell >{(row.iname === undefined || row.iname === null) ? ' ' : row.iname}</TableCell> */}
                      <TableCell>
                        {parseInt(row.score) > 15 ? (
                          <Done
                            className={Rep.right_size}
                            cls1="cls1_D"
                            cls2="cls2_D"
                          />
                        ) : (
                          <Reject
                            className={Rep.Wrong_size}
                            cls1="cls1_D"
                            cls2="cls2_D"
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </div>
    );
  }
  return (
    <Dialog
      open={snopen}
      maxWidth='sm'
      fullWidth="true"
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title"> Scores - {uname} </DialogTitle>
      <DialogContent>
        <div >

          {scoreData !== null && scoreData?.length > 0
            ? scoreData.map((sitem, idx) => (
              <div className={classes.root} key={idx}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      <h2 style={{ fontSize: "16px" }}>{sitem.otitle}</h2>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.root} key={idx}>
                      {userDetails?.curprgcou?.pid === 1 ? <div  >
                        {sitem?.scores != undefined && sitem.scores != null && sitem.scores.length > 0 &&
                          <AcccessibleTable rows={sitem.scores} iname={sitem.iname} />

                        }

                      </div> :
                        <div  >

                          {sitem?.pscores != undefined && sitem.pscores != null && sitem.pscores.length > 0 &&
                            <AcccessibleTable rows={sitem.pscores} iname={sitem.iname} title={sitem.title} />

                          }
                          {/* {JSON.stringify(sitem?.pscores)} */}
                        </div>
                      }

                    </div>

                  </AccordionDetails>
                </Accordion>
              </div>
            ))
            : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNuggetsClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
