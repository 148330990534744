import React from 'react';
import {
    Button,
    OutlinedInput,
} from "@material-ui/core";

import vregi from './AddEvent.module.scss';
import { Close } from '@material-ui/icons';

export default function ViewHonorarium(props) {

    const { hData, handleNuggetsClose } = props;

    console.log("hData", hData)

    return (
        <div style={{ margin: 20 }}>

            <div cl0assName={vregi.iconcontainer} style={{ display: "flex", alignItems: "center", placeContent: "space-between" }}>
                <p style={{ fontSize: 16, fontWeight: '700' }}>Honorarium form</p>
                <Close className={vregi.closebutton} onClick={handleNuggetsClose} />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >First Name (As per records):</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.firstName}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Second Name (As per records):</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.lastName}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont}>Address Line 1:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.perAddr?.addr1}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>
                    <p className={vregi.pfont} >Address Line 2:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.perAddr?.addr2}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>
                    <p className={vregi.pfont} >Address Line 3:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.perAddr?.addr3}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >State:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.perAddr?.state}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >City:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth} defaultValue={hData?.perAddr?.city}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >PIN:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.perAddr?.pin}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Mobile Number:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.phoneNo}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Designation:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.designation}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Institution:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.institution}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Type of Instructor:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.instructortype}
                />
            </div>
            <div>
                <p style={{
                    fontSize: 16,
                    fontWeight: '700',
                    marginTop: 20,
                }}>
                    Bank details:
                </p>
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Name of the bank:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.bankName}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>

                    <p className={vregi.pfont} >Address of the bank:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.bankBranch}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>
                    <p className={vregi.pfont} >Account Number:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.accNo}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>
                    <p className={vregi.pfont} >IFSC Code:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.ifsc}
                />
            </div>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
                <div style={{ flexDirection: 'row' }}>
                    <p className={vregi.pfont} >PAN number:</p>
                </div>
                <OutlinedInput style={{ fontSize: 14 }} disabled className={vregi.fullwidth}
                    defaultValue={hData?.pan}
                />
            </div>
            <div style={{
                borderWidth: 1,
                borderRadius: 5,
                height: 80,
            }}>
            </div>
        </div>
    )
}
