// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";
import Ann from "./AnnouncementFile.module.scss";
import Grd from "./GradingDetails.js";

import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { setCookie } from "react-use-cookie";
import { useSelector, useDispatch } from "react-redux";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { awsSignIn, authData } from "../../redux/auth/authSlice";


// Styles imports
import Miniassignmentfile from "./miniassignmentfile";
import Add from "./AddScore.module.scss";
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: Constants.app_dark_color,
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const AddScore = (props) => {
  let { handleClose, response, currentData, userDet, rowsdata, setRowsData } =
    props;
  let navigate = useNavigate();

  console.log("response", response);
  console.log("rowsdata", rowsdata);
  console.log("userdetails", userDet);
  let userDetails = useSelector(authData);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(response.score);
  const [obj, setObj] = useState();
  const [spin, setSpin] = useState(false);
  const [scoreErr, setScoreErr] = useState("");
  const [open, setOpen] = useState(false);
  console.log("rowdata" + JSON.stringify(rowsdata));
  const imgUrl = `https://${
    config.DOMAIN
  }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${
    currentData.oid.split("-")[0]
  }/`;
  const classes = useStyles();

  function answerPrev() {
    console.log("curr", currentQuestion);
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }
  useEffect(() => {
    if (response !== undefined && response.response !== undefined) {
      saveLocale(response.response[0]);
    }
    return () => {};
  }, []);

  console.log("userDetails", userDetails);

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < response.response.length) {
      setCurrentQuestion(nextQuestion);
    }
  }
  const saveLocale = (data) => {
    const expires = new Date().getTime() + 60 * 60 * 1000;
    setCookie("CloudFront-Expires", expires);
    setCookie("CloudFront-Policy", data.Policy);
    setCookie("CloudFront-Signature", data.Signature);
    setCookie("CloudFront-Key-Pair-Id", data.KeyPairId);
  };
  async function gradeAssignments(score) {

    if (score == undefined || score == "") {
      setScoreErr("Please enter the score");
      return;
    } else if (score > 10) {
      setScoreErr("Score Must be less than or equal to 10");
      return;
    } else if (score < 0) {
      setScoreErr("Score Must be greater than 0");
      return;
    } else {
      setSpin(true);
      const bodyParam = {
        body: {
          eid: userDet.eid,
          obid: userDet.qid,
          oid: config.aws_org_id,
          score: score,
          bpid: userDetails["curprgcou"]["bpid"],
          bcid: userDetails["grade"]["btid"],
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      console.log(bodyParam.body);
      try {
        console.log(bodyParam.body);
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          `/gradeAssignments`,
          bodyParam
        );
        let data = rowsdata;
        for (let i = 0; i < rowsdata.length; i++) {
          if (rowsdata[i].eid == userDet.eid) {
            data[i].score = score + "/10";
          }
        }

        setRowsData(data);
        swal({
          title: "Success",
          text: "Score updated Succesfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();
            // getMiniAssignments();
            // setSpin(false);
            window.location.reload();
          }
        });
        // window.location.reload();
        console.log("response", response);

        setSpin(false);
      } catch (error) {
        swal({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            // setSpin(false);
          }
        });
        console.error(error);
      }
    }
  }
  function handleChange(e) {
    setScore(e.target.value);
  }
  function addLink(item) {
    setObj(item);
    handleFileOpen();
  }
  const handleFileOpen = () => {
    setOpen(true);
  };

  const handleFileClose = () => {
    setOpen(false);
  };
  return (
    <div style={{ height:'80vh',overflow:'overlay'}}>
      {spin === false ? (
        <div className={Add.mainpopup} >
          <div className={Add.iconholder}>
            <Close onClick={handleClose} className={Add.icon} />
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Miniassignmentfile handleFileClose={handleFileClose} obj={obj} />
            </Fade>
          </Modal>

          <h3 className={Add.header}>Add Score</h3>

          <h4 className={Add.over}>Overall Score: </h4>
          <div
            style={{
              color: "#cc0000",
              marginBottom: "12px",
              fontSize: "13px",
              marginTop: "0px",
              textAlign: "center",
            }}
          >
            {scoreErr}
          </div>
          <div className={Add.overholder}>
            <input
              value={score}
              onChange={(e) => handleChange(e)}
              className={Add.overinput}
            ></input>
            <button
              onClick={() => gradeAssignments(score)}
              className={Add.submit}
            >
              Submit
            </button>
          </div>
          <div className={Add.questioncount}>
            <div className={Add.question}>
              Question {currentQuestion + 1}/{response.response.length}
            </div>
          </div>
          <div
            className={Add.questiontext}
            dangerouslySetInnerHTML={{
              __html: response.response[currentQuestion].istem,
            }}
          />
          {response.response[currentQuestion].imageurl ? (
            <div className={Add.questionsection}>
              {/* {quizQuestion[currentQuestion].istem} */}

              {/* {response.response[currentQuestion].filename} */}

              <div className={Add.imagecontainer}>
                <img
                  src={`${imgUrl}${response.response[currentQuestion].imageurl}`}
                  alt="item"
                />
              </div>
            </div>
          ) : null}
          <div className={Add.areaholder}>
            <textarea
              rows="6"
              style={{ width: "100%", resize: "none" }}
              value={response.response[currentQuestion].response}
            ></textarea>
          </div>
          <div>
            {response.response[currentQuestion].filename !== undefined ? (
              <div>
                <p
                  className={Ann.link}
                  style={{
                    color: "#007bff",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    addLink(response.response[currentQuestion]);
                  }}
                >
                  {response.response[currentQuestion].filename}
                </p>
                {response.response[currentQuestion].filename != undefined ? (
                  <a
                    className={Ann.link}
                    href={response.response[currentQuestion].ourl}
                    target="_blank"
                  >
                    Click here to open in New Tab
                  </a>
                ) : null}
              </div>
            ) : null}
          </div>
          {/* <div>
            <a href={response.response[currentQuestion].ourl} target="blank">
              
            </a>
          </div> */}
          <div className={Add.buttonholder}>
            {currentQuestion > 0 ? (
              <button className={Add.prev} onClick={() => answerPrev()}>
                Prev
              </button>
            ) : null}
            ​ ​ ​
            {currentQuestion + 1 === response.response.length ? null : (
              <button className={Add.next} onClick={() => answerNext()}>
                Next
              </button>
            )}
          </div>
          {/*  <div className={Add.buttonholder}>
                <button className={Add.submit} onClick={() => onsubmit()}>Add</button>
                <button className={Add.cancel} onClick={handleClose}>Cancel</button>
            </div> */}
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};
export default AddScore;
