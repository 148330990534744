// Dependencies imports
import React, { useEffect } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
// Local imports
import swal from "sweetalert";

import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";

// Styles imports
import moment from "moment";
import Swal from "sweetalert2";

// Data for User Stats Grid

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      background: config.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    '& .MuiDataGrid-sortIcon': {
      // Add your custom styles for the sort icon here
      color: 'white', // Example: Change the color to red
      fontSize: '20px', // Example: Change the font size
    },
  },

});

// Start of Instructor Grid Component
const UserStatsGrid = (props) => {
  let {
    userData,
    selectionModel,
    setSelectionModel,
    getFaculty,
    setSendLoading,
  } = props;
  const classes = useStyles();

  let userDetails = useSelector(authData);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  // function for toolbar options above datagrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }
  // Useeffect for faculty details api
  useEffect(() => {
    
    return () => { };
  }, []);

  const gFunction = async (data) => {
   
    setSendLoading(true);
    const bodyParam = {
      body: {
        eid: data?.eid,
        uname: data?.first_name,
        cname: data?.cname,
        type: "instructor||observer",
        des: `course (JR,KA,B/048   )held at St Joseph's College of Nursing, Mysuru on ${moment(
          parseInt(data?.start_date)
        ).format("DD-MM-YYYY")} and is certified as '${data?.cname
          } PROVIDER'.Certificate is valid for 5 years from this day.`,
        emailid: data?.emailid,
        domain: config.DOMAIN,
        cid: data?.bid,
        ur_id: data?.ur_id,
        pid: data?.pid,
        date: moment(parseInt(data?.start_date)).format("DD-MM-YYYY"),
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
   
    };

    try {
      const res = await API.post(
        config.aws_cloud_logic_custom_name,
        "/generatecertificate",
        bodyParam
      );
      
      setSendLoading(false);
      getFaculty();
    } catch (error) {
      console.error(error);
      setSendLoading(false);
    }
  };
  const handleGenarateCertificate = (data) => {
    Swal.fire({
      title: "Send Certificate",
      onfirmButtonText: "Send",
      showLoaderOnConfirm: true,
      preConfirm: () => gFunction(data),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((ok) => {
      if (ok.isConfirmed) {
        Swal.fire(
          "Good job!",
          "The certificate was sent successfully.!",
          "success"
        );
      }
    });
  };
  const columns = [
    {
      field: "first_name",
      headerName: "Name",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => <p onClick={(event) => handleCellClick(params, event)}>{params.value}</p>,
    },
    {
      field: "emailid",
      headerName: " Email",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
    },
    {
      field: "send",
      headerName: "Certificate",
      width: 300,
      flex: 1,
      headerClassName: "super-app-theme--header",
      editable: true,
      renderCell: (params) => (
        <strong>
          {params.value ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 16,
                background: config.main_color_2,
                color: "white",
                fontSize: "14px",
                fontFamily: "nunito",
              }}
              disabled={!window.navigator.onLine}
              onClick={() => handleGenarateCertificate(params.row)}
            >
              Send
            </Button>
          ) : null}
        </strong>
      ),
    },
    {
      field: "apr_status",
      headerName: "Approval Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>
         
          {userDetails.role == "Admin" && !params.value ? (
            <div> <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 16,
                background: config.main_color_2,
                color: "white",
                fontSize: "14px",
                fontFamily: "nunito",
              }}
              disabled={!window.navigator.onLine}
              onClick={() => handleApproveUser(params, 'approve')}
            >
              Approve
            </Button>
              <Button
                variant="contained"
                size="small"
                style={{
                  marginLeft: 16,
                  background: '#fff',
                  color: "#000",
                  fontSize: "14px",
                  fontFamily: "nunito",
                  border: '1px solid grey'
                }}
                disabled={!window.navigator.onLine}
                onClick={() => handleApproveUser(params, "reject")}
              >
                Reject
              </Button>
            </div>
          ) : null}
        </strong>
      ),
    },
    {
      field: "payment",
      headerName: "Payment Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      width: 400,
      renderCell: (params) => (
        <strong>
        
          {userDetails.role == "Admin" && !params.value ? (
            <Button
              variant="contained"
              size="small"
              style={{
                marginLeft: 16,
                background: config.main_color_2,
                color: "white",
                fontSize: "14px",
                fontFamily: "nunito",
              }}
              disabled={!window.navigator.onLine}
              onClick={(event) => handleApprovePayment(params, event)}
            >
              Bypass Payment
            </Button>
          ) : null}
        </strong>
      ),
    },
  ];

  const handleApproveUser = async (param, Status) => {
    try {
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          atype: 1,
          bid: userDetails.curprgcou.bid,
          pid: userDetails.curprgcou.pid,
          ur_id: param.row?.ur_id,
          apr_status: Status == 'approve' ? true : false,
          payment: param.row?.payment,
          pstatus: Status == 'approve' ? 'Approved' : 'Rejected',
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      
      var msg = Status == 'approve' ? 'Approved' : 'Rejected';
     
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/addUpdateRegistration",
        bodyParam
      );
      param.row.apr_status = true;
      swal({
        title: "Success",
        text: "User " + msg + " Successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
         
        }
      });
    } catch (error) { }
  };
  const handleApprovePayment = async (param) => {
    try {
      const bodyParam = {
        body: {
          
          atype: 1,
          bid: userDetails.curprgcou.bid,
          pid: userDetails.curprgcou.pid,
          ur_ids: [param.row],
          payment: true,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
       await API.post(
        config.aws_cloud_logic_custom_name,
        "/addUpdateRegistration",
        bodyParam
      );
      param.row.payment = true;
      swal({
        title: "Success",
        text: "Payment Approved Successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
         
        }
      });
    } catch (error) { }
  };
  const handleCellClick = (param) => {
    
    let sdata = { ...userDetails };
    sdata.selbatchuser = { ...param.row };
    let temp = [...sdata.breadcrumb];
   
    temp[3] = {
      name: sdata.selbatchuser.name,
      path: "/userprogress",
    };

    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));

    console.log("sdata", sdata);

    navigate("/userprogress");
   
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={userData}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          onRowSelected={(e) => console.log(e)}
          onSelectionModelChange={(newSelection) => {
            console.log(newSelection);
            setSelectionModel(newSelection);
          }}
          selectionModel={selectionModel}
          disableColumnMenu
          className={classes[".MuiDataGrid-root"]}
        />
      </div>
    </div>
  );
};

export default UserStatsGrid;
