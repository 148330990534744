import React, { useState } from "react";
import EmailTemp from "../components/Programs/EmailTemp";
import Aside from "../modules/SideNav/Aside";

function EmailTempLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            /> */}
      <EmailTemp handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default EmailTempLayout;
