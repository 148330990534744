import React, { useState } from "react";
import Users from "../components/Users/Users";
import Aside from "../modules/SideNav/Aside";

function UsersLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
        rtl={rtl}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      /> */}
      <Users handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default UsersLayout;
