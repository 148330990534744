import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import swal from "sweetalert";
//import { produce } from "immer";
//import { API } from "aws-amplify";
import config from "../../config/aws-exports";

//import GroupRadio from "./GroupRadio";
//import Cviewstyle from "./courseView.module.scss";
//import "./courseView.scss";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import ViewRegistration from '../Users/ViewRegistration';
import ViewHonorarium from '../Users/ViewHonorarium';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  ratediv:{
    alignItems:'flex-start',
    flexDirection:'column',
    justifyContent:'center',
    gap:'20px',
    display: 'flex',
  },
  ratehead:{
   width:'100%'
  },
  ratestar:{
    width: '40%'
  },
  note:{
    paddingBottom: '10px',
  },
}));

export default function RegistrationDtls({
    cmtOpen,
    setCmtOpen,
    regiDtls,
    hData,
    vType,
  cid,
}) {
  const classes = useStyles();
  let userDetails = useSelector(authData);
  console.log("mainhData",hData)
  function handleNuggetsClose() {
    
    //setCmtFeedback({'cmts':'','name':'','rate': 0,'headerName':''});
    setCmtOpen(false);
  }
  return (
    <Dialog
      open={cmtOpen}
      maxWidth='sm'
      fullWidth="true"
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title" >
      {/* <DialogTitle id="form-dialog-title"> Feedback</DialogTitle> */}
      <DialogContent>
        {vType === 1 && regiDtls && regiDtls[0] !== undefined &&<ViewRegistration handleNuggetsClose={handleNuggetsClose} regiDtls={regiDtls}/> }
        {vType === 2 && hData && <ViewHonorarium handleNuggetsClose={handleNuggetsClose} hData={hData} />}
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleNuggetsClose} color="primary">
          Cancel
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}

