import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import { TabContext } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import config from "../../config/aws-exports";
import { API, Auth } from "aws-amplify";
import { produce } from "immer";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { Theme } from "../../config/aws-exports";
import swal from "sweetalert";
import UserHeader from "../../components/Header/UserHeader/UserHeader";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate, Outlet, useMatch } from "react-router-dom";
import Swal from "sweetalert2";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import state from "sweetalert/typings/modules/state";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const useStyles = makeStyles((theme) => ({
  root: {
    background: Theme.color.white,
    fontSize: "14px",
  },
  iconButton: {
    "&.MuiIconButton-root": {
      padding: 0,
      "&.MuiIconButton-label > .MuiSvgIcon-root": {
        fontSize: "2.5rem !important",
      },
    },
  },
  selected: {
    color: Theme.color.white,
    background: Theme.color.secondary,
  },
  textColorPrimary: {
    color: Theme.color.black,
    background: Theme.color.white,
  },
}));
export const ButtonPrimary = {
  color: Theme.color.white,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  background: Theme.color.primary,
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
const ButtonSecondary = {
  color: Theme.color.primary,
  borderColor: Theme.color.primary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.primary,
    borderColor: Theme.color.primary,
    textTransform: "unset",
  },
};
export const ButtonTernary = {
  color: Theme.color.secondary,
  borderColor: Theme.color.secondary,
  textTransform: "unset",
  fontSize: "13px",
  "&:hover": {
    color: Theme.color.secondary,
    borderColor: Theme.color.secondary,
    textTransform: "unset",
  },
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const JobApplicationApprovals = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const matchApprovals = useMatch({
    path: "/jobapplicationapprovals",
    caseSensitive: true,
  });
  const [text, setText] = useState("");
  const [profileData, setProfileData] = useState();
  const [jobAppData, setJobAppData] = useState();
  // const [loading, setLoading] = useState(true);
  const [aploading, setApLoading] = useState(true);

  const [checked, setChecked] = useState(true);
  const classes = useStyles();
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange = (event, ind, type) => {
    // setChecked(event.target.checked);
    if (type === "pf") {
      setProfileData((currentFile) =>
        produce(currentFile, (draft) => {
          draft.profile[ind].checked =
            draft.profile[ind].checked === true ? false : true;
        })
      );
    } else {
      setJobAppData((currentFile) =>
        produce(currentFile, (draft) => {
          draft[ind].checked = draft[ind].checked === true ? false : true;
        })
      );
    }
  };
  useEffect(() => {
    // getProfileData();
    getJobApplicationData();
  }, []);
  // async function getProfileData() {
  //   const bodyParam = {
  //     body: {
  //       oid: config.aws_org_id,
  //       action: 0,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };
  //   console.log("profilebody==", bodyParam.body);
  //   try {
  //     const response = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       "/getProfileApprovals",
  //       bodyParam
  //     );
  //     setProfileData(response.body);
  //     console.log("responsebody===", response.body);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  async function getJobApplicationData() {
    setApLoading(true);
    const bodyParam = {
      body: {
        action: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getJobApplications",
        bodyParam
      );
      setJobAppData(response.body);

      setApLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const applicationApprovalAPI = async (appids, stdstatus, rejectText) => {
    const bodyParam = {
      body: {
        atype: 2,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    bodyParam.body.appids = appids;
    bodyParam.body.astatus = stdstatus;
    if (stdstatus === 2) {
      bodyParam.body.rjstatus = rejectText;
    } else {
      bodyParam.body.rjstatus = null;
    }

    try {
      console.log("appbody==", bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/approvalProfileOrApp",
        bodyParam
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text:
          "Profile " +
          (stdstatus === 1 ? "approval " : "rejected ") +
          "successfully",
        showConfirmButton: true,
      }).then((ok) => {
        if (ok.isConfirmed) {
          getJobApplicationData();
        }
      });
      // swal({
      //   title: "Success!",
      //   text:
      //     "Profile" +
      //     (stdstatus === 1 ? "approval " : "rejected ") +
      //     "successfully",
      //   icon: "success",
      //   dangerMode: false,
      // }).then((ok) => {
      //   if (ok) {
      //     getJobApplicationData();
      //   }
      // });
      // getProfileData();
      // getJobApplicationData();
    } catch (error) {
      console.error(error);
    }
  };
  const appOrRejrHandle = async (type, stdstatus) => {
    let appids = [];

    const checked2 = jobAppData.filter((job) => job.checked === true);
    if (checked2.length === 0) {
      swal({
        title: "oops.!",
        text: "Please select application to reject or approve",
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    for (let i = 0; i < jobAppData?.length; i++) {
      if (jobAppData[i].checked) {
        appids.push(jobAppData[i].app_id);
      }
    }

    if (stdstatus === 2) {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: "Rejection Reason",
        inputPlaceholder: "Type in the reason for rejection",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        preConfirm: (value) => {
          if (!value) {
            Swal.showValidationMessage(" Your response is required");
          }
        },
      });
      if (text) {
        applicationApprovalAPI(appids, stdstatus, text);
      } else {
        return;
      }
    } else {
      applicationApprovalAPI(appids, stdstatus, null);
    }
  };

  return (
    <main>
      <UserHeader />
      {matchApprovals !== null && matchApprovals !== undefined && (
        <div style={{ width: "100%" }}>
          <div style={{ borderBottom: "1rem", borderColor: "black" }}>
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Waiting Approvals"
                {...a11yProps(0)}
                className={`${classes.selected} ${classes.root}`}
              />
              <Tab
                label="Approved"
                {...a11yProps(1)}
                className={`${classes.selected} ${classes.root}`}
              />
              <Tab
                label="Rejected"
                {...a11yProps(2)}
                className={`${classes.selected} ${classes.root}`}
              />
            </Tabs>
          </div>

          <TabPanel value={value} index={0}>
            {jobAppData !== undefined && (
              <>
                {!jobAppData?.find((job) => job.ap_status === 0) && (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      There is no Application data yet.
                    </h1>
                  </div>
                )}
                {jobAppData?.find((job) => job.ap_status === 0) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "20px",
                    }}
                  >
                    <Button
                      style={ButtonSecondary}
                      variant="outlined"
                      size="small"
                      onClick={() => appOrRejrHandle("approve", 2)}
                    >
                      Reject
                    </Button>
                    <Button
                      style={ButtonPrimary}
                      variant="contained"
                      size="small"
                      onClick={() => appOrRejrHandle("approve", 1)}
                    >
                      Approve
                    </Button>
                  </div>
                )}

                {jobAppData?.map(
                  (jApDt, index) =>
                    jApDt.ap_status === 0 && (
                      <div
                        style={{
                          boxShadow: Theme.boxShadow,
                          padding: "25px",
                          marginTop: "20px",
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          style={{ height: "90px", width: "90px" }}
                          alt="name"
                        />

                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                            width: "34%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.std_name}
                            </h3>
                            {jApDt.pname && (
                              <FiberManualRecordIcon
                                style={{ height: "10px", width: "10px" }}
                              />
                            )}
                            <p>{jApDt.pname}</p>
                          </div>
                          {jApDt.grade && (
                            <div style={{ display: "flex", gap: "10px" }}>
                              <p>Grade :</p>
                              <h3 style={{ fontWeight: "bold" }}>
                                {jApDt.grade}/10
                              </h3>
                            </div>
                          )}
                          <p>Headline- {jApDt.head_line}</p>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Work experience: </p>
                            <h3 style={{ fontWeight: "bold" }}>1yr 6m</h3>
                          </div>
                        </div>
                        <div style={{ borderLeft: "2px solid #000" }} />
                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                          }}
                        >
                          <h3 style={{ fontWeight: "bold" }}>{jApDt.title}</h3>
                          <h3 style={{ fontWeight: "bold" }}>{jApDt.cname}</h3>
                          <p>
                            <i>{jApDt.etype}</i> <i>{jApDt.lname}</i>{" "}
                          </p>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Required work experience: </p>
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.reqexp} yrs
                            </h3>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "15px",
                            marginLeft: "14px",
                            gap: "15px",
                          }}
                        >
                          <Button
                            style={ButtonTernary}
                            variant="outlined"
                            onClick={() => {
                              navigate("application", { state: jApDt });
                            }}
                          >
                            View Application
                          </Button>
                          <div
                            className={classes.root}
                            style={{
                              background: jApDt.checked
                                ? `${Theme.color.primary}`
                                : "",
                              padding: "3px 16px 3px 9px",
                              borderRadius: "4px",
                              border: checked
                                ? `2px solid ${Theme.color.primary}`
                                : `2px solid ${Theme.color.primary}`,
                              color: jApDt.checked
                                ? Theme.color.white
                                : Theme.color.primary,
                              fontWeight: "600",
                            }}
                          >
                            <Checkbox
                              {...label}
                              checked={jApDt.checked ? true : false}
                              onChange={(event) =>
                                handleChange(event, index, "ap")
                              }
                              className={classes.iconButton}
                              style={{
                                color: jApDt.checked
                                  ? Theme.color.white
                                  : Theme.color.primary,
                              }}
                            />
                            <span style={{ marginLeft: "8px" }}>
                              {jApDt.checked ? "Selected" : "Select"}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {jobAppData !== undefined && (
              <>
                {!jobAppData?.find((job) => job.ap_status === 1) && (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      There is no Approved data yet.
                    </h1>
                  </div>
                )}
                {jobAppData?.map(
                  (jApDt, index) =>
                    jApDt.ap_status === 1 && (
                      <div
                        style={{
                          boxShadow: Theme.boxShadow,
                          padding: "25px",
                          marginTop: "20px",
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          style={{ height: "90px", width: "90px" }}
                          alt="name"
                        />

                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                            width: "34%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.std_name}
                            </h3>
                            <FiberManualRecordIcon
                              style={{ height: "10px", width: "10px" }}
                            />
                            <p>{jApDt.pname}</p>
                          </div>
                          {jApDt.grade && (
                            <div style={{ display: "flex", gap: "10px" }}>
                              <p>Grade :</p>
                              <h3 style={{ fontWeight: "bold" }}>
                                {jApDt.grade}/10
                              </h3>
                            </div>
                          )}
                          <p>Headline- {jApDt.head_line}</p>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Work experience: </p>
                            <h3 style={{ fontWeight: "bold" }}>1yr 6m</h3>
                          </div>
                        </div>
                        <div style={{ borderLeft: "2px solid #000" }} />
                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                          }}
                        >
                          <h3 style={{ fontWeight: "bold" }}>{jApDt.title}</h3>
                          <h3 style={{ fontWeight: "bold" }}>{jApDt.cname}</h3>
                          <p>
                            <i>{jApDt.etype}</i> <i>{jApDt.lname}</i>{" "}
                          </p>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Required work experience: </p>
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.reqexp} yrs
                            </h3>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "15px",
                            marginLeft: "14px",
                            gap: "15px",
                          }}
                        >
                          <Button
                            style={ButtonTernary}
                            variant="outlined"
                            onClick={() => {
                              navigate("application", { state: jApDt });
                            }}
                          >
                            View Application
                          </Button>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {jobAppData !== undefined && (
              <>
                {jobAppData?.find((job) => job.ap_status === 2) ? (
                  ""
                ) : (
                  <div
                    style={{
                      height: "70vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1 style={{ fontWeight: "bold" }}>
                      There is no rejected data yet.
                    </h1>
                  </div>
                )}
                {jobAppData?.map(
                  (jApDt, index) =>
                    jApDt.ap_status === 2 && (
                      <div
                        style={{
                          boxShadow: Theme.boxShadow,
                          padding: "25px",
                          marginTop: "20px",
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        <Avatar
                          style={{ height: "90px", width: "90px" }}
                          alt="name"
                        />

                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                            width: "34%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "1rem",
                            }}
                          >
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.std_name}
                            </h3>
                            <FiberManualRecordIcon
                              style={{ height: "10px", width: "10px" }}
                            />
                            <p>{jApDt.pname}</p>
                          </div>
                          {jApDt.grade && (
                            <div style={{ display: "flex", gap: "10px" }}>
                              <p>Grade :</p>
                              <h3 style={{ fontWeight: "bold" }}>
                                {jApDt.grade}/10
                              </h3>
                            </div>
                          )}
                          <p>Headline- {jApDt.head_line}</p>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Work experience: </p>
                            <h3 style={{ fontWeight: "bold" }}>1yr 6m</h3>
                          </div>
                        </div>
                        <div style={{ borderLeft: "2px solid #000" }} />
                        <div
                          style={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            fontSize: "15px",
                            marginLeft: "14px",
                          }}
                        >
                          <h3 style={{ fontWeight: "bold" }}>{jApDt.title}</h3>
                          <h3 style={{ fontWeight: "bold" }}>{jApDt.cname}</h3>
                          <p>
                            <i>{jApDt.etype}</i> <i>{jApDt.lname}</i>{" "}
                          </p>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <p>Required work experience: </p>
                            <h3 style={{ fontWeight: "bold" }}>
                              {jApDt.reqexp} yrs
                            </h3>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "15px",
                            marginLeft: "14px",
                            gap: "15px",
                          }}
                        >
                          <Button
                            style={ButtonTernary}
                            variant="outlined"
                            onClick={() => {
                              navigate("application", { state: jApDt });
                            }}
                          >
                            View Application
                          </Button>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </TabPanel>
          <Backdrop
            open={aploading}
            style={{ zIndex: "1500", backgroundColor: "rgba(0, 0, 0, 0.75)" }}
          >
            <CircularProgress style={{ color: Theme.color.secondary }} />
          </Backdrop>
        </div>
      )}
      <Outlet />
    </main>
  );
};

export default JobApplicationApprovals;
