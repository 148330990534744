// Dependencies imports
import React, { useEffect, useState } from "react";

import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { API } from "aws-amplify";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import CreateUser from "./CreateUser";
import UserBulkUpload from "./UserBulkUpload";
import UserBulkRoleChange from "./UserBulkRoleChange";
import EditUser from "./EditUser";
import config from "../../config/aws-exports";
import UserHeader from "../Header/UserHeader/UserHeader";
import { Constants } from "../../config/constants";
import DeletePlatUser from "./DeletePlatUser";
import { ReactComponent as Delete } from "../../assets/svg/delete_black_24dp.svg";
import { ReactComponent as Edit } from "../../assets/svg/edit_black_24dp.svg";
import RegistrationDtls from '../Users/RegistrationDtls'
// Styles imports
import Use from "./User.module.scss";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    '& .MuiDataGrid-sortIcon': {
      // Add your custom styles for the sort icon here
      color: 'white', // Example: Change the color to red
      fontSize: '20px', // Example: Change the font size
    },
  },
});

function Users() {

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const [openBulkRole, setOpenBulkRole] = useState(false);

  const [editUserData, setEditUserData] = useState(false);
  const [cmtOpen, setCmtOpen] = useState(false);
  const [regiDtls, setRegiDtls] = useState();
  const [hData, setHdata] = useState();
  const [vType, setVtype] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleCellClick = (param) => {

    if (param.field == "edit") {
      setOpenEdit(true);
      setEditUserData(param.row);
    } else {
      handleDeleteOpen(true);
      setEditUserData(param.row);
    }
  };

  useEffect(() => {
    getUsersDetails();
  }, []);

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };
  
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };
  const handleBulkOpen = () => {
    setOpenBulk(true);
  };

  const handleBulkClose = () => {
    setOpenBulk(false);
  };

  const handleBulkRoleOpen = () => {
    setOpenBulkRole(true);
  };

  const handleBulkRoleClose = () => {
    setOpenBulkRole(false);
  };
  async function getUsersDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      const users = response;
      let trows = [];

      for (let i = 0; i < users.body.length; i++) {
        let obj = {};
        obj.id = i;

        obj.col1 = users.body[i].first_name;
        obj.col2 = users.body[i].last_name;
        obj.col3 = users.body[i].emailid;
        obj.col4 = users.body[i].ur;
        obj.col5 = users.body[i].cno;
        obj.col14 = users.body[i].ctype == undefined ? '' : users.body[i].ctype == 'bcls' || users.body[i].ctype == 'BCLS' ? 'BCLS' : users.body[i].ctype == 'ecls' || users.body[i].ctype == 'ECLS' ? 'ECLS' : 'ENLS';

        obj.col6 = users.body[i].gen;
        obj.col7 = users.body[i].address;

        obj.col8 = users.body[i].ur_id;
        obj.col9 = users.body[i].eid;
        obj.col10 = users.body[i].rno;
        obj.role_toggle = users.body[i].role_toggle == true ? 'Yes' : 'No';
        obj.col11 = (users.body[i].ur == 'User' || users.body[i].ur == 'user') ? users.body[i].regi_dtls : users.body[i].hdtls;
        obj.col12 = users.body[i].enable;
        if ((users.body[i].ur == 'User' || users.body[i].ur == 'user')) {
          trows.push(obj);
        }
      }

      const tcolumns = [
        {
          field: "col1",
          headerName: "FirstName",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <p onClick={() => handleRowClick(params)}>{params.value}</p>
            )
          }
        },
        {
          field: "col2",
          headerName: "LastName",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <p onClick={() => handleRowClick(params)}>{params.value}</p>
            )
          }
        },
        {
          field: "col3",
          headerName: "Email",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <p onClick={() => handleRowClick(params)}>{params.value}</p>
            )
          }
        },
        {
          field: "col14",
          headerName: "Course",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => (
            <p onClick={() => handleRowClick()}>
              {params.value.toUpperCase()}
            </p>
          ),
        },
        {
          field: "col5",
          headerName: "Phone No",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <p onClick={() => handleRowClick(params)}>{params.value}</p>
            )
          }
        },
        {
          field: "col6",
          headerName: "Gender",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => (
            <p onClick={() => handleRowClick(params)}>
              {params.value === null ? '' : (params.value === 1 ? 'Male' : (params.value === 2 ? 'Female' : params.value === 3 ? 'Trans Gender': 'Others'))}
            </p>
          ),
        },
        {
          field: "col10",
          headerName: "Roll No",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <p onClick={() => handleRowClick(params)}>{params.value}</p>
            )
          }
        },
        {
          field: "edit",
          headerName: "Edit User",
          flex: 1,
          sortable: false,
          disableExport: true,
          disableClickEventBubbling: true,
          headerClassName: "super-app-theme--header",
          renderCell: (param) => {
            return (
              <Button
                size="small"

                startIcon={<Edit />}
                onClick={(event) => {
                  handleCellClick(param, event);
                }}
              ></Button>
            );
          },
        },
        {
          field: "delete",
          headerName: "Delete User",
          flex: 1,
          sortable: false,
          disableExport: true,
          disableClickEventBubbling: true,
          headerClassName: "super-app-theme--header",
          renderCell: (param) => {
            return (
              <Button
                size="small"

                startIcon={<Delete />}
                onClick={(event) => {
                  handleCellClick(param, event);
                }}
              ></Button>
            );
          },
        },
        {
          field: "role_toggle",
          headerName: "Role Changed",
          flex: 1,
          headerClassName: "super-app-theme--header",
          renderCell: (params) => {
            return (
              <p onClick={() => handleRowClick(params)}>{params.value}</p>
            )
          }
        },
      ];
      setRows(trows);

      setColumns(tcolumns);
      setLoading(false);

    } catch (error) {

      console.log("getUserError", error);
    }
  }
  const handleRowClick = (params) => {

    if (params && params.row !== undefined
      && params.row?.col4.toLowerCase() == "user") {
      setCmtOpen(true);
      setVtype(1);
      if (params.row.col11 == undefined
        || params.row.col11 == null) {
        var array = [];
        var obj = {};
        obj["First Name"] = params.row.col1;
        obj["Last Name"] = params.row.col2;
        obj["Email"] = params.row.col3;
        obj["Contact"] = params.row.col5;
        array.push(obj);
        setRegiDtls(array);
      } else {
        setRegiDtls(params.row.col11);
      }
    } else if (params && params.row !== undefined
      && (params.row?.col4.toLowerCase() == "instructor" || params.row?.col4.toLowerCase() == "observer" ||
        params.row?.col4.toLowerCase() == "cdirector")) {

      setCmtOpen(true);
      setVtype(2);

      if (params.row.col11 == undefined
        || params.row.col11 == null) {
        params.row.col11 = {};
        params.row.col11.firstName = params.row.col1;
        params.row.col11.lastName = params.row.col2;
        params.row.col11.phoneNo = params.row.col5;

        setHdata(params.row.col11);
      } else {
        params.row.col11.firstName = params.row.col1;
        params.row.col11.lastName = params.row.col2;
        setHdata(params.row.col11);
      }
    }
  };
  return (


    <div className={Use.maincontainer}>
      <UserHeader />
      <div className={Use.holder}>
        <h3 className={Use.header}>Manage Users</h3>
        <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
        <button
            onClick={handleBulkRoleOpen}
            className={
              window.navigator.onLine === true
                ? Use.btn_color
                : Use.btn_colordis
            }
            disabled={!window.navigator.onLine}
          >
            Role Change
          </button>
          <button
            onClick={handleBulkOpen}
            className={
              window.navigator.onLine === true
                ? Use.btn_color
                : Use.btn_colordis
            }
            disabled={!window.navigator.onLine}
          >
            Bulk Upload
          </button>
          <button
            onClick={handleClickOpen}
            className={
              window.navigator.onLine === true
                ? Use.btn_color
                : Use.btn_colordis
            }
            disabled={!window.navigator.onLine}
          >
            Create User
          </button>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulk}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBulk}>
          <UserBulkUpload handleClose={handleBulkClose} type="user" />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <CreateUser
            handleClose={handleClose}
            getUsersDetails={getUsersDetails}
            rows={rows}
            type={'user'}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <DeletePlatUser
            handleDeleteClose={handleDeleteClose}
            getUsersDetails={getUsersDetails}
            editUserData={editUserData}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <EditUser
            handleClose={handleCloseEdit}
            editUserData={editUserData}
            setEditUserData={setEditUserData}
            getUsersDetails={getUsersDetails}
            type={'user'}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openBulkRole}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBulkRole}>
          <UserBulkRoleChange handleClose={handleBulkRoleClose} type="role" />
        </Fade>
      </Modal>
      <div style={{ height: 600, width: "100%" }} className={classes.root}>
        {loading === false ? (
          <>
            <DataGrid
              rows={rows}
              columns={columns}
              className={classes[".MuiDataGrid-root"]}
              disableSelectionOnClick
              sx={{
                fontSize: '13px',
              }}
              components={{ Toolbar: CustomToolbar }}
              disableColumnMenu
            />
            <RegistrationDtls cmtOpen={cmtOpen} setCmtOpen={setCmtOpen} regiDtls={regiDtls} hData={hData} vType={vType} />
          </>

        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton variant="rect" width="100%" height={600} />{" "}
          </div>
        )}
      </div>
    </div>
  );
}
export default Users;
