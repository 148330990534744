import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Constants } from '../../config/constants';
import config from "../../config/aws-exports";


const ProgressChart = (props) => {
    let { progress } = props;
    progress = progress / 100;
    const data = {
        labels: ["Overall Progress"],
        datasets: [
            {
                label: 'Overall Progress',
                data: [progress],
                backgroundColor: config.main_color_1,
                borderWidth: 1,
                hoverOffset: 4,
                align: "start",
                offset: 3
            },
        ],
    };

    return (
        <>
            <div style={{ width: "100%", height: "100%" }}>
                <Doughnut data={data} width={130} height={100} options={{ maintainAspectRatio: false }} />
            </div>
        </>);
};

export default ProgressChart;