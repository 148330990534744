// Dependencies imports
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports
//import UserStatsGrid from "./UserStatsGrid";


// Styles imports
import Add from "./AddAnnouncement.module.scss";
import { authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const AddUser = (props) => {
  let { handleUserClose, getFaculty, userData, allUsers } = props;
  const [email, setEmail] = useState("");
  let userDetails = useSelector(authData);
  const [spin, setSpin] = useState(false);
  const [errmsg, setErrmsg] = useState("");
  const classes = useStyles();
console.log(allUsers);
  async function onsubmit() {
    if (
      email == "" ||
      email.replace(/\s/g, "").length === 0 ||
      email == undefined
    ) {
      setSpin(false);
      setErrmsg("*Please Enter email");
      return;
    } else if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        // formIsValid = false;
        setSpin(false);
        setErrmsg("*Please enter valid email-ID.");
        return;
      }
    } else {
      setErrmsg("");
    }
    setSpin(true);
    const userExists = allUsers?.some(user => user.emailid === email);
    if (!userExists) {
      swal({
        title: "warning",
        text: "User does not exist in platform!",
        icon: "warning",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);

        }
      })
      return;
    }
    if (userData.length == 40) {
      swal({
        title: "warning",
        text: "Slots are already full...!",
        icon: "warning",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      })
      return;
    };

    for (let i = 0; i < userData.length; i++) {

      if (email === userData[i].emailid) {
        swal({
          title: "warning",
          text: "User already exist",
          icon: "warning",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        })
        return;
      };

    }

    const bodyParam = {
      body: {
        emailid: email,
        role: 'User',
        atype: 1,
        batchid: userDetails.curprgcou.bid,
        batch: userDetails.curprgcou.bname,
        pid: userDetails.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER_TO_BATCH,
        bodyParam
      );
      console.log("ressponse", response);
      if (
        response.statusCode == 200
      ) {
        swal({
          title: "Success",
          text: "User Added Successfully",
          icon: "success",

          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            // handleUserClose();
            // getFaculty();
          }
        });
      }

      handleUserClose();
      setSpin(false);
      getFaculty();
    } catch (error) {
      console.error(error);
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Contact Developer");
          setSpin(false);
        }
      });
    }
  }

  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add User</h3>
          <p
            style={{
              color: "red",
              fontSize: "15px",
              alignItems: "center",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          >
            {errmsg}
          </p>
          <div
            style={{
              color: "#cc0000",
              marginBottom: "12px",
              marginTop: "-10px",
              textAlign: "start",
              marginLeft: "20%",
            }}
          ></div>
          <input
            type="email"
            placeholder="Email"
            className={Add.title}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className={Add.buttonholder}>
            <button className={Add.cancel} onClick={handleUserClose}>
              Cancel
            </button>
            <button
              className={
                window.navigator.onLine === true
                  ? Add.btn_color
                  : Add.btn_colordis
              }
              disabled={!window.navigator.onLine}
              onClick={() => {
                onsubmit();
              }}
            >
              Add
            </button>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default AddUser;
