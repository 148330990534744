// Dependencies imports
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Local imports
import config from "../../config/aws-exports";
// Styles imports
import Add from './AddAnnouncement.module.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        heigth: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "transparent"
    },
}));

const AddUser = (props) => {
    const classes = useStyles();

    let { handleClose, onSubmit, spin } = props;


    return (
        <div>
            {spin === false ?
                <div className={Add.mainpopup}>
                    <h3 className={Add.header}>Are you sure?</h3>
                    <div className={Add.buttonholder}>
                        <button className={Add.cancel} onClick={handleClose}>NO</button>
                        <button className={window.navigator.onLine === true ? Add.btn_color : Add.btn_colordis } disabled={!window.navigator.onLine} onClick={onSubmit} >Yes! I am sure</button>

                    </div>
                </div> : <div className={classes.root}>
                    <CircularProgress style={{ color: config.main_color_1 }} /></div>}
        </div>
    );
};

export default AddUser;