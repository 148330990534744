// Dependencies imports
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import AssesmentDetailsGrid from "./AssesmentDetailsGrid";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Grade from "./GradingDetails.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "600px",
    color: config.main_color_1,
    fontFamily: "nunito",
  },
  heading1: {
    fontSize: "18px",
    fontWeight: "600px",
    color: config.main_color_1,
    fontFamily: "nunito",
    paddingTop: "10px",
    paddingLeft: "10px",
    textAlign: "center",
  },
  dropoption: {
    fontSize: "16px",
    color: "#6a6a6a",
    fontWeight: "500",
    fontFamily: "nunito",
    padding: "10px 0px",
  },
}));

// Start of Assesment Details Screen
const AssesmentDetails = () => {
  const [course, setCourse] = useState([]);
  const [index, setIndex] = useState();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const [quizId, setQuizId] = useState("");
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    getassesment();
    breadcrumb();
  }, []);

  async function getassesment() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,

        bpid: userDetails.curprgcou.bpid, // these thre are same
        assessid: userDetails.asses.btid,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ELGETASSESSMENTREPORT,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      if (response.assessment !== undefined) {
        let courses = response.assessment;
        setLoading(false);
        setCourse(courses);
      } else {
        setLoading(false);
        setCourse([]);
      }
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Oooops!", "Contact developer");
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  const onClick = (data) => {
    console.log("ss" + JSON.stringify(data));
    setQuizId(data.assessid);
    convertRowData(data);
  };
  function convertRowData(currentData) {
    let udata = currentData.userlist;
    let rows = [];
    if (udata !== undefined) {
      for (let i = 0; i < udata.length; i++) {
        let obj = {};
        obj.id = i;
        obj.name = udata[i].ename;
        obj.eid = udata[i].eid;

        if (udata[i].score !== undefined) {
          if (udata[i].score == -1) {
            obj.score = "Not Graded";
          } else {
            var score = udata[i].score.split("/");

            obj.score =
              currentData.totalscore != undefined
                ? score[0] + "/" + currentData.totalscore
                : score[0];
            obj.qscore = score[0];
          }
        } else {
          obj.score = "Not Submitted";
        }

        rows.push(obj);
      }
    }
    setRows(rows);
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Assesment Details",
      path: "/assesmentdetails",
    };
    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={Grade.maincontainer}>
      <UserHeader Bindex={3} />
      <h3 className={Grade.header}>Assesment Details</h3>
      {loading === false ? (
        <div className={Grade.middlescreen}>
          <div className={Grade.leftscreen}>
            <Paper>
              {course.length !== 0 ? (
                <div className={classes.root}>
                  {course.map((obj, i) => (
                    <h3
                      className={
                        i === index ? Grade.headingactive : Grade.heading
                      }
                      onClick={() => {
                        onClick(obj);
                        setIndex(i);
                      }}
                    >
                      {obj.title}
                    </h3>
                  ))}
                </div>
              ) : (
                <div className={classes.root}>
                  <h3 className={classes.heading1}>No Data</h3>
                </div>
              )}
            </Paper>
          </div>

          <div className={Grade.rightscreen}>
            <Paper>
              <AssesmentDetailsGrid rows={rows} quizId={quizId} />
            </Paper>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingTop: "20px",
            width: "100%",
            gap: "10px",
          }}
        >
          <Skeleton
            variant="rect"
            width="40%"
            height={600}
            style={{ borderRadius: "5px" }}
          />{" "}
          <Skeleton
            variant="rect"
            width="58%"
            height={400}
            style={{ borderRadius: "5px" }}
          />
        </div>
      )}
    </div>
  );
};

export default AssesmentDetails;
