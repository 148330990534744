// Dependencies imports
import React, { useEffect, useState } from "react"; import {
  Checkbox,
  makeStyles,
  CircularProgress,
  ListItemText,
  TextField,
} from '@material-ui/core';

import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";
import { authData } from "../../redux/auth/authSlice";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import swal from "sweetalert";
// Local imports
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// Styles imports
import Add from "./AddAnnouncement.module.scss";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  program: {
    fontSize: "16px",
    color: "black",
    fontWeight: "500",
    paddingLeft: "5px",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },

}));

const AddInstructor = (props) => {
  let { handleClose, getFaculty, facultyData, Instructors,getUsersDetails } = props;
  const classes = useStyles();
  const [personName, setPersonName] = useState([]);
  const [courseErr, setCourseErr] = useState("");
  const [spin, setSpin] = useState(false);
  const [filtered, setFiltered] = useState([]);
  let userDetails = useSelector(authData);

  useEffect(() => {

    var arrray = [];
    if (facultyData != undefined && facultyData.length > 0) {
      for (let i = 0; i < facultyData.length; i++) {
        if (facultyData[i] !== null) {
          arrray.push(facultyData[i].email);
        }
      }
    }
    setPersonName(arrray);
    
    const filteredInstructors = Instructors?.filter(instructor => {
      return !(instructor?.col15?.some(role => role.batchid === userDetails?.curprgcou?.bpid));
    });
    
    setFiltered(filteredInstructors);
  }, []);

  const onsubmit = () => {
    let formIsValid = true;
    setCourseErr("");

    if (personName.length === 0) {
      formIsValid = false;
      setCourseErr("*Please select instructors from list");
      return;
    }

    if (formIsValid === true) {
      addInstructor();
    }
  };

  const handleChange = (event, newValue) => {
    let selectArray = newValue;
    let temparr = [...personName];

    for (let k = 0; k < selectArray.length; k++) {
      const col3Value = selectArray[k].col3;
      const index = temparr.indexOf(col3Value);
     
      if (col3Value != undefined) {
        if (index !== -1) {
          temparr.splice(index, 1);
        } else {
          temparr.push(col3Value);
        }
      }
    }
    setPersonName(temparr);
  };

  async function addInstructor() {

    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        batchid: userDetails.curprgcou.bpid,
        emailid: personName,
        batch: userDetails.curprgcou.bname,
        bpid: userDetails.curprgcou.bpid,
        atype: 1,
        role: "Instructor"

      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER_TO_BATCH,
        bodyParam

      );

      handleClose();
      if (response.statusCode === "200" || response.statusCode === 200) {
        swal({
          title: "Success",
          text: "Instructor added Successfully to the Batch",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();
            setSpin(false);
          }
        });
      } else if (response.statusCode === "0" || response.code === 0) {
        swal({
          title: "Oooops!",
          text: "Instructor already exist in the batch!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else if (response.statusCode === "300" || response.statusCode === 300) {
        swal({
          title: "Success",
          text: "Instructor successfully removed from the batch!",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            handleClose();
            setSpin(false);
          }
        });
      } else if (response.statusCode === "5" || response.statusCode === 5) {
        swal({
          title: "Oooops!",
          text: "This user is not an Instructor!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else {
        swal({
          title: "Oooops!",
          text: "Instructor does not exist!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      }
      setSpin(false);
      getFaculty();
      getUsersDetails();
    } catch (error) {
      setSpin(false);
      swal({
        title: "Oooops!",
        text: "Somthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
          swal({
            title: "Contact Developer",
          });
        }
      });
      setSpin(false);
    }
  }

  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Add Instructor</h3>
          <div className={Add.multiselect}>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
            >
              {courseErr}
            </div>
            <Autocomplete
              multiple
              options={filtered}
              value={personName}
              onChange={handleChange}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={personName?.indexOf(option.col3) > -1} />
                  <ListItemText>
                    <p style={{ fontSize: '15px' }}>{option.col3}</p>
                  </ListItemText>
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select"
                  placeholder="Search..."
                  style={{ fontSize: '15px' }}
                />
              )}
              filterOptions={(options, { inputValue }) =>
                options.filter(
                  (option) =>
                    option.col3.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              sx={{
                '.MuiAutocomplete-tag': {
                  marginRight: '8px',
                  fontSize: '15px',// Adjust the spacing as needed
                },
                '.MuiFormControl-fullWidth': {
                  height: '200px',
                  overflow: 'auto',
                },
                '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
                  fontSize: '12px',
                },
                '.css-6od3lo-MuiChip-label': {
                  overflow: 'unset'
                }
              }}
            />
          </div>

          <div className={Add.buttonholder}>
            <button className={Add.cancel} onClick={handleClose}>
              Cancel
            </button>
            <button className={Add.submit} onClick={onsubmit}>
              Add
            </button>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default AddInstructor;
