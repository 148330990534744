// Dependencies imports
import React, { useEffect, useState } from "react";
import { Tab } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import InstructorGrid from "./InstructorGrid";
import CoordinatorGrid from "./CoordinatorGrid";
import config from "../../config/aws-exports";

// Styles imports
import Fac from "./Faculty.module.scss";
import { Constants } from "../../config/constants";
import { API } from "aws-amplify";


const Faculty = () => {
  const dispatch = useDispatch();
  const [instructors, setInstructors] = useState(true);
  const [directors, setDirectors] = useState(true);
  
  // Data for tabs
  let userDetails = useSelector(authData);

  useEffect(() => {
    breadcrumb();
    getUsersDetails();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Faculty",
      path: "/Faculty",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  async function getUsersDetails() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_USERS,
        bodyParam
      );
      const users = response;
      let trows = [];
      let irows = [];

      for (let i = 0; i < users.body.length; i++) {
        let obj = {};
        obj.id = i;

        obj.col1 = users.body[i].first_name;
        obj.col2 = users.body[i].last_name;
        obj.col3 = users.body[i].emailid;
        obj.col4 = users.body[i].ur;
        obj.col5 = users.body[i].cno;
        obj.col14 = users.body[i].ctype == undefined ? '' : users.body[i].ctype == 'bcls' ? 'BCLS' : users.body[i].ctype == 'ecls' ? 'ECLS' : 'ENLS';
        obj.col15 = users.body[i].roles;
        obj.col6 = users.body[i].gen;
        obj.col7 = users.body[i].address;

        obj.col8 = users.body[i].ur_id;
        obj.col9 = users.body[i].eid;
        obj.col10 = users.body[i].rno;

        obj.col11 = (users.body[i].ur == 'User' || users.body[i].ur == 'user') ? users.body[i].regi_dtls : users.body[i].hdtls;
        obj.col12 = users.body[i].enable;
       
        if (!(users.body[i].ur == 'User' || users.body[i].ur == 'user') || users.body[i].role_toggle) {
          trows.push(obj);
        }
        if ((users.body[i].ur == 'instructor' || users.body[i].ur == 'Instructor') || users.body[i].role_toggle) {
          irows.push(obj);
        }
      }
      setDirectors(trows);
      setInstructors(irows);
    } catch (error) {

      console.log("getUserError", error);
    }
  }

  const panes = [
    {
      menuItem: " Course Director",
      render: () => (
        <Tab.Pane>
          <CoordinatorGrid Instructors={directors} getUsersDetails={getUsersDetails}/>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Instructor",
      render: () => (
        <Tab.Pane active={true}>
          <InstructorGrid Instructors={instructors} getUsersDetails={getUsersDetails} />
        </Tab.Pane>
      ),
    },
  ];

  const isAdmin = userDetails?.role === 'Admin';

  const filteredPanes = isAdmin ? panes : [panes[1]];

  return (
    <div className={Fac.maindiv}>
      <UserHeader Bindex={2} />
      <h3 className={Fac.header}>Faculty</h3>

      <Tab panes={filteredPanes} />

    </div>
  );
};

export default Faculty;
