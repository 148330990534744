import React, { useState } from "react";
import ApplicationDetails from "../components/Programs/ApplicationDetails";

import Aside from "../modules/SideNav/Aside";

function AllProgramsInfoLayout() {
  const rtl = false;

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <>
      {/* <Aside
                rtl={rtl}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
            /> */}
      <ApplicationDetails handleToggleSidebar={handleToggleSidebar} />
    </>
  );
}

export default AllProgramsInfoLayout;
