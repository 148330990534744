// Dependencies imports
import React, { useEffect, useState, createContext } from "react";
import { Tab } from "semantic-ui-react";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn, awsSignOut } from "../../redux/auth/authSlice";
// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
// Styles imports
import Fac from "../Programs/Faculty.module.scss";
import AddInstructorFeedBack from "./AddInstructorFeedBack";
import AddObserverFeedBack from "./AddObserverFeedBack";
import AddCdirectorFeedBack from "./AddCdirFeedBack";
import feedbackContext from './CreateContext';


// Start of CreateFeedback Component
const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const CreateFeedback = () => {
  const dispatch = useDispatch();
  const [floading, setFloading] = useState(true);
  const [infeedback, setInFeedback] = useState([]);
  const [obfeedback, setObFeedback] = useState([]);
  const [Cdfeedback, setCdFeedback] = useState([]);
  const [feedback, setFeedback] = useState();
  let obj = {fd: feedback, sfd: setFeedback };
  const classes = useStyles();
  
  // Data for tabs
  let userDetails = useSelector(authData);

  useEffect(() => {
    breadcrumb();
    getCourseList();
  }, []);

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[2] = {
      name: "Feedback",
      path: "/CreateFeedback",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }
  async function getCourseList() {
    const bodyParam = {
      body: {
        action: 1
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
   
    try {
     
      const Flist = await API.post(
        config.aws_cloud_logic_custom_name,
        '/getFeedbackQuestions',
        bodyParam
      );
      setFeedback(Flist);
      setInFeedback(Flist?.ifdetails);
      setObFeedback(Flist?.ofdetails);
      setCdFeedback(Flist?.cdfdetails);
      setFloading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const panes = [
    {
      menuItem: " Instructor Feedback",
      render: () => (
        <Tab.Pane>
          {!floading && <AddInstructorFeedBack infeedback={infeedback}  
          floading={floading} setFloading={setFloading} getCourseList={getCourseList}/>}
        </Tab.Pane>
      ),
    },
    {
        menuItem: "Observer Feedback",
        render: () => (
          <Tab.Pane>
           {!floading && <AddObserverFeedBack obfeedback={obfeedback} 
           floading={floading} setFloading={setFloading} getCourseList={getCourseList}/>}
          </Tab.Pane>
        ),
      },
    {
        menuItem: " Course Director Feedback",
        render: () => (
          <Tab.Pane>
           {!floading && <AddCdirectorFeedBack Cdfeedback={Cdfeedback} 
           floading={floading} setFloading={setFloading} getCourseList={getCourseList}/>}
          </Tab.Pane>
        ),
      },
  ];

  return (
    <div className={Fac.maindiv}>
      <UserHeader Bindex={2} />
    
      <feedbackContext.Provider value={obj}>
         <Tab panes={panes} />
      </feedbackContext.Provider>
    </div>
  );
};

export default CreateFeedback;
