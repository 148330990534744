// Dependencies imports
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import { produce } from "immer";
import moment from "moment";

// Local imports

// Styles imports
import Add from "./AddEvent.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const AddEvents = (props) => {
  let { eventData, handleClose, seteditsession, addtype } = props;
  const [state, setState] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [evnentName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventId, setEventId] = useState("");
  const [eventDateErr, setEventDateErr] = useState("");
  const [eventNameErr, setEventNameErr] = useState("");
  const [eventLinkErr, setEventLinkErr] = useState("");
  const [spin, setSpin] = useState(false);
  const classes = useStyles();

  let userDetails = useSelector(authData);
  useEffect(() => {
    let cursession = { ...seteditsession };
    if (addtype == "update") {
      setEventName(cursession.title);
      setEventId(cursession.eid);
      setEventDate(moment(cursession.start).format("YYYY-MM-DD"));
      setEventTime(moment(cursession.start).format("HH:mm"));
      setEventLink(cursession.link);
    }
  }, []);
  const handleChange = (event) => {
    setState(state === false ? true : false);
  };
  const onChangeHandlerEventName = (event) => {
    setEventName(event.target.value);
  };
  const onChangeHandlerEventDate = (event) => {
    setEventDate(event.target.value);
  };
  const onChangeHandlerEventTime = (event) => {
    setEventTime(event.target.value);
  };
  const onChangeHandlerEventLink = (event) => {
    setEventLink(event.target.value);
  };
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  const onsubmit = () => {
    // setname(name)

    let formIsValid = true;

    setEventDateErr("");
    setEventNameErr("");
    setEventLinkErr("");

    if (eventDate === "" || eventTime === "") {
      formIsValid = false;
      setEventDateErr("Please Enter Date & Time");
      return;
    }

    if (evnentName.trim() === "") {
      formIsValid = false;
      setEventNameErr("*Please Enter Event description");
      return;
    }

    if (evnentName.trim() != "" && evnentName.trim().length > 160) {
      formIsValid = false;
      setEventNameErr(
        "*Please Enter Event description length less than 160 character"
      );
      return;
    }

    if (!validURL(eventLink)) {
      formIsValid = false;
      setEventLinkErr("Please enter right URL format");
      return;
    }
    if (formIsValid === true) {
      addEvent();
    }
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  async function addEvent() {
    setSpin(true);

    let timestamp = new Date().getTime();

    let UTCDate = new Date((eventDate + " " + eventTime).split(" ").join("T"));
    UTCDate = UTCDate.getTime();

    const bodyParam = {
      body: {
        annjson: {
          eid: addtype == "update" ? eventId : timestamp,
          start: UTCDate,
          title: evnentName,
          link: eventLink,
          type: "event",
          bpid: userDetails.curprgcou.bpid,
        },
        rtype: addtype,
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        action: "events",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      handleClose();
      // const users = response;
      swal({
        title: "Success",
        text: "Event Added successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload();
          handleClose();
        }
      });
      setSpin(false);
      //getEvents();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  return (
    <div>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Add.mainpopup}>
        <div className={Add.form}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className={Add.fieldholder}>
              <div clasName={Add.labelholder}>
                <h3 className={Add.label}>Event Date & TIme</h3>
              </div>
              <div className={Add.inputholder}>
                <div style={{ textAlign: "left" }}>
                  <p className="firstViewValidation">{eventDateErr}</p>
                </div>
                <div style={{ display: "flex", gap: "3rem" }}>
                  <div>
                    {" "}
                    <input
                      type="date"
                      style={{ padding: "0px 0 0px 0" }}
                      className={Add.date}
                      onChange={onChangeHandlerEventDate}
                      value={eventDate}
                      min={disablePastDate()}
                    />{" "}
                  </div>
                  <div>
                    <input
                      type="time"
                      style={{ padding: "0px 0 0px 0" }}
                      className={Add.date}
                      onChange={onChangeHandlerEventTime}
                      value={eventTime}
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className={Add.fieldholder}>
              <div className={Add.labelholder}>
                <h3 className={Add.label}>Event Description</h3>
              </div>
              <div className={Add.inputholder}>
                <div style={{ textAlign: "left" }}>
                  <p className="firstViewValidation">{eventNameErr}</p>
                </div>
                <textarea
                  placeholder="Type your event here..."
                  maxlength="160"
                  rows={5}
                  className={Add.area}
                  onChange={onChangeHandlerEventName}
                  value={evnentName}
                ></textarea>
              </div>
            </div>
            <div className={Add.fieldholder}>
              <div className={Add.labelholder}></div>
              <div className={Add.inputholder}>
                <h4 className={Add.caution}>
                  * Event description should be less than 160 characters.
                </h4>
              </div>
            </div>
            <div className={Add.fieldholder}>
              <div clasName={Add.labelholder}>
                <h3 className={Add.label}>Event Link</h3>
              </div>
              <div className={Add.inputholder}>
                <div style={{ textAlign: "left" }}>
                  <p className="firstViewValidation">{eventLinkErr}</p>
                </div>
                <input
                  type="text"
                  placeholder={"Add your link"}
                  className={Add.date}
                  onChange={onChangeHandlerEventLink}
                  value={eventLink}
                ></input>
              </div>
            </div>
            <div className={Add.buttonholder}>
              <button className={Add.cancel} onClick={handleClose}>
                Cancel
              </button>
              <button
                className={
                  window.navigator.onLine === true
                    ? Add.btn_color
                    : Add.btn_colordis
                }
                disabled={!window.navigator.onLine}
                onClick={onsubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddEvents;
