// Dependencies imports
import React, { useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports

// Styles imports
import Add from "./AddEvent.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const DeleteEvents = (props) => {
  let { index, handleClose, seteditsession, addtype } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  let userDetails = useSelector(authData);

  const onsubmit = () => {
    addEvent();
  };

  async function addEvent() {
    setSpin(true);
    const bodyParam = {
      body: {
        rtype: "delete",
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        action: "events",
        index: index,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      handleClose();
      swal({
        title: "Success",
        text: "Event Deleted successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleClose();
        }
      });
      setSpin(false);
      //getEvents();
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  return (
    <div>
      {spin === false ? (
        <div className={Add.mainpopup}>
          <h3 className={Add.header}>Are you sure want to delete?</h3>
          <div className={Add.buttonholder}>
            <button className={Add.cancel} onClick={handleClose}>
              NO
            </button>
            <button
              className={
                window.navigator.onLine === true
                  ? Add.btn_color
                  : Add.btn_colordis
              }
              disabled={!window.navigator.onLine}
              onClick={onsubmit}
            >
              Yes
            </button>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default DeleteEvents;
