import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import config from "../../config/aws-exports";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { API } from "aws-amplify";
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useRef, useState } from 'react';
import fdcss from "./Feedback.module.scss";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import feedbackContext from './CreateContext';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function QuestionsList(props) {

  const { qlist, htype, qOpen, setQopen, floading, setFloading, getCourseList } = props;
  const refValue = useRef();
  const [error, setError] = useState(false);
  const refiidValue = useRef();

  const handleDClose = () => {
    setQopen(false);
  }
  async function saveData(atype, val) {
    setFloading(true);
    const bodyParam = {
      body: {
        utype: htype,
        pristem: refValue.current,
        atype
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (val !== undefined) {
      bodyParam.body.uiid = val.iid;
    }

    try {

      const Flist = await API.post(
        config.aws_cloud_logic_custom_name,
        '/updateFbQuestions',
        bodyParam
      );
      console.log(Flist, "refValue", bodyParam.body);
      setFloading(false);
      getCourseList();
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ width: '100%',display:'unset' }}>
        <Grid item xs={12} md={6} maxWidth={{maxWidth:'100% !important'}}>
          {/* <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {htype}
          </Typography> */}
          <Demo>
            <List sx={{ width: '100%' }}>
              {qlist.map((value, index) => (
                <>
                  <ListItem
                    secondaryAction={
                      <>
                        <IconButton edge="end" aria-label="edit" onClick={() => {
                          setQopen(true);
                          refValue.current = value.istem;
                          refiidValue.current = value.iid;
                        }}>
                          <EditIcon />
                        </IconButton>
                      </>
                    } style={{ border: '1px solid #DDD', borderRadius: '5px', marginBottom:'5px' }}
                  >
                    <ListItemText 
                      primary={(index + 1) + ". " + value.istem}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: '16px',
                        },
                      }}
                    />
                  </ListItem>
                </>
              )
              )}
            </List>
          </Demo>
        </Grid>
      </Grid>
      <Dialog
        open={qOpen}
        fullWidth="true"
        maxWidth="sm"
        onClose={handleDClose}
        aria-labelledby="form-dialog-title"
        onBackdropClick="false"
      >

        <DialogContent>
          <div className={fdcss.inputholder}>
            {error && <p style={{ color: 'red', width: '100%' }}> Question is required.</p>}
            <TextareaAutosize
              className={fdcss.inputbox}
              aria-label="maximum height"
              minRows={3}
              defaultValue={refValue.current}
              placeholder="Question"
              style={{ resize: 'none' }}
              onChange={(event) => {
                const { value } = event.target;
                refValue.current = value;
              }}
            />

          </div>
        </DialogContent>
        <div >
          <DialogActions>
            <button onClick={handleDClose} className={fdcss.cancel} >
              Cancel
            </button>
            <button
              onClick={() => {
                if (refValue.current !== undefined && refValue.current !== null && refiidValue.current === undefined) {
                  setError(false);
                  saveData(0);
                } else {
                  setError(true);
                }
                if (refValue.current !== undefined && refValue.current !== null && refiidValue.current !== undefined) {
                  var obj = {};
                  obj.iid = refiidValue.current;
                  setError(false);
                  saveData(1, obj);
                } else {
                  setError(true);
                }
              }} className={fdcss.save}>
              Save
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </Box>
  );
}
