import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn, awsSignOut } from "../../redux/auth/authSlice";
import { Auth } from "aws-amplify";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import DashboardIcon from "../../assets/svgjs/DashboardIcon";
import MyProgramsIcon from "../../assets/svgjs/MyProgramsIcon";
import ReportIcon from "../../assets/svgjs/ReportIcon";
import EventsIcon from "../../assets/svgjs/EventsIcon";
import UsersIcon from "../../assets/svgjs/UsersIcon";
import MyApplicationsIcon from "../../assets/svgjs/MyApplicationsIcon";
import JssLogo from "../../assets/images/logo.jpg";
import edLogo from "../../assets/images/edlogo.png";
import swal from "sweetalert";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import { Link, useLocation, useNavigate } from "react-router-dom";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AccountBoxIcon from "@mui/icons-material/Work";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AssignmentIndIcon from "@mui/icons-material/Groups";
import { FaList } from "react-icons/fa";

const Aside = ({ toggled, handleToggleSidebar }) => {
  const [active, setactive] = useState("");
  let location = useLocation();
  let navigate = useNavigate();
  let userDetails = useSelector(authData);
  const [subMenu, setSubMenu] = useState(false);
  // console.log("path " + JSON.stringify(location))

  const dispatch = useDispatch();

  useEffect(() => {
    setactive(userDetails.sideactive);
    return () => {};
  }, []);

  useEffect(() => {
    get();
    CheckSession();
    return () => {};
  }, [location]);

  async function CheckSession() {
    const sessionCheck = await localStorage.getItem("sessionStore");
    console.log("check", sessionCheck);
    try {
      let session = await Auth.currentSession();
      // alert(JSON.stringify(session))
      if (sessionCheck === null || sessionCheck === false) {
        console.log("session", session);
        if (session == undefined) {
          await localStorage.setItem("sessionStore", true);

          Auth.signOut();
          dispatch(awsSignOut());
          swal("Session Time Out");
        }
      }
    } catch {
      if (sessionCheck === null || sessionCheck === false) {
        await localStorage.setItem("sessionStore", true);

        Auth.signOut();
        dispatch(awsSignOut());
        swal("Session Time Out");
      }
    }
  }

  function get() {
    //  alert(location.pathname)
    let sdata = { ...userDetails };

    if (location.pathname == "/dashboard") {
      sdata.sideactive = "/dashboard";
      dispatch(awsSignIn(sdata));
      setactive("/dashboard");
    } else if (
      location.pathname == "/Programs" ||
      location.pathname == "/ProgramsInfo"
    ) {
      sdata.sideactive = "/Programs";
      dispatch(awsSignIn(sdata));
      setactive("/Programs");
    } else if (location.pathname == "/users") {
      sdata.sideactive = "/users";
      dispatch(awsSignIn(sdata));
      setactive("/users");
    } else if (location.pathname == "/instructors") {
      sdata.sideactive = "/instructors";
      dispatch(awsSignIn(sdata));
      setactive("/instructors");
    } else if (location.pathname == "/") {
      sdata.sideactive = "/dashboard";
      dispatch(awsSignIn(sdata));
      setactive("/dashboard");
    }
  }

  function setClass(val) {
    let sdata = { ...userDetails };
    sdata.sideactive = val;
    dispatch(awsSignIn(sdata));
    setactive(val);
  }

  return (
    <ProSidebar
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "grid",
            placeItems: "center",
          }}
        >
          {userDetails.role === "Admin" ? (
            <Link
              to="/dashboard"
              className="sidebar-btn"
              rel="noopener noreferrer"
              onClick={() => {
                setClass("/dashboard");
              }}
            >
              <img
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.jpg`}
                alt=""
                style={{ height: "7.5rem" }}
              />
            </Link>
          ) : (
            <Link
              to="/Programs"
              className="sidebar-btn"
              rel="noopener noreferrer"
              onClick={() => {
                setClass("/Programs");
              }}
            >
              <img
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.jpg`}
                alt=""
                style={{ height: "7.5rem" }}
              />
            </Link>
          )}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu>
          {userDetails.role !== "PO" ? (
            <>
              {userDetails.role == "Instructors" ||
              userDetails.role == "Coordinators" ? null : (
                <MenuItem
                  className={`${
                    active === "/dashboard" ? "side-nav__item--active" : "null"
                  }`}
                  icon={<DashboardIcon className="side-nav__icon" />}
                  onClick={() => {
                    setClass("/dashboard");
                  }}
                >
                  {/* side-nav__item--active */}
                  Dashboard
                  <Link to="/dashboard" />
                </MenuItem>
              )}
              {userDetails.role !== "Admin" ? null : (
                <MenuItem
                  className={`${
                    active === "/users" ? "side-nav__item--active" : "null"
                  }`}
                  icon={<UsersIcon className="side-nav__icon" />}
                  onClick={() => {
                    setClass("/users");
                  }}
                >
                  Users
                  <Link to="/users" />
                </MenuItem>
              )}
              {userDetails.role !== "Admin" ? null : (
                <MenuItem
                  className={`${
                    active === "/instructors" ? "side-nav__item--active" : "null"
                  }`}
                  icon={<UsersIcon className="side-nav__icon" />}
                  onClick={() => {
                    setClass("/instructors");
                  }}
                >
                  Instructors
                  <Link to="/instructors" />
                </MenuItem>
              )}
              <MenuItem
                className={`${
                  active === "/Programs" ? "side-nav__item--active" : "null"
                }`}
                icon={<EventsIcon className="side-nav__icon" />}
                onClick={() => {
                  setClass("/Programs");
                }}
              >
                Programs
                <Link to="/Programs" />
              </MenuItem>
            </>
          ) : (
            <>
              {config.aws_org_id.toLocaleLowerCase() === "learned" && (
                <>
                  <MenuItem
                    className={`${
                      active === "/dashboard" ||
                      location.pathname === "/dashboard"
                        ? "side-nav__item--active"
                        : ""
                    }`}
                    icon={<DashboardIcon className="side-nav__icon" />}
                    onClick={() => {
                      setClass("/dashboard");
                    }}
                  >
                    Dashboard
                    <Link to="/dashboard" />
                  </MenuItem>
                  <MenuItem
                    className={`${
                      active === "/profileapprovals"
                        ? "side-nav__item--active"
                        : ""
                    }`}
                    onClick={() => {
                      setClass("/profileapprovals");
                    }}
                    icon={<AccountBoxIcon className="side-nav__icon" />}
                  >
                    Profile
                    <Link to="/profileapprovals" />
                  </MenuItem>
                  <MenuItem
                    className={`${
                      active === "/jobapplicationapprovals"
                        ? "side-nav__item--active"
                        : ""
                    }`}
                    onClick={() => {
                      setClass("/jobapplicationapprovals");
                    }}
                    icon={<FolderSharedIcon className="side-nav__icon" />}
                  >
                    Job application
                    <Link to="/jobapplicationapprovals" />
                  </MenuItem>

                  <MenuItem
                    className={`${
                      active === "/allstudents" ? "side-nav__item--active" : ""
                    }`}
                    onClick={() => {
                      setClass("/allstudents");
                    }}
                    icon={<AssignmentIndIcon className="side-nav__icon" />}
                  >
                    All students
                    <Link to="/allstudents" />
                  </MenuItem>
                </>
              )}
            </>
          )}
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          // className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <a
            href="https://enhanzed.com/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <div className="legal">
              <p>&copy; 2024</p>
              Powered by <img src={edLogo} alt="lo" />
            </div>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
