// Dependencies imports
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/edit_black_24dp.svg";

// styles imports
import Edit from "./EditUser.module.scss";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import DisbleUser from "./DisbleUser";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/styles";
import swal from "sweetalert";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "rgb(38, 101, 170)",
    },
  },
  root1: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
});
const EditUser = (props) => {
  let { handleClose, editUserData, getUsersDetails, type } = props;
console.log(editUserData);
  const [userFirstName, setuserFirstName] = useState(editUserData.col1);
  const [rollNumber, setrollNumber] = useState(editUserData.col10);
  const [userLastName, setuserLastName] = useState(editUserData.col2);
  const [email, setEmail] = useState(editUserData.col3);
  const [ctype, setCtype] = useState(editUserData.col14);
  const [pno, setPno] = useState(editUserData.col5);
  const [gender, setGender] = useState(editUserData.col6);
  const [address, setAddress] = useState(editUserData.col7);
  const [urole, setUrole] = useState(editUserData.col4);
  const [ur_id, setUr_Id] = useState(editUserData.col8);
  const [eid, setEid] = useState(editUserData.col9);
  const [spin, setSpin] = useState(false);
  const [edspin, setEdSpin] = useState(false);
  const classes = useStyles();
  const [userNameErr, setUserNameErr] = useState("");
  const [rollNumberErr, setRollNumberErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [pnoErr, setPnoErr] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const [openUser, setopenUser] = useState(false);

  const onChangeHandleruserFirstName = (event) => {
    setuserFirstName(event.target.value);
  };

  const onChangeHandlersetuserLastName = (event) => {
    setuserLastName(event.target.value);
  };
  const onChangeHandlerrollNumber = (event) => {
    setrollNumber(event.target.value);
  };

  const onChangeHandlerEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangeHandlerPno = (event) => {
    setPno(event.target.value);
  };
  const onChangeHandlerGender = (event) => {
    setGender(event.target.value);
  };
  const onChangeHandlerAddress = (event) => {
    setAddress(event.target.value);
  };

  const handleClickOpenuser = () => {
    setopenUser(true);
  };

  const handleCloseDisable = () => {
    setopenUser(false);
  };

  const onsubmit = () => {

    let formIsValid = true;
    setRollNumberErr("");
    setUserNameErr("");
    setEmailErr("");
    setPnoErr("");
    setGenderErr("");
    if (
      (rollNumber === "" || rollNumber === null || (rollNumber !== undefined && rollNumber.trim() === ""))
    ) {
      formIsValid = false;
      setRollNumberErr("Please enter roll number");
      return;
    }
    if (
      userFirstName === "" ||
      userFirstName === null ||
      (userFirstName != undefined && userFirstName.trim() === "")
    ) {
      formIsValid = false;
      setUserNameErr("Please enter user name");
      return;
    }
    if (
      email === "" ||
      email === null ||
      (email != undefined && email.trim() === "")
    ) {
      formIsValid = false;
      setEmailErr("*Please enter your email-ID.");
      return;
    }
    if (
      pno !== null &&
      pno != undefined &&
      pno.trim() !== "" &&
      pno.length < 10
    ) {
      formIsValid = false;
      setPnoErr(
        "Please enter a valid phone number (should be atleast 10 digits)"
      );
      return;
    }
    if (gender === "" || gender === null) {
      formIsValid = false;
      setGenderErr("Please select gender");
      return;
    }
    if (userFirstName !== "") {
      if (!userFirstName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setUserNameErr("*Please enter alphabet characters only.");
        return;
      }
    }
    if (email !== "") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailErr("*Please enter valid email-ID.");
        return;
      }
    }

    if (formIsValid == true) {
      editUserData.col10 != rollNumber ? Checkeuser() : editUsersDetails();
    }
    async function Checkeuser() {
      setEdSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          rno: rollNumber,
          emailid: email.toLowerCase(),
          urole: urole,
          cno: pno,
          schema: "jeevarakshasm",
          atype: 1,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          '/checkUserExistsOrNot',
          bodyParam

        );

        if (response.rno > 0) {
          swal({
            title: "Oooops!",
            text: response.msg,
            icon: "warning",
            dangerMode: true,
          }).then(() => {

          });
          setSpin(false);
          setEdSpin(false);
        }

        else {
          editUsersDetails();
        }
      } catch (error) {
        setSpin(false);
        swal({
          title: "Oooops!",
          text: "Something went wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });

      }
    }
    async function editUsersDetails() {
      setSpin(true);
      const bodyParam = {
        body: {
          oid: config.aws_org_id,
          first_name: userFirstName,
          rno: rollNumber,
          last_name: userLastName,
          urole: urole,
          gen: gender,
          address: address !== undefined ? address : " ",
          cno: pno !== undefined ? pno : " ",
          atype: 1,
          schema: "jeevarakshasm",
          ur_id: ur_id,
          eid: eid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      if (urole?.toLowerCase() === 'user') {
        bodyParam.body.rno = rollNumber;
      }

      try {
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_ADDUSER,
          bodyParam

        );
        const users = response;
        setEdSpin(false);

        swal({
          title: "Success",
          text: "User Edited successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);

          }
        });
        handleClose();
        getUsersDetails();
      } catch (error) {
        swal({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            setEdSpin(false);
          }
        });

      }

    }
  };

  return (
    <div className={Edit.maindiv}>
      {spin === false ? (
        <div className={Edit.containermain}>
          <div className={Edit.iconcontainer} >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EditIcon className={Edit.icon} onClick={handleClose} />
              <h3 className={Edit.username} style={{ marginLeft: '5px' }}> Edit {type === 'user' ? 'User' : 'Instructor'}</h3>
            </div>
            <Close className={Edit.icon} onClick={handleClose} />
          </div>
          <div className={Edit.namebutton} style={{ justifyContent: 'end', padding: '10px 0 0 0' }}>

            {editUserData.col4 !== 'User' && (editUserData.col12 === null || editUserData.col12 === true) ? (
              <button
                onClick={handleClickOpenuser}
                className={
                  window.navigator.onLine === true
                    ? Edit.btn_color
                    : Edit.btn_colordis
                }
                disabled={!window.navigator.onLine}
              >
                Disable {type === 'user' ? 'User' : 'Instructor'}
              </button>
            ) : null}
            {editUserData.col4 != 'User' && editUserData.col12 === false ? (
              <button
                onClick={handleClickOpenuser}
                className={Edit.disablebutton}
              >
                Enable {type === 'user' ? 'User' : 'Instructor'}
              </button>
            ) : null}
          </div>
          {edspin && <div className={classes.root} style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress style={{ color: config.main_color_1 }} />
          </div>}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openUser}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openUser}>
              <DisbleUser
                handleClose={handleCloseDisable}
                editUserData={editUserData}
                getUsersDetails={getUsersDetails}
              />
            </Fade>
          </Modal>
          <div className={Edit.maindivision}>

            <div className={Edit.form}>
              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="rollNumber"
                  required
                >
                  Roll Number
                </label>
                <input
                  className={Edit.inputcontainer}
                  type="text"
                  id="rollNumber"
                  onChange={onChangeHandlerrollNumber}
                  value={rollNumber}
                  required="required"
                />
              </div>
              <div style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                marginLeft: "20%",
              }}
              >
                {rollNumberErr}
              </div>
              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="name"
                  required
                >
                  First Name
                </label>
                <input
                  className={Edit.inputcontainer}
                  type="text"
                  id="name"
                  onChange={onChangeHandleruserFirstName}
                  value={userFirstName}
                  required="required"
                />
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="name"
                  required
                >
                  Last Name
                </label>
                <input
                  className={Edit.inputcontainer}
                  type="text"
                  id="name"
                  onChange={onChangeHandlersetuserLastName}
                  value={userLastName}
                  required="required"
                />
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {userNameErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="email"
                  required
                >
                  Email
                </label>
                <input
                  className={Edit.inputcontainer}
                  type="email"
                  id="email"
                  onChange={onChangeHandlerEmail}
                  value={email}
                  required="required"
                  disabled
                />
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {emailErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="contact"
                >
                  Ph. No
                </label>
                <input
                  className={Edit.inputcontainer}
                  type="number"
                  id="contact"
                  onChange={onChangeHandlerPno}
                  value={pno}
                />
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {pnoErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="gender"
                >
                  Gender
                </label>
                <select
                  className={Edit.inputcontainer}
                  name="gender"
                  id="gender"
                  onChange={onChangeHandlerGender}
                  value={gender}
                >
                  <option value="0">Select Gender</option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Trans Gender</option>
                </select>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  marginBottom: "12px",
                  marginTop: "-10px",
                  textAlign: "start",
                  marginLeft: "20%",
                }}
              >
                {genderErr}
              </div>

              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="address"
                >
                  Address
                </label>
                <textarea
                  className={Edit.textareacontainer}
                  id="address"
                  rows="4"
                  onChange={onChangeHandlerAddress}
                  value={address}
                />
              </div>
              <div className={Edit.field}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500px",
                    fontFamily: "nunito",
                  }}
                  for="course"
                  required
                >
                  Course
                </label>
                <input
                  className={Edit.inputcontainer}
                  type="text"
                  id="course"
                  value={ctype}
                  required="required"
                  disabled
                />
              </div>
              <br></br>
              <br></br>
              {!edspin && <input
                type="submit"
                value="Save"
                className={
                  window.navigator.onLine === true
                    ? Edit.btn_color
                    : Edit.btn_colordis
                }
                disabled={!window.navigator.onLine}
                onClick={onsubmit}
              ></input>}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default EditUser;
