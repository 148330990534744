// Dependencies imports
import { Add } from "@material-ui/icons";
import React from "react";
import ReactPlayer from "react-player";
// Local imports
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
import Image from "../../assets/images/i3.png";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";

import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
// Styles imports
import Ann from "./AnnouncementFile.module.scss";

const AssignmentFile = (props) => {
  let { handleFileClose, fileobj, quizId, userData, type } = props;

  let userDetails = useSelector(authData);
  console.log("btid", JSON.stringify(userDetails));
  const dispatch = useDispatch();
  console.log("data" + JSON.stringify(fileobj));
  let extend =
    fileobj.orgname.substring(
      fileobj.orgname.lastIndexOf(".") + 1,
      fileobj.orgname.length
    ) || fileobj.orgname;
  console.log("extend", extend);
  console.log("userDetails", userDetails);

  return (
    <div className={Ann.mainpopup}>
      <div className={Ann.iconholder}>
        <Close onClick={handleFileClose} className={Ann.icon} />
      </div>
      <div
        className={Ann.mediaholder}
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          height: "100% !important",
          width: "100% !important",
          overflowY: "auto",
        }}
      >
        <div>
          {extend === "pdf" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100% !important",
                  height: "500px",
                  overflowX: "hidden",
                  paddingBottom: "20px",
                  objectFit: "cover",
                }}
              >
                <iframe
                  src={`https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/userdata/${
                    userData.eid
                  }/${type}/${userDetails.asses.btid}/${quizId}/${
                    fileobj.orgname
                  }`}
                  width={600}
                  title="Iframe Example"
                ></iframe>
                {/* <Document
                      file={`https://${config.DOMAIN}/jssaher-resources/images/announcement-images/0047786524-3.pdf`}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document> */}
              </div>
            </>
          ) : null ||
            extend === "mp4" ||
            extend === "mov" ||
            extend === "flv" ||
            extend === "mkv" ? (
            <div
              className="player-wrapper"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <ReactPlayer
                url={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/userdata/${
                  userData.eid
                }/${type}/${userDetails.asses.btid}/${quizId}/${
                  fileobj.orgname
                }`}
                controls={true}
                width="100%"
              />
            </div>
          ) : null || extend === "mp3" ? (
            <div className="player-wrapper">
              <ReactPlayer
                url={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/userdata/${
                  userData.eid
                }/${type}/${userDetails.asses.btid}/${quizId}/${
                  fileobj.orgname
                }`}
                controls={true}
                height="50px"
              />
            </div>
          ) : null ||
            extend === "png" ||
            extend === "jpg" ||
            extend === "jpeg" ||
            extend === "gif" ? (
            <div
              style={{
                width: "100%",
                height: "300px",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/userdata/${
                  userData.eid
                }/${type}/${userDetails.asses.btid}/${quizId}/${
                  fileobj.orgname
                }`}
                style={{ objectFit: "cover" }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AssignmentFile;
