// Dependencies imports
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import AddScore from "./AddScore";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Skeleton from "@material-ui/lab/Skeleton";

// Local imports
import UserHeader from "../Header/UserHeader/UserHeader";
import GradingDetailsGrid from "./GradingDetailsGrid";
import config from "../../config/aws-exports";
import { Constants } from "../../config/constants";

// Styles imports
import Grade from "./GradingDetails.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "600px",
    color: config.main_color_1,
    fontFamily: "nunito",
  },
  heading1: {
    fontSize: "18px",
    fontWeight: "600px",
    color: config.main_color_1,
    fontFamily: "nunito",
    paddingTop: "10px",
    paddingLeft: "10px",
    textAlign: "center",
  },
  dropoption: {
    fontSize: "16px",
    color: "#6a6a6a",
    fontWeight: "500",
    fontFamily: "nunito",
    padding: "10px 0px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
}));

// Start of Grading Details Screen
const GradingDetails = () => {
  const [course, setCourse] = useState([]);
  const [currentData, setCurrentData] = useState("");
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  let userDetails = useSelector(authData);
  console.log("usdata" + JSON.stringify(userDetails));
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    getMiniAssignments();
    breadcrumb();
  }, []);

  async function getMiniAssignments() {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails.curprgcou.opid,
        bpid: userDetails.curprgcou.bpid, // these thre are same
        assignid: userDetails.grade.btid,
        tid: userDetails.grade.tid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("GET_MINI_ASSIGNMENTS==", JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_MINI_ASSIGNMENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      const users = response;
      //console.log("2222==="+ JSON.stringify(users))

      if (response.courses !== undefined) {
        let courses = response.courses;
        // setCourse(response.courses);
        function groupByKey(array, key) {
          return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, {
              [obj[key]]: (hash[obj[key]] || []).concat(obj),
            });
          }, {});
        }
        var result = groupByKey(courses, "ntitle");
        let temp = [];
        for (const [key, value] of Object.entries(result)) {
          let obj = {};
          obj.title = key;
          obj.data = value;
          temp.push(obj);
        }
        //   for (let i = 0; i < courses.length; i++) {
        //     for (let j = 0; j < courses[i].objects.length; j++) {
        //       temp.push(courses[i].objects[j]);
        //     }
        // }
        setCourse(temp);
        setLoading(false);
        // alert("hiii"+JSON.stringify(currentData));
        // if (currentData != '') {
        //     alert("byy"+JSON.stringify(currentData));
        //     onClick(currentData);
        // }

        console.log("tmd " + JSON.stringify(result));
      } else {
        setCourse([]);
        setLoading(false);
      }
    } catch (error) {
      setCourse([]);
      setLoading(false);
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Oooops!", "Contact developer");
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  const onClick = (data) => {
    console.log("onClick==", data);
    setCurrentData(data);
    convertRowData(data);
  };
  function convertRowData(currentData) {
    let udata = currentData.users;
    let rows = [];
    for (let i = 0; i < udata.length; i++) {
      console.log("udatass" + JSON.stringify(udata));
      let obj = {};
      obj.id = i;
      obj.eid = udata[i].EID;
      obj.name = udata[i].UNAME;

      if (
        udata[i].GRADES !== undefined &&
        udata[i].GRADES[userDetails.curprgcou.bpid] !== undefined &&
        udata[i].GRADES[userDetails.curprgcou.bpid][userDetails.grade.btid] !==
          undefined &&
        udata[i].GRADES[userDetails.curprgcou.bpid][userDetails.grade.btid][
          currentData.oid
        ] !== undefined
      ) {
        if (
          udata[i].GRADES[userDetails.curprgcou.bpid][userDetails.grade.btid][
            currentData.oid
          ] == -1
        ) {
          obj.score = "Not Graded";
        } else {
          obj.score =
            udata[i].GRADES[userDetails.curprgcou.bpid][userDetails.grade.btid][
              currentData.oid
            ] + "/10";
        }
      } else {
        obj.score = "Not Submitted";
      }

      rows.push(obj);
    }

    setRows(rows);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[3] = {
      name: "Grading Details",
      path: "/gradingdetails",
    };
    sdata.breadcrumb = temp;
    dispatch(awsSignIn(sdata));
  }

  return (
    <div className={Grade.maincontainer}>
      <UserHeader Bindex={3} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AddScore handleClose={handleClose} />
        </Fade>
      </Modal>
      <h3 className={Grade.header}>{userDetails.grade.ttitle}</h3>
      {loading === false ? (
        <div className={Grade.middlescreen}>
          <div className={Grade.leftscreen}>
            <Paper style={{ height: "90%" }}>
              {course.length !== 0 ? (
                <div className={classes.root}>
                  {course.map((obj, i) => (
                    <Accordion style={{ width: "100%" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ width: "100%" }}
                      >
                        <Typography className={classes.heading}>
                          {obj.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ width: "100%" }}>
                        <Typography style={{ width: "100%" }}>
                          {obj.data.map((data, i) => (
                            <h3
                              className={
                                i === index
                                  ? Grade.dropoptionactive
                                  : Grade.dropoption
                              }
                              onClick={() => {
                                onClick(data);
                                setIndex(i);
                              }}
                            >
                              {data.otitle}{" "}
                            </h3>
                          ))}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ) : (
                <div className={classes.root}>
                  <Typography className={classes.heading1}>No Data</Typography>
                </div>
              )}
            </Paper>
          </div>
          <div className={Grade.rightscreen}>
            {console.log("cur", currentData)}
            <Paper style={{ height: "90%" }}>
              <GradingDetailsGrid
                currentData={currentData}
                rows={rows}
                getMiniAssignments={getMiniAssignments}
                userDetails={userDetails}
              />
            </Paper>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingTop: "20px",
            width: "100%",
            gap: "10px",
          }}
        >
          <Skeleton
            variant="rect"
            width="40%"
            height={600}
            style={{ borderRadius: "5px" }}
          />{" "}
          <Skeleton
            variant="rect"
            width="58%"
            height={400}
            style={{ borderRadius: "5px" }}
          />
        </div>
      )}
    </div>
  );
};

export default GradingDetails;
