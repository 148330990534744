import React, { useState, useEffect } from "react";
import {
  Button,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import swal from "sweetalert";
import { produce } from "immer";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import "./courseView.scss";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";

import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    border: "1px solid #6a6a6a",
    height: "fit-content",
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    fontFamily: "nunito",
    padding: "5px",
  },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function InstructorDialogBox({
  type,
  inopen,
  setNuggets,
  nuggets,
  setInopen,
  setIsLoading,
  courseDetails,
  errors,
  unitIndex,
  nuggetindex,
  instructorData,
  getCourseDetails
}) {
  const [instructorEmail, setInstructorEmail] = useState("");
  const [isdload, setIsdload] = useState(false);
  const classes = useStyles();
  const userDetails = useSelector(authData);
  const [filteredInstructors, setFilteredInstructors] = useState([]);
  const [courseName, setcourseName] = useState([]);
  const [uncheckedIds, setUncheckedIds] = useState([]);
  const [selectedPopular, setSelectedPopular] = useState([]);

  useEffect(() => {

    var arrray = [];
    if (instructorData != undefined && instructorData.length > 0) {
      for (let i = 0; i < instructorData.length; i++) {
        if (instructorData[i] !== null) {
          arrray.push(instructorData[i].emailid);
        }
      }
    }
    setcourseName(arrray);
    const filteredInstructor = courseDetails?.instructorList?.filter(instructor => {
        if (instructor?.roles?.length > 0) {
          return instructor.roles?.some(role => role.batchid == userDetails?.curprgcou?.bpid && role.role == 'Instructor');
        }
        return true; 
      });
      setFilteredInstructors(filteredInstructor);
  }, []);

  function handleNuggetsClose() {
    setInopen(false);
    setIsLoading(false);
  }
  const handleChange = (event) => {
    let selectArray = event.target.value;
    setSelectedPopular(selectArray);
    setcourseName(event.target.value);
    handleUncheck(selectArray);
  };
  const handleUncheck = (selected) => {
    const unselectedValues = filteredInstructors
    .filter(item => !selected.includes(item.emailid))
    .map(item => item.emailid);
   setUncheckedIds(unselectedValues)
    // Do something with unselected values
  }
  return (
    <Dialog
      open={inopen}
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title"
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title"><p style={{ fontSize: "25px" }}>Add Instructor</p></DialogTitle>
      <DialogContent>
     
        <div style={{ width: "400px" }}>
         
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={courseName}
              // onChange={handleChange}
              onChange={(event) => {
                
                handleChange(event);
                
              }}
              input={<Input />}
              renderValue={(selected) => {
               console.log(selected);
                selected.join(", ");
                let temp = ["Instructors"];
                
                return temp;
              }}
              MenuProps={MenuProps}
            >
              {filteredInstructors?.map((item) => (
                <MenuItem key={item.emailid} value={item.emailid}>
                  <Checkbox checked={courseName.indexOf(item.emailid) > -1} />
                  <ListItemText>
                  <p style={{ fontSize: '15px' }}>{item.emailid + ' (' + item.first_name + ')'}</p>
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </div>
        <h3 style={{ textAlign: "left", fontSize: "16px", marginTop: '15px', marginBottom: '10px' }}>
          Selected Instructors
        </h3>
        <div style={{ textAlign: "left", fontSize: "15px", marginLeft: "15px" }}>
          <ol>
            {courseName.map((item) => (
              <li>{item}</li>
            ))}
          </ol>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNuggetsClose} color="primary" style={{fontSize:'12px'}}>
          Cancel
        </Button>
        {!isdload ? (
          <Button
            onClick={async () => {
              setIsdload(true);
              let tmpnt = [...nuggets];
              errors.uval = false;
              errors.nval = false;

              if (selectedPopular.length === 0) {
                swal("Oops!", "Please Enter Instructor Email", "error");
                setIsdload(false);
              } else {

                const bodyParam = {
                  body: {
                    emailid: instructorEmail,
                    uid: tmpnt[unitIndex].unit_id,
                    nid: tmpnt[unitIndex].nuggets[nuggetindex].nid,
                    bpid: userDetails.curprgcou.bpid,
                    bname: userDetails.curprgcou.bname,
                    role: 'Instructor',
                    ur_ids: selectedPopular,
                    uncheckedId: uncheckedIds
                  },
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                 
                };
                try {
                 
                  const users = await API.post(
                    config.aws_cloud_logic_custom_name,
                    "/updateInstructorToCourseModule",
                    bodyParam
                  );

                  setIsdload(false);
                  if (users.statusCode === 400) {
                    swal("Oops!", users.msg, "error");
                    return;
                  }
                  tmpnt = produce(tmpnt, (draft) => {
                    draft[unitIndex].nuggets[nuggetindex].instructor =
                      users.users;
                  });
                 
                  setNuggets(tmpnt);
                  getCourseDetails();
                } catch (error) {
                  console.error(error);
                }
                handleNuggetsClose();
              }
            }}
            className={
              window.navigator.onLine === true ? "btn_color" : "btn_colordis"
            }
            disabled={!window.navigator.onLine}
            style={{fontSize:'12px'}}
          >
            Save
          </Button>
        ) : (
          <Button
            className={
              window.navigator.onLine === true ? "btn_color" : "btn_colordis"
            }
            disabled={!window.navigator.onLine}
          >
            Loading...
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
