// Dependencies imports
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbar,
} from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/styles";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import swal from "sweetalert";
import config from "../../config/aws-exports";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import PopAssignment from "./PopAssignment";
import { setUseProxies } from "immer";
import { Constants } from "../../config/constants";
import useWindowDimensions from "../../modules/Window/Window";
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";
// Local imports

// Styles imports

// Data for Instructor Grid
const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "score",
    headerName: "Score",
    flex: 1,
    headerClassName: "super-app-theme--header",
  },
];

// const rows = [
//     { id: 1, name: 'Seramaan', score: 'In progress' },
//     { id: 2, name: 'Jennifer', score: 'Not graded' },
//     { id: 3, name: 'Lee', score: 'Not graded' }
// ];

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      backgroundColor: config.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".MuiDataGrid-root": {
    fontSize: "14px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
  },
  paper: {
    backgroundColor: "white",

    height: "100px",
    width: "500px",
    borderRadius: "4px",
    border: "none",
    padding: "20px",
  },
});

// Start of Instructor Grid Component
const AssignmentDetailsGrid = (props) => {
  let { rows, currentData, quizId } = props;
  const [open, setOpen] = useState(false);
  const [responseQuiz, setResponseQuiz] = useState({});
  const [userDet, setUserDetail] = useState({});
  const [notSubmitted, setNotSubmitted] = useState(false);
  const [userData, setUserData] = useState("");
  const { height, width } = useWindowDimensions();
  // const [rows, setRows] = useState([]);
  console.log("cc", currentData);
  const classes = useStyles();

  async function getAssignmentData(data) {
    const bodyParam = {
      body: {
        eid: data.eid,
        oid: config.aws_org_id,
        quizid: data.qid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log(bodyParam.body);
    try {
      console.log(bodyParam.body);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/getAssignmentData`,
        bodyParam
      );

      console.log("response", response);
      setResponseQuiz(response);
      if (response.errorType === "string") {
        setNotSubmitted(true);
      } else {
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
  // let userDetails = useSelector(authData);
  // useEffect(() => {
  //     if (currentData.users != undefined)
  //     {
  //         convertRowData();
  //     }

  //   }, []);
  // function convertRowData() {
  //     let udata = currentData.users;
  //     let rows = [];
  //     for (let i = 0; i < udata.length; i++)
  //     {
  //         let obj = {};
  //         obj.id = i;
  //         obj.name = udata[i].UNAME;
  //         if (udata.GRADES != undefined && udata.GRADES.miniassignments[currentData.oid] != undefined )
  //         {
  //             if (udata.GRADES.miniassignments[currentData.oid] == -1)
  //             {
  //                 obj.score = "Submited";
  //             }
  //             else
  //             {
  //                 obj.score = udata.GRADES.miniassignments[currentData.oid] + "/10";
  //             }
  //         }
  //         else
  //         {
  //             obj.score = "Not Graded";

  //         }

  //         rows.push(obj);

  //     }
  //     alert("hi"+JSON.stringify(rows));
  //     setRows(rows);
  // }

  // function for toolbar options above datagrid
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <GridToolbar />
      </GridToolbarContainer>
    );
  }
  const handleCellClick = (param, event) => {
    console.log("row data", param.row);
    // console.log("ss1", currentData.users[param.id]);

    // var body = {
    //     qid: currentData.oid,
    //     eid: currentData.users[param.id].EID
    // }
    // console.log("body", body)
    // setUserDetail(body)
    // getAssignmentData(body)

    // console.log(event);
    if (param.row.score == "Not Submitted") {
      setNotSubmitted(true);
    } else {
      setOpen(true);
      setUserData(param.row);
    }
  };

  function handleClose() {
    setOpen(false);
  }
  function handleCloseNotSubmitted() {
    setNotSubmitted(false);
  }

  return (
    <div
      className={classes.root}
      style={{ height: height - 200, width: "100%" }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PopAssignment
            handleClose={handleClose}
            response={responseQuiz}
            currentData={currentData}
            userDet={userDet}
            userData={userData}
            quizId={quizId}
            type="assignment"
          />
        </Fade>
      </Modal>
      <DataGrid
        rows={rows}
        columns={columns}
        components={{ Toolbar: CustomToolbar }}
        onCellClick={handleCellClick}
        disableSelectionOnClick
        className={classes[".MuiDataGrid-root"]}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={notSubmitted}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={notSubmitted}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Close
                onClick={handleCloseNotSubmitted}
                style={{ cursor: "pointer" }}
              />
            </div>
            <h3 className={classes.header} style={{ textAlign: "center" }}>
              Not Submitted
            </h3>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default AssignmentDetailsGrid;
