import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./CreateQuiz.scss";
// Swiper
import "swiper/swiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/components/navigation/navigation.scss";
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Controller,
} from "swiper";

//import TextField from "@material-ui/core/TextField";
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import config from "../../config/aws-exports";
import { useState, useRef } from "react";
import { Constants } from "../../config/constants";
import { current, produce } from "immer";
//import { generate } from "shortid";
import axios from "axios";
import { API } from "aws-amplify";
import { Select, MenuItem, FormControl } from '@mui/material';
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

const CustomButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 15px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#fff",
    borderColor: "#01498e",
    color: "#01498e",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#01498e",
      border: "1px solid #fff",
      color: "#fff",
      boxShadow: "#fff",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#fff",
      borderColor: "#01498e",
    },
    "&:focus": {
      //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);
const quizStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiTypography-body1": {
      flexGrow: "1",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "0",
    },
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
  },
  flexBasis: {
    flexBasis: "15%",
  },
  textCenter: {
    textAlign: "center",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  quizButtonsContainer: {
    display: "flex",
    gap: "2rem",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "30px",
  },
  input: {
    display: "none",
  },
  uploadBtn: {
    color: "#fff",
    background: "#01498e",
    padding: ".5rem 5rem",
    fontSize: "1.4rem",
    "&:hover": {
      backgroundColor: "#01498e",
      color: "#fff",
      boxShadow: "#fff",
    },
  },
  fontSize: {},
  small: {
    fontSize: "1rem",
  },
  customInputLabel: {
    "& legend": {
      display: "none"
    }
  }
}));
const BlueRadio = withStyles({
  root: {
    color: "#01498e",
    "&$checked": {
      color: "#01498e",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const initialValues = [
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
  { content: "" },
];
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, Controller]);
export default function CreateQuiz({
  folder,
  setSelectedObj,
  selectedObj,
  finalSave,
  typesButtons,
  setTypeButtons,
  quizQuestion,
  setQuizQuestion,
  editQuiz,
}) {
  const classes = quizStyles();
  const [quizForm, setQuizForm] = useState(false);
  const [titleForm, setTitleForm] = useState(false);
  const [typesQuiz, setTypeQuiz] = useState(false);
  const [cutOff, setCutOff] = useState(false);
  const [cutOffVal, setCutOffVal] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [values, setValues] = useState(initialValues);
  const [istem, setIstem] = useState("");
  const [swiperRef, setSwiperRef] = useState(null);
  const [questionObj, setQuestionObj] = useState({ currentq: 0 });
  const [quesType, setQuesType] = useState("");
  const [feedbackView, setFeedbackView] = useState(false);
  const [optionFb, setOptionFb] = useState(false);
  const [errlogo, setErrlogo] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [catList, setcatList] = useState([]);
  let userDetails = useSelector(authData);
  console.log(userDetails);
  // set error
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [error7, setError7] = useState(false);
  const [error8, setError8] = useState(false);
  const perval = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  const [attempt, setAttempt] = useState({ "1": { "qitems": [] }, "2": { "qitems": [] }, "3": { "qitems": [] }, "4": { "qitems": [] }, "5": { "qitems": [] } });
  const onFileChange = (event) => {
    var ext = getExtension(event.target.files[0].name);
    setErrlogo(false);

    if (event.target.files == null) {
      return;
    } else if (ext != "jpg" && ext != "jpeg" && ext != "png") {
      setErrlogo(true);
      return;
    }
    setSelectedFile({ file: event.target.files[0] });
    const file = event.target.files[0];

    let id = Math.floor(Math.random() * (999 - 100 + 1) + 100);
    setQuizQuestion((currentistem) =>
      produce(currentistem, (v) => {
        v[questionObj.currentq - 1].imageurl = id + ".png";
        v[questionObj.currentq - 1].image = true;
      })
    );
    uploadfile(event.target.files[0], id);
  };

  useEffect(() => {
    if (selectedObj.qtype !== undefined) {
      addQuesType(selectedObj.qtype);
      setQuesType(selectedObj.qtype);
      if (selectedObj.quizQuestion !== undefined) {
        let ques = [...selectedObj.quizQuestion];
        ques = produce(ques, (v) => {
          for (let k = 0; k < v.length; k++) {
            if (v[k].iopts.length > 0) {
              for (let j = 0; j < 6; j++) {
                if (v[k].iopts[j] === undefined) {
                  let obj = {
                    content: "",
                    correct: "",
                  };
                  v[k].iopts[j] = obj;
                }
                if (
                  v[k].iopts[j] !== undefined &&
                  (v[k].iopts[j].correct == true ||
                    v[k].iopts[j].correct == "true")
                ) {
                  v[k].correctoption = j.toString();
                }
              }
            }
          }
        });
        console.log(JSON.stringify(ques, null, 2));
        setQuizQuestion(ques);
      }
      if (selectedObj.qtitle) {
        setTitle(selectedObj.qtitle);
      }
      if (selectedObj.cutoff) {
        setCutOffVal(selectedObj.cutoff);
      }
    }
  }, []);
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  const handleChange = (event, qidx) => {
    setValue(event.target.value);
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts.forEach((opts) => {
          opts.correct = false;
        });
      })
    );
    const correctoptionvalue = event.target.value;
    console.log(correctoptionvalue);
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].iopts[correctoptionvalue].correct = true;
      })
    );
    setQuizQuestion((option) =>
      produce(option, (v) => {
        v[qidx].correctoption = correctoptionvalue;
      })
    );
  };
  const handleSave = (catList) => {
    console.log("catList==", catList)
    var quizjson = {};
    quizjson.qitems = [];
    quizjson.qtype = quesType;

    if (quesType == '2') {
      for (let index = 0; index < catList.length; index++) {
        catList[index].questions = catList[index].questions.sort(() => Math.random() - 0.5)
        let qlength = Math.round(0.25 * catList[index]?.questions?.length);
        let questions;
        if (catList[index].pval === undefined) {
          questions = catList[index].questions.slice(0, qlength);
        } else {
          qlength = Math.round((catList[index].pval / 100) * catList[index]?.questions?.length);
          console.log(qlength, catList[index].pval, catList[index].questions.length)
          questions = catList[index].questions.slice(0, qlength);
        }
        quizjson.qitems = quizjson.qitems.concat(questions);
      }
      selectedObj.quizdata = {
        qitems: quizjson.qitems,
        qtype: quesType,
        cutoff: cutOffVal,
      };
      setSelectedObj((currObj) =>
        produce(currObj, (v) => {
          v.otherdata.dur = quizjson.qitems.length * 60;
        })
      );
    } else if (quesType == '3') {
      const tattempt = { ...attempt };
      for (const key in tattempt) {
        for (let index = 0; index < tattempt[key]?.catList?.length; index++) {
          tattempt[key].catList[index].questions = tattempt[key].catList[index].questions.sort(() => Math.random() - 0.5)
          let qlength = Math.round(0.25 * tattempt[key].catList[index]?.questions?.length);
          let questions;
          if (tattempt[key].catList[index].pval === undefined) {
            questions = tattempt[key].catList[index].questions.slice(0, qlength);
          } else {
            qlength = Math.round((tattempt[key].catList[index].pval / 100) * tattempt[key].catList[index]?.questions?.length);
            //console.log(qlength,catList[index].pval,attempt[key].catList[index].questions.length)
            questions = tattempt[key].catList[index].questions.slice(0, qlength);
          }
          console.log("questionsquestionsquestions", questions);
          tattempt[key].qitems = tattempt[key].qitems.concat(questions);
        }
      }

      selectedObj.quizdata = {
        attempt: tattempt,
        qtype: quesType,
        cutoff: 100,
      };
    }
    console.log(quizjson, "quizjson=", attempt,quesType);
    selectedObj.otherdata.title = title;
    selectedObj.otherdata.desc = title;
    selectedObj.otherdata.quesType = quesType;

    finalSave();
  }
  const handleInputChange = (e, oidx, qidx) => {
    const { name, value } = e.target;
    setQuizQuestion((currentoptions) =>
      produce(currentoptions, (v) => {
        v[qidx].iopts[oidx].content = value;
      })
    );
  };
  const handleFeedbackChange = (e, oidx, qidx) => {
    const { name, value } = e.target;
    setQuizQuestion((currentoptions) =>
      produce(currentoptions, (v) => {
        v[qidx].iopts[oidx].feedback = value;
      })
    );
  };
  const reset = () => {
    setValues(initialValues);
    setIstem("");
    setSelectedFile("");
    setValue("");
  };
  async function getCategory() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        pid: userDetails?.curprgcou.pid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        '/getCategoryList',
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      console.log('response==', response.category);
      setcatList(response.category);
      attempt["1"].catList = response.category;
      attempt["2"].catList = response.category;
      attempt["3"].catList = response.category;
      attempt["4"].catList = response.category;
      attempt["5"].catList = response.category;
      setAttempt(attempt);
      console.log('attempt==', attempt);
      setisLoading(false);
      // setIsLoading(false);
    } catch (error) {
      console.log("getUserError", error);
    }
  }
  const addQuestion = (idx) => {
    setQuizForm(true);
    setTitleForm(false);
    setTypeButtons(false);

    setQuizQuestion((currentQuestion) => [
      ...currentQuestion,
      {
        iid: Math.floor(Math.random() * (999 - 100 + 1) + 100),
        istem: "",
        iopts: initialValues,
      },
    ]);
    console.log("currentQuestion===", questionObj.currentq);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);

    questionObj.currentq = quizQuestion.length + 1;

    setQuestionObj((currentQuestion) => ({ ...questionObj }));
    //setCurrentQuestion((currentState) => (currentQuestion +1));
    console.log("currentQuestion===", questionObj.currentq);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);
    reset();
  };
  const editObjNext = (idx) => {
    setQuizForm(true);
    setTitleForm(false);
    setTypeButtons(false);

    console.log("currentQuestion===", questionObj.currentq);
    let cq = currentQuestion + 1;
    setCurrentQuestion(cq);

    questionObj.currentq = cq;

    setQuestionObj((currentQuestion) => ({ ...questionObj }));
    //setCurrentQuestion((currentState) => (currentQuestion +1));
    console.log("current===", questionObj);
  };
  const addQuesType = (type) => {
    if (type !== undefined) {

      setQuesType(type);
      setTitleForm(true);
      setTypeButtons(false);

      if (type === "0") {
        setTitleForm(true);
        setFeedbackView(true);
      }

      setQuizForm(true);

      if (type == "5" || type == "6" || type == "4") {

        setTitleForm(true);
        setOptionFb(true);
      }
      setQuizQuestion([]);
      setQuestionObj({ currentq: 0 });
      setCurrentQuestion(0);
      reset();
      setCutOffVal(cutOffVal);
    }
  };
  const prevQues = (question) => {
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);
    if (quesType != "4" && quesType != "5" && quesType != "6") {
      if (
        (question.istem == undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0) &&
        (question.iopts[0].content == undefined ||
          question.iopts[0].content == "" ||
          question.iopts[0].content.toString().replace(/\s/g, "").length ===
          0) &&
        (question.iopts[1].content == undefined ||
          question.iopts[1].content == "" ||
          question.iopts[1].content.toString().replace(/\s/g, "").length ===
          0) &&
        (question.iopts[2].content == undefined ||
          question.iopts[2].content == "" ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
          0) &&
        (question.iopts[3].content == undefined ||
          question.iopts[3].content == "" ||
          question.iopts[3].content.toString().replace(/\s/g, "").length ===
          0) &&
        (question.iopts[4].content == undefined ||
          question.iopts[4].content == "" ||
          question.iopts[4].content.toString().replace(/\s/g, "").length ===
          0) &&
        (question.iopts[5].content == undefined ||
          question.iopts[5].content == "" ||
          question.iopts[5].content.toString().replace(/\s/g, "").length === 0)
      ) {
        let filterPrev = quizQuestion.filter((ques) => ques.istem !== "");

        setQuizQuestion(filterPrev);
      } else {
        if (
          question.istem == undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0
        ) {
          setError1(true);
          return;
        } else {
          setError1(false);
        }

        let filterOps = question.iopts.filter((opts) => opts.correct === true);
        if (
          question.iopts[0].content == undefined ||
          question.iopts[0].content == "" ||
          question.iopts[0].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[1].content == undefined ||
          question.iopts[1].content == "" ||
          question.iopts[1].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError2(true);
          return;
        } else {
          setError2(false);
        }
        if (question.correctoption == undefined) {
          setError3(true);
          return;
        } else {
          setError3(false);
        }

        if (filterOps.length !== 0 && filterOps !== undefined) {
          if (
            filterOps[0].content === "" ||
            filterOps[0].content.length === 0
          ) {
            setError4(true);
            return;
          } else {
            setError4(false);
          }
        }
        if (
          question.iopts[2].content != undefined &&
          question.iopts[2].content != ""
        ) {
          if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == question.iopts[3].content ||
            question.iopts[2].content == question.iopts[4].content ||
            question.iopts[2].content == question.iopts[5].content
          ) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }
        if (
          question.iopts[3].content != undefined &&
          question.iopts[3].content != ""
        ) {
          if (
            question.iopts[3].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == undefined ||
            question.iopts[2].content.toString().replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
          if (
            question.iopts[3].content == question.iopts[4].content ||
            question.iopts[3].content == question.iopts[5].content
          ) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }
        if (
          question.iopts[4].content != undefined &&
          question.iopts[4].content != ""
        ) {
          if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == undefined ||
            question.iopts[2].content.replace(/\s/g, "").length === 0 ||
            question.iopts[3].content == undefined ||
            question.iopts[3].content.replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
          if (question.iopts[4].content == question.iopts[5].content) {
            setError8(true);
            return;
          } else {
            setError8(false);
          }
        }
        if (
          question.iopts[5].content != undefined &&
          question.iopts[5].content != ""
        ) {
          if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
            setError5(true);
            return;
          } else {
            setError5(false);
          }
          if (
            question.iopts[2].content == undefined ||
            question.iopts[2].content.replace(/\s/g, "").length === 0 ||
            question.iopts[3].content == undefined ||
            question.iopts[3].content.replace(/\s/g, "").length === 0 ||
            question.iopts[4].content == undefined ||
            question.iopts[4].content.replace(/\s/g, "").length === 0
          ) {
            setError6(true);
            return;
          } else {
            setError6(false);
          }
        }
        if (
          question.iopts[0].content == question.iopts[1].content ||
          question.iopts[0].content == question.iopts[2].content ||
          question.iopts[0].content == question.iopts[3].content ||
          question.iopts[0].content == question.iopts[4].content ||
          question.iopts[0].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
        if (
          question.iopts[1].content == question.iopts[2].content ||
          question.iopts[1].content == question.iopts[3].content ||
          question.iopts[1].content == question.iopts[4].content ||
          question.iopts[1].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }
    } else {
      if (
        question.istem === undefined ||
        question.istem.toString().replace(/\s/g, "").length === 0
      ) {
        let filterQues = quizQuestion.filter((quiz) => quiz.istem !== "");
        setQuizQuestion(filterQues);
      } else {
        if (
          question.istem === undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0
        ) {
          setError1(true);
          return;
        } else {
          setError1(false);
        }
      }
    }
  };
  const nextQues = (ques, questionObj) => {
    let question = { ...ques };
    let filterOps = question.iopts.filter((opts) => opts.correct === true);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);

    if (
      question.istem == undefined ||
      question.istem.replace(/\s/g, "").length === 0
    ) {
      setError1(true);
      return;
    } else {
      setError1(false);
    }

    if (quesType != "4" && quesType != "5" && quesType != "6") {
      if (
        question.iopts[0].content == "" ||
        question.iopts[0].content == undefined ||
        question.iopts[0].content.replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == "" ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content.replace(/\s/g, "").length === 0
      ) {
        setError2(true);
        return;
      } else {
        setError2(false);
      }
      if (question.correctoption == undefined) {
        setError3(true);
        return;
      } else {
        setError3(false);
      }
      // console.log(question);
      if (filterOps.length !== 0 && filterOps !== undefined) {
        if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
          setError4(true);
          return;
        } else {
          setError4(false);
        }
      }

      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (
          question.iopts[2].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }
      if (
        question.iopts[3].content != undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.toString(0).replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (
          question.iopts[4].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError5(true);
          return;
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setError8(true);
          return;
        }
      }
      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content !== ""
      ) {
        if (
          question.iopts[5].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError5(true);
          return;
        }
        if (
          question.iopts[2].content === undefined ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[4].content === undefined ||
          question.iopts[4].content.toString().replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      }
      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      }
      let qcurrentq = questionObj.currentq + 1;
      if (qcurrentq <= quizQuestion.length) {
        questionObj.currentq = qcurrentq;
        setQuestionObj((currentQuestion) => ({
          ...questionObj,
        }));
      }
    } else {
      if (
        question.istem === undefined ||
        question.istem.toString().replace(/\s/g, "").length === 0
      ) {
        let filterQues = quizQuestion.filter((quiz) => quiz.istem !== "");
        setQuizQuestion(filterQues);
      } else {
        if (
          question.istem === undefined ||
          question.istem.toString().replace(/\s/g, "").length === 0
        ) {
          setError1(true);
          return;
        } else {
          setError1(false);
        }
      }
      let qcurrentq = questionObj.currentq + 1;
      if (qcurrentq <= quizQuestion.length) {
        questionObj.currentq = qcurrentq;
        setQuestionObj((currentQuestion) => ({
          ...questionObj,
        }));
      }
    }
  };

  const validate = (question) => {
    let filterOps = question.iopts.filter((opts) => opts.correct === true);

    if (
      question.istem === undefined ||
      question.istem.toString().replace(/\s/g, "").length === 0
    ) {
      setError1(true);
      return;
    } else {
      setError1(false);
    }
    if (quesType != "4" && quesType != "5" && quesType != "6") {
      if (
        question.iopts[0].content === undefined ||
        question.iopts[0].content == "" ||
        question.iopts[0].content.toString().replace(/\s/g, "").length === 0 ||
        question.iopts[1].content == undefined ||
        question.iopts[1].content == "" ||
        question.iopts[1].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError2(true);
        return;
      } else {
        setError2(false);
      }

      if (question.correctoption == undefined) {
        setError3(true);
        return;
      } else {
        setError3(false);
      }
      if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
        setError4(true);
        return;
      } else {
        setError4(false);
      }
      if (
        question.iopts[2].content !== undefined &&
        question.iopts[2].content != ""
      ) {
        if (question.iopts[2].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == question.iopts[3].content ||
          question.iopts[2].content == question.iopts[4].content ||
          question.iopts[2].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }
      if (
        question.iopts[3].content !== undefined &&
        question.iopts[3].content != ""
      ) {
        if (question.iopts[3].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (
          question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content
        ) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }

      if (
        question.iopts[4].content !== undefined &&
        question.iopts[4].content != ""
      ) {
        if (question.iopts[4].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
        if (question.iopts[4].content == question.iopts[5].content) {
          setError8(true);
          return;
        } else {
          setError8(false);
        }
      }

      if (
        question.iopts[5].content !== undefined &&
        question.iopts[5].content != ""
      ) {
        if (question.iopts[5].content.replace(/\s/g, "").length === 0) {
          setError5(true);
          return;
        } else {
          setError5(false);
        }
        if (
          question.iopts[2].content == undefined ||
          question.iopts[2].content.replace(/\s/g, "").length === 0 ||
          question.iopts[3].content == undefined ||
          question.iopts[3].content.replace(/\s/g, "").length === 0 ||
          question.iopts[4].content == undefined ||
          question.iopts[4].content.replace(/\s/g, "").length === 0
        ) {
          setError6(true);
          return;
        } else {
          setError6(false);
        }
      }
      if (
        question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
      if (
        question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
    }

    addQuestion(currentQuestion);
  };

  async function uploadfile(file, id) {
    const bodyParam = {
      body: {
        filetype: file.type,
        filename: id + ".png",
        folder: folder,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log(bodyParam.body);
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        //"/getpreSignedURL",
        bodyParam
      );
      console.log(bodyParam.body);
      console.log(id);

      console.log(preSignedData, file);
      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const quizSave = (question) => {
    console.log(question);
    let filterOps = question.iopts.filter((opts) => opts.correct === true);
    setError1(false);
    setError2(false);
    setError3(false);
    setError4(false);
    setError5(false);
    setError6(false);
    setError7(false);
    setError8(false);
    if (
      question.istem === undefined ||
      question.istem == "" ||
      question.istem.toString().replace(/\s/g, "").length === 0
    ) {
      setError1(true);
      return;
    } else {
      setError1(false);
    }
    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      (question.iopts[0].content === undefined ||
        question.iopts[0].content == "" ||
        question.iopts[0].content.toString().replace(/\s/g, "").length === 0 ||
        question.iopts[1].content === undefined ||
        question.iopts[1].content == "" ||
        question.iopts[1].content.toString().replace(/\s/g, "").length === 0)
    ) {
      setError2(true);
      return;
    } else {
      setError2(false);
    }
    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      question.correctoption === undefined
    ) {
      setError3(true);
      return;
    } else {
      setError3(false);
    }

    if (quesType != "4" && quesType != "5" && quesType != "6") {
      if (filterOps.length !== 0 && filterOps !== undefined) {
        if (filterOps[0].content === "" || filterOps[0].content.length === 0) {
          setError4(true);
          return;
        } else {
          setError4(false);
        }
      }
    }
    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      question.iopts[2].content !== undefined &&
      question.iopts[2].content != ""
    ) {
      if (
        question.iopts[2].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError5(true);
        return;
      } else {
        setError5(false);
      }
      if (
        question.iopts[2].content == question.iopts[3].content ||
        question.iopts[2].content == question.iopts[4].content ||
        question.iopts[2].content == question.iopts[5].content
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
    }

    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      question.iopts[3].content !== undefined &&
      question.iopts[3].content != ""
    ) {
      if (
        question.iopts[3].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError5(true);
        return;
      } else {
        setError5(false);
      }
      if (
        (quesType != "4" &&
          quesType != "5" && quesType != "6" &&
          question.iopts[2].content === undefined) ||
        question.iopts[2].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError6(true);
        return;
      } else {
        setError6(false);
      }
      if (
        quesType != "4" &&
        quesType != "5" && quesType != "6" &&
        (question.iopts[3].content == question.iopts[4].content ||
          question.iopts[3].content == question.iopts[5].content)
      ) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
    }

    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      question.iopts[4].content != undefined &&
      question.iopts[4].content != ""
    ) {
      if (
        question.iopts[4].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError5(true);
        return;
      } else {
        setError5(false);
      }
      if (
        quesType != "4" &&
        quesType != "5" && quesType != "6" &&
        (question.iopts[2].content === undefined ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.toString().replace(/\s/g, "").length === 0)
      ) {
        setError6(true);
        return;
      } else {
        setError6(false);
      }
      if (question.iopts[4].content == question.iopts[5].content) {
        setError8(true);
        return;
      } else {
        setError8(false);
      }
    }

    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      question.iopts[5].content != undefined &&
      question.iopts[5].content != ""
    ) {
      if (
        question.iopts[5].content.toString().replace(/\s/g, "").length === 0
      ) {
        setError5(true);
        return;
      } else {
        setError5(false);
      }
      if (
        quesType != "4" &&
        quesType != "5" && quesType != "6" &&
        (question.iopts[2].content === undefined ||
          question.iopts[2].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[3].content === undefined ||
          question.iopts[3].content.toString().replace(/\s/g, "").length ===
          0 ||
          question.iopts[4].content === undefined ||
          question.iopts[4].content.toString().replace(/\s/g, "").length === 0)
      ) {
        setError6(true);
        return;
      } else {
        setError6(false);
      }
    }
    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      (question.iopts[0].content == question.iopts[1].content ||
        question.iopts[0].content == question.iopts[2].content ||
        question.iopts[0].content == question.iopts[3].content ||
        question.iopts[0].content == question.iopts[4].content ||
        question.iopts[0].content == question.iopts[5].content)
    ) {
      setError8(true);
      return;
    } else {
      setError8(false);
    }
    if (
      quesType != "4" &&
      quesType != "5" && quesType != "6" &&
      (question.iopts[1].content == question.iopts[2].content ||
        question.iopts[1].content == question.iopts[3].content ||
        question.iopts[1].content == question.iopts[4].content ||
        question.iopts[1].content == question.iopts[5].content)
    ) {
      setError8(true);
      return;
    } else {
      setError8(false);
    }
    let arr = [];

    quizQuestion.forEach((ques, idx, array) => {
      const fill = ques.iopts.filter((opts) => opts.content !== "");

      let quesd = {};
      quesd = { ...ques };
      delete quesd.correctoption;
      console.log(quesd);
      quesd.iopts = [];
      quesd.iopts = fill;
      arr.push(quesd);
    });

    // let selected = { ...selectedObj };

    selectedObj.otherdata.title = title;
    selectedObj.otherdata.desc = title;

    selectedObj.otherdata.quesType = quesType;

    selectedObj.quizdata = {
      qitems: arr,
      qtype: quesType,
      cutoff: cutOffVal,
    };
    if (quesType == '7') {
      selectedObj.quizdata.cutoff = 100;
    }
    setSelectedObj((currObj) =>
      produce(currObj, (v) => {
        v.otherdata.dur = arr.length * 60;
      })
    );

    finalSave();
    console.log(folder);
  };
  return (
    <div className={classes.container}>
      <div className={classes.quizButtonsContainer}
        style={!isLoading ? { overflowY: 'auto', maxHeight: "620px", display: 'block' } : null}>
        {titleForm ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "600px",
              gap: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3rem",
                width: "100%",
              }}
            >
              <h3
                style={{
                  flexBasis: "15%",
                  fontSize: "1.6rem",
                  fontWeight: "500",
                }}
              >
                Title:
              </h3>
              <input
                type="text"
                style={{
                  flexGrow: "1",
                  height: "30px",
                  outline: "none",
                  fontSize: "1.5rem",
                  paddingLeft: "1rem",
                  border: "1px solid #01498e",
                  boxShadow: "0px 0px 5px 0px lightgrey",
                }}
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {cutOff ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3rem",
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    flexBasis: "15%",
                    fontSize: "1.6rem",
                    fontWeight: "500",
                  }}
                >
                  Cutoff Score:
                </h3>
                <input
                  type="text"
                  style={{
                    flexGrow: "1",
                    height: "30px",
                    outline: "none",
                    fontSize: "1.5rem",
                    paddingLeft: "1rem",
                    border: "1px solid #01498e",
                    boxShadow: "0px 0px 5px 0px lightgrey",
                  }}
                  required
                  autoComplete="false"
                  pattern="\d*"
                  value={cutOffVal}
                  maxLength="3"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setCutOffVal(e.target.value);
                    }
                  }}
                />
              </div>
            ) : null}
            <div>
              {editQuiz === false || editQuiz === undefined ? (
                <div>
                  <CustomButton
                    disabled={title.length >= 1 ? false : true}
                    onClick={() => {
                      if (quesType === "2" || quesType === "3") {
                        setTypeQuiz(true);
                        setisLoading(true);
                        setTitleForm(false);
                        setTypeButtons(false);
                        console.log("type==", quesType)
                        getCategory();
                      } else {
                        addQuestion();
                      }

                    }}
                  >
                    Add Question
                  </CustomButton>
                </div>
              ) : (
                <div>
                  <CustomButton
                    disabled={title.length >= 1 ? false : true}
                    onClick={() => {
                      console.log('quesType=', quesType)
                      if (quesType == "2" || quesType == "3") {
                        setTypeQuiz(true);
                        setisLoading(true);
                        setTitleForm(false);
                        setTypeButtons(false);
                        console.log("type==", quesType)
                        getCategory();
                      } else {
                        editObjNext();
                      }

                    }}
                  >
                    Next
                  </CustomButton>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {typesButtons ? (
          <div className={classes.displayFlex} style={{ flexWrap: 'wrap' }}>
            <CustomButton onClick={() => addQuesType("1")}>
              Formative Quiz{" "}
            </CustomButton>
            <CustomButton onClick={() => addQuesType("2")}>
              Pre Test{" "}
            </CustomButton>
            <CustomButton onClick={() => addQuesType("3")}>
              {" "}
              Post Test
            </CustomButton>
            <CustomButton onClick={() => addQuesType("4")}>
              {" "}
              Feedback
            </CustomButton>
            <CustomButton onClick={() => addQuesType("5")}>
              {" "}
              Pre Survey
            </CustomButton>
            <CustomButton onClick={() => addQuesType("6")}>
              {" "}
              Post Survey
            </CustomButton>
            <CustomButton onClick={() => addQuesType("7")}>
              {" "}
              Modular Quiz
            </CustomButton>
          </div>
        ) : null}
        {quizForm ? (
          <>
            {quizQuestion.map((question, qidx) => (
              <>
                {qidx == questionObj.currentq - 1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2rem",
                    }}
                    key={question.iid}
                  >
                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>question No:</h4>
                      </div>
                      <div className={classes.root}>
                        <h4 className={classes.textCenter}>
                          {qidx + 1} of {quizQuestion.length}
                        </h4>
                      </div>
                    </div>
                    <div className={classes.displayFlex}>
                      <div className={classes.flexBasis}>
                        <h4>Question</h4>
                      </div>
                      <div className={classes.root}>
                        {error1 ? (
                          <span className="color-red">
                            Question cannot be empty
                          </span>
                        ) : null}
                        <textarea
                          className="classTextarea"
                          value={question.istem}
                          autoComplete="off"
                          onChange={(e) => {
                            const istem = e.target.value;
                            setIstem(istem);
                            setQuizQuestion((currentistem) =>
                              produce(currentistem, (v) => {
                                v[questionObj.currentq - 1].istem = istem;
                              })
                            );
                          }}
                        ></textarea>
                      </div>
                    </div>
                    {optionFb && (
                      <div className={classes.displayFlex}>
                        <div className={classes.flexBasis}>
                          <h4>Image</h4>{" "}
                          <sub className={classes.small}>(Optional)</sub>
                        </div>

                        <div
                          className={classes.root}
                        // style={{ display: "grid" }}
                        >
                          {errlogo ? (
                            <span className="color-red">
                              Please select png, jpg, jpeg file types
                            </span>
                          ) : null}
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(e) => {
                              onFileChange(e);
                            }}
                          />

                          <label htmlFor="contained-button-file">
                            <Button
                              className={classes.uploadBtn}
                              component="span"
                            >
                              Upload
                            </Button>
                          </label>
                          {
                            <h4
                              style={{
                                display: "inline-block",
                                marginLeft: "2rem",
                              }}
                            >
                              {selectedFile !== null &&
                                selectedFile.file !== undefined
                                ? selectedFile.file.name
                                : ""}
                            </h4>
                          }
                        </div>
                      </div>
                    )}

                    <div className={classes.displayFlex}>
                    {!optionFb ? (<div className={classes.flexBasis}>
                        <h4>Options:</h4>
                        <sub className={classes.small}>
                          (Select the correct option)
                        </sub>
                      </div> ): null}
                      <div className={classes.root}>
                        {error2 ? (
                          <span className="color-red">
                            You need to enter at least 2 options
                          </span>
                        ) : null}
                        {error3 ? (
                          <span className="color-red">
                            Please select the correct option
                          </span>
                        ) : null}
                        {error4 ? (
                          <span className="color-red">
                            You have selected an option for which no value has
                            been entered
                          </span>
                        ) : null}
                        {error5 ? (
                          <span className="color-red">
                            Option cannot just contain space
                          </span>
                        ) : null}
                        {error6 ? (
                          <span className="color-red">
                            You have entered an option by skipping one of the
                            previous one
                          </span>
                        ) : null}
                        {error7 ? (
                          <span className="color-red">
                            Please enter a feedback
                          </span>
                        ) : null}
                        {error8 ? (
                          <span className="color-red">
                            Entered options must have different values
                          </span>
                        ) : null}
                        {!optionFb ? (
                          <FormControl
                            component="fieldset"
                            fullWidth
                            style={{ overflowY: "auto", maxHeight: "330px" }}
                          >
                            <RadioGroup
                              onChange={(e) => handleChange(e, qidx)}
                              value={question.correctoption}
                            >
                              {question.iopts.map((opts, oidx) => (
                                <>
                                  <FormControlLabel
                                    value={oidx.toString()}
                                    control={<BlueRadio />}
                                    label={
                                      <input
                                        value={opts.content}
                                        className="classInput"
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) =>
                                          handleInputChange(e, oidx, qidx)
                                        }
                                      />
                                    }
                                  />
                                  {feedbackView && opts.content !== "" ? (
                                    <input
                                      value={opts.feedback}
                                      className="classInputFeedback"
                                      type="text"
                                      name="feedback"
                                      autoComplete="off"
                                      placeholder="Feedback"
                                      onChange={(e) =>
                                        handleFeedbackChange(e, oidx, qidx)
                                      }
                                    />
                                  ) : null}
                                </>
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : null}

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            gap: "2rem",
                            marginTop: "2rem",
                          }}
                        >
                          <CustomButton
                            type="submit"
                            disabled={
                              questionObj.currentq - 1 === 0 ? true : false
                            }
                            onClick={() => {
                              let qcurrentq = questionObj.currentq - 1;

                              if (qcurrentq > 0) {
                                questionObj.currentq = qcurrentq;
                                setQuestionObj((currentQuestion) => ({
                                  ...questionObj,
                                }));
                              }
                              prevQues(question);
                            }}
                          >
                            Previous
                          </CustomButton>
                          <CustomButton
                            type="submit"
                            disabled={
                              questionObj.currentq + 1 <= quizQuestion.length
                                ? false
                                : true
                            }
                            onClick={() => {
                              nextQues(question, questionObj);

                            }}
                          >
                            Next
                          </CustomButton>
                          <CustomButton
                            type="submit"
                            onClick={() => {
                              validate(question);
                            }}
                          >
                            Add question
                          </CustomButton>
                          <CustomButton
                            type="submit"
                            disabled={quizQuestion.length === 1 ? true : false}
                            onClick={() => {
                              const filteredArray = quizQuestion.filter(
                                (rvques) => rvques.iid !== question.iid
                              );
                              setQuizQuestion(filteredArray);

                              if (questionObj.currentq != 1) {
                                questionObj.currentq = questionObj.currentq - 1;
                              }
                              setQuestionObj((currentstate) => ({
                                ...questionObj,
                              }));
                            }}
                          >
                            Remove question
                          </CustomButton>
                          <CustomButton
                            type="submit"
                            onClick={() => quizSave(question)}
                          >
                            Save
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
        ) : null}
        {typesQuiz ? <>
          {!isLoading && quesType == "2" && catList && catList.map((list, lind) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
              }}
              key={list.iid}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div style={{ display: 'contents' }}>
                  <p style={{ lineHeight: '58px', marginRight: '10px', fontSize: '14px' }}>{list.catename}</p>

                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ lineHeight: '58px',fontSize: '14px' }}>Percentage of Qs</p>
                  <FormControl sx={{ m: 1, minWidth: 120 }}   >
                    <Select key={lind} onChange={(e) => {
                      list.pval = e.target.value;
                    }} defaultValue={25}
                      className={classes.customInputLabel}>
                      {perval.map((pval, pidx) => (
                        <MenuItem key={pidx} value={pval} sx={{ fontSize: '14px' }}>{pval}%</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          ))}
          {!isLoading && quesType == "3" && Object.keys(attempt).map((key, aidx) => (
            <>
              {!isLoading && attempt[key].catList && <h4 style={{ width: '100%', textAlign: 'left', fontWeight: 'bold' }}> Attempt {key}</h4>}
              {attempt[key].catList && attempt[key].catList.map((list, lind) => (
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
                  key={aidx}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'contents' }}>
                      <p style={{ lineHeight: '58px', marginRight: '10px', fontSize: '14px' }}>{list.catename}</p>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ lineHeight: '58px', fontSize: '14px' }}>Percentage of Qs</p>
                      <FormControl sx={{ m: 1, minWidth: 120 }}   >
                        <Select key={aidx + lind} onChange={(e) => {
                          list.pval = e.target.value;
                        }} defaultValue={25}
                          className={classes.customInputLabel}>
                          {perval.map((pval, pidx) => (
                            <MenuItem key={pidx} sx={{ fontSize: '14px' }} value={pval}>{pval}%</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ))}

            </>
          ))}

          {!isLoading && catList && <div className={classes.displayFlex}>
            <div className={classes.root}>
              {error2 ? (
                <span className="color-red">
                  You need to enter at least 2 options
                </span>
              ) : null}
              {error3 ? (
                <span className="color-red">
                  Please select the correct option
                </span>
              ) : null}
            </div>
          </div>}
          {!isLoading && catList && <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: "2rem",
            marginTop: "2rem",
          }}
          >

            <CustomButton onClick={() => {
              handleSave(catList)
            }} >
              Save
            </CustomButton>

          </div>}
          {isLoading && <CircularProgress style={{ color: config.main_color_1 }} />}
        </> : null}
      </div>
    </div>
  );
}
