import React, { useEffect, useState } from "react";
import swal from "sweetalert";

import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

import { produce } from "immer";

import moment from "moment";
import "./LiveSession.scss";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const BlueRadio = withStyles({
  root: {
    color: "#01498e",
    "&$checked": {
      color: "#01498e",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const initialValidation = {
  error1: false,
  error2: false,
  error3: false,
  error4: false,
  error5: false,
  error6: false,
  error7: false,
  error8: false,
  error9: false,
  error10: false,
  error11: false,
  error12: false,
};
export default function LiveSession({
  handleClose,
  seteditsession,
  liveSessionData,
  addtype,
  coursetype,
}) {
  const [state, setState] = useState({});
  const [firstViewValidation, setFirstViewValidation] =
    useState(initialValidation);

  const [disableamount, setDisableAmount] = useState(false);
  let userDetails = useSelector(authData);
  const [spin, setSpin] = useState(false);
  const [editViewPayment, setEditViewPayment] = React.useState("");
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    let cursession = { ...liveSessionData };
    // alert(moment(cursession.start).format("yyyy-MM-DD"));
    cursession = produce(cursession, (v) => {
      var hours = Math.floor(cursession.duration / 60);
      var minutes = cursession.duration % 60;
      v.title = cursession.title;
      v.eid = cursession.eid;
      v.date = moment(cursession.start).format("yyyy-MM-DD");
      v.time = moment(cursession.start).format("HH:mm");
      v.dhr = parseInt(hours);
      v.dmin = parseInt(minutes);
      v.description = cursession.description;
      v.link = cursession.link;
      v.webid = cursession.webid;
      v.passcode = cursession.passcode;
      v.payment = cursession.payment;
      v.rupees = parseInt(cursession.amount);
    });
    if (addtype == "update") {
      if (cursession.payment == "paid") {
        setDisableAmount(false);
      } else {
        setDisableAmount(true);
      }
      setEditViewPayment(cursession.payment);
      setState(cursession);
    }
  }, []);
  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  const handleSubmit = () => {
    setFirstViewValidation(initialValidation);
    if (
      state.title === undefined ||
      state.title === "" ||
      state.title.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error1: true });
      return;
    } else {
      setFirstViewValidation({ error1: false });
    }
    if (
      state.date === undefined ||
      state.date === "" ||
      state.date.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error2: true });
      return;
    } else {
      setFirstViewValidation({ error2: false });
    }
    if (
      state.time === undefined ||
      state.time === "" ||
      state.time.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error3: true });
      return;
    } else {
      setFirstViewValidation({ error3: false });
    }

    if (
      state.dhr === undefined ||
      state.dhr === "" ||
      state.dhr.length === 0 ||
      state.dmin === undefined ||
      state.dmin === "" ||
      state.dmin.length === 0
    ) {
      setFirstViewValidation({ error11: true });
      return;
    } else {
      setFirstViewValidation({ error11: false });
    }
    if (
      state.description === undefined ||
      state.description === "" ||
      state.description.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error5: true });
      return;
    } else {
      setFirstViewValidation({ error5: false });
    }
    if (
      state.link === undefined ||
      state.link === "" ||
      state.link.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error4: true });
      return;
    } else {
      setFirstViewValidation({ error4: false });
    }
    if (!validURL(state.link)) {
      setFirstViewValidation({ error10: true });
      return;
    } else {
      setFirstViewValidation({ error10: false });
    }

    if (
      state.webid === undefined ||
      state.webid === "" ||
      state.webid.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error6: true });
      return;
    } else {
      setFirstViewValidation({ error6: false });
    }
    if (
      state.passcode === undefined ||
      state.passcode === "" ||
      state.passcode.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error7: true });
      return;
    } else {
      setFirstViewValidation({ error7: false });
    }

    if (
      state.payment === undefined ||
      state.payment === "" ||
      state.payment.replace(/\s/g, "").length === 0
    ) {
      setFirstViewValidation({ error8: true });
      return;
    } else {
      setFirstViewValidation({ error8: false });
    }

    if (!disableamount) {
      if (
        state.payment != "free" &&
        (state.rupees === undefined ||
          state.rupees === "" ||
          state.rupees.length === 0)
      ) {
        setFirstViewValidation({ error9: true });
        return;
      } else {
        setFirstViewValidation({ error9: false });
      }

      if (
        state.payment != "free" &&
        state.rupees !== undefined &&
        state.rupees > 0
      ) {
        setFirstViewValidation({ error12: false });
      } else {
        setFirstViewValidation({ error12: true });
        return;
      }
    }
    addEvent();
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  async function addEvent() {
    setSpin(true);
    let timestamp = new Date().getTime();

    let duration = parseInt(state.dhr) * 60 + parseInt(state.dmin);

    let date = new Date((state.date + " " + state.time).split(" ").join("T"));

    date = date.getTime();

    const bodyParam = {
      body: {
        annjson: {
          eid: state.eid != undefined ? state.eid : timestamp,
          start: date,
          title: state.title,
          duration: duration,
          description: state.description,
          link: state.link,
          webid: state.webid,
          passcode: state.passcode,
          payment: state.payment,
          amount: state.rupees == undefined ? 0 : state.rupees,
          cby: userDetails.name,
          cbyid: userDetails.eid,
          bpid: userDetails.curprgcou.bpid,
          type: "livesession",
        },

        rtype: addtype,
        oid: config.aws_org_id,
        bpid: userDetails.curprgcou.bpid,
        // topicid: `${coursetype == "batch" ? undefined : userDetails.btid}`,
        action: `${coursetype == "batch" ? "livesessions" : "clivesessions"}`,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (coursetype != "batch") {
      bodyParam.body.topicid = userDetails.btid;
      bodyParam.body.annjson.topicid = userDetails.btid;
    }

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_EVENTS,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );

      // const users = response;
      swal({
        title: "Success",
        text: "Live Session Added successfully",
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.reload();
          handleClose();
        }
      });
      setSpin(false);
    } catch (error) {
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          // setSpin(false);
        }
      });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }
  const handlePayment = (e) => {
    if (e.target.value == "free") {
      setState((curstate) =>
        produce(curstate, (v) => {
          v.payment = e.target.value;
        })
      );
      setEditViewPayment("free");
      setDisableAmount(true);
    } else {
      setState((curstate) =>
        produce(curstate, (v) => {
          v.payment = e.target.value;
        })
      );
      setEditViewPayment("paid");
      setDisableAmount(false);
    }
  };

  return (
    <div>
      <table className="liveSession__table">
        <tr>
          <td>Title</td>
          <td>
            <div>
              <input
                onChange={(event) => {
                  setState((curstate) =>
                    produce(curstate, (v) => {
                      v.title = event.target.value;
                    })
                  );
                }}
                title="liveSessionInput"
                type="text"
                value={state.title}
              />
              {firstViewValidation.error1 && (
                <p className="firstViewValidation">Title cannot be empty</p>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td>Date & time</td>
          <td>
            <div style={{ display: "flex", gap: "3rem" }}>
              <div>
                <input
                  onChange={(event) => {
                    setState((curstate) =>
                      produce(curstate, (v) => {
                        v.date = event.target.value;
                      })
                    );
                  }}
                  type="date"
                  value={state.date}
                  min={disablePastDate()}
                />
              </div>
              <div>
                <input
                  type="time"
                  onChange={(e) => {
                    setState((curstate) =>
                      produce(curstate, (v) => {
                        v.time = e.target.value;
                      })
                    );
                  }}
                  value={state.time}
                />
              </div>
              {(firstViewValidation.error2 || firstViewValidation.error3) && (
                <p className="firstViewValidation">
                  Date and time cannot be empty
                </p>
              )}
            </div>
          </td>
        </tr>
        <tr>
          <td>Duration</td>
          <td>
            <div style={{ display: "flex", gap: "2rem" }}>
              <div style={{ display: "flex" }}>
                <input
                  style={{ width: "50px", height: "30px" }}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setState((currState) =>
                        produce(currState, (v) => {
                          v.dhr = e.target.value;
                        })
                      );
                    }
                  }}
                  value={state.dhr}
                  maxLength="2"
                  min="0"
                  type="number"
                />
                <span>Hr</span>
              </div>
              <div style={{ display: "flex" }}>
                <input
                  style={{ width: "50px", height: "30px" }}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setState((currState) =>
                        produce(currState, (v) => {
                          v.dmin = e.target.value;
                        })
                      );
                    }
                  }}
                  value={state.dmin}
                  maxLength="2"
                  min="0"
                  type="number"
                />
                <span>Min</span>
              </div>
            </div>
            {firstViewValidation.error11 && (
              <p className="firstViewValidation">Duration cannot be Zero</p>
            )}
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Description</td>
          <td>
            <div>
              <textarea
                onChange={(event) => {
                  setState((curstate) =>
                    produce(curstate, (v) => {
                      v.description = event.target.value;
                    })
                  );
                }}
                value={state.description}
                rows="5"
                className="LDescription"
                style={{ width: "100%", resize: "none" }}
              ></textarea>
            </div>
            {firstViewValidation.error5 && (
              <p className="firstViewValidation">Please enter description.</p>
            )}
          </td>
        </tr>
        <tr>
          <td>Link</td>
          <td>
            <div>
              <input
                onChange={(event) => {
                  setState((curstate) =>
                    produce(curstate, (v) => {
                      v.link = event.target.value;
                    })
                  );
                }}
                value={state.link}
                title="liveSessionInput"
                type="text"
              />
            </div>
            {firstViewValidation.error4 && (
              <p className="firstViewValidation">Field cannot be empty.</p>
            )}
            {firstViewValidation.error10 && (
              <p className="firstViewValidation">
                Please enter right URL format.
              </p>
            )}
          </td>
        </tr>
        <tr>
          <td>Webinar ID</td>
          <td>
            <div>
              <input
                onChange={(event) => {
                  setState((curstate) =>
                    produce(curstate, (v) => {
                      v.webid = event.target.value;
                    })
                  );
                }}
                value={state.webid}
                title="liveSessionInput"
                type="text"
              />
            </div>
            {firstViewValidation.error6 && (
              <p className="firstViewValidation">Please enter Webinar ID.</p>
            )}
          </td>
        </tr>
        <tr>
          <td>Passcode</td>
          <td>
            <div>
              <input
                onChange={(event) => {
                  setState((curstate) =>
                    produce(curstate, (v) => {
                      v.passcode = event.target.value;
                    })
                  );
                }}
                value={state.passcode}
                title="liveSessionInput"
                type="text"
              />
            </div>
            {firstViewValidation.error7 && (
              <p className="firstViewValidation">Please enter Passcode.</p>
            )}
          </td>
        </tr>
        <tr>
          <td>Payment</td>
          <td>
            <div style={{ display: "flex" }}>
              <FormControl
                component="fieldset"
                fullWidth
                style={{ overflowY: "auto", maxHeight: "330px" }}
              >
                <RadioGroup
                  row
                  onChange={handlePayment}
                  value={editViewPayment}
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="free"
                    control={<BlueRadio />}
                    label="Free"
                  />
                  <FormControlLabel
                    value="paid"
                    control={<BlueRadio />}
                    label="Paid"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {firstViewValidation.error8 && (
              <p className="firstViewValidation">
                Please select payment method.
              </p>
            )}
          </td>
        </tr>
        {!disableamount && (
          <tr>
            <td>Amount in Rupees</td>
            <td>
              <div>
                <input
                  value={state.rupees == 0 ? "" : state.rupees}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setState((currState) =>
                        produce(currState, (v) => {
                          v.rupees = e.target.value;
                        })
                      );
                    }
                  }}
                  title="liveSessionInput"
                  type="text"
                />
              </div>
              {firstViewValidation.error9 && (
                <p className="firstViewValidation">
                  Please enter the amount to pay in rupees.
                </p>
              )}
              {firstViewValidation.error12 && (
                <p className="firstViewValidation">
                  Amount should be greater than zero.
                </p>
              )}
            </td>
          </tr>
        )}
      </table>
      <div style={{ display: "grid", placeItems: "center" }}>
        <button onClick={handleSubmit} className="add" disabled={spin}>
          {spin ? "Loading..." : "Add"}
        </button>
      </div>
    </div>
  );
}
