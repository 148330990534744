// Dependencies imports
import React from "react";

import config from "../../config/aws-exports";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& .super-app-theme--header": {
      background: config.main_color_1,
      fontSize: "16px",
      color: "rgb(255,255,255)",
      fontFamily: "nunito",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
    },
  },

  ".MuiDataGrid-root": {
    fontSize: "15px",
    fontFamily: "nunito",
    fontWeight: 400,
    color: "black",
    cursor: "pointer",
    width: "100%",
    overflow: "auto",
  },
});

// Start of Reports component
const FeedbackByOthers = (props) => {
  let {
    iFeedBackData,
    oFeedBackData,
    vtype,
    sfdLoading,
    Cdfeedback,
    cid,
    setLoading,
    getOtherFeedBack,
    CFeedBackData,
  } = props;

  const classes = useStyles();
 
  return (
    <div style={{ height: 400, width: "100%" }}>
      {!sfdLoading && iFeedBackData?.users?.length > 0 ? (
        <div className={classes.root}>
          <div style={{ height: 400 }}>
            <DataGrid
              rows={iFeedBackData.users}
              columns={iFeedBackData.columns}
              disableSelectionOnClick
              onRowSelected={(e) => console.log("eeee==", e)}
              className={classes[".MuiDataGrid-root"]}
            />
          </div>
        </div>
      ) : null}
      {!sfdLoading && oFeedBackData?.users?.length > 0 ? (
        <div className={classes.root}>
          <div style={{ height: 400 }}>
            
            <DataGrid
              rows={oFeedBackData.users}
              columns={oFeedBackData.columns}
              disableSelectionOnClick
              onRowSelected={(e) => console.log(e)}
              className={classes[".MuiDataGrid-root"]}
            />
          </div>
        </div>
      ) : null}
      {!sfdLoading && CFeedBackData?.users?.length > 0 ? (
        <div className={classes.root}>
          <div style={{ height: 400 }}>
            
            <DataGrid
              rows={CFeedBackData.users}
              columns={CFeedBackData.columns}
              disableSelectionOnClick
              onRowSelected={(e) => console.log(e)}
              className={classes[".MuiDataGrid-root"]}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FeedbackByOthers;
