import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Fade,
  Backdrop,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  makeStyles,
  TextareaAutosize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import swal from "sweetalert";
import { produce } from "immer";
import shortid from "shortid";
import add from './AddRecording.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function DialogBox({
  type,
  nopen,
  setNuggets,
  nuggets,
  setNopen,
  setIsLoading,
  courseDetails,
  setCourseDetails,
  errors,
  setErrors,
  unitIndex,
  setEditObj,
  editObj,
}) {
  const refnuggetValue = useRef();
  const refUnitValue = useRef("");
  const [fromData, setFromData] = useState({ uval: "", nval: "" });
  const [isdload, setIsdload] = useState(false);
  const classes = useStyles();
  function handleNuggetsClose() {
    setNopen(false);
    setIsLoading(false);
  }
  return (
    <Dialog
      open={nopen}
      fullWidth="true"
      maxWidth="sm"
      onClose={handleNuggetsClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" ><p style={{fontSize:'20px'}}>Add {type} </p></DialogTitle>
      <DialogContent>
        {!isdload && (
          <div>
            {type == "Unit" ? (
              <TextField
                margin="dense"
                id="uname"
                label="Unit Name"
                type="text"
                fullWidth
                inputProps={{ style: { fontSize: 18 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 18 } }}
                onChange={(event) => {
                  const { value } = event.target;
                  refUnitValue.current = value;
                }}
              />
            ) : (
              <TextField
                margin="dense"
                id="name"
                label={`${type} Name`}
                type="text"
                fullWidth
                inputProps={{ style: { fontSize: 18 } }} // font size of input text
                InputLabelProps={{ style: { fontSize: 18 } }}
                onChange={(event) => {
                  const { value } = event.target;
                  refnuggetValue.current = value;

                  // setrefUnitValue({nugget: value});
                }}
              />
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleNuggetsClose} color="primary" style={{fontSize:'12px'}}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            setIsdload(true);
            let tmpnt = [...nuggets];
            let objs = {};
            let cnid = 0;
            errors.uval = false;
            errors.nval = false;

            console.log(courseDetails);
            // setCourseDetails({ ...courseDetails, totalNuggets: cnid });
            if (type === "Unit") {
              if (
                refUnitValue.current === undefined ||
                refUnitValue.current.toString().replace(/\s/g, "").length === 0
              ) {
                swal("Oops!", "Please Enter Unit Name", "error");
                setIsdload(false);
              } else {
                tmpnt = produce(tmpnt, (v) => {
                  v.push({
                    unit_id:
                      v.length === 0 ? 0 : tmpnt[tmpnt.length - 1].unit_id + 1,
                    uname: refUnitValue.current,
                    nuggets: [],
                  });
                });
                setNuggets(tmpnt);
                editObj.meditmode = true;

                setEditObj({ ...editObj });
                handleNuggetsClose();
              }
            } else {
              if (
                refnuggetValue.current === undefined ||
                refnuggetValue.current.toString().replace(/\s/g, "").length ===
                  0
              ) {
                swal("Oops!", "Please Enter Module Name", "error");
                setIsdload(false);
              } else {
                setIsLoading(true);
                setIsdload(false);
                console.log(nuggets);
                cnid = courseDetails.totalNuggets + 1;
                let courseDetail = { ...courseDetails };
                courseDetail = produce(courseDetail, (draft) => {
                  draft.totalNuggets = cnid;
                });
                setCourseDetails(courseDetail);
                objs.nid = shortid.generate();
                objs.enabled = false;
                objs.ntitle = refnuggetValue.current;
                objs.objects = [];
                objs.unit = refUnitValue.current;
                tmpnt = produce(tmpnt, (draft) => {
                  draft[unitIndex].nuggets.push(objs);
                });
                setNuggets(tmpnt);
                console.log(JSON.stringify(tmpnt, null, 2));
                editObj.meditmode = true;

                setEditObj({ ...editObj });
                handleNuggetsClose();
              }
            }
          }}
          color="primary"
         className={add.addbutton}
         style={{backgroundColor:'#3f51b5', color:'#fff', fontSize:'12px'}}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
