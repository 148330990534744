// Dependencies imports
import React, { useState, useEffect, useRef } from "react";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
// Local imports
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
// Styles imports
import assessStyle from "./Assessment.module.scss";
import Add from "./AddScore.module.scss";
import Done from "../../assets/svgjs/Done";
import { Image } from "semantic-ui-react";
import Reject from "../../assets/svgjs/Reject";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import config from "../../config/aws-exports";
import { API, Auth } from "aws-amplify";
import AssignmentFile from "./AssignmentFile";
import {
  List,
  ListItem,
  ListItemIcon,
  TextareaAutosize,
  Typography,
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  Grid,
  Modal,
  Button,
  Box,
} from "@material-ui/core";
import { Constants } from "../../config/constants";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: "transparent",
    padding: 0,
    height: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  root: {
    width: "100%",
    backgroundColor: "transparent",
    "& .MuiBox-root": {
      margin: "-7px",
      padding: "8px",
      backgroundColor: "white",
    },
  },
}));
const PopAssesment = (props) => {
  let { handleClose, usetData, quizId, type } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [assessAns, setAssessAns] = useState({});
  const [imgUrl, setImgUrl] = useState();
  const [formatCurDate, setFormatCurDate] = useState(0);
  const [assessmentData, setAssessmentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fileobj, setFileobj] = useState();
  const [open, setOpen] = useState(false);
  const [score, setScore] = useState("");
  const [errorTscore, setErrorTscore] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  let userDetails = useSelector(authData);
  console.log("row" + JSON.stringify(usetData));
  // console.log("udata"+JSON.stringify(userDetails));

  useEffect(() => {
    getAssessmentData(userDetails);
    formatDate();
    let sdata = { ...userDetails };
    if (usetData.qscore == "Not Graded" || usetData.score == "Submited") {
      setScore(0);
    } else if (
      usetData.qscore !== undefined ||
      usetData.qscore === 0 ||
      usetData.qscore === "0"
    ) {
      setScore(usetData.qscore);
    }

    setImgUrl(
      `https://${
        config.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/assessment-images/${
        userDetails.asses.btid
      }/${quizId}/`
    );
    dispatch(awsSignIn(sdata));
  }, []);
  async function getAssessmentData(userDetails) {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: usetData.eid,
        bpid: userDetails.curprgcou.bpid,
        assessid: userDetails.asses.btid,
        quizid: quizId,
        quiztype: "assess",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSESSMENT_ANSWERS,
        bodyParam
      );
      const { assessment } = response;
      console.log("responseassessAns===" + JSON.stringify(response));
      setAssessmentData(assessment);
      let totalscore = 0;
      for (var l = 0; l < response.qitems.length; l++) {
        totalscore += parseInt(response.qitems[l].qscore);
      }
      response.totalscore = totalscore;

      setAssessAns(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const handleFileClose = () => {
    setOpen(false);
  };
  function viewAns() {
    if (
      assessAns.response !== undefined &&
      assessAns.response.response !== undefined
    ) {
      if (
        assessAns.response.response[currentQuestion].iid ===
        assessAns.qitems[currentQuestion].iid
      ) {
        if (
          assessAns.qitems[currentQuestion].atype == "3" ||
          assessAns.qitems[currentQuestion].atype == "5"
        ) {
          if (
            assessAns.response.response[currentQuestion].response !== undefined
          ) {
            var pp = [];
            var sp = "";
            assessAns.response.response[currentQuestion].response.forEach(
              (element) => {
                if (
                  assessAns.qitems[currentQuestion].iopts[element].content ==
                  undefined
                ) {
                  pp.push(
                    <img
                      style={{ margin: "5px", width: "450px" }}
                      src={
                        imgUrl +
                        assessAns.qitems[currentQuestion].iopts[element].url
                      }
                      rounded
                    />
                  );
                } else {
                  sp +=
                    sp !== ""
                      ? "," +
                        assessAns.qitems[currentQuestion].iopts[element].content
                      : assessAns.qitems[currentQuestion].iopts[element]
                          .content;
                }
              }
            );
            if (pp.length > 0) {
              return pp;
            } else {
              return sp;
            }
          }
        } else if (assessAns.qitems[currentQuestion].atype == "7") {
          return assessAns.response.response[currentQuestion].response;
        } else if (assessAns.qitems[currentQuestion].atype == "8") {
          return (
            <p
              style={{
                cursor: "pointer",
                textDecoration: "underlined",
                paddingLeft: "10px",
                color: "blue",
              }}
              onClick={() => {
                addLink(assessAns.response.response[currentQuestion]);
              }}
            >
              {assessAns.response.response[currentQuestion].fname}
            </p>
          );
        } else if (
          assessAns.qitems[currentQuestion].atype != undefined &&
          (assessAns.qitems[currentQuestion].atype == "1" ||
            assessAns.qitems[currentQuestion].atype == "2" ||
            assessAns.qitems[currentQuestion].atype == "4" ||
            assessAns.qitems[currentQuestion].atype == "6")
        ) {
          if (
            assessAns.qitems[currentQuestion].iopts[
              assessAns.response.response[currentQuestion].response
            ].url != undefined
          ) {
            return (
              <Image
                style={{ margin: "5px", width: "450px" }}
                src={
                  imgUrl +
                  assessAns.qitems[currentQuestion].iopts[
                    assessAns.response.response[currentQuestion].response
                  ].url
                }
                rounded
              />
            );
          } else {
            return assessAns.qitems[currentQuestion].iopts[
              assessAns.response.response[currentQuestion].response
            ].content;
          }
        }
      } else {
        return null;
      }
    } else {
      return <div style={{ color: "red" }}> You have not answerd </div>;
    }
  }
  function addLink(item) {
    setFileobj(item);
    handleFileOpen();
  }
  const handleFileOpen = () => {
    setOpen(true);
  };
  function answerPrev() {
    console.log("curr", currentQuestion);
    const prevQuestion = currentQuestion - 1;

    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    // console.log("curr", currentQuestion);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < assessAns.qitems.length) {
      setCurrentQuestion(nextQuestion);
    }
    console.log("curr", currentQuestion);
  }
  function formatDate() {
    //let some = toTimestamp(moment().format('YYYY-MM-DD h:mm:ss a'));
    setInterval(() => {
      setFormatCurDate(toTimestamp(moment().format("YYYY-MM-DD h:mm:ss a")));
    }, 2000);

    /* console.log(currentDate);
  let res = currentDate.split("-");
      if(res[1] != 0){
        res[1] = res[1] - 1;
      }
  let d = new Date(res[0], res[1], res[2]);
  setFormatCurDate(d.getTime());
  console.log("d",formatCurDate);
  console.log("date",moment(formatCurDate).format("DD/MM/YYYY  h:mm:ss a")) */
  }
  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1;
  }
  function handleChange(e) {
    setErrorTscore(false);
    if (e.target.value <= assessAns.totalscore) {
      setScore(e.target.value);
    } else {
      setErrorTscore(true);
    }
  }
  async function gradeAssesment(score) {
    setLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: usetData.eid,
        bpid: userDetails.curprgcou.bpid,
        assessid: userDetails.asses.btid,
        quizid: quizId,
        score: score,
        quiztype: "assess",
        tscore: assessAns.totalscore,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_UPDATE_ASSESMENT_REPORT,
        bodyParam
      );
      const { assessment } = response;
      console.log("response" + JSON.stringify(response));
      handleClose();
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className={Add.mainpopup}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AssignmentFile
            handleFileClose={handleFileClose}
            fileobj={fileobj}
            quizId={quizId}
            userData={usetData}
            type={type}
          />
        </Fade>
      </Modal>
      <p onClick={handleClose}>X</p>
      {/* <h3 >Add Score</h3>
            <button >Submit</button> */}
      <div style={modalStyle} className={classes.paper}>
        <div className={assessStyle.bodyAns}>
          {/*  <pre style={{ color: "white" }}>
                {quizQuestion === undefined || quizQuestion.length === 0 ? (
                  <p>p </p>
                ) : quizQuestion.istem === undefined ? null : (
                  <pre>
                    {console.log("ll", quizQuestion[currentQuestion].istem)}
                  </pre>
                )}
              </pre> */}

          {assessAns.qitems === undefined || assessAns.qitems.length === 0 ? (
            <div style={{ fontSize: "15px" }}>Loading.. </div>
          ) : assessAns.qitems[currentQuestion].atype !== undefined ? (
            <div>
              <div className={assessStyle.questionsection}>
                <div
                  style={{
                    fontWeight: "bold",
                    float: "right",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenAnswer(false);
                    setCurrentQuestion(0);

                    setAssessAns([]);
                  }}
                >
                  {" "}
                  {/* X{" "} */}
                  <p onClick={handleClose}>X</p>
                </div>
                <h3 className={Add.header}>Add Score</h3>
                <div style={{ display: "flex", gap: "62%" }}>
                  <h4 className={Add.over}>Overall Score: {score}</h4>
                  <h4 className={Add.over}>
                    Total Score: {assessAns.totalscore}
                  </h4>
                </div>

                {errorTscore && (
                  <h5 style={{ color: "red", padding: "0 0 10px" }}>
                    score should be less than total score or equal
                  </h5>
                )}
                <div className={Add.overholder}>
                  <input
                    value={score}
                    onChange={(e) => handleChange(e)}
                    className={Add.overinput}
                  ></input>
                  <button
                    onClick={() => {
                      if (
                        score.replace(/\s/g, "").length !== 0 &&
                        score <= assessAns.totalscore
                      ) {
                        gradeAssesment(score);
                      } else {
                        setErrorTscore(true);
                      }
                    }}
                    className={Add.submit}
                  >
                    Submit
                  </button>
                </div>
                <div className={assessStyle.questioncount}>
                  <span>Question {currentQuestion + 1}</span>/
                  {assessAns.qitems.length}
                </div>
                <div className={assessStyle.questiontext}>
                  {assessAns.qitems[currentQuestion].atype != "6" &&
                    assessAns.qitems[currentQuestion].istem}
                  {assessAns.qitems[currentQuestion].atype == "6" && (
                    <p
                      className={assessStyle.matchThe}
                      dangerouslySetInnerHTML={{
                        __html: assessAns.qitems[currentQuestion].istem,
                      }}
                    ></p>
                  )}
                  {assessAns.qitems[currentQuestion].img ? (
                    <div className="img_container">
                      <img
                        src={`${imgUrl}${assessAns.qitems[currentQuestion].img}`}
                        alt="item"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={assessStyle.answersection}>
                <div className={"btn-group " + assessStyle.btngroupvertical}>
                  {assessAns.qitems[currentQuestion].atype == "1" ||
                  assessAns.qitems[currentQuestion].atype == "2" ||
                  assessAns.qitems[currentQuestion].atype == "3" ||
                  assessAns.qitems[currentQuestion].atype == "4" ||
                  assessAns.qitems[currentQuestion].atype == "5" ||
                  assessAns.qitems[currentQuestion].atype == "6"
                    ? assessAns.qitems[currentQuestion].iopts.map(
                        (answerOption, index, arrayobj) => (
                          <>
                            <div style={{}}>
                              <div>
                                {/* <input
                                  type="radio"
                                  className="btn-check"
                                  name="options"
                                  id={answerOption.content}
                                /> */}
                                <div style={{ display: "flex" }}>
                                  <label
                                    className={
                                      assessStyle.labelstyleAns +
                                      " " +
                                      assessStyle.buttonQuizAns
                                    }
                                    for={answerOption.content}
                                  >
                                    {answerOption.content}
                                    {answerOption.url ? (
                                      <Image
                                        src={imgUrl + answerOption.url}
                                        rounded
                                      />
                                    ) : null}
                                  </label>
                                  <span>
                                    {answerOption.correct === "true" ||
                                    answerOption.correct === true ? (
                                      <Done
                                        className="right_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    ) : (
                                      <Reject
                                        className="Wrong_size"
                                        cls1="cls1_D"
                                        cls2="cls2_D"
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )
                    : null}
                </div>
              </div>
              <br />

              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  display: "flex",
                }}
              >
                <div style={{ width: "115px" }}>
                  <p>Student Answer :</p>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    margin: "0 0 10px 10px",
                    width: "85%",
                    textAlign: "start",
                    wordWrap: "break-word",
                  }}
                >
                  {
                    viewAns()

                    /*  */
                  }
                </div>
              </div>
              {currentQuestion > 0 ? (
                <Button
                  color="primary"
                  variant="contained"
                  className="btn-size"
                  onClick={() => answerPrev()}
                  style={{ fontSize: "12px" }}
                >
                  Prev
                </Button>
              ) : null}
              {currentQuestion + 1 === assessAns.qitems.length ? (
                <div></div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  className="btn-siz"
                  onClick={() => answerNext()}
                  style={{ float: "right", fontSize: "12px" }}
                >
                  Next
                </Button>
              )}
            </div>
          ) : (
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  float: "right",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenAnswer(false);
                  setCurrentQuestion(0);
                  setAssessAns([]);
                }}
              >
                {" "}
                {/* X{" "} */}
                <p onClick={handleClose}>X</p>
              </div>
              <div style={{}}>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  {" "}
                  No Answers
                </p>
              </div>
            </div>
          )}
          {/* <h3 className={Add.header}>Add Score</h3>
      <h4 className={Add.over}>Overall Score: </h4>
      <div className={Add.overholder}>
        <input value={score} onChange={(e) => handleChange(e)} className={Add.overinput}></input>
        <button onClick={() => gradeAssesment(score)} className={Add.submit}>Submit</button>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default PopAssesment;
