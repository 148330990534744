import React, { useState, useRef, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import Cryptr from "cryptr";
import { useDispatch } from "react-redux";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { awsSignIn } from "../../redux/auth/authSlice";
import { SignIn } from "../../redux/auth/loginSlice";
import {
  Image,
} from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import Password from "../../assets/svgjs/password";

import landingStyle from "./landingScreen.module.scss";
import { ReactComponent as Close } from "../../assets/svg/close_black_24dp.svg";

import EmailIcon from "../../assets/svgjs/EmailIcon.js";
import UsersIcon from "../../assets/svgjs/UsersIcon";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  modal: {
    "& .MuiInputBase-input": {
      fontSize: "14px",
      paddingLeft: "34px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    textBox: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
      },
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    margin: "auto",
    height: "auto",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  SignInPaper: {
    backgroundColor: theme.palette.background.paper,

    height: "auto",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  forgotPasswordPaper: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center",
    margin: "auto",
    height: "auto",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  ResetPasswordPaper: {
    backgroundColor: theme.palette.background.paper,

    height: "auto",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  ResetPasswordPaperFirst: {
    backgroundColor: theme.palette.background.paper,

    height: "auto",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },

  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
      marginBottom: "15px",
    },
  },
}));

function AppHeader(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailTextIssue, setEmailTextIssue] = useState("");
  const [passTextIssue, setPassTextIssue] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [resetPasswordOpenFirst, setResetPasswordOpenFirst] = useState(false);
  const [cognitoErrorUsername, setcognitoErrorUsername] = useState("");
  const [cognitoErrorPassword, setcognitoErrorPassword] = useState("");
  let navigate = useNavigate();
  const emailInput = useRef();
  const passwordInput = useRef();
  const clearEmail = () => emailInput.current.clear();

  //register
  const [name, setName] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [regConfirmPassword, setRegConfirmPassword] = useState("");
  const [regCognitoErrorUsername, setRegCognitoErrorUsername] = useState("");
  const [regCognitoErrorPassword, setRegCognitoErrorPassword] = useState("");
  const [regEmailTextIssue, setRegEmailTextIssue] = useState("");
  const [regNameTextIssue, setRegNameTextIssue] = useState("");
  const [regPassTextIssue, setRegPassTextIssue] = useState("");
  const [regConfirmpassTextIssue, setRegConfrimPassTextIssue] = useState("");

  //forgot password
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordEmailTextIssue, setForgotPasswordEmailTextIssue] =
    useState("");
  const [
    forgotPasswordCognitoErrorUsername,
    setForgotPasswordCognitoErrorUsername,
  ] = useState("");

  //Reset password
  const [verificationCode, setVerificationCode] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [confirmResetPassword, setConfirmResetPassword] = useState("");
  const [resetPassVerificationCode, setResetPassVerificationCode] =
    useState("");
  const [resetPasswordCodeIssue, setResetPasswordCodeIssue] = useState("");
  const [resetPasswordMatch, setResetPasswordMatch] = useState("");
  //reset first time

  const [resetPasswordFirst, setResetPasswordFirst] = useState("");
  const [confirmResetPasswordFirst, setConfirmResetPasswordFirst] =
    useState("");
  const [resetPassVerificationCodeFirst, setResetPassVerificationCodeFirst] =
    useState("");
  const [resetPasswordCodeIssueFirst, setResetPasswordCodeIssueFirst] =
    useState("");
  const [resetPasswordMatchFirst, setResetPasswordMatchFirst] = useState("");

  const dispatch = useDispatch();
  const classes = useStyles();

  const [ApplicationCate, setApplicationCate] = useState({});
  const [certificateData, setCertificateData] = useState([]);
  const [diplomaData, setDiplomaData] = useState([]);
  const [graduateData, setGraduateData] = useState([]);
  const [pgDiplomaData, setPgDiplomaData] = useState([]);
  const [underGraduateData, setUnderGraduateData] = useState([]);


  const cryptr = new Cryptr("myTotalySecretKey");

  useEffect(() => {
    getProgramsList();
  }, []);

  async function getProgramsList() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ALL_PROGRAM,
        bodyParam
      );
      const ProgramsList = response;
      setApplicationCate(ProgramsList);
      setCertificateData(ProgramsList.certificate);
      setDiplomaData(ProgramsList.diploma);
      setGraduateData(ProgramsList.graduate);
      setPgDiplomaData(ProgramsList.pgdiploma);
      setUnderGraduateData(ProgramsList.undergraduate);
      
    } catch (error) {
      console.error(error);
    }
  }

  const openLogin = () => {
    setOpen(true);
    setSignInOpen(false);
  };

  const openSignIn = () => {
    setSignInOpen(true);
    setOpen(false);
  };

  const forgotPasswordSwap = () => {
    setForgotPasswordOpen(true);
    setOpen(false);
    setEmail("");
    setPassword("");
    setEmailTextIssue("");
    setPassTextIssue("");
  };

  const emailOnKeyPress = () => {
    setcognitoErrorUsername("");
  };
  const passwordOnKeyPress = () => {
    setcognitoErrorPassword("");
  };

  let SignInModal = () => {
    useEffect(() => {
      setRegEmailTextIssue(regCognitoErrorUsername);
      setRegPassTextIssue(regCognitoErrorPassword);
    }, [regCognitoErrorUsername, regCognitoErrorPassword]);

    const nameOnKeyPress = () => {};

    const emailOnKeyPress = () => {};

    const passwordOnKeyPress = () => {};

    const confirmPasswordOnKeyPress = () => {};

    
    const handleClose = () => {
      setSignInOpen(false);
      openLogin();
      setName("");
      setRegEmail("");
      setRegPassword("");
      setRegConfirmPassword("");
    };
    const onClickRegister = async () => {
      try {
        setLoading(true);

        const signUpResponse = await Auth.signUp({
          username: regEmail.toLowerCase(),
          password: regPassword,
          attributes: {
            email: regEmail,
            name: name,
          },
        });
        if (signUpResponse) {
          alert("Registered Succesfully. Please Verify Using Your Email.");
          setLoading(false);
          setName("");
          setRegEmail("");
          setRegPassword("");
          setRegConfirmPassword("");
        }
      } catch (error) {
        console.log("err", error);
        setLoading(false);
        if (error.code === "InvalidParameterException") {
          setRegCognitoErrorPassword("Password must be at least 8 characters");
        } else {
          setRegCognitoErrorPassword("");
        }
        if (error.code === "UsernameExistsException") {
          setRegCognitoErrorUsername(
            "An account with the given email already exists."
          );
        } else if (error.message === "Invalid email address format.") {
          setRegCognitoErrorUsername("Invalid email address format.");
        } else {
          setRegCognitoErrorUsername("");
        }
      }
    };
    function setRegPasswordFocus(event) {
      if (event.key === "Tab" || event.key === "Enter") {
        passwordInput.current.focus();
      }
    }

    function validateEmail() {
      
      var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

      if (regEmail === "" || !reg.test(regEmail)) {
        setRegEmailTextIssue("Please enter a valid email");
      } else {
        setRegEmailTextIssue("");
      }
    }

    function validatePass() {
      if (regPassword === "") {
        setRegPassTextIssue("Please enter a password");
      } else {
        setRegPassTextIssue("");
      }
    }
    function validateName() {
      if (name === "") {
        setRegNameTextIssue("Please enter a Name");
      } else {
        setRegNameTextIssue("");
      }
    }
    function samePassVerify() {
      if (regConfirmPassword === "") {
        setRegConfrimPassTextIssue("Please enter a password");
      } else {
        setRegConfrimPassTextIssue("");
      }
      if (regPassword !== regConfirmPassword) {
        setRegConfrimPassTextIssue("Passwords don't Match!");
      } else {
        setRegConfrimPassTextIssue("");
      }
    }
    function getSignedUp() {
      if (
        regPassword !== "" &&
        regEmail !== "" &&
        regConfirmPassword !== "" &&
        name !== ""
      ) {
        onClickRegister();
      }
    }

    function passwordClick(event) {
      if (event.key === "Enter") {
        validateName();
        validateEmail();
        validatePass();
        samePassVerify();
        getSignedUp();
      }
    }
    function confirmPasswordClick(event) {
      if (event.key === "Enter") {
        validateName();
        validateEmail();
        validatePass();
        samePassVerify();
        getSignedUp();
      }
    }

    function registerClick() {
      validateName();
      validateEmail();
      validatePass();
      samePassVerify();
      getSignedUp();
    }

    function resetEmailPassIssueText(type) {
      if (type === 0) {
        setRegEmailTextIssue("");
      } else {
        setRegPassTextIssue("");
      }
    }

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={signInOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={signInOpen}>
            <div className={classes.SignInPaper}>
              <span
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <Close />
              </span>
              {loading && (
                <CircularProgress className={landingStyle.spinnerStyle} />
              )}
              <Image
                size="small"
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo-dark.jpg`}
                style={{
                  marginLeft: "170px",
                  marginTop: "20px",
                  width: "200px",
                }}
              />
              <form
                 className={classes.root}
                Validate
                autoComplete="off"
              >
                <label
                  style={{ marginLeft: "80px", marginBottom: "-80px" }}
                  className={landingStyle.labelForIssue}
                >
                  {regNameTextIssue}
                </label>
                <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <EmailIcon
                    className={landingStyle.emailIc}
                    cls1={landingStyle.cls1}
                  />
                  <input
                    style={{
                      textAlign: "center",
                      marginRight: "10%",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                    className={classes.textBox}
                    type="text"
                    required
                    id="standard-required"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyPress={nameOnKeyPress}
                  />
                </div>
                <label
                  style={{ marginLeft: "80px", marginBottom: "-80px" }}
                  className={landingStyle.labelForIssue}
                >
                  {regEmailTextIssue}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <EmailIcon
                    className={landingStyle.emailIc}
                    cls1={landingStyle.cls1}
                  />
                  <input
                    style={{
                      textAlign: "center",
                      marginRight: "10%",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                    className={classes.textBox}
                    type="text"
                    required
                    id="standard-required"
                    placeholder="Email"
                    value={regEmail}
                    onChange={(e) => setRegEmail(e.target.value)}
                    onClick={() => {
                      resetEmailPassIssueText(0);
                    }}
                    onKeyPress={(event) => {
                      emailOnKeyPress();
                      setRegPasswordFocus(event);
                    }}
                  />
                </div>
                <label
                  style={{ marginLeft: "80px", marginBottom: "-80px" }}
                  className={landingStyle.labelForIssue}
                >
                  {regPassTextIssue}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Password
                    className={landingStyle.passIc}
                    fill={landingStyle.lockcolor}
                  />
                  <input
                    style={{
                      textAlign: "center",
                      marginRight: "10%",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                    className={classes.textBox}
                    id="standard-password-input"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={regPassword}
                    onClick={() => {
                      resetEmailPassIssueText(1);
                    }}
                    onChange={(e) => setRegPassword(e.target.value)}
                    onKeyPress={(event) => {
                      passwordOnKeyPress();
                      passwordClick(event);
                    }}
                  />
                </div>
                <label
                  style={{ marginLeft: "80px", marginBottom: "-80px" }}
                  className={landingStyle.labelForIssue}
                >
                  {regConfirmpassTextIssue}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Password
                    className={landingStyle.passIc}
                    fill={config.main_color_2}
                  />
                  <input
                    style={{
                      textAlign: "center",
                      marginRight: "10%",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                    className={classes.textBox}
                    id="standard-password-input"
                    placeholder="Confirm Password"
                    type="password"
                    autoComplete="current-password"
                    value={regConfirmPassword}
                    onClick={() => {
                      resetEmailPassIssueText(1);
                    }}
                    onChange={(e) => setRegConfirmPassword(e.target.value)}
                    onKeyPress={(event) => {
                      confirmPasswordOnKeyPress();
                      confirmPasswordClick(event);
                    }}
                  />
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    width: "360px",
                    textAlign: "center",
                    marginLeft: "90px",
                    color: "rgba(0, 0, 0, 0.4)",
                  }}
                >
                  *Password should be at least 8 characters, contain at least
                  one lowercase letter & one number & one special character.
                </p>
                <br />
                <input
                  type="button"
                  style={{
                    backgroundColor: "white",
                    marginLeft: "220px",
                    marginTop: "20px",
                    color: config.main_color_2,
                    border: "1px solid",
                    height: "30px",
                    width: "80px",
                    borderRadius: "3px",
                    borderColor: config.main_color_2,
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  className={landingStyle.loginbutton}
                  onClick={() => {
                    registerClick();
                  }}
                  value="SignUp"
                />
                <br /> <br />
                <div
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    fontSize: "16px",
                  }}
                  onClick={() => {
                    openLogin();
                  }}
                >
                  Already have an account? Login
                </div>
              </form>
            </div>
          </Fade>
        </Modal>
      </>
    );
  };

  let ForgotPasswordModal = () => {
    useEffect(() => {
      setForgotPasswordEmailTextIssue(forgotPasswordCognitoErrorUsername);
    }, [forgotPasswordCognitoErrorUsername]);

    const forgotPasswordHandler = async () => {
      try {
       
        const forgot = await Auth.forgotPassword(forgotPasswordEmail);
        if (forgot) {
          setResetPasswordOpen(true);
          setOpen(false);
          
        }
      } catch (error) {
        if (error.code === "UserNotFoundException") {
          setForgotPasswordEmailTextIssue("User does not exist");
        } else if (error.code === "LimitExceededException") {
          setForgotPasswordEmailTextIssue(
            "Daily limit  exceeded for forgot password change!"
          );
        } else {
          setForgotPasswordEmailTextIssue("");
        }
      }
     
    };

    function validateEmail() {
     
      var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
     
      if (forgotPasswordEmail === "" || !reg.test(forgotPasswordEmail)) {
        setForgotPasswordEmailTextIssue("Please enter a valid email");
      } else {
        setForgotPasswordEmailTextIssue("");
      }
    }
    function getForgotPass() {
     
      var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      
      if (forgotPasswordEmail !== "" && reg.test(forgotPasswordEmail)) {
        forgotPasswordHandler();
      }
    }

    function forgotClick() {
      validateEmail();
      getForgotPass();
    }

    function resetEmailPassIssueText(type) {
      if (type === 0) {
        setForgotPasswordEmailTextIssue("");
      }
    }

    const handleClose = () => {
      setForgotPasswordOpen(false);
      setOpen(true);
      setForgotPasswordEmail("");
      setForgotPasswordEmailTextIssue("");
    };

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={forgotPasswordOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={forgotPasswordOpen}>
            <div className={classes.forgotPasswordPaper}>
              <span
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              >
                <Close />
              </span>
              {loading && (
                <CircularProgress className={landingStyle.spinnerStyle} />
              )}

              <Image
                size="small"
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo-dark.jpg`}
                style={{
                  marginLeft: "190px",
                  marginBottom: "10px",
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Forgot Password
              </p>
              <form
                className={classes.root}
                Validate
                autoComplete="off"
              >
                <label
                  style={{ marginLeft: "0px" }}
                  className={landingStyle.labelForIssue}
                >
                  {forgotPasswordEmailTextIssue}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <EmailIcon
                    className={landingStyle.emailIc}
                    cls1={landingStyle.cls1}
                  />
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                    className={classes.textBox}
                    type="text"
                    required
                    id="standard-required"
                    placeholder="Email"
                    value={forgotPasswordEmail}
                    onChange={(e) => setForgotPasswordEmail(e.target.value)}
                    onClick={() => {
                      resetEmailPassIssueText(0);
                    }}
                  
                  />
                </div>

                <br />

                <input
                  type="button"
                  style={{
                    backgroundColor: config.main_color_2,
                    color: "white",
                    height: "30px",
                    width: "80px",
                    borderRadius: "3px",
                    marginTop: "10px",
                    border: "none",
                    fontSize: "15px",
                  }}
                  className={landingStyle.loginbutton}
                  onClick={() => {
                    forgotClick();
                  }}
                  value="Next"
                />
              </form>
            </div>
          </Fade>
        </Modal>
      </>
    );
  };

  let ResetPasswordModal = () => {
    useEffect(() => {
      setResetPassVerificationCode(resetPasswordCodeIssue);
    }, [resetPasswordCodeIssue]);

    function resetHandler() {
      if (verificationCode === "") {
        setResetPassVerificationCode("Please enter the code");
      }
      samePassVerify();
    }
    const passwordVerificationHandler = async (event) => {
      try {
        setLoading(true);
        await Auth.forgotPasswordSubmit(
          forgotPasswordEmail,
          verificationCode,
          resetPassword
        );
        swal({
          title: "Success!",
          text: "Changed Password Successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        setLoading(false);
        setResetPasswordOpen(false);
        setForgotPasswordOpen(false);
        setOpen(true);
        setVerificationCode("");
        setResetPassword("");
        setConfirmResetPassword("");
        setResetPassVerificationCode("");
        setResetPasswordCodeIssue("");
        setResetPasswordMatch("");
        setForgotPasswordEmail("");
      } catch (error) {
        setLoading(false);
        console.log("err", error);
        if (error.code === "InvalidParameterException") {
          setResetPasswordCodeIssue("Invalid code entered");
        } else {
          setResetPasswordCodeIssue("");
        }
        if (error.code === "CodeMismatchException") {
          setResetPasswordCodeIssue("Code mismatch");
        } else if (error.code === "InvalidPasswordException") {
          setResetPasswordCodeIssue("Please match the password type.");
        } else {
          setResetPasswordCodeIssue("");
        }
      }
    };

    const passwordOnKeyPress = () => {};

    const VerificationcodeOnKeyPress = () => {};
 
    const handleClose = () => {
      setResetPasswordOpen(false);
      setVerificationCode("");
      setResetPassword("");
      setConfirmResetPassword("");
      setResetPassVerificationCode("");
      setResetPasswordCodeIssue("");
      setResetPasswordMatch("");
    };

    function samePassVerify() {
      var regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

      if (confirmResetPassword === "") {
        setResetPasswordMatch("Please enter a password");
      } else {
        setResetPasswordMatch("");
      }
      if (resetPassword !== confirmResetPassword) {
        setResetPasswordMatch("Passwords don't Match!");
      } else {
        setResetPasswordMatch("");
      }
      if (!regularExpression.test(resetPassword)) {
        setResetPasswordMatch(
          "Password should have 1 uppercase letter, 1 special charecter and should be atleast 8 charecters"
        );
      } else {
        setResetPasswordMatch("");
      }
      passwordVerificationHandler();
    }
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={resetPasswordOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={resetPasswordOpen}>
            <div className={classes.ResetPasswordPaper}>
              <span style={{ float: "right", cursor: "pointer" }}>
                <Close
                  onClick={() => {
                    handleClose();
                  }}
                />
              </span>
              {loading && (
                <CircularProgress className={landingStyle.spinnerStyle} />
              )}
              <Image
                size="small"
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo-dark.jpg`}
                style={{
                  marginLeft: "190px",
                  marginBottom: "20px",
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Password Reset
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.6)",
                  paddingTop: "20px",
                }}
              >
                {" "}
                Please enter the verification code sent to your email
              </p>
              <br />
              <form
                /* onSubmit={handleSubmit(onSubmit)} */ className={classes.root}
                Validate
                autoComplete="off"
              >
                <label
                  style={{ marginLeft: "100px" }}
                  className={landingStyle.labelForIssue}
                >
                  {resetPassVerificationCode}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div style={{ width: "30px", height: "30px" }}></div>
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                   
                    className={classes.textBox}
                    type="text"
                    required
                    id="standard-required"
                    value={verificationCode}
                    placeholder={"Verification Code"}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    onKeyPress={VerificationcodeOnKeyPress}
                    autocomplete="off"
                    
                  />
                </div>
                <label
                  style={{
                    marginLeft: "80px",
                    marginBottom: "-80px",
                    width: "73% !important",
                  }}
                  className={landingStyle.labelForIssue}
                >
                  {resetPasswordMatch}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    padding: "20px 0px",
                  }}
                >
                  {" "}
                  <Password
                    className={landingStyle.passIc}
                    fill={config.main_color_2}
                  />
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                   
                    className={classes.textBox}
                    type="password"
                    required
                    id="standard-required"
                    value={resetPassword}
                    autocomplete="off"
                    placeholder={"New password"}
                    onChange={(e) => setResetPassword(e.target.value)}
                    onKeyPress={passwordOnKeyPress}
                   
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    padding: "20px 0px",
                  }}
                >
                  {" "}
                  <Password
                    className={landingStyle.passIc}
                    fill={config.main_color_2}
                  />
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                   
                    className={classes.textBox}
                    type="password"
                    required
                    id="standard-required"
                    value={confirmResetPassword}
                    autocomplete="off"
                    placeholder={"Confirm password"}
                    onChange={(e) => setConfirmResetPassword(e.target.value)}
                    onKeyPress={VerificationcodeOnKeyPress}
                    
                  />
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    width: "360px",
                    textAlign: "center",
                    marginLeft: "90px",
                    color: "rgba(0, 0, 0, 0.4)",
                  }}
                >
                  *Password should be at least 8 characters, contain at least
                  one lowercase letter & one number & one special character.
                </p>
                <br /> <br />
                <input
                  type="button"
                  onClick={() => {
                    resetHandler();
                  }}
                  style={{
                    backgroundColor: config.main_color_2,
                    marginLeft: "220px",
                    color: "white",
                    height: "30px",
                    width: "100px",
                    borderRadius: "3px",
                    border: "none",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  value="Submit"
                />
              </form>
            </div>
          </Fade>
        </Modal>
      </>
    );
  };

  function ResetPasswordModalFirstTime() {
    useEffect(() => {
      setResetPassVerificationCodeFirst(resetPasswordCodeIssue);
    }, [resetPasswordCodeIssue]);

    function resetHandlerFirst() {
      samePassVerifyFirst();
    }

    const passwordVerificationHandlerFirst = async (event) => {
      try {
        setLoading(true);
        await Auth.signIn(email, password).then((users) => {
          if (users.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(users, confirmResetPasswordFirst, {
              name: name,
            });
          }
        });
        swal({
          title: "Success!",
          text: "Changed Password Successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });

        setLoading(false);
        setResetPasswordOpenFirst(false);
        setResetPasswordOpen(false);
        setForgotPasswordOpen(false);
        setOpen(true);
        setPassword("");

        setResetPasswordFirst("");
        setConfirmResetPasswordFirst("");

        setResetPasswordCodeIssueFirst("");
        setResetPasswordMatchFirst("");
      } catch (error) {
        setLoading(false);
        console.log("err", error);
        if (error.code === "InvalidParameterException") {
          setResetPasswordCodeIssue("Invalid code entered");
        } else {
          setResetPasswordCodeIssue("");
        }
        if (error.code === "CodeMismatchException") {
          setResetPasswordCodeIssue("Code mismatch");
        } else if (error.code === "InvalidPasswordException") {
          setResetPasswordCodeIssue("Please match the password type.");
        } else {
          setResetPasswordCodeIssue("");
        }
      }
    };

    const passwordOnKeyPress = () => {};

    const VerificationcodeOnKeyPress = () => {};

    const handleOpen = () => {
      setResetPasswordOpen(true);
    };

    const handleClose = () => {
      setResetPasswordOpenFirst(false);
      setResetPasswordOpen(false);
      setVerificationCode("");
      setResetPassword("");
      setConfirmResetPassword("");
      setResetPassVerificationCode("");
      setResetPasswordCodeIssue("");
      setResetPasswordMatch("");
    };

    function samePassVerifyFirst() {
      setResetPasswordMatchFirst("");
      var regularExpression =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

      if (confirmResetPasswordFirst === "") {
        setResetPasswordMatchFirst("Please enter a password");
        return;
      }
      if (resetPasswordFirst !== confirmResetPasswordFirst) {
        setResetPasswordMatchFirst("Passwords don't Match!");
        return;
      }
      if (!regularExpression.test(resetPasswordFirst)) {
        setResetPasswordMatchFirst(
          "Password should have 1 uppercase letter, 1 special charecter and should be atleast 8 charecters"
        );
        return;
      }
      passwordVerificationHandlerFirst();
    }

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={resetPasswordOpenFirst}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={resetPasswordOpenFirst}>
            <div className={classes.ResetPasswordPaperFirst}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Close
                  onClick={() => {
                    handleClose();
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
              {loading && (
                <CircularProgress className={landingStyle.spinnerStyle} />
              )}

              <Image
                size="small"
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/logo-dark.jpg`}
                style={{
                  marginLeft: "190px",
                  marginBottom: "20px",
                }}
              />
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Password Reset
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                {" "}
                Please complete reset password
              </p>
              <br />
              <form
                 className={classes.root}
                Validate
                autoComplete="off"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <UsersIcon
                    className={landingStyle.emailIc}
                    cls1={landingStyle.cls1}
                    cls2={landingStyle.cls2}
                  />
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                    type="text"
                    required
                    id="standard-required"
                    placeholder="Name"
                    value={name}
                    autocomplete="off"
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <label
                  style={{ marginLeft: "80px", marginBottom: "-80px" }}
                  className={landingStyle.labelForIssue}
                >
                  {resetPasswordMatchFirst}
                </label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {" "}
                  <Password
                    className={landingStyle.passIc}
                    fill={config.main_color_2}
                  />
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                    }}
                   
                    className={classes.textBox}
                    type="password"
                    required
                    id="standard-required"
                    value={resetPasswordFirst}
                    autocomplete="off"
                    placeholder={"New password"}
                    onChange={(e) => setResetPasswordFirst(e.target.value)}
                    onKeyPress={passwordOnKeyPress}
                   
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {" "}
                  <Password
                    className={landingStyle.passIc}
                    fill={config.main_color_2}
                  />
                  <input
                    style={{
                      textAlign: "left",
                      width: "70%",
                      fontSize: "16px",
                      height: "30px",
                      border: "1px solid whitesmoke",
                      borderRadius: "5px",
                      paddingLeft: "10px",
                      background: "transparent",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }} 
                    className={classes.textBox}
                    type="password"
                    required
                    id="standard-required"
                    value={confirmResetPasswordFirst}
                    placeholder={"Confirm password"}
                    onChange={(e) =>
                      setConfirmResetPasswordFirst(e.target.value)
                    }
                    onKeyPress={VerificationcodeOnKeyPress}
                    
                  />
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    width: "360px",
                    textAlign: "center",
                    marginLeft: "90px",
                    color: "rgba(0, 0, 0, 0.4)",
                  }}
                >
                  *Password should be at least 8 characters, one number & one
                  special character.
                </p>
                <br /> <br />
                <input
                  type="button"
                  onClick={() => {
                    resetHandlerFirst();
                  }}
                  style={{
                    backgroundColor: config.main_color_2,
                    marginLeft: "220px",
                    color: "white",
                    height: "30px",
                    width: "100px",
                    borderRadius: "3px",
                    border: "none",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  value="Submit"
                />
              </form>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  function resetEmailPassIssueText(type) {
    if (type === 0) {
      setForgotPasswordEmailTextIssue("");
    }
  }

  function setPasswordFocus(event) {
   
    if (event.key === "Tab" || event.key === "Enter") {
      
      passwordInput.current.focus();
    }
  }

  function validateEmail() {
    if (email === "" /* || re.test(email) */) {
      setEmailTextIssue("Please enter a valid email");
    } else {
      setEmailTextIssue("");
    }
  }

  function validatePass() {
    if (password === "" /* || re.test(email) */) {
      setPassTextIssue("Please enter a password");
    } else {
      setPassTextIssue("");
    }
  
  }
  async function analyticsAPI(datagot) {
   
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eventtype: "AuthenticatedViaCognito",
        email: datagot.eid,
        emailid: datagot.emailid,
        name: datagot.firstname,
        admin: true,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ANALYTICS_WEB_APP,
        bodyParam
        
      );
      navigate(`/dashboard`);
     
    } catch (error) {
      console.log("getCategoryError", error);
    }
  }

  const onClickRegister = async () => {
    try {
      setLoading(true);

      const signUpResponse = await Auth.signUp({
        username: regEmail.toLowerCase(),
        password: regPassword,
        attributes: {
          email: regEmail,
          name: name,
        },
      });
      if (signUpResponse) {
        alert("Registered Succesfully. Please Verify Using Your Email.");
        setLoading(false);
        setName("");
        setRegEmail("");
        setRegPassword("");
        setRegConfirmPassword("");
      }
    } catch (error) {
      console.log("err", error);
      setLoading(false);
      if (error.code === "InvalidParameterException") {
        setRegCognitoErrorPassword("Password must be at least 8 characters");
      } else {
        setRegCognitoErrorPassword("");
      }
      if (error.code === "UsernameExistsException") {
        setRegCognitoErrorUsername(
          "An account with the given email already exists."
        );
      } else if (error.message === "Invalid email address format.") {
        setRegCognitoErrorUsername("Invalid email address format.");
      } else {
        setRegCognitoErrorUsername("");
      }
    }
  };

  function passwordClick(event) {
    if (event.key === "Enter") {
      validateEmail();
      validatePass();
      getLoggedIn();
    }
  }

  async function onClickLogin() {
   
    try {
      setLoading(true);
      
      const user = await Auth.signIn(email, password);
     
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
       
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setResetPasswordOpenFirst(true);
        setOpen(false);
      } else if (user.challengeName === "MFA_SETUP") {
       
      }
      
      let userdata = user.attributes;
      userdata.eid = user.username;
      userdata.emailid = user.attributes.email;
      setUser(JSON.stringify(userdata));
      let userGroup =  user.signInUserSession.accessToken.payload["cognito:groups"];
     
      if (userGroup === undefined || userGroup === null) {
        getUserDetails(userdata);
      } else {
        for (var i = 0; i < userGroup.length; i++) {
          var role = userGroup[i];
          var value1 = role.indexOf("-");
          let userrole = role.substring(value1 + 1, role.length);
         
          if ( userrole === "Admin" ) {
            let obj1 = {};
            userdata.role = "Admin";
            obj1.firstname = user.attributes.name;
            obj1.eid = user.username;
            obj1.emailid = user.attributes.email;
            userdata.uData = obj1 ;
            userdata.sideactive = '/dashboard';
            dispatch(awsSignIn(userdata));
            dispatch(SignIn());
            navigate(`/dashboard`);
          }
        }
      }
  
      await localStorage.setItem("sessionStore", false);

    } catch (error) {
      setLoading(false);

      if (error.code === "UserNotFoundException") {
        setEmailTextIssue("User does not exist");
      } else if (error.code === "UserNotConfirmedException") {
        setEmailTextIssue("User Not Confirmed");
      } else {
        setEmailTextIssue("");
      }
      if (error.code === "NotAuthorizedException") {
        setPassTextIssue("Invalid Password");
      } else {
        setPassTextIssue("");
      }
    }
  }
  const getUserDetails = async (userdata) => {
   
    const bodyParam = {
      body: {
        emailid: userdata.emailid,
        schema: config.schema,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
   
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getUserDetails",
        bodyParam
      );
      if (response) {
        
        userdata.uData = response.res[0];
        userdata.role = userdata.uData.ur;
        userdata.name = userdata.uData.first_name;
        dispatch(awsSignIn(userdata));
        dispatch(SignIn());
        analyticsAPI(userdata.uData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function getLoggedIn() {
    if (password !== "" && email !== "") {
      onClickLogin();
    }
  }

  function loginClick() {
    validateEmail();
    validatePass();
    getLoggedIn();
  }

  return (
    <div style={{ width: "100vw" }}>
      {SignInModal()}
      {ForgotPasswordModal()}
      {ResetPasswordModal()}
      {ResetPasswordModalFirstTime()}
      {loading === true ? (
        <CircularProgress
          style={{ position: "absolute", top: "48%", left: "48%" }}
        />
      ) : null}
      <div className={landingStyle.maincontainer}>
        <div className={classes.paper}>
          <Image
            size="small"
            src={`https://${
              config.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-dark.jpg`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "auto",
              margin: "auto",
              width: "200px",
              marginBottom: "30px",
            }}
          />
          <p
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "-20px",
              color: "black",
            }}
          >
            Login to your accounts
          </p>
          <form className={classes.root} Validate autoComplete="off">
            <label
              style={{ marginBottom: "-20px" }}
              className={landingStyle.labelForIssue}
            >
              {emailTextIssue}
            </label>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <EmailIcon
                className={landingStyle.emailIc}
                cls1={landingStyle.cls1}
              />
              <input
                style={{
                  textAlign: "left",
                  width: "70%",
                  fontSize: "16px",
                  height: "30px",
                  border: "1px solid whitesmoke",
                  borderRadius: "5px",
                  paddingLeft: "10px",
                  background: "transparent",
                }}
                type="text"
                required
                id="standard-required"
                placeholder="User Name"
                value={email}
                ref={emailInput}
                onClick={() => {
                  resetEmailPassIssueText(0);
                }}
                onChange={(event) => setEmail(event.target.value)}
                onKeyPress={(event) => {
                  emailOnKeyPress();
                  setPasswordFocus(event);
                }}
              />
            </div>
            <br />
            <label
              style={{ marginBottom: "-20px" }}
              className={landingStyle.labelForIssue}
            >
              {passTextIssue}
            </label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Password
                className={landingStyle.passIc}
                fill={config.main_color_2}
              />
              <input
                style={{
                  textAlign: "left",
                  width: "70%",
                  fontSize: "16px",
                  height: "30px",
                  border: "1px solid whitesmoke",
                  borderRadius: "5px",
                  paddingLeft: "10px",
                  background: "transparent",
                }}
                placeholder="Password"
                type="password"
                autoComplete="current-password"
                value={password}
                ref={passwordInput}
                onClick={() => {
                  resetEmailPassIssueText(1);
                }}
                onChange={(event) => setPassword(event.target.value)}
                onKeyPress={(event) => {
                  passwordOnKeyPress();
                  passwordClick(event);
                }}
              />
            </div>
            <br />
            <a
              href
              style={{
                marginBottom: "-10px",
                marginRight: "10%",
                cursor: "pointer",
                fontSize: "14px",
                display: "flex",
                justifyContent: "flex-end",
                textAlign: "right",
              }}
              onClick={() => {
                forgotPasswordSwap();
              }}
            >
              Forgot password?
            </a>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "50px  ",
              }}
            >
              <input
                type="button"
                className={landingStyle.loginbutton}
                style={{
                  backgroundColor: config.main_color_2,
                  color: "white",
                  height: "30px",
                  width: "110px",
                  borderRadius: "3px",
                  border: "none",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
                onClick={() => {
                  loginClick();
                }}
                value="Login"
              />
             
            </div>
          </form>
          {/* <div className={landingStyle.faqholder}>
            <Link to="/faqs" className={landingStyle.faqlink}>
              FAQ
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
